  export default function ErrorMessage (err) {
    var text=err.message;

    if(err.code==="300"||err.code==="301"||err.code==="302"||err.code==="303"||err.code==="304"||err.code==="305"||err.code==="200"|| err.code==="10")
    {
      text="You don't have permission for this request. Check with your system administrator";
    }
    else if(err.code==="201"){
      text="Not Allow Direct Access To System Table";
    }
    else if(err.code==="200"){
      text="Data Not Found";
    }
    else if(err.code === "1101"){
      text="Please end the active session before submitting timesheet";
    }
    else if(err.code === '307' || err.code ==='308')
    {
      text="Data Already Exists";
    }

    return (text);
  }