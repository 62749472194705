import React, {useState} from 'react';
import { 
  List,
  ListItem,
  ListItemSecondaryAction,
  Grid,
  Typography
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import '../style.css';

const AddMaterial = (props) => {
  const [newValue, setNewValue] = useState([]);
  const [quantity] = useState(0);
  
  // TODO: set newValue with the value 'undefined' every time the user clicks the add material button
  function handleChange(newData){
    setNewValue(newData);
  }

  /**
   * TODO: The first time when the user clicks the add icon, default newValue = [], so newValue != undefined and runs the addMaterial function from props with parameter 1 = newValue(default = []) and parameter 2 = quantity(default = 0) ==> then the return result (undefined) is passed to the handleChange function, the next time when the user clicks the add icon it will run the addMaterial function from props by sending 1 parameter with a null value
   * * This function is executed when the user clicks the add material button
   * @param {event} e 
   */
  const handleSubmit = e => {
    e.preventDefault();
    if(newValue === undefined)
    {
      handleChange(props.addMaterial(null));
    }
    else
    {
      handleChange(props.addMaterial(newValue, quantity));
    }
  }

  return(
    <form>
      <List dense={true}>
        <ListItem>
          <Grid  container spacing={0}>
            <Grid item xs={9}>
              <Typography>Materials</Typography>
            </Grid>
          </Grid>
          <ListItemSecondaryAction>
            <AddCircleIcon id="iconButton" className="add_material_iconButton" color="action" style={{ fontSize: 20 }} onClick={handleSubmit}/>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </form>
  );
};

export default AddMaterial;
