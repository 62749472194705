import React from 'react';
import ReactDOM from 'react-dom';
// import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { SnackbarProvider } from 'notistack';
import App from './App';
// import DisconnectView from './views/errors/DisconnectionView';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '3217177457a6478a1e7f4fb60d2f16a5',
  plugins: [new BugsnagPluginReact()]
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

ReactDOM.render((
  <ErrorBoundary>
    <BrowserRouter>
      <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} autoHideDuration={10000}>
        <App />                         
      </SnackbarProvider>
    </BrowserRouter>
  </ErrorBoundary>
), document.getElementById('root'));

// ReactDOM.createRoot(
//   document.getElementById('root')
// ).render(
//   <ErrorBoundary>
//     <BrowserRouter>
//       <SnackbarProvider 
//         maxSnack={3} 
//         anchorOrigin={{ vertical: "bottom", horizontal: "right" }} 
//         autoHideDuration={10000}
//       >
//         <App />
//       </SnackbarProvider>
//     </BrowserRouter>
//   </ErrorBoundary>
// );

serviceWorker.unregister();
