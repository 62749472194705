
import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardActions,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemSecondaryAction,
  Divider,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Chip,
  Tooltip,
  Drawer
} from '@material-ui/core';
import {
  useStylesSessionList,
  CustomTooltip,
  Accordion,
  CustomListItem,
  MapButton,
} from './style.js';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import directus from '../../services/directus';
import InfoIcon from '@material-ui/icons/Info';
import ClearIcon from '@material-ui/icons/Clear';
import DoneIcon from '@material-ui/icons/Done';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import MapIcon from '@mui/icons-material/Map';
import SessionListDetails from './SessionListDetails';
import EditSessionList from '../SessionCRUD/EditSessionList';
import EditSessionListDetails from '../SessionCRUD/EditSessionListDetails';
import ApproveRejectButton from './ApproveRejectButton';
import ApproveRejectButtonTimesheet from '../Timesheet/ApproveRejectButton';
import TimesheetEvent from './TimesheetEvent';
import moment from 'moment';
import linq from "linq";
import useStateRef from "react-usestateref";
import { withStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import EventEmitter from 'src/utils/EventEmitter';
import LocationMap from './LocationMap.js';

import { useLoadScript } from "@react-google-maps/api";

let defaultExpanded = true;
var countHidden = [];
var countValue = [];

const AccordionSummary = withStyles({
  root: {
    marginBottom: 2,
    minHeight: 35,
    height: 35,
    '&$expanded': {
      minHeight: 35,
      height: 35
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const SessionList = React.forwardRef((props, ref) => {
  // TODO: get data from the parent and then send its value as a parameter to the changeSessionData func
  useImperativeHandle(ref, () => ({
    getAlert(data) {
      changeSessionData(data);
    }
  }));

  let code = 0;
  let disableButton = false;
  let enableEdit = false;
  let enableCheckbox = false;
  let sessionGroup = [];
  let sessionGroupTime = 0;
  let materialId = [];
  let equipmentId = [];
  let notFinish = true;
  let path = window.location.pathname.split('/')[1];
  let Total = 0;

  const classes = useStylesSessionList();
  const childRef = useRef();
  const [checkboxValueList] = React.useState([]);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [hide, setHide] = React.useState(false);
  const [isDelete, setIsDelete] = React.useState(false);
  const [selectedDate, setSelectedDate, selectedDateRef] = useStateRef(null);
  const [autoActivity, setAutoActivity] = React.useState(null);
  const [autoCustomer, setAutoCustomer] = React.useState(null);
  const [editStartTime, setEditStartTime, editStartTimeRef] = useStateRef(null);
  const [editEndTime, setEditEndTime, editEndTimeRef] = useStateRef(null);
  const [editBreakStartTime, setBreakEditStartTime, editBreakStartTimeRef] = useStateRef(null);
  const [editBreakEndTime, setBreakEditEndTime, editBreakEndTimeRef] = useStateRef(null);
  const [editDuration, setEditDuration, editDurationRef] = useStateRef(null);
  const [editSmoko, setEditSmoko] = React.useState(null);
  const [editFullDay, setEditFullDay] = React.useState(null);
  const [editPublicHoliday, setEditPublicHoliday] = React.useState(null);
  const [activity] = React.useState([]);
  const [customer] = React.useState([]);
  const [loadingEdit, setLoadingEdit] = React.useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [sessionDataGroup, setSessionDataGroup] = useState([]);
  const [equipmentExist, setEquipmentExist] = useState([]);
  const [materialExist, setMaterialExist] = useState([]);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [totalMinutesTimesheet, setTotalMinutesTimesheet, totalMinutesTimesheetRef] = useStateRef(props.timesheets.total_minutes);
  const [expandedGroup, setExpandedGroup] = React.useState([]);
  const [expandedSession, setExpandedSession] = React.useState([]);
  const [anchor, setAnchor] = React.useState(false);

  var enableTimesheetBankHours = JSON.parse(window.localStorage.getItem('config_ts')).enable_timesheet_bank_hours;
  var enableGPS = JSON.parse(window.localStorage.getItem('config_ts')).enable_gps;
  var total_duration_with_break_time = JSON.parse(window.localStorage.getItem('configuration')).total_duration_with_break_time;

  const { enqueueSnackbar } = useSnackbar();

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDmyzTQFyUm3ZNXU1_SAz6MViHXB8Quk6w" // Add your API key
  });

  // TODO: close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * @param DataValue is session data from the timesheet being reviewed
   * TODO: 
   * * 1. Set the sessionData state value, with the session data value obtained from the parameter, by changing the readonly, select, or checkedStatus value in the session according to checking the conditions that have been determined
   * * 2. Set materialExist and equipmentExist state if session data has it
   * * 3. Set sessionDataGroup by grouping sessions based on dates, then set the title and time for each session data group
   * * 4. Set expandedGroup and expandedSession state with 'true' values, with as many [key, value] pairs as there are data
   */
  function changeSessionData(DataValue) {
    setSessionData(
      DataValue.map((d) => {
        if (d.status === 'pending' || d.status === 'leave_pending') {
          return {
            readonly: false,
            select: false,
            ...d
          };
        }
        else {
          let readonly = false;
          let checkedStatus;
          if (d.status === 'approved' || d.status === 'leave_approved' || d.status === 'rejected' || d.status === 'leave_rejected' || d.status === 'sent') {
            readonly = true;
            if (d.status === 'approved' || d.status === 'leave_approved' || d.status === 'sent') {
              checkedStatus = true;
            }
            else {
              checkedStatus = false;
            }
          }
          return {
            checkedStatus: checkedStatus,
            readonly: readonly,
            ...d
          };
        }
      })
    );

    let count = 0;
    DataValue.map((d, index) => {
      if (index < 2 && index > 0) {
        if (moment(d.session_date).format('L') !== moment(DataValue[index - 1].session_date).format('L')) {
          sessionGroupTime = 0;
        }
      }

      if (d.duration === 0) {
        if (d.session_type.include_in_total === true) {
          if (d.session_end_time !== null) {
            if (total_duration_with_break_time) {
              sessionGroupTime = sessionGroupTime + durationTimeDay(d.session_start_time, d.session_end_time);
            }
            else {
              if (d.break_time === 0 && (d.break_end_time !== null && d.break_start_time !== null)) {
                sessionGroupTime = sessionGroupTime + durationTimeDay(d.session_start_time, d.session_end_time) - durationTimeDay(d.break_start_time, d.break_end_time);
              }
              else {
                sessionGroupTime = sessionGroupTime + durationTimeDay(d.session_start_time, d.session_end_time) - d.break_time;
              }
            }
          }
        }
      }
      else {
        if (d.session_type.include_in_total === true) {
          sessionGroupTime = sessionGroupTime + Math.floor(d.duration);
        }
      }

      if (index === 0) {
        sessionGroup[count] = {
          title: moment(d.session_date).format('dddd') + ", " + moment(d.session_date).format('DD MMMM'),
          time: durationWords(sessionGroupTime),
          ...d
        }
      }
      else if (index !== 0) {
        if (moment(d.session_date).format('L') !== moment(DataValue[index - 1].session_date).format('L')) {
          count = count + 1;
          sessionGroup[count] = {
            title: moment(d.session_date).format('dddd') + ", " + moment(d.session_date).format('DD MMMM'),
            time: durationWords(sessionGroupTime),
            ...d
          }
        }
        else {
          sessionGroup[count] = {
            title: moment(d.session_date).format('dddd') + ", " + moment(d.session_date).format('DD MMMM'),
            time: durationWords(sessionGroupTime),
            ...d
          }
        }

        if (index + 1 < DataValue.length) {
          if (moment(d.session_date).format('L') !== moment(DataValue[index + 1].session_date).format('L')) {
            sessionGroupTime = 0;
          }
        }
      }

      d.resources.map(resourceData => {
        if (resourceData.resource) {
          if (resourceData.resource.type === 'chemical') {
            materialId[index] = 1;
          }
          else if (resourceData.resource.type === 'equipment') {
            equipmentId[index] = 1;
          }
        }
        return null;
      });
      return null;
    });

    updateDuration();
    setMaterialExist(materialId);
    setEquipmentExist(equipmentId);
    setSessionDataGroup(sessionGroup);
    setExpandedGroup(sessionGroup ? Array.from(Array(sessionGroup.length).fill(true)) : '');
    setExpandedSession(DataValue ? Array.from(Array(DataValue.length).fill(true)) : '');
  }

  /**
   * TODO: show or hide the button loading depends on param value
   * @param {boolean} value is true or false
   */
  function changeButtonLoading(value) {
    setButtonLoading(value);
  }

  /**
   * TODO: If 'started' is greater than 'ended', it means 'end time' on a different day or the following day, then calculate the duration with 'start time' and 'end time' on different days, but if 'started' less than 'ended' it means it is still on the same day, so calculate the duration with the time on the same day
   * @param {time} started = session start time
   * @param {time} ended = session end time
   * @returns if the duration is less than one hour only return a total of minutes, but if the duration is equal to or greater than one hour return total of hours plus a total of minutes
   */
  function durationTimeDay(started, ended) {
    if (ended != null) {
      var dif;
      if (started > ended) {
        dif = moment.duration(moment(moment('1991-01-02T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        dif = moment.duration(moment(moment('1991-01-01T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }

      if (dif.hours() === 0) {
        return (parseInt(dif.minutes()));
      }
      else {
        return ((parseInt(dif.hours()) * 60) + parseInt(dif.minutes()));
      }

    }
  }

  /**
   * TODO: calculate the total duration, starting from start_time to end_time session data
   * @param {time} started is start_time session
   * @param {time} ended is end_time session
   */
  function durationTime(started, ended) {
    if (ended != null) {
      var diff;
      if (started > ended) {
        diff = moment.duration(moment(moment('1991-01-02T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }
      else {
        diff = moment.duration(moment(moment('1991-01-01T' + ended).format('YYYY-MM-DDTHH:mm:00')).diff(moment('1991-01-01T' + started).format('YYYY-MM-DDTHH:mm:00')));
      }

      return diff.hours() * 60 + diff.minutes();

    }
    return 0;
  }

  /**
   * TODO: change the mins param to absolute value, then calculate hours and minutes. If hour = 0 just returns the total of minutes, but if the hour is not 0 return the total of hours and minutes.
   * @param {number} mins total session duration
   * @param {boolean} force_hours if the session has a value of include_in_total, this param will have a 'true' value 
   */
  function durationWords(mins, force_hours = false) {
    mins = Math.abs(mins);
    var hours = Math.floor(mins / 60);
    var minutes = Math.floor(mins) % 60; // remove seconds

    if (hours === 0 && !force_hours) {
      return (<span><b>{minutes}</b> min</span>);
    }
    else {
      return (<span><b>{hours}</b> hr <b>{minutes}</b> min</span>);
    }
  }

  /**
   * * check if the session list is opened on the approvals page or the timesheet page
   * * if approvals =>
   * *  > set enableEdit to true, if the timesheet status is not 'pending' and 'rejected'
   * *  > set enableCheckbox to true, if the timesheet status is 'awaiting_approval'
   * * if timesheet =>
   * *  > set enableEdit to true, if the timesheet status is not 'awaiting_approval' and 'rejected'
   * *  > set disableButton to true
   */
  if (path === 'approvals') {
    if (props.timesheets.status !== 'pending' && props.timesheets.status !== 'rejected') {
      enableEdit = true;
    }
    if (props.timesheets.status === 'awaiting_approval') {
      enableCheckbox = true;
    }
  }
  else if (path === 'timesheet') {
    if (props.timesheets.status !== 'awaiting_approval' && props.timesheets.status !== 'rejected') {
      enableEdit = true;
    }

    disableButton = true;
  }

  /**
   * TODO: split h and m from timeString parameters, check if any of the splits results have a length greater than 1:
   * * -> if true => if the results of the split parts.length = 1 (meaning there is only a value for minutes) then it returns only the value of the total minutes, but other than that it returns the results of calculating the hours multiplied by 60 plus the total minutes
   * * -> if false => returns the hour and minute calculation results
   * @param {*} timeString has the value equal to the editDuration state, if the editDuration state is not null or empty
   */
  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if (parts.length > 1 || parts2.length > 1) {
      if (parts.length === 1) {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
      }
    }
    else {
      var decimalTimeString = timeString;
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  /**
   * * This function is executed when leave_status on session data is not 'pending' and is not empty
   * @param {string} data :
   * * has the value 'approved' if approved by a supervisor
   * * has the value 'approved_hrd' if approved by a admin
   * @returns information on whether the leave has been approved or rejected
   */
  const leave_status = (data) => {
    if (data === 'approved') {
      return 'Approved by Supervisor from leave request';
    }
    else if (data === 'approved_hrd') {
      return 'Approved by Payroll from leave request';
    }
    else {
      return 'Rejected from leave request';
    }
  }

  // TODO: get timesheets items from directus based on end_time, start-time, and employee id of the timesheet being reviewed, then update the totalMinutesTimesheet state value with the latest total_minutes value from that timesheet data
  const updateDuration = async () => {
    try {
      let TempTimesheetsMinutes = await directus.getItems('timesheets', {
        fields: 'id, total_minutes',
        filter: {
          end_time: { gte: props.timesheets.start_time },
          start_time: { lte: props.timesheets.end_time },
          employee: { eq: props.timesheets.employee.id },
        },
      });
      setTotalMinutesTimesheet(TempTimesheetsMinutes.data[0].total_minutes);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  /**
   * TODO: set expandedGroup based on the id or index of the group the user clicked on, becomes false (if the user wants to close) or true (if the user wants to open)
   * * This function is executed when the user clicks the accordion from the session data group to show all sessions or to hide
   * @param {number} id is the number or index of the group that the user clicked on
   */
  const handleChangeGroup = (panel, id) => (event, newExpanded) => {
    let newArrGroup = [...expandedGroup];
    newArrGroup[id] = newExpanded ? true : false;
    setExpandedGroup(newArrGroup);
  };

  /**
   * TODO: set expandedSession based on the id or index of the session data the user clicked on, becomes false (if the user wants to close) or true (if the user wants to open)
   * * This function is executed when the user clicks the accordion from the session data to show session or to hide
   * @param {number} id is the number or index of the session data that the user clicked on
   */
  const handleChangeSession = (panel, id) => (event, newExpanded) => {
    let newArrSession = [...expandedSession];
    newArrSession[id] = newExpanded ? true : false;
    setExpandedSession(newArrSession);

  };

  // TODO: show or hide all session description
  const handleChangeAll = () => (event, newExpanded) => {
    let newArrGroup = [...expandedGroup];
    let newArrSession = [...expandedSession];

    defaultExpanded = defaultExpanded === true ? false : true;

    for (let i = 0; i < expandedGroup.length; i++) {
      newArrGroup[i] = defaultExpanded;
      newArrSession[i] = defaultExpanded;
    }
    setExpandedGroup(newArrGroup);
    setExpandedSession(newArrSession);
  };

  /**
   * TODO: determine the start and end for break time in the middle between the start_time and end_time roster according to the break value of the roster
   * @param {time} a start time roster
   * @param {time} b end time roster
   * @param {number} c break roster
   */
  function getMiddleStartEndTime(a, b, c) {
    var basedate = '1/1/1991 ';
    var momentStart = moment(basedate + a);
    var momentEnd = moment(basedate + b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes() / 2 - (c / 2);
    var start = moment(basedate + a).add(diff, 'minutes').format();
    var end = moment(start).add(c, 'minutes').format();

    return { start_time: moment(start), end_time: moment(end) };
  }

  /**
   * TODO: calculates the time duration from param a to b and returns the total minutes of that duration
   * @param {time} a start
   * @param {time} b end
   */
  function getDuration(a, b) {
    var momentStart = moment(a);
    var momentEnd = moment(b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes();

    return diff;
  }

  return (
    <div>
      <Card className={classes.card} elevation={0}>
        <CardContent className={classes.cardHeader}>
          <List dense={true} disablePadding={true}>
            <ListItem>
              {/* HEADER */}
              <ListItemText primary={
                <Grid container spacing={0} justifyContent="flex-start">
                  <Grid item xs={3} className={classes.grid3}>
                    <Typography>Customer</Typography>
                  </Grid>
                  <Grid item xs={3} className={classes.grid3}>
                    <Typography>Activity</Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.grid1}>
                    <Typography>Start</Typography>
                  </Grid>
                  <Grid item xs={1} className={classes.grid1}>
                    <Typography>End</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>Duration</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography>Break</Typography>
                  </Grid>
                </Grid>
              } />

              <ListItemSecondaryAction style={{ marginBottom: '2px' }}>
                {disableButton ?
                  ''
                  :
                  enableCheckbox ?

                    <Checkbox
                      onChange={e => {
                        let checked = e.target.checked;
                        //* to tick all checkbox
                        setSessionData(
                          sessionData.map(d => {
                            d.select = checked;
                            return d;
                          })
                        );
                      }}
                      disabled={props.status === 'deleted'}
                    />
                    : ''}

                {expandedGroup ?
                  expandedGroup.includes(true) ?
                    <ExpandLessIcon onClick={handleChangeAll()} style={{ color: 'black', verticalAlign: 'middle', marginLeft: '5px' }} />
                    :
                    <ExpandMoreIcon onClick={handleChangeAll()} style={{ color: 'black', verticalAlign: 'middle', marginLeft: '5px' }} />
                  : ''
                }
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </CardContent>
        <CardContent className={classes.cardContent}>
          <List className={classes.root} subheader={<li />}>
            {sessionDataGroup ?
              sessionDataGroup.map((sectionId, number) => {
                return (
                  <li key={`section-${number}`} className={classes.listSection}>
                    <ul className={classes.ul}>
                      <Accordion
                        expanded={expandedGroup[number] ? expandedGroup[number] : false}
                        onChange={handleChangeGroup(`panel_${number}`, number)}
                      >
                        <AccordionSummary
                          className={classes.subHeader}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel_${number}d-content`}
                          id={`panel_${number}d-header`}
                        >
                          <Grid container spacing={0} justifyContent="flex-start">
                            <Grid item xs={3} className={classes.grid3}>
                              <Typography id={`section_title_${number}`}><b>{sectionId.title}</b></Typography>
                            </Grid>
                            <Grid item xs={3} className={classes.grid3custom}></Grid>
                            <Grid item xs={1} className={classes.grid1}></Grid>
                            <Grid item xs={1} className={classes.grid1}></Grid>
                            <Grid item xs={2} className={classes.grid2}>
                              <Typography id={`section_duration_${number}`}> {sectionId.time}</Typography>
                            </Grid>
                            <Grid item xs={2} className={classes.grid2}></Grid>
                          </Grid>
                          {disableButton ?
                            '' :
                            enableCheckbox ?
                              <ListItemSecondaryAction style={{ right: '45px' }}>
                                <Checkbox
                                  onClick={(event) => event.stopPropagation()}
                                  onFocus={(event) => event.stopPropagation()}
                                  onChange={e => {
                                    let checked = e.target.checked;
                                    //* to tick or untick session data group
                                    setSessionData(
                                      sessionData.map(d => {
                                        if (moment(d.session_date).format('L') === moment(sectionId.session_date).format('L')) {
                                          d.select = checked;
                                          return d;
                                        }
                                        else {
                                          return d;
                                        }
                                      })
                                    );
                                  }}
                                  disabled={props.status === 'deleted'}
                                />
                              </ListItemSecondaryAction> : ''}
                        </AccordionSummary>

                        {sessionData.map((session, index) => {
                          if (moment(session.session_date).format('L') === moment(sectionId.session_date).format('L') && (session.session_type.include_in_total || session.session_type.internal)) {
                            let duration;

                            // TODO: calculates the duration value based on total_duration_with_break_time in the session data, which is null or not
                            if (total_duration_with_break_time) {
                              if (session.duration === 0) {
                                duration = durationTime(session.session_start_time, session.session_end_time);
                              }
                              else {
                                duration = session.duration;
                              }
                            }
                            else {
                              if (session.duration === 0) {
                                if (session.break_time === 0 && (session.break_end_time !== null && session.break_start_time !== null)) {
                                  duration = durationTime(session.session_start_time, session.session_end_time) - durationTime(session.break_start_time, session.break_end_time);
                                }
                                else {
                                  duration = durationTime(session.session_start_time, session.session_end_time) - session.break_time;
                                }
                              }
                              else {
                                if (session.break_time === 0 && (session.break_end_time !== null || session.break_start_time !== null)) {
                                  duration = session.duration - durationTime(session.break_start_time, session.break_end_time);
                                }
                                else {
                                  duration = session.duration - session.break_time;
                                }
                              }
                            }


                            if (session.session_type.include_in_total) Total += duration;

                            if (session.end_time === null) { notFinish = false }

                            /**
                             * TODO: show or hide edit fiture
                             * @param {number} index is the index of the session data that the user wants to edit
                             * @param {boolean} value is true or false => show or hide edit fiture
                             */
                            async function editMode(index, value) {
                              setIsEdit([]);
                              setIsEdit({ [index]: value });
                              setHide(value);
                              setAutoActivity(null);
                              setAutoCustomer(null);
                              setLoadingEdit(false);
                            }

                            /**
                             * TODO: opens or closes the confirmation dialog (delete session data)
                             * @param {number} index is the index of the session data
                             * @param {boolean} value :
                             * * has the value 'true' : if the user clicks the Delete session icon button
                             * * has the value 'false' : if the user clicks the 'No' button in the dialog confirmation deletes session data or when the dialog is onClose
                             */
                            function deleteMode(index, value) {
                              setIsDelete([]);
                              setIsDelete({ [index]: value });
                            }

                            /**
                             * TODO: set selectedDate state with value from parameter value and emit an event with the name 'compareDate' and set selectedDate, editStartTime, and editEndTime values ​​in it
                             * @param {date} value The session date that the user changes when editing the session
                             */
                            function changeSelectedDate(value) {
                              setSelectedDate(value);

                              EventEmitter.emit('compareDate', {
                                selectedDate: moment(selectedDateRef.current).format('YYYY-MM-DD'),
                                editStartTime: editStartTimeRef.current,
                                editEndTime: editEndTimeRef.current,
                              });
                            }

                            /**
                             * TODO: set activity data change to autoActivity state, according to activity data that the user selected when editing the session
                             * @param {object} value Activity data selected by the user when editing the session
                             */
                            function changeAutoActivity(value) {
                              setAutoActivity(value);
                            }

                            /**
                             * TODO: set customer data change to autoCustomer state, according to customer data that the user selected when editing the session
                             * @param {object} value Customer data selected by the user when editing the session
                             */
                            function changeAutoCustomer(value) {
                              setAutoCustomer(value);
                            }

                            /**
                             * TODO: set editStartTime to null if the received parameter value is an invalid date, but if valid set editStartTime equal to the parameter value. After that, emit an event with the name 'compareRoster' and set editStartTime and editEndTime values ​​in it
                             * @param {time} value The start time value that the user changes when editing the session
                             */
                            function changeEditStartTime(value) {
                              if (value === 'Invalid date Invalid date' || value === session.session_date + ' Invalid date') {
                                setEditStartTime(null);
                              }
                              else {
                                setEditStartTime(value);
                              }

                              EventEmitter.emit('compareRoster', {
                                editStartTime: editStartTimeRef.current,
                                editEndTime: editEndTimeRef.current,
                              });
                            }

                            /**
                             * TODO: set editEndTime to null if the received parameter value is an invalid date, but if valid set editEndTime equal to the parameter value. After that, emit an event with the name 'compareRoster' and set editStartTime and editEndTime values ​​in it
                             * @param {time} value The start time value that the user changes when editing the session
                             */
                            function changeEditEndTime(value) {
                              if (value === 'Invalid date Invalid date' || value === session.session_date + ' Invalid date') {
                                setEditEndTime(null)
                              }
                              else {
                                setEditEndTime(value);
                              }

                              EventEmitter.emit('compareRoster', {
                                editStartTime: editStartTimeRef.current,
                                editEndTime: editEndTimeRef.current,
                              });
                            }

                            /**
                             * TODO: set the time change in the 'break start time' column to breakEditStartTime state, when the user edits the session data
                             * @param {time} value Time changes, which are inputted by the user in the 'break start time' column
                             */
                            function changeEditBreakStartTime(value) {
                              if (value === 'empty') {
                                setBreakEditStartTime('empty');
                              }
                              else {
                                setBreakEditStartTime(value);
                              }
                            }

                            /**
                             * TODO: set the time change in the 'break end time' column to breakEditEndTime state, when the user edits the session data
                             * @param {time} value Time changes, which are inputted by the user in the 'break end time' column
                             */
                            function changeEditBreakEndTime(value) {
                              if (value === 'empty') {
                                setBreakEditEndTime('empty');
                              }
                              else {
                                setBreakEditEndTime(value);
                              }
                            }

                            /**
                             * TODO: set break duration change to editSmoke state
                             * @param {number} value Change in break duration
                             */
                            function changeEditSmoko(value) {
                              setEditSmoko(value);
                            }

                            /**
                             * TODO: set break duration change to editDuration state
                             * @param {number} value Change in break duration
                             */
                            function changeEditDuration(value) {
                              setEditDuration(value);
                            }

                            /**
                             * TODO: set editFullDay to true or false, depending on whether the user tick or untick the checkbox 'full day'
                             * @param {boolean} value 
                             */
                            function changeEditFullDay(value) {
                              setEditFullDay(value);
                            }

                            /**
                             * TODO: set editPublicHoliday to true or false, depending on whether the user tick or untick the checkbox 'Public Holiday'
                             * @param {boolean} value 
                             */
                            function changeEditPublicHoliday(value) {
                              setEditPublicHoliday(value);
                            }

                            /**
                             * TODO:
                             * * 1. Check while editing the session whether the user ticks on the 'FullDay' checkbox: if true => then set editStartTime, editEndTime, breakEditStartTime, breakEditEndTime, and duration based on roster data obtained from directus. But if no roster data is obtained or if the roster data obtained has 'start time' or 'end time' = null, then set editStartTime, editEndTime, breakEditStartTime, and breakEditEndTime with a null value and set duration with the default_duration value from session_type in the edited session
                             * 
                             * * 2. Check when editing a session whether the user unticks the checkbox that appears when the session type of the session being edited is 'Public Holiday': if yes => set editStartTime, editEndTime, breakEditStartTime, and breakEditEndTime with a null value and set duration with the default_duration value from session_type in the edited session
                             * 
                             * * 3. If when editing the session the user edits the duration column but does not edit the start time and end time: it will change the start time and end time to null and change the duration value which is input by the user in directus
                             * 
                             * * 4. If when editing the session the user edits the session date or edits the start time or edits the end time or doesn't edit at duration => then check the session data that overlaps from changing the date or start time or end time and edit that data and other data that changes on directus
                             * 
                             * * 5. If when editing the session the user does not edit the session date, start time, and also the end time, but the user edits session duration or other data => then edits the data that changes in the session in directus
                             * 
                             * * 6. Finally, set editStartTime, editEndTime, editDuration, breakEditEndTime, breakEditStartTime, selectedDate, autoActivity, autoCustomer, editSmoko, editFullDay to = null, then run the callChild function by sending the edited session data as its parameter
                             * 
                             * @param {number} index Index session yang diedit
                             * @param {object} session Session data yang diedit
                             * @param {id} customerId Customer id if the session is with a customer, if it doesn't have a customer it will be null
                             * @param {id} activityId Activity id if the session is with a activity, if it doesn't have a activity it will be null
                             */
                            const submitEdit = async (index, session, customerId, activityId) => {
                              setLoadingEdit(true);

                              const session_fields = 'id, employee, session_type, timesheet, session_date, session_end_time, session_start_time, entry_number, activity, customer';

                              let editData;

                              if (editFullDay === true) {
                                try {
                                  var rosterList = await directus.getItems('roster',
                                    {
                                      fields: 'break, day, start_time, end_time, ordinary_hours',
                                      filter: {
                                        employee: { eq: props.employeeIdChosen },
                                        day: { eq: selectedDateRef.current ? moment(selectedDateRef.current).format('dddd').toLowerCase() : moment(session.session_date).format('dddd').toLowerCase() },
                                        status: { eq: 'published' },
                                      },
                                    });
                                }
                                catch (e) {
                                  enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
                                }

                                if (rosterList.data.length > 0 && session.session_type.start_end_time_from_roster) {
                                  rosterList.data.map((data) => {
                                    if (data.start_time !== null && data.end_time !== null) {
                                      let end_time = '';
                                      end_time = moment('1/1/1991 ' + data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");

                                      setEditStartTime(moment(session.session_date + ' ' + data.start_time).format("YYYY-MM-DD HH:mm:ss"));
                                      setEditEndTime(moment(session.session_date + ' ' + end_time).format("YYYY-MM-DD HH:mm:ss"));
                                      setBreakEditStartTime(getMiddleStartEndTime(data.start_time, end_time, data.break).start_time.format("HH:mm:ss"));
                                      setBreakEditEndTime(getMiddleStartEndTime(data.start_time, end_time, data.break).end_time.format("HH:mm:ss"));
                                      setEditDuration(null);
                                    }
                                    else {
                                      setEditStartTime(null);
                                      setEditEndTime(null);
                                      setEditDuration(session.session_type.default_duration);
                                      setBreakEditStartTime(null);
                                      setBreakEditEndTime(null);
                                    }
                                  })
                                }
                                else {
                                  setEditStartTime(null);
                                  setEditEndTime(null);
                                  setEditDuration(session.session_type.default_duration);
                                  setBreakEditStartTime(null);
                                  setBreakEditEndTime(null);
                                }
                              }
                              else if (editPublicHoliday === false) {
                                setEditStartTime(null);
                                setEditEndTime(null);
                                setEditDuration(session.session_type.default_duration);
                                setBreakEditStartTime(null);
                                setBreakEditEndTime(null);
                              }

                              if (editDurationRef.current !== '' && editDurationRef.current !== null && editDurationRef.current !== '0h 0m' && (editStartTimeRef.current === null && editEndTimeRef.current === null)) {
                                editData = {
                                  activity: autoActivity ? autoActivity.id : activity[index] ? activity[index].id : activityId,
                                  customer: autoCustomer ? autoCustomer.id : customer[index] ? customer[index].id : customerId,
                                  session_date: selectedDateRef.current ? moment(selectedDateRef.current).format('YYYY-MM-DD') : session.session_date,
                                  session_start_time: null,
                                  session_end_time: null,
                                  duration: editDurationRef.current ? Number.isInteger(editDurationRef.current) ? editDurationRef.current : parseTimeSpan(editDurationRef.current) : 0,
                                  break_time: editSmoko ? editSmoko : session.break_time ? session.break_time : 0
                                }

                                if (session.session_type.show_break_start_end_time) {
                                  editData = {
                                    ...editData,
                                    break_start_time: editBreakStartTimeRef.current === 'empty' || editBreakStartTimeRef.current === null ? null : editBreakStartTimeRef.current ? editBreakStartTimeRef.current : session.break_start_time,
                                    break_end_time: editBreakEndTimeRef.current === 'empty' || editBreakEndTimeRef.current === null ? null : editBreakEndTimeRef.current ? editBreakEndTimeRef.current : session.break_end_time,
                                  }
                                }
                              }
                              else if (selectedDateRef.current || editStartTimeRef.current || editEndTimeRef.current || editDurationRef.current === '') {
                                let date = selectedDateRef.current ? moment(selectedDateRef.current).format('YYYY-MM-DD') : session.session_date;
                                let start = editStartTimeRef.current ? moment(editStartTimeRef.current).format('HH:mm:ss') : session.session_start_time;
                                let end = editEndTimeRef.current ? moment(editEndTimeRef.current).format('HH:mm:ss') : session.session_end_time;

                                let nextDay = false;
                                if (moment('1/1/1991 ' + end).diff('1/1/1991 ' + start, 'minutes') < 0) {
                                  nextDay = true;
                                }
                                let compareEnd = nextDay ?
                                  moment(moment(date).add(1, 'd')).format("YYYY-MM-DD") : selectedDateRef.current ? moment(selectedDateRef.current).format('YYYY-MM-DD') : session.session_date;

                                var middle = [];


                                middle = await directus.getItems('sessions', {
                                  fields: session_fields,
                                  filter: {
                                    id: { neq: session.id },
                                    employee: { eq: props.employeeIdChosen },
                                    session_date: { eq: date },
                                    session_start_time: { lt: start },
                                    session_end_time: { gt: end }
                                  },
                                });

                                // Insert in the middle of next session
                                if (nextDay) {
                                  middle = linq.from(middle.data)
                                    .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') < 0).toArray();
                                }
                                else {
                                  middle = middle.data;
                                }

                                if (middle.length > 0) {
                                  /**
                                   * * Sessions overlap, with conditions:
                                   * * The new session (start time-end time) that will be edited is in the middle or between the time range (start time-end time) of the old session in directus
                                   * * Example : old session = 08.00-12.00, new session = 09.00-10.00
                                   * * Output => 3 session => 08.00-09.00, 09.00-10.00, and 10.00-12.00
                                   */
                                  var firstSession = window.confirm("1 session will be split, Are you sure?")
                                  if (firstSession === false) {
                                    setEditStartTime(null);
                                    setEditEndTime(null);
                                    setEditDuration(null);
                                    setSelectedDate(null)
                                    setAutoActivity(null);
                                    setAutoCustomer(null);
                                    setEditSmoko(null);
                                    props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
                                    editMode(index, false)
                                    updateDuration();
                                    return
                                  }

                                  // Insert in the middle of one session
                                  await directus.updateItem('sessions', middle[0].id, { session_end_time: start })

                                  let overlapEnd = middle[0];
                                  overlapEnd = {
                                    ...overlapEnd,
                                    session_date: compareEnd,
                                    session_start_time: end,
                                  }

                                  delete overlapEnd.id;
                                  delete overlapEnd.entry_number;

                                  try {
                                    await directus.createItem('sessions', overlapEnd);
                                  }
                                  catch (e) {
                                    enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
                                  }
                                }
                                else {
                                  var inMiddle = await directus.getItems('sessions', {
                                    fields: session_fields,
                                    filter: {
                                      id: { neq: session.id },
                                      employee: { eq: props.employeeIdChosen },
                                      session_date: { eq: date },
                                      session_start_time: { gt: start },
                                      session_end_time: { lt: end }
                                    },
                                  });

                                  if (inMiddle.data.length > 0) {
                                    /**
                                     * * Sessions overlap, with conditions:
                                     * * The old session (start time-end time) is in the middle or between the time range (start time-end time) of the new session to be edited
                                     * * Example : old session = 08.00 - 09.00, new session = 07.00 - 10.00
                                     * * Output => new session = 07.00 - 10.00
                                     * * The old session will be deleted and a new session will be added
                                     */
                                    var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")
                                    if (multipleSession === false) {
                                      setEditStartTime(null);
                                      setEditEndTime(null);
                                      setEditDuration(null);
                                      setSelectedDate(null)
                                      setAutoActivity(null);
                                      setAutoCustomer(null);
                                      setEditSmoko(null)
                                      props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
                                      updateDuration();
                                      editMode(index, false)
                                      return
                                    }

                                    let deleteId = [];

                                    let insideMiddle = linq.from(inMiddle.data)
                                      .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') > 0).toArray();

                                    insideMiddle.map((data, index) => {
                                      deleteId[index] = data.id;
                                    })

                                    if (deleteId.length > 0) {
                                      await directus.deleteItems('sessions', deleteId)
                                    }
                                  }
                                  let deleteId = [];

                                  if (nextDay) {
                                    var inMiddleAfter = await directus.getItems('sessions', {
                                      fields: session_fields,
                                      filter: {
                                        employee: { eq: props.employeeIdChosen },
                                        session_date: { eq: compareEnd },
                                        session_end_time: { lt: end }
                                      },
                                    });

                                    let insideMiddleAfter = linq.from(inMiddleAfter.data)
                                      .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') > 0).toArray();

                                    insideMiddleAfter.map((data, index) => {
                                      deleteId[index] = data.id;
                                    })

                                    if (deleteId.length > 0) {
                                      var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")
                                      if (multipleSession === false) {
                                        setEditStartTime(null);
                                        setEditEndTime(null);
                                        setEditDuration(null);
                                        setSelectedDate(null)
                                        setAutoActivity(null);
                                        setAutoCustomer(null);
                                        setEditSmoko(null)
                                        props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
                                        updateDuration();
                                        editMode(index, false)
                                        return
                                      }

                                      await directus.deleteItems('sessions', deleteId)
                                    }
                                  }

                                  var first = await directus.getItems('sessions', {
                                    fields: session_fields,
                                    filter: {
                                      id: { neq: session.id },
                                      employee: { eq: props.employeeIdChosen },
                                      session_date: { eq: date },
                                      session_start_time: { lt: start },
                                      session_end_time: { gt: start }
                                    },
                                  });

                                  var endd = await directus.getItems('sessions', {
                                    fields: session_fields,
                                    filter: {
                                      id: { neq: session.id },
                                      employee: { eq: props.employeeIdChosen },
                                      session_date: { eq: compareEnd },
                                      session_start_time: { lt: end },
                                      session_end_time: { gt: end }
                                    },
                                  });

                                  if ((first.data.length > 0 || endd.data.length > 0) && deleteId.length === 0 && inMiddle.data.length === 0) {
                                    var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")
                                    if (multipleSession === false) {
                                      setEditStartTime(null);
                                      setEditEndTime(null);
                                      setEditDuration(null);
                                      setSelectedDate(null)
                                      setAutoActivity(null);
                                      setAutoCustomer(null);
                                      setEditSmoko(null)
                                      props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
                                      updateDuration();
                                      editMode(index, false)
                                      return
                                    }
                                  }

                                  if (first.data.length > 0) {
                                    /**
                                     * * Sessions overlap, with conditions:
                                     * * That new session that will be edited has a 'start time' that is in the middle or between the time range (start time-end time) of the old session in the directus
                                     * * Example : old session = 08.00 - 13.00, new session = 10.00 - 17.00
                                     * * Output => 08.00 - 10.00 (old session), 10.00 - 17.00 (new session)
                                     * * Update the end time of the old session to be the same as the start time of the new session
                                     */
                                    await directus.updateItem('sessions', first.data[0].id, { session_end_time: start })
                                  }

                                  if (endd.data.length > 0) {
                                    /**
                                     * * Sessions overlap, with conditions:
                                     * * That new session that will be edited has an 'end time' that is in the middle or between the time range (start time-end time) of the old session in the directus
                                     * * Example : old session = 14.00 - 16.00, new session = 13.00 - 15.00
                                     * * Output => 15.00 - 16.00 (old session), 13.00 - 15.00 (new session)
                                     * * Update the start time of the old session to be the same as the end time of the new session
                                    */
                                    await directus.updateItem('sessions', endd.data[0].id, { session_start_time: end })
                                  }

                                  var checkbeforeFirst = await directus.getItems('sessions', {
                                    fields: session_fields,
                                    filter: {
                                      employee: { eq: props.employeeIdChosen },
                                      session_date: { eq: moment(moment(date).add(-1, 'd')).format("YYYY-MM-DD") },
                                    },
                                  });

                                  if (checkbeforeFirst.data.length > 0) {
                                    checkbeforeFirst = linq.from(checkbeforeFirst.data)
                                      .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') < 0).toArray();

                                    if (checkbeforeFirst.length > 0) {
                                      if (moment('1/1/1991 ' + checkbeforeFirst[0].session_end_time).diff('1/1/1991 ' + start, 'minutes') > 0) {
                                        await directus.updateItem('sessions', checkbeforeFirst[0].id, { session_end_time: start })
                                      }
                                    }
                                  }
                                }

                                editData = {
                                  activity: autoActivity ? autoActivity.id : activity[index] ? activity[index].id : activityId,
                                  customer: autoCustomer ? autoCustomer.id : customer[index] ? customer[index].id : customerId,
                                  session_date: selectedDateRef.current ? moment(selectedDateRef.current).format('YYYY-MM-DD') : session.session_date,
                                  session_start_time: editStartTimeRef.current ? moment(editStartTimeRef.current).format('HH:mm:ss') : session.session_start_time,
                                  session_end_time: editEndTimeRef.current ? moment(editEndTimeRef.current).format('HH:mm:ss') : session.session_end_time,
                                  duration: editStartTimeRef.current && editStartTimeRef.current ? 0 : editDurationRef.current ? Number.isInteger(editDurationRef.current) ? editDurationRef.current : parseTimeSpan(editDurationRef.current) : session.duration,
                                  break_time: editSmoko ? editSmoko : session.break_time ? session.break_time : 0
                                  // session_start_time: editStartTimeRef.current?editStartTimeRef.current==='Invalid date'?null:moment(editStartTimeRef.current).format('HH:mm:ss'):session.session_start_time,
                                  // session_end_time: editEndTimeRef.current?editEndTimeRef.current==='Invalid date'?null:moment(editEndTimeRef.current).format('HH:mm:ss'):session.session_end_time,
                                  // duration: editStartTimeRef.current && editStartTimeRef.current?0:editDurationRef.current?Number.isInteger(editDurationRef.current)?editDurationRef.current:parseTimeSpan(editDurationRef.current):session.duration,

                                }

                                if (session.session_type.show_break_start_end_time) {
                                  editData = {
                                    ...editData,
                                    break_start_time: editBreakStartTimeRef.current === 'empty' ? null : editBreakStartTimeRef.current ? editBreakStartTimeRef.current : session.break_start_time,
                                    break_end_time: editBreakEndTimeRef.current === 'empty' ? null : editBreakEndTimeRef.current ? editBreakEndTimeRef.current : session.break_end_time,
                                    // break_start_time: editBreakStartTimeRef.current==='empty'|| editBreakStartTimeRef.current==='Invalid date'?null:editBreakStartTimeRef.current?editBreakStartTimeRef.current:session.break_start_time,
                                    // break_end_time: editBreakEndTimeRef.current==='empty'|| editBreakEndTimeRef.current === 'Invalid date'?null:editBreakEndTimeRef.current?editBreakEndTimeRef.current:session.break_end_time, 
                                  }
                                }

                                if (editFullDay === true) {
                                  if (editDurationRef.current === null || editDurationRef.current === '') {
                                    editData = {
                                      ...editData,
                                      session_start_time: editStartTimeRef.current ? editStartTimeRef.current === 'Invalid date' ? null : moment(editStartTimeRef.current).format('HH:mm:ss') : session.session_start_time,
                                      session_end_time: editEndTimeRef.current ? editEndTimeRef.current === 'Invalid date' ? null : moment(editEndTimeRef.current).format('HH:mm:ss') : session.session_end_time,
                                    }
                                  }
                                  else if ((editStartTimeRef.current === 'Invalid date' && editEndTimeRef.current === 'Invalid date') || (editStartTimeRef.current === null && editEndTimeRef.current === null)) {
                                    editData = {
                                      ...editData,
                                      duration: editDurationRef.current ? Number.isInteger(editDurationRef.current) ? editDurationRef.current : parseTimeSpan(editDurationRef.current) : session.duration
                                    }
                                  }
                                }
                              }
                              else {
                                editData = {
                                  activity: autoActivity ? autoActivity.id : activity[index] ? activity[index].id : activityId,
                                  customer: autoCustomer ? autoCustomer.id : customer[index] ? customer[index].id : customerId,
                                  session_date: selectedDateRef.current ? moment(selectedDateRef.current).format('YYYY-MM-DD') : session.session_date,
                                  session_start_time: editStartTimeRef.current ? moment(editStartTimeRef.current).format('HH:mm:ss') : session.session_start_time,
                                  session_end_time: editEndTimeRef.current ? moment(editEndTimeRef.current).format('HH:mm:ss') : session.session_end_time,
                                  duration: editDurationRef.current ? Number.isInteger(editDurationRef.current) ? editDurationRef.current : parseTimeSpan(editDurationRef.current) : session.duration,
                                  break_time: editSmoko ? editSmoko : session.break_time ? session.break_time : 0
                                }

                                if (session.session_type.show_break_start_end_time) {
                                  editData = {
                                    ...editData,
                                    break_start_time: editBreakStartTimeRef.current === 'empty' ? null : editBreakStartTimeRef.current ? editBreakStartTimeRef.current : session.break_start_time,
                                    break_end_time: editBreakEndTimeRef.current === 'empty' ? null : editBreakEndTimeRef.current ? editBreakEndTimeRef.current : session.break_end_time,
                                  }
                                }
                              }
                              handleClickOpenEdit();
                              await directus.updateItem('sessions', session.id, editData);

                              updateDuration();
                              setEditStartTime(null);
                              setEditEndTime(null);
                              setEditDuration(null);
                              setBreakEditEndTime(null);
                              setBreakEditStartTime(null);
                              setSelectedDate(null)
                              setAutoActivity(null);
                              setAutoCustomer(null);
                              setEditSmoko(null);
                              setEditFullDay(null);
                              callChild(session);
                            }

                            // TODO: set openEdit state to true
                            const handleClickOpenEdit = () => {
                              setOpenEdit(true);
                            };

                            /**
                             * TODO: sends session data to be edited to the updateSessionDetail function in the EditSessionListDetails file
                             * @param {object} session Session data to be edited
                             */
                            function callChild(session) {
                              childRef.current.confirmEditDetails(session);
                            }

                            /**
                             * * this function is executed when the user clicks 'Yes' button, on dialog: confirm delete session data
                             * TODO: 
                             * * -> check if the session has the option, if it has then delete the session option from directus (employees who have session options are employees who already have awards)
                             * * -> check if the session has resources, if it has then remove the session resources from directus
                             * * -> then delete data sessions from directus based on the selected session id to delete
                             * * -> finally, re-render the session page by running the loadSession function from the props and running the updateDuration function
                             * @param index is the index of the session data that the user wants to delete 
                             * @param session is the session data that the user wants to delete
                             */
                            const deleteSession = async (index, session) => {
                              setLoadingEdit(true);
                              deleteMode(index, false);
                              let session_resources = [];
                              let session_options = [];

                              if (session.options.length > 0) {
                                session.options.map((data, index) => {
                                  session_options[index] = data.id;
                                });
                                try {
                                  await directus.deleteItems('sessions_session_options', session_options);
                                }
                                catch (e) {
                                  enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
                                }
                              }

                              if (session.resources.length > 0) {
                                session.resources.map((data, index) => {
                                  session_resources[index] = data.id;
                                  return null;
                                });
                                try {
                                  await directus.deleteItems('sessions_resources', session_resources);
                                }
                                catch (e) {
                                  enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
                                }
                              }

                              try {
                                await directus.deleteItem('sessions', session.id);
                              }
                              catch (e) {
                                //bugsnagClient.notify(e);
                                enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
                              }
                              setLoadingEdit(false);
                              props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
                              updateDuration();
                            }

                            /**
                             * TODO:
                             * * 1. the session data being checked is not the last data
                             * * 2. the session data being checked has the same date as the date of the next session data
                             * * 3. if points 1 and 2 are fulfilled then:
                             * * 4. if the page that opens is the timesheet page, then check from bottom to top (because on the timesheet page, sessions are sorted starting from the largest date), but if the page that opens is the approval page, check from top to bottom (because on the approval page, sessions are sorted starting from the smallest date)
                             * * 5. check between two adjacent sessions whether they have a time gap, for example on the timesheet page, the session being checked has a start time of 13.00 and the following session has an end time of 12.00, so it has a time gap of one hour and if there is a gap in time between the 2 sessions adjacent then set divider(line) becomes red
                             */
                            function line() {
                              if (index + 1 < sessionData.length) {
                                if (session.session_date === sessionData[index + 1].session_date) {
                                  if (path === 'timesheet') {
                                    if (moment(moment(sessionData[index + 1].session_date + ' ' + sessionData[index + 1].session_end_time).format('YYYY-MM-DD HH:mm')).diff(moment(session.session_date + ' ' + session.session_start_time).format('YYYY-MM-DD HH:mm')) < 0) {
                                      return classes.dividerStyleRed;
                                    }
                                    else {
                                      return classes.dividerStyle;
                                    }
                                  }
                                  else {
                                    if (moment(moment(sessionData[index + 1].session_date + ' ' + sessionData[index + 1].session_start_time).format('YYYY-MM-DD HH:mm')).diff(moment(session.session_date + ' ' + session.session_end_time).format('YYYY-MM-DD HH:mm')) > 0) {
                                      return classes.dividerStyleRed;
                                    }
                                    else {
                                      return classes.dividerStyle;
                                    }
                                  }
                                }

                              }
                            }

                            return (
                              <div key={index}>
                                {/* ==================Content========================================== */}
                                {(isEdit[index] && !session.readonly) ?
                                  //Edit Mode=====================================================================================================
                                  <>
                                    <CustomListItem
                                      key={`item-${index}`}
                                      style={
                                        session.session_type.name === 'Lunch' ?
                                          { color: '#606A7B', paddingRight: '16px' } :
                                          session.session_type.name !== 'Manual Entry' && session.session_type.name !== 'Clock In' ?
                                            { color: '#F79256', paddingRight: '16px' } : { paddingRight: '16px' }
                                      }
                                    >
                                      <EditSessionList
                                        timesheets={props.timesheets}
                                        session={session}
                                        duration={duration}
                                        customer={session.customer || customer[index] ? customer[index] ? customer[index].name : session.customer.name : 'Customer'}
                                        activity={session.activity || activity[index] ? activity[index] ? activity[index].name : session.activity.name : 'Activity'}
                                        changeSelectedDate={changeSelectedDate}
                                        changeAutoCustomer={changeAutoCustomer}
                                        changeAutoActivity={changeAutoActivity}
                                        changeEditStartTime={changeEditStartTime}
                                        changeEditEndTime={changeEditEndTime}
                                        changeEditBreakStartTime={changeEditBreakStartTime}
                                        changeEditBreakEndTime={changeEditBreakEndTime}
                                        changeEditSmoko={changeEditSmoko}
                                        changeEditDuration={changeEditDuration}
                                        changeEditFullDay={changeEditFullDay}
                                        changeEditPublicHoliday={changeEditPublicHoliday}
                                      />

                                      <ListItemSecondaryAction style={{ right: '5px' }}>
                                        {isEdit[index] ?
                                          loadingEdit ?
                                            <CircularProgress className={classes.circular} size={20} />
                                            :
                                            <span>
                                              <IconButton id={`save_session_${index}`} aria-label="edit"
                                                className={classes.iconButton}
                                                onClick={() => submitEdit(index, session, session.customer ? session.customer.id : null, session.activity ? session.activity.id : null)}
                                              >
                                                <DoneIcon style={{ color: '#4caf50' }} fontSize="small" />
                                              </IconButton>
                                              <IconButton id={`cancel_session_${index}`} aria-label="close"
                                                className={classes.iconButton}
                                                onClick={() => editMode(index, false)}
                                              >
                                                <ClearIcon color="error" fontSize="small" />
                                              </IconButton>
                                            </span>
                                          : ''}
                                      </ListItemSecondaryAction>
                                    </CustomListItem>
                                    <EditSessionListDetails
                                      session={session}
                                      ref={childRef}
                                      loadSession={props.loadSession}
                                      employeeIdChosen={props.employeeIdChosen}
                                      timesheetIdChosen={props.timesheetIdChosen}
                                      sessionIdentify={index}
                                      editMode={editMode}
                                    />
                                  </>
                                  :
                                  //View Mode=====================================================================================================
                                  <>
                                    <Accordion
                                      expanded={expandedSession[index] ? expandedSession[index] : false}
                                      onChange={handleChangeSession(`panel_${index}`, index)}
                                    >
                                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                        expandIcon={
                                          session.resources.length === 0 && (session.notes === null || session.notes === '') ?
                                            (session.attachments.length === 0) && session.options.length === 0 ?
                                              <ExpandMoreIcon style={{ color: 'transparent' }} />
                                              : <ExpandMoreIcon />
                                            : <ExpandMoreIcon />}
                                        // expandIcon={
                                        //   session.resources.length === 0 && (session.notes === null || session.notes === '')?
                                        //     (session.attachments.length===0)?
                                        //     <ExpandMoreIcon style={{color:'transparent'}}/>
                                        //     :<ExpandMoreIcon />
                                        //   : <ExpandMoreIcon/>}
                                        style={
                                          session.session_type.name === 'Lunch' ?
                                            { color: '#606A7B', paddingRight: '16px' } :
                                            session.session_type.name !== 'Manual Entry' && session.session_type.name !== 'Clock In' ?
                                              { color: '#F79256', paddingRight: '16px' } : { paddingRight: '16px' }
                                        }
                                      >
                                        <Grid container spacing={0} justifyContent="space-between">
                                          {/* show session type(GTNT) or show session customer name(MFM) */}
                                          <Grid item xs={3} className={classes.grid3}>
                                            <Typography variant="h6" id={`session_type_${index}`} >{
                                              session.session_type ?
                                                session.session_type.show_customer ?
                                                  session.customer || customer[index] ? customer[index] ? customer[index].name : session.customer.name
                                                    :
                                                    'No Customer'
                                                  :
                                                  session.session_type.name
                                                :
                                                session.type === 'session' ?
                                                  session.customer || customer[index] ? customer[index] ? customer[index].name : session.customer.name
                                                    :
                                                    'No Customer'
                                                  :
                                                  session.type
                                            }
                                            </Typography>
                                          </Grid>
                                          {/* show session activity(only MFM) */}
                                          <Grid item xs={3} className={classes.grid3}>
                                            <Typography variant="h6" id={`activity_name_${index}`}>
                                              {
                                                session.session_type ?
                                                  session.session_type.show_customer ?
                                                    //* show customer
                                                    session.activity || activity[index] ?
                                                      activity[index] ? activity[index].name : session.activity.name
                                                      :
                                                      'No Activity'
                                                    :
                                                    ''
                                                  :
                                                  session.type === 'session' ?
                                                    session.activity || activity[index] ?
                                                      activity[index] ? activity[index].name : session.activity.name
                                                      :
                                                      'No Activity'
                                                    :
                                                    ''
                                              }
                                            </Typography>
                                            {session.leave_status !== 'pending' ?
                                              <Typography variant="h6">
                                                {session.leave_status ? leave_status(session.leave_status) : ''}
                                                {session.leave_note ?
                                                  <CustomTooltip title={<Typography variant="h6">{session.leave_note}</Typography>}>
                                                    <InfoIcon fontSize='small' style={{ height: '15px', marginBottom: '-2px' }} />
                                                  </CustomTooltip>
                                                  : ''}
                                              </Typography>
                                              : ''}
                                          </Grid>
                                          {/* show session start time */}
                                          <Grid item xs={1} className={classes.grid1}>
                                            <Typography variant="h6" id={`session_start_time_${index}`}>{session.session_start_time ? moment(session.session_start_time, "HH:mm:ss").format('LT') : ''}</Typography>
                                          </Grid>
                                          {/* show session end time */}
                                          <Grid item xs={1} className={classes.grid1}>
                                            <Typography variant="h6" id={`session_end_time_${index}`}>
                                              {session.session_end_time ? moment(session.session_end_time, "HH:mm:ss").format('LT') : ''}

                                              {
                                                moment(moment(session.session_end_time, "HH:mm:ss").format()).diff(moment(session.session_start_time, "HH:mm:ss").format(), 'minutes') < 0 ?
                                                  <Tooltip title={moment(moment(session.session_date).add(1, 'd')).format("DD MMM YYYY")} arrow>
                                                    <InfoIcon style={{ height: '15px', marginBottom: '-2px' }} />
                                                  </Tooltip>
                                                  : ''
                                              }
                                            </Typography>
                                          </Grid>
                                          {/* show session duration */}
                                          <Grid item xs={2} className={classes.grid2}>
                                            <Typography variant="h6"
                                            >
                                              {session.session_type.include_in_total ? durationWords(duration, true) : ''}
                                            </Typography>
                                          </Grid>
                                          {/* show session break time */}
                                          <Grid item xs={2} className={classes.grid2}>
                                            {!session.session_type.include_in_total ?
                                              <Typography id={`break_${index}`} variant="h6">{durationWords(duration)}</Typography>
                                              :
                                              <>{session.session_type.show_break_start_end_time ?
                                                session.break_start_time !== null && session.break_end_time !== null ?
                                                  session.break_start_time !== 0 && session.break_end_time !== 0 ?
                                                    <Chip id={`break_chip_${index}`}
                                                      className={classes.Chip}
                                                      label={moment(moment(session.session_date + ' ' + session.break_end_time).format('YYYY-MM-DD HH:mm:00')).diff(moment(session.session_date + ' ' + session.break_start_time).format('YYYY-MM-DD HH:mm:00'), "minutes") + ' mins'}
                                                    />
                                                    : ''
                                                  : ''
                                                :
                                                session.session_type.show_break_time ?
                                                  session.break_time !== null ?
                                                    session.break_time !== 0 ?
                                                      <Chip id={`break_chip_${index}`}
                                                        className={classes.Chip}
                                                        label={session.break_time + ' mins'}
                                                      />
                                                      : ''
                                                    : ''
                                                  : ''
                                              }
                                              </>
                                            }
                                          </Grid>
                                        </Grid>
                                        <ListItemSecondaryAction style={{ right: '45px' }}>
                                          {enableGPS ?
                                            session.session_start_longitude && session.session_start_longitude ?
                                              <IconButton id={`edit_session_${index}`} aria-label="icon"
                                                onClick={() => window.open('https://www.google.com/maps/place/' + session.session_start_latitude + ',' + session.session_start_longitude)}
                                                className={classes.iconButton}
                                              >
                                                <MapIcon fontSize="small" />
                                              </IconButton>
                                              : ''
                                          : ''}
                                          {isEdit[index] || enableEdit === false || session.readonly ? '' :
                                            loadingEdit ?
                                              <CircularProgress className={classes.circular} size={20} />
                                              :
                                              hide ?
                                                '' :
                                                <span>
                                                  
                                                  <IconButton id={`edit_session_${index}`} aria-label="edit"
                                                    className={classes.iconButton}
                                                    onClick={() => editMode(index, true)}
                                                    disabled={props.status === 'deleted'}
                                                  >
                                                    <CreateIcon fontSize="small" />
                                                  </IconButton>
                                                  <IconButton id={`delete_session_${index}`} aria-label="delete"
                                                    className={classes.iconButton}
                                                    onClick={(event) => { deleteMode(index, true); event.stopPropagation() }}
                                                    disabled={props.status === 'deleted'}
                                                  >
                                                    <DeleteIcon color="error" fontSize="small" />
                                                  </IconButton>
                                                  <Dialog
                                                    id={`delete_session_dialog_${index}`}
                                                    open={isDelete[index] ? isDelete[index] : false}
                                                    isopen={`${isDelete[index] ? isDelete[index] : false}`}
                                                    onClose={() => deleteMode(index, false)}
                                                    aria-labelledby="alert-dialog-title"
                                                    aria-describedby="alert-dialog-description"
                                                  >
                                                    <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete this session?"}</DialogTitle>
                                                    <DialogActions>
                                                      <Button id={`cancel_delete_session_${index}`} onClick={() => deleteMode(index, false)} color="primary">
                                                        No
                                                      </Button>
                                                      <Button id={`save_delete_session_${index}`} onClick={() => deleteSession(index, session)} color="primary" autoFocus>
                                                        Yes
                                                      </Button>
                                                    </DialogActions>
                                                  </Dialog>
                                                </span>
                                          }
                                          <span>
                                            {loadingEdit ?
                                              ''
                                              :
                                              enableCheckbox && !session.readonly ?
                                                <Checkbox
                                                  onClick={(event) => event.stopPropagation()}
                                                  onFocus={(event) => event.stopPropagation()}
                                                  id={`Code ${code} ${index}`}
                                                  checked={session.select}
                                                  name={`${code}`}
                                                  onChange={event => {
                                                    let checked = event.target.checked;
                                                    setSessionData(
                                                      sessionData.map(data => {
                                                        if (session.id === data.id) {
                                                          data.select = checked;
                                                        }
                                                        return data;
                                                      })
                                                    );
                                                  }}
                                                  disabled={props.status === 'deleted'}
                                                />
                                                : ''
                                            }
                                          </span>
                                          {session.readonly && !isEdit[index] ?
                                            session.checkedStatus ?
                                              <Checkbox
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                className={classes.checkedStatus}
                                                checked={session.readonly}
                                                disabled={props.status === 'deleted'}
                                              />
                                              :
                                              <Checkbox
                                                onClick={(event) => event.stopPropagation()}
                                                onFocus={(event) => event.stopPropagation()}
                                                className={classes.checkedStatusFailed}
                                                checked={session.readonly}
                                                disabled={props.status === 'deleted'}
                                              />
                                            :
                                            ''
                                          }
                                        </ListItemSecondaryAction>
                                      </AccordionSummary>
                                      <SessionListDetails
                                        session={session}
                                        loadSession={props.loadSession}
                                        sessionIdentify={index}
                                        materialExist={materialExist}
                                        equipmentExist={equipmentExist}
                                      />
                                    </Accordion>

                                  </>
                                }
                                <Divider className={line()} />
                              </div>
                            );
                          }
                          return null;
                        })}
                      </Accordion>
                    </ul>
                  </li>
                );
              })
              : ''}

          </List>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <Grid container spacing={0} justifyContent="space-between">
            <Grid item xs={3} className={classes.grid3}>
              <Typography>&nbsp;&nbsp;<b>Total</b></Typography>
            </Grid>
            <Grid item xs={3} className={classes.grid3}></Grid>
            <Grid item xs={1} className={classes.grid1}></Grid>
            <Grid item xs={2} className={classes.grid2}>
              <Typography id="total_duration">
                {durationWords(Total, true)}
              </Typography>
            </Grid>
            <Grid item xs={2} className={classes.grid2}></Grid>
          </Grid>
        </CardActions>
      </Card>

      {buttonLoading ?
        <div style={{ float: 'right', paddingTop: '10px', paddingRight: '50px', paddingBottom: '10px', }}>
          <CircularProgress className={classes.circular} />
        </div>
        :
        <>
          {path !== 'timesheet' ?
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <TimesheetEvent />
              </Grid>
              <Grid item xs={6}>
                {isLoaded && enableGPS && props.totalCountLatLong !== 0 && props.marker.length > 0 ?
                  <>
                    <MapButton onClick={() => setAnchor(true)} variant="contained">Map</MapButton>
                    <Drawer
                      anchor="bottom"
                      open={anchor}
                      onClose={() => setAnchor(false)}
                    >
                      <LocationMap
                        sessionData={props.sessionData}
                        markerData={props.marker}
                      />
                    </Drawer>
                  </>
                  : ''}
              </Grid>

              <Grid item xs={6}>
                <ApproveRejectButton
                  timesheets={props.timesheets}
                  checkboxValueList={checkboxValueList}
                  employeeIdChosen={props.employeeIdChosen}
                  changeButtonLoading={changeButtonLoading}
                  sessionData={sessionData}
                  changeSessionData={changeSessionData}
                  loadTimesheets={props.loadTimesheets}
                  right={props.right}
                  enableTimesheetBankHours={enableTimesheetBankHours}
                  totalDuration={Total}
                />
              </Grid>
              <Grid item xs={12}>
                {props.timesheets.employee.award !== null ? props.timesheets.employee.award.name : ''}
              </Grid>
            </Grid>
            :
            <Grid container spacing={0}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <div style={{ float: 'right', paddingTop: '10px', paddingRight: '50px', paddingBottom: '10px' }}>
                  <ApproveRejectButtonTimesheet
                    timesheets={props.timesheets}
                    checkboxValueList={checkboxValueList}
                    employeeIdChosen={props.employeeIdChosen}
                    changeButtonLoading={changeButtonLoading}
                    sessionData={sessionData}
                    changeSessionData={changeSessionData}
                    loadTimesheets={props.loadTimesheets}
                    right={props.right}
                    totalDuration={Total}
                    enableTimesheetBankHours={enableTimesheetBankHours}
                  />
                </div>
              </Grid>
            </Grid>
          }
        </>
      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Use Google's location service?"}</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default SessionList;