import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  CircularProgress,
  Grid,
  Button,
  Menu,
  MenuItem,
  Card,
  CardContent,
  // Dialog,
  // DialogTitle,
  // DialogContent,
  // DialogContentText,
  // DialogActions,
  // FormControlLabel,
  // ListItemIcon,
  TextField,
  Box,
} from '@material-ui/core';
import { 
  useStyles, 
  // CancelButton, 
  // ActionButton,
  // LockButton, 
  // ColorButton,
  // ApprovedPaidButton,
  // YesPayrollButton,
  // NoPayrollButton,
} from './style.js';
import Page from 'src/components/Page';
// import { enableRipple } from '@syncfusion/ej2-base';
// import WarningIcon from '@mui/icons-material/Warning';
// import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
// import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
// import AddIcon from '@material-ui/icons/Add';
// import SendIcon from '@material-ui/icons/Send';
// import PaymentIcon from '@material-ui/icons/Payment';
// import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
// import Icon from "@material-ui/core/Icon";
import linq from "linq";
import useStateRef from "react-usestateref";
import directus from '../../services/directus';
import ReportingTimesheetList from './ReportingTimesheetList';
import moment from 'moment';
// import { AdminPermission, SupervisorPermission } from 'src/utils/Permission';
import { getConfiguration } from 'src/utils/sessions';
// import EventEmitter from 'src/utils/EventEmitter';
// import LockIcon from '@material-ui/icons/Lock';
// import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import DateRangePicker from '@mui/lab/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import addMonths from 'date-fns/addMonths';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let startApprovals;
let endApprovals;
let filterSessionLeave = 0;
let filterTimesheets = [];
let loop = 0;

const Reporting = () => {
  /* eslint-disable */
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [items, setItems] = React.useState({ employees: null, timesheets: null, sessions: null });
  const [employeesFilterData, setEmployeesFilterData] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);
  const [employeesFilterChosen, setEmployeesFilterChosen] = React.useState(null);
  const [status, setStatus, statusRef] = useStateRef('all');
  const [statusView, setStatusView, statusViewRef] = useStateRef(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openNotif, setOpenNotif] = React.useState(false);
  const [addTimesheetLoading, setAddTimesheetLoading] = React.useState(false);
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [anchorElExport, setAnchorElExport] = React.useState(null);
  const [openDialogPayroll, setOpenDialogPayroll] = React.useState(false);
  const [openDialogPayrollMsg, setOpenDialogPayrollMsg] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [openDialogChangeStatus, setOpenDialogChangestatus] = React.useState(false);
  const [changeLoading, setChangeLoading] = React.useState(false);

  const [payrollMessage, setPayrollMessage] = React.useState(false);
  const [approvalLockData, setApprovalLockData, approvalLockDataRef] = useStateRef('');
  const [lockLoading, setLockLoading] = React.useState(false);
  const [exportdata, setExportData] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(5);
  const [selectedView, setSelectedView] = React.useState(1);
  const [errorText, setErrorText] = React.useState(null);
  // const [timesheetReview, setTimesheetReview, timesheetReviewRef] = useStateRef(null);
  const [configuration, setConfiguration] = useState(null);
  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [description, setDescription, descriptionRef] = useStateRef('');
  const [notification, setNotification, notificationRef] = useStateRef('');
  const [title, setTitle, titleRef] = useStateRef('');
  const [statusValue, setStatusValue] = useState(null);
  const [codeAll, setCodeAll] = useState(false);
  const [value, setValue, valueRef] = useStateRef([null, null]);
  const [name, setName, nameRef] = useStateRef('');


  /* eslint-enable */
  // enableRipple(true);

  var react_app_url=process.env.REACT_APP_URL;
  if(window.location.hostname.search('interax') !== -1){
    react_app_url=process.env.REACT_APP_URL_INTERAX;
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
      loadConfiguration();
      statusSummaryTypeLoad();
    }

    return () => {
      setInitialized(false);
    }
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const loadConfiguration = async () => {
    try {
      var configuration_data = await directus.getItems('config_ts');
      setConfiguration(configuration_data.data[0]);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  /* eslint-disable array-callback-return*/
  const statusSummaryTypeLoad = async () => {
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      var timesheet_list = [];
      if (timesheet_status.length > 0) {
        timesheet_status.map((value) => {
          if(value[1].value !== 'deleted')
          {
            var background_color = "";
            var name = value[1].name;
            var text_color = "#000000de";

              if (value[1].value === "approved") {
                background_color = "#DAF0DC";
              } else if (value[1].value === "rejected") {
                background_color = "#FFDBD9";
              } else if (value[1].value === "pending") {
                background_color = "#D4E3F2";
                name = "Not Yet Submitted";
              } else if (value[1].value === "awaiting_approval") {
                background_color = "#FFF0DD";
                name = "Awaiting Approvals";
              } else if (value[1].value === "sent") {
                background_color = "deepskyblue";
                name = "Sent to Payroll";
              } else if (value[1].value === "paid") {
                background_color = "#acebac";
              } else {
                background_color = "#ffffff";
              }
            timesheet_list.push({
              value: value[1].value,
              name: name,
              background_color: background_color,
              text_color : text_color
            })
          }
        })

        setTimesheetStatusType(timesheet_list);
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const firstLoad = async (data = false) => {

    var ConfigurationResult = await getConfiguration();
    let config_day = moment(moment().day(ConfigurationResult.first_day_of_week)._d);

    if (moment(moment().add(-2, 'd').format('YYYY-MM-DD')).isBefore(moment(config_day).format('YYYY-MM-DD'))) {
      startApprovals = (moment(config_day).add(-7, 'd').format('YYYY-MM-DD 00:00:00'));
      endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
    }
    else {
      startApprovals = (moment(config_day).format('YYYY-MM-DD 00:00:00'));
      endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
    }

    setValue([startApprovals ? startApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 00:00:00'),endApprovals ? endApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 23:59:59')]);
    loadTimeSheets(statusRef.current);
  }

  const loadTimeSheets = async (status) => {

    setMoveLoading(true);
    // let employeesId = [];
    let filterData;
    // let employeeArray = [];
    // let loopCountEmployee = 0;
    // let employeeCount = 0;

    // if (employeeData !== 'null') {
    //   if (employeeData.length > 30) {
    //     employeeData.map((data, index) => {

    //       if (data) {
    //         if (index !== 0 && index % 30 === 0) {
    //           employeeArray[loopCountEmployee] = employeesId;
    //           employeesId = [];
    //           loopCountEmployee = loopCountEmployee + 1;
    //           employeeCount = 0
    //         }
    //         employeesId[employeeCount] = data.id;
    //         employeeCount = employeeCount + 1;

    //         if (index + 1 === employeeData.length) {
    //           employeeArray[loopCountEmployee] = employeesId;
    //         }
    //       }
    //     })
    //   }
    //   else {
    //     employeeData.map((data, index) => {
    //       if (data) {
    //         employeesId[index] = data.id;
    //       }
    //     })
    //   }
    // }
    // else {
      if (nameRef.current) {
        filterData = {
          'employee.status': { in: ['published','deleted']},
          'employee.user.first_name': { like: nameRef.current },
          'employee.user.last_name': { 'logical': 'or', like: nameRef.current },
          'employee.code': { 'logical': 'or', like: nameRef.current },
        };
      }
      else {
        filterData = {
          'employee.status': { in: ['published','deleted']},
        };
      }
    // }
    if(status ==='all'){
      filterData = {
        ...filterData,
      }
    }
    else
    {
      filterData = {
        ...filterData,
        status: { eq: status }
      }
    }
    filterData = {
      ...filterData,
      start_time: {gte: moment(valueRef.current[0]).format('YYYY-MM-DD 00:00:00') },
      end_time: {lte: moment(valueRef.current[1]).format('YYYY-MM-DD 23:59:59')}
    }



    var employeeID = JSON.parse(window.localStorage.getItem('directus_employee')).id;

    let fetchTimesheetResult=''
    try {
      fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
        fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
        filter: filterData,
        limit: -1,
        show_timesheet_review: true,
        timesheet_review_filter:
        {
          "read_by.employees_id": { in: { employeeID } }
        },
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    let timesheetId = [];

    fetchTimesheetResult.data.map((data) => {
      timesheetId = [...timesheetId, data.id];
    })

    //eslint-disable-next-line no-redeclare
    var employeeID = JSON.parse(window.localStorage.getItem('directus_employee')).id;
    try {

      var TimesheetReview = await directus.api.post('/custom/mobile/timesheets/batch-data', {
        "timesheet_review": {
          fields: '*,employee.user.first_name,employee.user.last_name,read_by.employees_id.id',
          filter: {
            'timesheet.id': { in: timesheetId }
          },
          sort: "created_on"
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    fetchTimesheetResult.data.map((data, index) => {
      let result = 0
        result = linq.from(TimesheetReview.data.timesheet_review)
          .where(x => x.timesheet.id === data.id).count();

      fetchTimesheetResult.data[index] = { ...data, totalCountTimesheet: result, select: false};
    })

      setItems(prevItems => ({
        ...prevItems,
        timesheets: fetchTimesheetResult.data,
      }));

      loadTimesheetsFinishLoading();
  };

  const loadTimesheetsFinishLoading = () => {
    window.localStorage.setItem('approvals_date', JSON.stringify({ start: valueRef.current[0], end: valueRef.current[1] }));
    setMoveLoading(false);
  }

  const filterLeaveStatus = async (TimesheetsResult) => 
  {
    try {
      var SessionResult = await directus.getItems('sessions', {
        fields: '*.*',
        filter: {
          'timesheet.id': { eq: TimesheetsResult[filterSessionLeave].id },
          'session_type.leave': { nempty: true }
        },
        limit: -1
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (SessionResult.data.length > 0) {
      filterTimesheets[loop] = TimesheetsResult[filterSessionLeave];
      loop = loop + 1;
    }
    
    if (filterSessionLeave + 1 === TimesheetsResult.length) {
      TimesheetFinishing(filterTimesheets);
    }
    else {
      filterSessionLeave = filterSessionLeave + 1;
      filterLeaveStatus(TimesheetsResult);
    }
  }

  const TimesheetFinishing = async (TimesheetsResult) => {
    let filterdata;
    let filterTimesheet;

    if (nameRef.current) {
      filterdata = linq.from(TimesheetsResult)
        .where(x => x.start_time === valueRef.current[0] && x.end_time === valueRef.current[1]).toArray();
      filterTimesheet = linq.from(filterdata)
        .orderBy(x => x.employee.code).toArray();
    }
    else {
      filterTimesheet = linq.from(TimesheetsResult)
        .orderBy(x => x.employee.code).toArray();
    }

    filterTimesheet = linq.from(filterTimesheet)
      .orderByDescending(x => x.status === 'awaiting_approval')
      .thenByDescending(x => x.status === 'pending')
      .thenByDescending(x => x.status === 'approved')
      .thenByDescending(x => x.status === 'rejected').toArray();
    
    setItems(prevItems => ({
      ...prevItems,
      timesheets: filterTimesheet
    }));

    loadTimesheetsFinishLoading();
  }

  function filterStatus(input) {
    setSelectedStatus(input);
    setStatus(input);
    loadTimeSheets(input);
    handleClose();
  }

  function moveTimesheet() {
      setValue([moment(valueRef.current[0]).format('YYYY-MM-DD 00:00:00'),moment(valueRef.current[1]).format('YYYY-MM-DD 23:59:59')])
      loadTimeSheets(statusRef.current);
  }

  /* eslint-enable array-callback-return*/

  function getMonthsAfter(date, amount) {
    return date ? addMonths(date, amount) : undefined;
  }
  
  const handleChangeDate = (e) => {
    setValue(e);
  }

  const handleChangeName = (e) => {
    setName(e.target.value);
  }

  return (
    <Page className={classes.root} title="Reporting">
      <Container maxWidth={false}>
        <div className="control-pane">
          <div className="control-section">
            <Grid container spacing={0}>
              <Grid item xs={9} sm={9} md={9} lg={9}>
                <Grid container>
                  <LocalizationProvider dateAdapter={AdapterDateFns} className={classes.datecustom}>
                    <DateRangePicker
                      startText="Start Date"
                      endText="End Date"
                      value={valueRef.current}
                      inputFormat="dd/MM/yyyy"
                      name="date_range"
                      onChange={handleChangeDate}
                      maxDate={getMonthsAfter(valueRef.current[0] , 2)}
                      renderInput={(startProps, endProps) => (
                        <React.Fragment>
                          <TextField  {...startProps} size="small" variant="outlined"/>
                          <Box sx={{ mx: 2 }}> to </Box>
                          <TextField {...endProps} size="small" variant="outlined"/>
                        </React.Fragment>
                      )}
                    />
                  </LocalizationProvider>

                  <TextField id="name" name="name" label="Name" size="small" variant="outlined"  onChange={handleChangeName} style={{ marginLeft: '8px', width:'350px' }} />

                  {moveLoading ? 
                    <CircularProgress className={classes.circularLoading} size={20} style={{ marginTop: '8px', marginLeft: '6px' }} />
                  :
                    <Button id="approvals_date_range" variant="outlined" size="medium" onClick={() => moveTimesheet()} className={classes.buttonSearch}>
                      Search
                    </Button>
                  }
                  
                </Grid>
              </Grid>
            </Grid>
            <Card elevation={0} className={classes.card} style={{marginTop:'15px'}}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={0} className={classes.headerWidth}>
                  <Grid item xs={3} sm={3} md={3} lg={2}>
                    <Typography variant="h5"><b>Code</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} lg={3}>
                    <Typography variant="h5"><b>Name</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant="h5"><b>Phone</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant="h5"><b>Status</b></Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Typography variant="h5"><b>Total Time</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2}>
                  <div className={classes.filter}>
                      <Button
                        id="status_filter_btn"
                        size="small"
                        onClick={handleClick}
                        disabled={moveLoading}
                      >
                        <i className="fa fa-filter fa-lg"></i><Typography variant="h6">Status</Typography>
                      </Button>
                      <Menu
                        id="status_filter_menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        isopen={`${Boolean(anchorEl)}`}
                        onClose={handleClose}
                        className={classes.filterandsort}
                      >
                        {timesheetStatusType.map((status, index)=>{
                         return(
                          <div key={index}>   
                            <MenuItem onClick={() => filterStatus(status.value)} selected={status.value === selectedStatus} isselected={`${status.value === selectedStatus}`}>{status.name==='Waiting Approval'?"Awaiting Approval":status.name}</MenuItem>
                          </div>
                         );
                        })
                        }
                        <MenuItem id="all_status_menu" onClick={() => filterStatus('all')} selected={selectedStatus === 'all'} isselected={`${selectedStatus === 'all'}`}>All</MenuItem>
                      </Menu>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {items.timesheets ?
              items.timesheets.length > 0 ?
                <div>
                  <ReportingTimesheetList
                    employees={items.employees}
                    sessions={items.sessions}
                    timesheets={items.timesheets}
                    timesheetStatusType={timesheetStatusType}
                    configuration={configuration?configuration.enable_update_status:0}
                  />
                </div>
                :
                !moveLoading ?
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ opacity: '0.2', paddingTop: '6pc' }}
                  >
                    <Grid item xs={12} sm={12} lg={12}>
                      <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                      <Typography variant="h3" style={{ textAlign: 'end' }}>No Data Found</Typography>
                    </Grid>

                  </Grid>
                  : ''
              :
              <CircularProgress className={classes.circularMain} />
            }
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default Reporting;