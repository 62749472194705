import React, { useState, useRef } from 'react';
import {
  withStyles,
  Dialog,
  Button,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormControlLabel,
  DialogContentText
} from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import directus from '../../../services/directus';
import AddSessionList from './AddSessionList';
import AddSessionListDetails from './AddSessionListDetails';
import useStateRef from "react-usestateref";
import moment from 'moment';
import linq from "linq";
import '../style.css';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../Components/ErrorMessage';
import Checkbox from '@mui/material/Checkbox';
import { AddChip } from '../style.js';
import Enumerable from 'linq';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const AddSession = (props) => {
  const childRef = useRef();
  const [addLoading, setAddLoading] = React.useState(false);
  const [dateLoading, setDateLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectData, setSelectData] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(
    moment(props.timesheets.start_time).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") && moment(props.timesheets.end_time).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD") ?
      moment(new Date()).format("YYYY-MM-DD")
      :
      moment(props.timesheets.start_time).format("YYYY-MM-DD")
  );
  const [activity, setActivity, activityRef] = useStateRef(null);
  const [customer, setCustomer, customerRef] = useStateRef(null);
  const [startTime, setStartTime, startTimeRef] = useStateRef(null);
  const [endTime, setEndTime, endTimeRef] = useStateRef(null);
  const [rosterStart, setRosterStart, rosterStartRef] = useStateRef(null);
  const [rosterEnd, setRosterEnd, rosterEndRef] = useStateRef(null);
  const [ordinaryHours, setOrdinaryHours, ordinaryHoursRef] = useStateRef(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [breakStartTime, setBreakStartTime] = React.useState(null);
  const [breakEndTime, setBreakEndTime] = React.useState(null);
  const [createAnother, setCreateAnother] = React.useState(false);
  const [keepCustomer, setKeepCustomer] = React.useState(true);
  const [keepActivity, setKeepActivity] = React.useState(false);
  const [input, setInput, inputRef] = useStateRef({ notes: '', smoko: 0 });
  const [duration, setDuration] = useState('');
  const [sessionOptions, setSessionOptions, sessionOptionsRef] = useStateRef([]);
  const [loadingSession, setLoadingSession] = React.useState(false);
  const [loadingSessionOption, setLoadingSessionOption] = React.useState(false);
  const [optionsList, setOptionsList, optionsListRef] = useStateRef([]);
  const [optionsNoneList, setOptionsNoneList, optionsNoneListRef] = useStateRef([]);
  const [state, setState, stateRef] = useStateRef({
    select: '',
    data: null
  });
  const { enqueueSnackbar } = useSnackbar();

  // eslint-disable-next-line no-mixed-operators
  const disableSession = customer === null || activity === null || (startTime === null || endTime === null || moment(startTime, 'HH:mm:ss').format('HH:mm:ss') === 'Invalid date' || moment(endTime, 'HH:mm:ss').format('HH:mm:ss') === 'Invalid date') && (duration === 0 || duration === '');
  const disableLunch = (startTime === null || endTime === null || moment(startTime, 'HH:mm:ss').format('HH:mm:ss') === 'Invalid date' || moment(endTime, 'HH:mm:ss').format('HH:mm:ss') === 'Invalid date') && (duration === 0 || duration === '');


  React.useEffect(() => {
    getSelectList();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps*/

  /**
   * TODO:
   * * 1. Get session type from directus and set the result into selectData state
   * * 2. Get the same data as props.configuration (session_type_default set in local storage) from point 1, and set its value to the state
   * * 3. If there is a data session from point 1 that has a start_end_time_from_roster = true, then run the rosterData function. start_end_time_from_roster = true for all session types on GTNT except the Leave Without Pay type
   */
  const getSelectList = async () => {
    try {
      var selectResult = await directus.getItems('session_types', {
        // fields: '*,session_options.*',
        fields: 'id, name, full_day, public_holiday, start_end_time_from_roster, show_break_start_end_time, show_start_end_time, show_break_time, show_customer, show_activity, show_duration, default_duration, upload_description, session_options.*',
        filter: {
          status: { eq: 'published' }
        },
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setSelectData(selectResult.data);

    //eslint-disable-next-line array-callback-return
    selectResult.data.map(data => {
      if (data.id === props.configuration) {
        setState({
          ...state,
          data: data,
          select: data.name,
          checkbox_full_day: data.full_day,
          checkbox_public_holiday: data.public_holiday === true ? false : true,
        });
      }
      if (data.start_end_time_from_roster) {
        rosterData();
      }
    })
    return null;
  };

  /* eslint-disable react-hooks/exhaustive-deps*/

  /**
   * * This function is executed when the user clicks the 'Add' button
   * TODO: 
   * * 1. set selectedDate with today's date if the start_time of the timesheet is smaller than today's date and the end_time of the timesheet is greater than today's date
   * * 2. set selectedDate with start_time value from timesheet if condition at point 1 is false
   * * 3. if data in stateRef is null then set startTime, endTime, duration, customer, activity, breakStartTime, breakEndTime, rosterStart, rosterEnd, and ordinaryHours to null
   * * 4. but if the data in stateRef is not null, check whether start_end_time_from_roster in session type is true or false, if true, run the rosterData function
   */
  const handleClickOpen = () => {
    setOpen(true);
    sessionOptionData();
    setSelectedDate
      (
        moment(props.timesheets.start_time).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") && moment(props.timesheets.end_time).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD") ?
          moment(new Date()).format("YYYY-MM-DD")
          :
          moment(props.timesheets.start_time).format("YYYY-MM-DD")
      )

    if (stateRef.current.data !== null) {
      if (stateRef.current.data.start_end_time_from_roster) {
        rosterData();
      }
    }
    else {
      setStartTime(null);
      setEndTime(null);
      setDuration('');
      setCustomer(null);
      setActivity(null);
      setBreakStartTime(null);
      setBreakEndTime(null);
      setRosterStart(null);
      setRosterEnd(null);
      setOrdinaryHours(null);
    }
  };

  // TODO: set selectedDate is the same as setSelectedDate in the handleClickOpen function and sets all states to null. After that, close the dialog
  const handleClose = () => {
    getSelectList();
    setSelectedDate(
      moment(props.timesheets.start_time).format("YYYY-MM-DD") < moment(new Date()).format("YYYY-MM-DD") && moment(props.timesheets.end_time).format("YYYY-MM-DD") > moment(new Date()).format("YYYY-MM-DD") ?
        moment(new Date()).format("YYYY-MM-DD")
        :
        moment(props.timesheets.start_time).format("YYYY-MM-DD"));

    setStartTime(null);
    setEndTime(null);
    setDuration('');
    setCustomer(null);
    setActivity(null);
    setBreakStartTime(null);
    setBreakEndTime(null);
    setRosterStart(null);
    setRosterEnd(null);
    setOrdinaryHours(null);
    setOptionsList([]);
    setOptionsNoneList([]);
    setOpen(false);
  };

  /**
   * * This function is executed when the user selects a session type in the add session dialog
   * TODO: set state value with the session type selected by the user and if the selected session type has a value start_end_time_from_roster = true, then run the rosterData function, but if = false set startTime, endTime,breakStartTime, breakEndTime, rosterStart, rosterEnd, and ordinaryHours to null
   */
  const handleChange = (event) => {
    selectData.map(data => {
      if (data.name === event.target.value) {
        setState({
          ...state,
          select: data.name,
          data: data,
          checkbox_full_day: data.full_day,
          checkbox_public_holiday: data.public_holiday === true ? false : true,
        });

        if (data.start_end_time_from_roster) {
          rosterData(selectedDate);
        }
        else {
          setStartTime(null);
          setEndTime(null);
          setBreakStartTime(null);
          setBreakEndTime(null);
          setRosterStart(null);
          setRosterEnd(null);
          setOrdinaryHours(null);
        }
        sessionOptionData();
      }
    })
  };

  /**
   * * function is only executed for GTNT company
   * @param id The id of the selected session type
   * TODO:
   * * 1. When the user selects one of the following session types: (Annual Leave, Sick Leave, RDO Taken, Trade School, Compassionate Leave, Leave Without Pay), a checkbox will appear with the label 'Full day' and if the user ticks or unticks the checkbox, this function will be executed to change the status checkbox to true or false in the state.
   * * 2. Or when the user selects the session type 'Public Holiday' it will display a checkbox with the label 'Did you work on the public holiday?' when the checkbox ticked or unticked, this function will be executed to change the checkbox status to true or false in the state.
   * * 3. If the selected session type has a value start_end_time_from_roster = true, then run the rosterData function, but if = false set startTime, endTime, breakStartTime, breakEndTime, rosterStart, rosterEnd, and ordinaryHours to null
   */
  const handleChangeChecked = (event, id) => {
    const { name } = event.target;

    selectData.map(data => {
      if (data.id === id) {
        setState({
          ...state,
          select: data.name,
          data: data,
        });

        if (name === 'checkbox_full_day') {
          setState({
            ...state,
            checkbox_full_day: event.target.checked,
          });
        }
        else if (name === 'checkbox_public_holiday') {
          setState({
            ...state,
            checkbox_public_holiday: event.target.checked,
          });
        }

        if (data.start_end_time_from_roster) {
          rosterData(selectedDate);
        }
        else {
          setStartTime(null);
          setEndTime(null);
          setBreakStartTime(null);
          setBreakEndTime(null);
          setRosterStart(null);
          setRosterEnd(null);
          setOrdinaryHours(null);
        }
      }
    })
  };

  const ApproveButton = withStyles({
    root: {
      borderRadius: '24px',
      backgroundColor: '#2AC940',
      color: '#ffffff',
      height: '30px',
      width: '120px',
      '&:hover': {
        backgroundColor: '#22a033',
      },
    },
  })(Button);

  const CancelButton = withStyles({
    root: {
      borderRadius: '24px',
      backgroundColor: '#e0e0e0de',
      height: '30px',
      width: '120px',
      '&:hover': {
        backgroundColor: '#bdbdbdde',
      },
    },
  })(Button);

  /**
   * @param {data} value an activity selected by the user
   * TODO: set activity state with the activity data selected by the user
   */
  function changeActivity(value) {
    setActivity(value);
  }
  
  /**
   * @param {data} value customer selected by the user
   * TODO: set customer state with the customer data selected by the user
   */
  function changeCustomer(value) {
    setCustomer(value)
  }
  
  /**
   * @param {time} value The time selected by the user in the start time field
   * TODO: set startTime state with the time selected by the user and run getSessionOptionsData function
   */
  function changeStartTime(value) {
    setStartTime(value);
    getSessionOptionsData(sessionOptionsRef.current);
  }
  
  /**
   * @param {time} value The time selected by the user in the end time field
   * TODO: set endTime state with the time selected by the user and run getSessionOptionsData function
   */
  function changeEndTime(value) {
    setEndTime(value);
    getSessionOptionsData(sessionOptionsRef.current);
  }
  
  /**
   * TODO: set breakStartTime state with the time selected by the user
   * * only in GTNT
   * @param {time} value The time selected by the user in the break start time field
   */
  function changeBreakStartTime(value) {
    setBreakStartTime(value);
  }
  
  /**
   * TODO: set breakEndTime state with the time selected by the user
   * * only in GTNT
   * @param {time} value The time selected by the user in the break end time field
   */
  function changeBreakEndTime(value) {
    setBreakEndTime(value);
  }
  
  /**
   * TODO: set selectedDate state with the date selected by the user and if the selected session type has start_end_time_from_roster = true, then run the rosterData function by sending the selected date in the session date field as a parameter
   * @param {date} value The date selected by the user in the session date field
   */
  async function handleDateChange(value) {
    setSelectedDate(value);
    
    if (state.data.start_end_time_from_roster) {
      rosterData(value);
    }
    sessionOptionData();
  }
  
  /**
   * TODO: set duration state with the number typed by the user
   * * only in MFM
   * @param {number} e
   */
  function changeDuration(e) {
    setDuration(e.target.value)
  }

  /**
   * TODO: displays a confirmation message in the dialog
   * * if the employee has roster data on the selected date and changes the start time or end time so that it is not the same as the value set on the roster,  and the user clicks the save button, this function will be executed. (only in GTNT)
   */
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  // TODO: close the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  /**
   * TODO: split h and m from timeString parameters, check if any of the splits results have a length greater than 1:
   * * -> if true => if the results of the split parts.length = 1 (meaning there is only a value for minutes) then it returns only the value of the total minutes, but other than that it returns the results of calculating the hours multiplied by 60 plus the total minutes
   * * -> if false => returns the hour and minute calculation results
   * @param timeString has the value equal to the duration state, if the duration state is not null or empty
   */
  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if (parts.length > 1 || parts2.length > 1) {
      if (parts.length === 1) {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
      }
    }
    else {
      var decimalTimeString = timeString;
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  /**
   * TODO: check whether the input in the duration field is valid or not
   * @param {time} data 
   */
  const checkTimeValid = (data) => {
    if (moment(data).isValid() && moment(data).format('HH:mm') !== '00:00') {
      return true;
    }
    else {
      setDuration('');
    }
  }

  /**
   * * only MFM company
   * * when the user leaves the duration field or the duration field loses focus (onBlur) after typed, this function is executed
   * * duration equal to which the user typed in the duration field
   * TODO: 
   * * if duration is not empty or not null or not = 0 then:
   * * check whether the input from the user includes the letter h(for hours) or m(for minutes)?
   * *  > if true :
   * *    -> check if there is only m?
   * *      ~ if true, then parse to integer and check whether the value is valid or not, if valid set duration state with the results of the parse to integer and combined with the letter m. But if it is not valid then set the duration state to be empty
   * *      ~ if false, then calculate: the result of parse to integer(split h index-0) * 60 plus the result of parse to integer(split h index-1 which is given a default value of 0 so that it is not NaN) if the result is valid set duration by combining hour values and the minutes, but if not valid set the duration state to be empty.
   * *  > if the input from the user does not include the letters h or m, then: get the hour from the number typed, if what is typed is the number 24 it means it is at 00.00, if it is more than 24, then subtract with the number 24, until the last subtraction result is smaller than 24 (because in 1 day there are only 24 hours). For example: if the user types 50, then the way it works is 50-24 = 26, 26-24 = 2, because 2 is smaller than 24, then set hour to 2.
   */
  function time_convert() {
    if (duration !== '' || duration !== "0" || duration !== null) {
      let parts = duration.split("h");
      let parts2 = duration.split("m");
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
          }
        }
      }
      else {
        var decimalTimeString = duration;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkTimeValid(n)) {
          setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  /**
   * * selectedDate => The initial value is today's date if the start_time from the timesheet is smaller than today's date and the end_time from the timesheet is larger than today's date, but otherwise the initial value is equal to the start_time timesheets
   * @param {date} selectDate roster date which will be taken from directus
   * TODO: get roster data from directus based on employee id chosen, the date selected, and based status equal to 'published', and then set rosterStart, rosterEnd, ordinaryHours, startTime, endTime, breakStartTime, and breakEndTime according to that data
   */
  const rosterData = async (selectDate) => {
    setDateLoading(true);
    try {
      var rosterList = await directus.getItems('roster',
        {
          fields: 'break, day, start_time, end_time, ordinary_hours',
          filter: {
            employee: { eq: props.employeeIdChosen },
            day: { eq: selectDate ? moment(selectDate).format('dddd').toLowerCase() : moment(selectedDate).format('dddd').toLowerCase() },
            status: { eq: 'published' },
          },
        });
    }

    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (rosterList.data.length > 0) {
      rosterList.data.map((data) => {
        let end_time = '';
        if (!stateRef.current.data.leave) {
          end_time = data.end_time;
        }
        else {
          end_time = moment('1/1/1991 ' + data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");
        }

        // end_time= moment('1/1/1991 '+data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");
        setRosterStart(moment('1/1/1991 ' + data.start_time).format());
        setRosterEnd(moment('1/1/1991 ' + end_time).format());
        setOrdinaryHours(data.ordinary_hours * 60);

        setStartTime(moment('1/1/1991 ' + data.start_time).format());
        setEndTime(moment('1/1/1991 ' + end_time).format());
        setBreakStartTime(getMiddleStartEndTime(data.start_time, end_time, data.break).start_time.format());
        setBreakEndTime(getMiddleStartEndTime(data.start_time, end_time, data.break).end_time.format());
      })
    }
    else {
      setRosterStart(null);
      setRosterEnd(null);
      setOrdinaryHours(null);
      setStartTime(null);
      setEndTime(null);
      setBreakStartTime(null);
      setBreakEndTime(null);
    }
    setDateLoading(false);
  }

  /**
   * TODO: determine the start and end for break time in the middle between the start_time and end_time roster according to the break value of the roster
   * @param {time} a start_time roster
   * @param {time} b end_time roster
   * @param {number} c break roster
   */
  function getMiddleStartEndTime(a, b, c) {
    var basedate = '1/1/1991 ';
    var momentStart = moment(basedate + a);
    var momentEnd = moment(basedate + b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes() / 2 - (c / 2);
    var start = moment(basedate + a).add(diff, 'minutes').format();
    var end = moment(start).add(c, 'minutes').format();
    
    return { start_time: moment(start), end_time: moment(end) };
  }

  /**
   * TODO: calculates the time duration from param a to b and returns the total minutes of that duration
   * @param {time} a start
   * @param {time} b end
   */
  function getDuration(a, b) {
    var momentStart = moment(a);
    var momentEnd = moment(b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes();
    return diff;
  }

  /**
   * * This function is executed when the user clicks the 'Save' button to create new session data
   * TODO: resolve overlapping sessions and update and save the old and new session data, according to the overlapping conditions that occur
   */
  const submitAdd = async () => {
    setAddLoading(true);
    const session_fields = 'id, employee, session_type, timesheet, session_date, session_end_time, session_start_time, entry_number, activity, customer';

    let date = moment(selectedDate).format('YYYY-MM-DD');
    let start = moment(startTime).format('HH:mm:ss');
    let endd = moment(endTime).format('HH:mm:ss');
    let nextDay = false;

    handleCloseDialog();
    
    if (moment('1/1/1991 ' + endd).diff('1/1/1991 ' + start, 'minutes') < 0) {
      nextDay = true;
    }
    
    let compareEnd = nextDay ?
      moment(moment(date).add(1, 'd')).format("YYYY-MM-DD")
      :
      moment(selectedDate).format('YYYY-MM-DD');

    var middle = [];
    
    try {
      middle = await directus.getItems('sessions', {
        // fields: '*',
        fields: session_fields,
        filter: {
          employee: { eq: props.employeeIdChosen },
          session_date: { eq: date },
          session_start_time: { lt: start },
          session_end_time: { gt: endd }
        },
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    
    if (nextDay) {
      middle = linq.from(middle.data)
      .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') < 0).toArray();
    }
    else {
      middle = middle.data;
    }
    
    if (middle.length > 0) {
      /**
       * * Sessions overlap, with conditions:
       * * The new session (start time-end time) that will be added is in the middle or between the time range (start time-end time) of the old session in directus
       * * Example : old session = 08.00-12.00, new session = 09.00-10.00
       * * Output => 3 session => 08.00-09.00, 09.00-10.00, and 10.00-12.00
       */
      var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")
      if (multipleSession === false) {
        setAddLoading(false);
        return
      }
      
      try {
        await directus.updateItem('sessions', middle[0].id, { session_end_time: moment(startTime).format("HH:mm:00") })
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      let overlapEnd = middle[0];
      overlapEnd = {
        ...overlapEnd,
        session_date: compareEnd,
        session_start_time: moment(endTime).format("HH:mm:00"),
        break_time: 0
      }
      delete overlapEnd.id;
      delete overlapEnd.entry_number;
      
      try {
        await directus.createItem('sessions', overlapEnd);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else {
      try {
        var inMiddle = await directus.getItems('sessions', {
          // fields: '*.*',
          fields: session_fields,
          filter: {
            employee: { eq: props.employeeIdChosen },
            session_date: { eq: moment(selectedDate).format('YYYY-MM-DD') },
            session_start_time: { gte: moment(startTime).format("HH:mm:ss") },
            session_end_time: { lte: moment(endTime).format("HH:mm:ss") }
          },
        });        
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if (inMiddle.data.length > 0) {
        /**
         * * Sessions overlap, with conditions:
         * * The old session (start time-end time) is in the middle or between the time range (start time-end time) of the new session to be added
         * * Example : old session = 08.00 - 09.00, new session = 07.00 - 10.00
         * * Output => new session = 07.00 - 10.00
         * * The old session will be deleted and a new session will be added
         */
        let deleteId = [];

        inMiddle.data.map((data, index) => {
          let hitung = moment('1/1/1991 ' + data.session_end_time).diff('1/1/1991 ' + data.session_start_time, 'minutes');

          if (hitung > 0) {
            deleteId[index] = data.id;
          }
        })

        if (deleteId.length > 0) {
          var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")

          if (multipleSession === false) {
            setAddLoading(false);
            return
          }
        }

        try {
          if (deleteId.length > 0) {
            await directus.deleteItems('sessions', deleteId)
          }
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      let deleteId = [];

      if (nextDay) {

        try {
          var inMiddleAfter = await directus.getItems('sessions', {
            // fields: '*',
            fields: session_fields,
            filter: {
              employee: { eq: props.employeeIdChosen },
              session_date: { eq: compareEnd },
              session_end_time: { lt: end }
            },
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }

        let insideMiddleAfter = linq.from(inMiddleAfter.data)
          .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') > 0).toArray();

        insideMiddleAfter.map((data, index) => {
          deleteId[index] = data.id;
        })

        if (deleteId.length > 0) {
          var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")
          if (multipleSession === false) {
            setAddLoading(false);
            return
          }

          try {
            await directus.deleteItems('sessions', deleteId)
          }
          catch (e) {
            enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
          }
        }
      }

      try {
        var Check = await directus.getItems('sessions', {
          // fields: '*',
          fields: session_fields,
          filter: {
            employee: { eq: props.employeeIdChosen },
            session_date: { eq: moment(selectedDate).format('YYYY-MM-DD') },
          },
        });

        var firstCheck = await directus.getItems('sessions', {
          // fields: '*',
          fields: session_fields,
          filter: {
            employee: { eq: props.employeeIdChosen },
            session_date: { eq: moment(selectedDate).format('YYYY-MM-DD') },
            session_start_time: { lt: moment(startTime).format("HH:mm:ss") },
          },
        });

        var first = await directus.getItems('sessions', {
          // fields: '*',
          fields: session_fields,
          filter: {
            employee: { eq: props.employeeIdChosen },
            session_date: { eq: moment(selectedDate).format('YYYY-MM-DD') },
            session_start_time: { lt: moment(startTime).format("HH:mm:ss") },
            session_end_time: { gt: moment(startTime).format("HH:mm:ss") }
          },
        });

      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      try {
        var end = await directus.getItems('sessions', {
          // fields: '*',
          fields: session_fields,
          filter: {
            employee: { eq: props.employeeIdChosen },
            session_date: { eq: compareEnd },
            session_start_time: { lt: moment(endTime).format("HH:mm:ss") },
            session_end_time: { gt: moment(endTime).format("HH:mm:ss") }
          },
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if ((first.data.length > 0 || end.data.length > 0) && deleteId.length === 0 && inMiddle.data.length === 0) {
        var multipleSession = window.confirm("Other overlapping sessions will also be modified. Are you sure?")
        if (multipleSession === false) {
          setAddLoading(false);
          return
        }
      }

      if (first.data.length > 0) {
        /**
         * * Sessions overlap, with conditions:
         * * That new session that will be added has a 'start time' that is in the middle or between the time range (start time-end time) of the old session in the directus
         * * Example : old session = 08.00 - 13.00, new session = 10.00 - 17.00
         * * Output => 08.00 - 10.00 (old session), 10.00 - 17.00 (new session)
         * * Update the end time of the old session to be the same as the start time of the new session
         */
        try {
          await directus.updateItem('sessions', first.data[0].id, { session_end_time: moment(startTime).format("HH:mm:00") })
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      if (end.data.length > 0) {
        /**
         * * Sessions overlap, with conditions:
         * * That new session that will be added has an 'end time' that is in the middle or between the time range (start time-end time) of the old session in the directus
         * * Example : old session = 14.00 - 16.00, new session = 13.00 - 15.00
         * * Output => 15.00 - 16.00 (old session), 13.00 - 15.00 (new session)
         * * Update the start time of the old session to be the same as the end time of the new session
        */
        try {
          await directus.updateItem('sessions', end.data[0].id, { session_start_time: moment(endTime).format("HH:mm:00") })
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      try {
        var checkbeforeFirst = await directus.getItems('sessions', {
          // fields: '*',
          fields: session_fields,
          filter: {
            employee: { eq: props.employeeIdChosen },
            session_date: { eq: moment(moment(date).add(-1, 'd')).format("YYYY-MM-DD") },
          },
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
      
      if (checkbeforeFirst.data.length > 0) {
        //* check if there is a data session on a date before the date selected and check if there is a data session that has an end time the next day
        checkbeforeFirst = linq.from(checkbeforeFirst.data)
        .where(x => moment('1/1/1991 ' + x.session_end_time).diff('1/1/1991 ' + x.session_start_time, 'minutes') < 0).toArray();
        
        if (checkbeforeFirst.length > 0) {
          if (moment('1/1/1991 ' + checkbeforeFirst[0].session_end_time).diff('1/1/1991 ' + start, 'minutes') > 0) {
            /**
             * * Sessions overlap, with conditions:
             * * The new session that will be added has a start time that is in the middle or between the time range (start time-end time) of the old session on the previous date, which is in the directus
             * * Example: old session on the previous date = 22.00 - 03.00, new session on the selected date = 02.00 - 05.00
             * * Output: 22.00 - 02.00 (old session on previous date), 02.00 - 05.00 (new session on selected date)
             * * Update the end time of the old session on the previous date to be the same as the start time of the new session
             */
            try {
              await directus.updateItem('sessions', checkbeforeFirst[0].id, { session_end_time: start })
            }
            catch (e) {
              enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
            }
          }
        }
      }
    }

    let data;

    //* if the selected session type is Manual Entry (on MFM Company) show_customer = true, then add the customer id and activity id selected by the user to the data variable. But if the session type selected is not 'Manual Entry' then just add the employee id chosen, the date selected, the id of session type selected, notes, and timesheet id chosen to the data variable
    if (state.data.show_customer === true) {
      data = {
        employee: props.employeeIdChosen,
        activity: activity.id,
        customer: customer.id,
        session_date: moment(selectedDate).format('YYYY-MM-DD'),
        session_type: state.data.id,
        notes: input.notes,
        timesheet: props.timesheetIdChosen
      }
    }
    else
    {
      data = {
        employee: props.employeeIdChosen,
        session_date: moment(selectedDate).format('YYYY-MM-DD'),
        session_type: state.data.id,
        notes: input.notes,
        timesheet: props.timesheetIdChosen,
      }
    }
    
    if(duration !== 0 && duration !== '')
    {
      data = {
        ...data,
        duration: parseTimeSpan(duration),
        session_start_time: null,
        session_end_time: null,
      }
    }
    else if ((startTime !== null && endTime !== null) || (startTime !== "Invalid date" && endTime !== "Invalid date")) {
      data = {
        ...data,
        session_start_time: moment(startTime).format("HH:mm:ss"),
        session_end_time: moment(endTime).format("HH:mm:ss"),
        duration: 0,
      }
    }

    if (state.data.show_break_start_end_time === true) {
      data = {
        ...data,
        break_start_time: breakStartTime === null || breakStartTime === 'Invalid date' ? null : moment(breakStartTime).format("HH:mm:ss"),
        break_end_time: breakEndTime === null || breakEndTime === 'Invalid date' ? null : moment(breakEndTime).format("HH:mm:ss"),
        break_time: 0
      }
    }
    else if (state.data.show_break_time === true) {
      data = {
        ...data,
        break_time: input.smoko ? input.smoko : 0,
        break_start_time: null,
        break_end_time: null,
      }
    }

    if ((state.checkbox_full_day === true || state.checkbox_public_holiday === false)) {
      if (((startTime === null && endTime === null) || (startTime === 'Invalid date' && endTime === 'Invalid date')) && ((rosterStartRef.current === null && rosterEndRef.current === null) || (rosterStartRef.current === 'Invalid date' && rosterEndRef.current === 'Invalid date'))) {
        data = {
          ...data,
          duration: state.data.default_duration,
          session_start_time: null,
          session_end_time: null,
          break_start_time: null,
          break_end_time: null,
        }
      }
    }

    var updateSession;

    //* add session data to directus based on data that has been typed or filled in by the user
    try {
      updateSession = await directus.createItem('sessions', data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      setAddLoading(false);
      return null;
    }

    callChild(updateSession.data, state.data.show_customer, state.data.upload_description, stateRef.current.data.session_options);

    if (keepCustomer === false) { setCustomer(null) }
    if (keepActivity === false) { setActivity(null) }

    setStartTime(null);
    setEndTime(null);
    setDuration('');
    setBreakStartTime(null);
    setBreakEndTime(null);
    setRosterStart(null);
    setOrdinaryHours(null);
    setRosterEnd(null);
    setInput({ notes: '', smoko: 0 });

    return null;
  }

  /**
   * TODO: Triggers run the updateSessionDetail function in the AddSessionListDetails file with passing some of its parameter data
   * @param {object} session Session data to be added
   * @param {boolean} value Session data with customer? true | false
   * @param {*} upload Session data with upload_description? true | false
   */
  function callChild(session, value, upload) {
    childRef.current.confirmEditDetails(session, createAnother, value, upload, optionsListRef.current);
  }

  // TODO: Handles input changes for break and note fields. When the user types greater than 20 in the break field (MFM), set the value of the break field to 20 in the input state. But if the input is less than 20 or when a change occurs in the notes field => set the value to be the same as what the user typed in the input state
  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    let smokoValue = null;
    if (name === 'smoko' && value > 20) {
      smokoValue = 20
    }

    if (smokoValue !== null) {
      setInput({ ...input, [name]: smokoValue });
    }
    else {
      setInput({ ...input, [name]: value });
    }
  }

  /**
   * * stateRef is the session type that is selected when the user wants to add a session
   * TODO : 
   * * 1. if the selected session type has session options data and has customer data assigned to employees from the currently opened timesheet, then get session_options based on these two data from directus
   * * 2. if the selected session type has session options data and the employee data from the currently opened timesheet has an award, then get session_options based on these two data from directus
   * * 3. if points 1 and 2 are not null, combine the results and take only the different data and set their value to the sessionOptions state. But if only point 1 is not null then set the value of point 1 into the sessionOptions state, similarly, if only point 2 is not null
   */
  const sessionOptionData = async () => {
    setLoadingSession(true);
    let listId = [];
    let csaId = [];
    let awardId = [];
    let sessionData = [];
    var OptionData1, OptionData2;

    try {
      if (stateRef.current.data) {
        if (stateRef.current.data.session_options.length > 0) {
          stateRef.current.data.session_options.map((value) => {
            if (value.session_options_id !== null) {
              listId = [...listId, value.session_options_id];
            }
          })

          var EmployeeData = await directus.getItems('employees', {
            fields: 'customer_assigned_to.id,award.id',
            filter: {
              'id': { in: props.employeeIdChosen }
            },
            limit: -1,
          });

          if (EmployeeData.data.length > 0) {
            EmployeeData.data.map((data) => {
              if (data.customer_assigned_to) {
                if (data.customer_assigned_to.id !== null) {
                  csaId = [...csaId, data.customer_assigned_to.id];
                }
              }
              if (data.award) {
                if (data.award.id !== null) {
                  awardId = [...awardId, data.award.id];
                }
              }
            })
          }

          if (listId.length > 0 && csaId.length > 0) {
            OptionData1 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'customers.customers_id': { in: csaId }
              },
              limit: -1,
            });
          }
          else {
            OptionData1 = null;
          }

          if (listId.length > 0 && awardId.length > 0) {
            OptionData2 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'awards.awards_id': { in: awardId },
              },
              limit: -1,
            });
          }
          else {
            OptionData2 = null;
          }

          if (OptionData1 && OptionData2) {
            sessionData = OptionData1.data.concat(OptionData2.data);

            var result = Enumerable.from(sessionData)
              .distinct(d => JSON.stringify(d))
              .toArray();

            const output = [...new Map(result.map(o => [o.id, o])).values()];

            setSessionOptions(output);
          }
          else if (OptionData1 === null && OptionData2) {
            setSessionOptions(OptionData2.data);
          }
          else if (OptionData1 && OptionData2 === null) {
            setSessionOptions(OptionData1.data);
          }
        }
        else {
          setSessionOptions([]);
        }
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    getSessionOptionsData(sessionOptionsRef.current);
    setLoadingSession(false);
  }

  /**
   * TODO: 
   * * > Show session option with set type = 1, if it has a value of only_overtime = true and the overtime duration is met, and input_type != 'none'
   * * > Show session option with set type = 5, if it has only_overtime = false and input_type != 'none'
   * * > Besides that, set the type value in the session option to not display
   * @param {object} sessionOptions Session options data 
   */
  function getSessionOptionsData(sessionOptions) {
    let result = [];
    sessionOptions.map((data, index) => {
      if (data.only_rostered_days && (rosterStartRef.current === 'Invalid date' && rosterEndRef.current === 'Invalid date')) {
        (result[index] = { id: data.id, value: data.default_value, type: '7', data: data })
      }
      else {
        if (data.only_overtime) {
          if ((ordinaryHoursRef.current + (data.after_overtime * 60)) <= getDuration(moment(startTimeRef.current).format("1991-01-01THH:mm:ss"), moment(endTimeRef.current).format("1991-01-01THH:mm:ss"))) {
            //* Check if there is overtime and check the type
            (data.input_type !== 'none' ?
              result[index] = { id: data.id, value: data.default_value, type: '1', data: data } //show
              : result[index] = { id: data.id, value: data.default_value, type: '2', data: data })
          }
          else {
            //* Check if duration of time is less than roster duration and check the type
            (data.input_type !== 'none' ?
              result[index] = { id: data.id, value: data.default_value, type: '3', data: data }
              : result[index] = { id: data.id, value: data.default_value, type: '4', data: data })
          }
        }
        else {
          //* Check if the only overtime is false and check the type
          (data.input_type !== 'none' ?
            result[index] = { id: data.id, value: data.default_value, type: '5', data: data } //show
            : result[index] = { id: data.id, value: data.default_value, type: '6', data: data })
        }
      }
    })
    setOptionsList(result);
  }

  /**
   * TODO: handle changes that occur in the session option by set optionList value that is ticked or unticked or typed in the input column based on its id
   * @param {event} event 
   * @param {*} id id of typed or checked or unchecked session options
   */
  const handleChangeSessionOptions = (event, id) => {
    const { name, value } = event.target;
    setOptionsList(
      optionsListRef.current.map((data) =>
      (id === data.id ?
        name === 'number' ?
          { ...data, value: value }
          :
          { ...data, value: event.target.checked === true ? 'true' : 'false' }
        : data)
      )
    );
  };

  if (selectData) {
    return (
      <span>
        <AddChip
          id="add_session_btn"
          size="medium"
          icon={<AddIcon />}
          disabled={props.status === 'deleted'}
          label="Add"
          onClick={handleClickOpen}
        />
        <Dialog
          id="add_session_dialog"
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          isopen={`${open}`}
          fullWidth={true}
          maxWidth={'lg'}
          scroll={'paper'}
        >
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <FormControl variant="outlined" size="small">
              <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="type_select"
                value={selectData ? selectData.length > 0 ? state.select : '' : ''}
                onChange={handleChange}
                label="Type"
              >
                {/* eslint-disable array-callback-return */}
                {Object.keys(selectData).map(function (key) {
                  if (selectData[key].name !== 'Clock In') {
                    return <MenuItem id={`type_${selectData[key].name.replace(/\s/g, '')}`} key={key} value={selectData[key].name}>{selectData[key].name}</MenuItem>
                  }
                })}
                {/* eslint-disable array-callback-return */}
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                id="session_date_picker"
                value={selectedDate}
                onChange={handleDateChange}
                format="EEE, MMMM do"
                size="small"
                inputVariant="outlined"
                label="Session Date"
                animateYearScrolling
                maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
              />
            </MuiPickersUtilsProvider>

            &nbsp;&nbsp;&nbsp;
            {state.data ?
              state.data.full_day ?
                <>
                  <FormControlLabel
                    label="Full day"
                    control={
                      <Checkbox
                        id="full_day_cb"
                        name="checkbox_full_day"
                        defaultChecked
                        checked={state.checkbox_full_day}
                        onChange={(e) => handleChangeChecked(e, state.data.id)}
                        sx={{
                          "&.Mui-checked": {
                            color: "#db8a2e"
                          }
                        }}
                      />
                    }
                  />
                </>
                : state.data.public_holiday ?
                  <>
                    <FormControlLabel
                      label="Did you work on the public holiday?"
                      control={
                        <Checkbox
                          id="public_holiday_cb"
                          name="checkbox_public_holiday"
                          checked={state.checkbox_public_holiday}
                          onChange={(e) => handleChangeChecked(e, state.data.id)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#db8a2e"
                            }
                          }}
                        />
                      }
                    />
                  </>
                  : ''
              : ''}
          </DialogTitle>
          <DialogContent dividers>
            <AddSessionList
              selectData={state.data}
              selectedDate={selectedDate}
              timesheets={props.timesheets}
              selectValue={state.select}
              changeActivity={changeActivity}
              changeCustomer={changeCustomer}
              changeStartTime={changeStartTime}
              changeEndTime={changeEndTime}
              changeDuration={changeDuration}
              time_convert={time_convert}
              startTime={startTime}
              endTime={endTime}
              duration={duration}
              activity={activityRef.current}
              customer={customerRef.current}
              dateLoading={dateLoading}
              fullDay={state.checkbox_full_day}
              publicHoliday={state.checkbox_public_holiday}
            />

            <AddSessionListDetails
              optionsList={optionsListRef.current}
              selectData={state.data}
              selectedDate={selectedDate}
              selectValue={state.select}
              session={props.session}
              ref={childRef}
              loadSession={props.loadSession}
              employeeIdChosen={props.employeeIdChosen}
              timesheetIdChosen={props.timesheetIdChosen}
              loadingSession={loadingSession}
              loadingSessionOption={loadingSessionOption}
              handleChangeSessionOptions={handleChangeSessionOptions}
              changeBreakStartTime={changeBreakStartTime}
              changeBreakEndTime={changeBreakEndTime}
              breakStartTime={breakStartTime}
              breakEndTime={breakEndTime}
              handleChangeInput={handleChangeInput}
              inputNotes={inputRef.current.notes}
              inputSmoko={inputRef.current.smoko}
              handleClose={handleClose}
              setAddLoading={setAddLoading}
              dateLoading={dateLoading}
              fullDay={state.checkbox_full_day}
              publicHoliday={state.checkbox_public_holiday}
              sessionOptions={sessionOptionsRef.current}
              rosterDuration={rosterStartRef.current !== null || rosterEndRef.current !== null ? getDuration(rosterStartRef.current, rosterEndRef.current) : 0}
              startEndDuration={startTimeRef.current !== null || endTimeRef.current !== null ? getDuration(moment(startTimeRef.current).format("1991-01-01THH:mm:ss"), moment(endTimeRef.current).format("1991-01-01THH:mm:ss")) : ''}
            />
          </DialogContent>
          <DialogActions>
            {createAnother ?
              <div>
                <FormControlLabel
                  control={<Checkbox id="keep_customer_cb" checked={keepCustomer} onChange={e => { setKeepCustomer(e.target.checked); }} />}
                  label="Keep Customer"
                />
                <FormControlLabel
                  control={<Checkbox id="keep_activity_cb" checked={keepActivity} onChange={e => { setKeepActivity(e.target.checked); }} />}
                  label="Keep Activity"
                />
              </div> : ''}
            <FormControlLabel
              control={<Checkbox id="create_another_cb" checked={createAnother} onChange={e => { setCreateAnother(e.target.checked); }} />}
              label="Create Another"
            />

            {addLoading ?
              <CircularProgress color="inherit" size={20} />
              :
              <div>
                <DialogActions>
                  <CancelButton
                    id="cancel_btn"
                    onClick={handleClose}
                  >
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                  <ApproveButton
                    disabled=
                    {state.checkbox_full_day === false && state.checkbox_public_holiday === true ?
                      state.data ?
                        state.data.show_customer === true ?
                          disableSession
                          :
                          disableLunch
                        : true
                      : ''
                    }
                    onClick=
                    {
                      state.data ?
                        state.data.start_end_time_from_roster ?
                          (rosterStartRef.current !== null || rosterEndRef.current !== null) && (rosterStartRef.current !== 'Invalid date' || rosterEndRef.current !== 'Invalid date') ?
                            (rosterStartRef.current !== startTime || rosterEndRef.current !== endTime) && (startTime !== null || endTime !== null) ?
                              () => handleClickOpenDialog()
                            :
                            () => submitAdd()
                          : () => submitAdd()
                        :
                        () => submitAdd()
                      : ''
                    }
                  >
                    <Typography variant="h6">Save</Typography>
                  </ApproveButton>
                </DialogActions>
              </div>
            }
          </DialogActions>
        </Dialog>

        <Dialog id="openDialogPaid" isopen={`${openDialog}`} open={openDialog} onClose={handleCloseDialog} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Save Session</DialogTitle>
          <DialogContent>
            <DialogContentText>
              This session has different start and end time with roster.
              <br />
              Are you sure to continue?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <ApproveButton
              onClick={() => submitAdd()}
            >
              <Typography variant="h6">Save</Typography>
            </ApproveButton>
            <CancelButton
              id="cancel_btn"
              onClick={() => handleCloseDialog()}
            >
              <Typography variant="h6">Cancel</Typography>
            </CancelButton>
          </DialogActions>
        </Dialog>

        {/* eslint-enable react/jsx-no-duplicate-props */}
      </span>
    );
  }
};

export default AddSession;