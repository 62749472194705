import directus from 'src/services/directus';
import { AdminPermission } from 'src/utils/Permission';
import linq from "linq";

/**
 * @param employeeData value is null 
 * @param status has a default value of 'approved' if the admin is logged in and 'pending' other than the admin being logged in. The status value can change when the user selects an item in the filter menu
 * @param type has value 2 if called from callSessions function in views>LeaveRequest>ApprovalListView.js but if called from callSessions function in file DashboardLayout>NavBar>index.js has value 1
 * TODO: Get session data from leave requests in directus, filter it based on the parameters obtained, and return it
 */
export const loadSessions = async (employeeData, status, type) => {
  let employeesChildIds = [];
  let filterData;

  if (AdminPermission()) {
    //* If the logged-in is admin, add filterData based on the status employee equal to 'published'
    filterData = {
      'employee.status': { eq: 'published' }
    }
  }
  else {
    /**
     * * If it's not the admin logged-in, get the logged-in user id from local storage,
     * * then get the data from employee items in directus.
     * * Check whether the user has employee data that must be approved for leave in the leave_approver data.
     * * Then add filterData based on employee id in one of the leave_approver data and filterData for employee status with value 'published'
     */
    let myId = JSON.parse(window.localStorage.getItem('directus_employee')).id;

    var EmployeeResult = await directus.getItems('employees', {
      // fields: '*.*',
      fields: 'leave_approver.child, leave_approver.id',
      filter: { id: { eq: myId } },
      limit: 1
    });

    if (EmployeeResult.data[0].leave_approver.length > 0) {
      EmployeeResult.data[0].leave_approver.map((leave, index) => {
        employeesChildIds[index + 1] = leave.child;
      })
    }

    filterData = {
      'employee.id': { in: employeesChildIds },
      'employee.status': { eq: 'published' }
    }
  }

  /**
   * * if the status is not 'all' and not 'approved' add filterData leave_status with operator eq or equal to the status
   * * if the status is 'pending' or 'approved' use the in (exists in one of the values) operator ​​to filter leave_status
   * * but if the status is 'all' then leave_status filter with nempty operator with true value. Nempty is the value that is not empty (null or falsy)
   */
  if (status !== 'all' && status !== 'approved') {
    filterData = {
      ...filterData,
      leave_status: { eq: status }
    }
  }
  else if (status === 'pending') {
    filterData = {
      ...filterData,
      leave_status: { in: ['pending'] }
    }
  }
  else if (status === 'approved') {
    filterData = {
      ...filterData,
      leave_status: { in: ['approved'] }
    }
  }
  else {
    filterData = {
      ...filterData,
      leave_status: { nempty: true }
    }
  }

  //* add filters for session_type.fulltime_only, session_date, and status
  filterData = {
    ...filterData,
    'session_type.fulltime_only': { nempty: true },
    'session_date': { gte: 'now' },
    'status': { in: ['pending', 'rejected', 'approved'] },
  }

  /**
   * * if the user is not an admin or does not have employee data that must be approved for leave then return an empty array
   * * if the logged-in user is admin or the user has employee data that must be approved for leave, then get the session data with the required fields from Directus with pre-defined filters.
   * * if no data in SessionsResult is obtained from directus, then check the type if it is equal to 2 return with [SessionsResult.data, SessionsResult.data], but if the type is not 2 return with SessionsResult.data
   * * but if there is data in SessionsResult obtained from directus, then :
   * *  - sort the data starting from data with pending status, then approved, approved_hrd, and rejected. 
   * *  - Then check the type, if type is not 2, then return the sorted session data.
   * *  - But if the type is equal to 2, then get only user data that is not null in the data that was sorted earlier. After that, get leave request data with different users, if there is the same user it is only taken once. Finally, return both data.
   */
  if (employeesChildIds.length > 0 || AdminPermission()) {
    var SessionsResult = await directus.getItems('sessions', {
      // fields: 'id,leave_status,session_type.*.*,attachments.*,employee.*.*,break_end_time,break_start_time,break_time,duration,options,resources,session_date,session_end_time,session_start_time,end_time,start_time,status,type',
      fields: 'id, leave_status, leave_note, session_date, duration, session_start_time, session_end_time, session_type.name, employee.id, employee.code, employee.user.id, employee.user.first_name, employee.user.last_name, employee.user.email',
      filter: filterData,
      sort: 'session_date',
      limit: -1,
    });

    let filterSession;
    if (SessionsResult.data.length > 0) {
      filterSession = linq.from(SessionsResult.data)
        .orderByDescending(x => x.leave_status === 'pending')
        .thenByDescending(x => x.leave_status === 'approved')
        .thenByDescending(x => x.leave_status === 'approved_hrd')
        .thenByDescending(x => x.leave_status === 'rejected').toArray();

      if (type === 2) {
        let employeesId = [];
        var employeeFiltered = [];

        filterSession.map((data, index) => {
          if (data.user !== null) {
            employeesId[index] = data.employee;
          }
        })

        /**
         * * the variable i will be worth -1 if no data is found
         * TODO: get leave request data with different users, if there is the same user it is only taken once
         */
        employeesId.forEach(function (item) {
          var i = employeeFiltered.findIndex(x => x.user.id === item.user.id);
          if (i <= -1) {
            employeeFiltered.push({ ...item });
          }
        });

        //* sort the data in the employeeFiltered variable based on the code
        let filterEmployee = linq.from(employeeFiltered)
          .orderBy(x => x.code).toArray();

        return [filterSession, filterEmployee];
      }

      return filterSession;
    }
    else {
      if (type === 2) {
        return [SessionsResult.data, SessionsResult.data];
      }
      return SessionsResult.data;
    }
  }
  else {
    return [];
  }
};