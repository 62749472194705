import React, { useState } from 'react';
import { 
  Grid,
  ListItemText,
  CircularProgress,
  TextField,
  OutlinedInput,
  InputAdornment,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import InsertInvitationIcon from '@material-ui/icons/AccessAlarm';
import directus from '../../services/directus';
import './style.css';
import { MuiPickersUtilsProvider,  KeyboardTimePicker, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useStyles } from './style.js';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import Checkbox from '@mui/material/Checkbox';

const EditSessionList = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [smoko, setSmoko] = React.useState(props.session.break_time?props.session.break_time:0);
  const [duration, setDuration] = React.useState(props.session.duration?time_convert(props.session.duration):'');
  const [selectedDate, setSelectedDate] = useState(moment(props.session.session_date).format("YYYY-MM-DD"));
  const [lengthCustomer, setLengthCustomer] = React.useState('');
  const [lengthActivity, setLengthActivity] = React.useState('');
  const [fullDay, setFullDay] = React.useState(false);
  const [publicHoliday, setPublicHoliday] = React.useState(true);
  const loading = open && options.length === 0;
  const { enqueueSnackbar } = useSnackbar();

  /**
   * TODO: calculate hours and minutes from session duration
   * @param {number} num is session duration
   * @returns hours and minutes
   */
  function time_convert(num)
  { 
    var hours = Math.floor(num / 60);  
    var minutes = num % 60;

    return hours + "h " + minutes +"m";         
  }

  /**
   * TODO: check whether the input in the duration field is valid or not
   * @param {time} data 
   */
  const checkTimeValid = (data) => {
    if(moment(data).isValid() && moment(data).format('HH:mm') !== '00:00')
    {
      return true;
    }
    else
    {
      setDuration('');
    }
  }
  
  /**
   * * when then the user leaves the duration field or the duration field loses focus (onBlur), this function is executed
   * * duration equal to which the user typed in the duration field
   * TODO: 
   * * if duration is not empty or not null or not = 0 then:
   * * check whether the input from the user includes the letter h(for hours) or m(for minutes)?
   * *  > if true :
   * *    -> check if there is only m (parts.length === 1)?
   * *      ~ if true, then parse to integer and check whether the value is valid or not, if valid set duration state with the results of the parse to integer and combined with the letter m. But if it is not valid then set the duration state to be empty
   * *      ~ if false, then calculate: the result of parse to integer(split h index-0) * 60 plus the result of parse to integer(split h index-1 which is given a default value of 0 so that it is not NaN), if the result is valid set duration by combining hour values and the minutes
   * *  > if the input from the user does not include the letters h or m, then: get the hour from the number typed, if typed is the number 24 it means it is at 00.00, if it is more than 24, then subtract with the number 24, until the last subtraction result is smaller than 24 (because in 1 day there are only 24 hours)
   */
  function timeConvertBlur()
  { 
    if(duration !== '' || duration !== "0" || duration !== null || duration !== 0)
    {
      let parts = duration.split("h");
      let parts2 = duration.split("m");
      if(parts.length > 1 || parts2.length > 1)
      {
        if(parts.length === 1)
        {
          if(checkTimeValid(parseInt(parts[0].slice(0, -1), 10)))
          {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m")
          }
        }
        else
        {
          if(checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1)|0, 10)))))
          {
            setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1)|0, 10) +"m"))
          }
        }
      }
      else
      {
        var decimalTimeString = duration;
        var n = new Date(0,0);
        n.setMinutes(+decimalTimeString * 60);
        if(checkTimeValid(n))
        {
          setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  /**
   * TODO: get all the customer data from directus and get each first letter, then set that value to the options state
   * * If the user clicks on the column select customer, this function will be executed
   */
  function callCustomers(){
    let active = true;

    if (!loading) {
      return undefined;
    }

    (async () => {
      // var employee = await getEmployee();
      try
      {
        var CustomerEmployeeResult = await directus.getItems('customers', {
          // fields: '*',
          fields: 'id, name',
          filter: {
            selectable: 1,
            status: 'published'
          },
          limit:-1,
          sort: "name"
        });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }

      const options = CustomerEmployeeResult.data.map((option) => {
        const firstLetter = option.name[0].toUpperCase();
        return {
          firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
          ...option,
        };
      });

      if (active) {
        setOptions(options);
      }
    })();

    return () => {
      active = false;
    };
  }

  // TODO: If there is a change value in the loading variable it will run the callCustomers function
  React.useEffect(() => {
    callCustomers();
  }, [loading]);

  // TODO: if the user clicks on the 'select customer' field, the 'open' state will be set to true, but if not, the 'open' state will be set to false, so the options state will be set to an empty array
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const [openActivity, setOpenActivity] = useState(false);
  const [optionsActivity, setOptionsActivity] = useState([]);
  const loadingActivity = openActivity && optionsActivity.length === 0;

  /**
   * * If the user clicks on the column select activity, this function will be executed
   * TODO: get all activity data from directus and set that value to the optionsActivity state
   */
  function callActivity(){
    let activeActivity = true;

    if (!loadingActivity) {
      return undefined;
    }
    
    try
    {
      (async () => {
        var Activities = await directus.getItems('activities', {
          // fields: '*',
          fields: 'id, code, name',
          limit:-1,
          filter: {
            selectable: 1,
            status: 'published'
          },
          sort: "name"
        });

        if (activeActivity) {
          setOptionsActivity(Object.keys(Activities.data).map((key) => Activities.data[key]));
        }
      })();
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    return () => {
      activeActivity = false;
    };
  }

  // TODO: If there is a change value in the loadingActivity variable it will run the callActivity function
  React.useEffect(() => {
    callActivity();
  }, [loadingActivity]);

  // TODO: if the user clicks on the 'select activity' field, the 'openActivity' state will be set to true, but if not, the 'openActivity' state will be set to false, so the optionsActivity state will be set to an empty array
  React.useEffect(() => {
    if (!openActivity) {
      setOptionsActivity([]);
    }
  }, [openActivity]);

  const [startDate, setStartDate] = useState(props.session.session_start_time?moment(props.session.session_date + "T" +props.session.session_start_time).toDate():null);
  const [endDate, setEndDate] = useState(props.session.session_end_time?moment(props.session.session_date + "T" +props.session.session_end_time).toDate():null);
  const [breakStartTime, setBreakStartTime] =  React.useState(props.session.break_start_time?moment(props.session.session_date+' '+props.session.break_start_time).toDate():null);
  const [breakEndTime, setBreakEndTime] = React.useState(props.session.break_end_time?moment(props.session.session_date+' '+props.session.break_end_time).toDate():null);

  /**
   * TODO: handles changes from the 'Start Time' field, by changing the 'startDate' state to be equal to the selected time in the 'Start Time' input field and invoking the changeEditStartTime function from props by passing the user's selected time as the parameter value
   * @param {time} e 
   */
  const handleStartDateChange = e => {
    setStartDate(new Date(moment(props.session.session_date).format("YYYY-MM-DD") +"T"+ moment(e).format("HH:mm:ss")));
    setEndDate(new Date(moment(props.session.session_date).format("YYYY-MM-DD") +"T"+ moment(endDate).format("HH:mm:ss")));
    
    props.changeEditStartTime(moment(props.session.session_date).format("YYYY-MM-DD") +" "+ moment(e).format("HH:mm:ss"));
    props.changeEditEndTime(moment(props.session.session_date).format("YYYY-MM-DD") +" "+ moment(endDate).format("HH:mm:ss"));
  }
  
  /**
   * TODO: handles changes from the 'End Time' field, by changing the 'EndDate' state to be equal to the selected time in the 'End Time' input field and invoking the changeEditEndTime function from props by passing the user's selected time as the parameter value
   * @param {time} e 
   */
  const handleEndDateChange = e => {
    setEndDate(new Date(moment(props.session.session_date).format("YYYY-MM-DD") +"T"+ moment(e).format("HH:mm:ss")));
    props.changeEditEndTime(moment(props.session.session_date).format("YYYY-MM-DD") +" "+ moment(e).format("HH:mm:ss"));  
  }

  /**
   * TODO: sets the changed value in the 'Break Start Time' input field to the breakStartTime state and sends the changed value as a parameter to the changeEditBreakStartTime function of props. (Only in GTNT Compony)
   * @param {time} e 
   */
  const handleBreakStartChange = e => {
    setBreakStartTime(e);
    props.changeEditBreakStartTime(e !== null?moment(e).format("HH:mm:ss"):'empty');
  }
  
  /**
   * TODO: sets the changed value in the 'Break End Time' input field to the breakEndTime state and sends the changed value as a parameter to the changeEditBreakEndTime function of props. (Only in GTNT Compony)
   * @param {time} e 
   */
  const handleBreakEndChange = e => {
    setBreakEndTime(e);
    props.changeEditBreakEndTime(e !== null?moment(e).format("HH:mm:ss"):'empty');
  }

  /**
   * TODO: 
   * * > If name = smoke and the input of user is greater than 20, set the value to 20 to smoke state and pass that value as a parameter to the changeEditSmoke function of props. But if the input is less than 20 continue with the value input by the user.
   * * > If name = 'duration', then set value to duration state and pass value as parameter to the changeEditDuration function of props.
   * @param {event} e 
   */
  const handleChangeInput = (e) => {
    const {name, value} = e.target;
    
    let smokoValue=null;
    if(name === 'smoko')
    {
      if(value > 20)
      {
        smokoValue = 20
      }

      if(smokoValue !== null)
      {
        setSmoko(smokoValue);
        props.changeEditSmoko(smokoValue)
      }
      else
      {
        setSmoko(value);
        props.changeEditSmoko(value);
      }
    }
    else if(name === 'duration')
    {
      setDuration(value);
      props.changeEditDuration(value);
    }
  }

  /**
   * TODO: sets the session date change to selectedDate state and passes that date change as a parameter to the changeSelectedDate function of props
   * @param {date} value The date selected by the user
   */
  function handleDateChange(value)
  {
    setDuration(time_convert(props.session.duration));
    props.changeEditDuration(time_convert(props.session.duration));
    
    setSelectedDate(value);
    props.changeSelectedDate(value);
    
  }

  //* Options filter in select customer column. To filter customer options based on the option name typed by the user in the select customer column
  const filterOptionsCustomer = createFilterOptions({
    matchFrom: lengthCustomer.length<2?'start':'any',
    stringify: option => option.name,
  });

  //* Options filter in select activity column. To filter activity options based on the option name typed by the user in the select activity column
  const filterOptionsActivity = createFilterOptions({
    matchFrom: lengthActivity.length<2?'start':'any',
    stringify: option => option.name,
  });

  /**
   * TODO: 
   * * 1. set the fullDay state value to 'true' if the user ticked or 'false' if unticked in the 'full day' checkbox and pass that value as a parameter to the changeEditFullDay function of props
   * * 2. set the publicHoliday state value to 'true' if the user ticks or 'false' if unticked in the checkbox labeled 'Did you work on the public holiday' that appears if the user selects session type 'Public Holiday' and sends that value as a parameter to the changeEditPublicHoliday function from props
   * @param {event} event 
   */
  const handleChangeChecked = (event) =>{
    const { name } = event.target;

    if (name === 'checkbox_full_day') {
      setFullDay(event.target.checked);
      props.changeEditFullDay(event.target.checked);
    }
    else if (name === 'checkbox_public_holiday') {
      setPublicHoliday(event.target.checked);
      props.changeEditPublicHoliday(event.target.checked);
    }
  }

  //* If from the session to be edited the session type does not display a customer or show_customer = false
  if(props.session.session_type.show_customer === false)
  {
    //* no customer and with breaks start and end time
    if(props.session.session_type.show_break_start_end_time)
    {
      return(
        <ListItemText
          key={props.session.id}
          primary={
          <>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={5} className={classes.grid4}>
                <Typography>
                  <span className={classes.sessiontype}>{props.session.session_type.name}</span>   
                  {props.session.session_type.full_day?
                    <FormControlLabel
                      label="Full day"
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          id="full_day_cb"
                          name="checkbox_full_day"
                          label="Full day"
                          onChange={(e)=>handleChangeChecked(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#db8a2e"
                            }
                          }}
                        />
                      }
                    />
                  :props.session.session_type.public_holiday?
                    <>
                      <FormControlLabel
                        label="Did you work on the public holiday?"
                        className={classes.checkbox}
                        control={
                          <Checkbox
                            id="public_holiday_cb"
                            name="checkbox_public_holiday"
                            checked={publicHoliday}
                            onChange={(e)=>handleChangeChecked(e)}
                            sx={{
                              "&.Mui-checked": {
                                color: "#db8a2e"
                              }
                            }}
                          />
                        }
                      />
                    </>
                  :''}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>
              <Grid item xs={2} className={classes.grid2}></Grid>
              <Grid item xs={2} className={classes.grid2}></Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>
            </Grid>

            <Grid container spacing={0} justifyContent="flex-start" className={classes.grid}>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    label="Session Date"
                    inputVariant="outlined"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>

              {fullDay === false && publicHoliday === true?
                <>
                {props.session.session_type.show_start_end_time?
                <>
                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        format="hh:mm a"
                        inputVariant="outlined"
                        size="small"
                        value={startDate}
                        label="Start Time"
                        error={false}
                        helperText={null}
                        onChange={handleStartDateChange}
                        keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        format="hh:mm a"
                        inputVariant="outlined"
                        size="small"
                        label="End Time"
                        value={endDate}
                        error={false}
                        helpertext={null}
                        onChange={handleEndDateChange}
                        keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  </>
                :''}

                {props.session.session_type.show_duration?
                  <Grid item xs>
                    <OutlinedInput
                      style={{width: '100%'}}
                      name="duration"
                      className={classes.duration}
                      value={duration}
                      error={false}
                      helpertext={null}
                      onChange={handleChangeInput}
                      onBlur={timeConvertBlur}
                    />
                  </Grid>
                :''} 
                <Grid item xs>  
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      id="break_start_time"
                      name="break_start_time"
                      label="Break start"
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      style={{width: '100%'}}
                      error={false}
                      helperText={null}
                      value={breakStartTime}
                      onChange={handleBreakStartChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                    />
                  </MuiPickersUtilsProvider> 
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      id="break_end_time"
                      name="break_end_time"
                      label="Break End"
                      format="hh:mm a"
                      inputVariant="outlined"
                      style={{width: '100%'}}
                      size="small"
                      error={false}
                      helperText={null}
                      value={breakEndTime}
                      onChange={handleBreakEndChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                    />
                  </MuiPickersUtilsProvider> 
                </Grid>
                <Grid item xs></Grid>
              </>
              :''}
            </Grid>
          </>
        }/>
      );
    }
    //* no customer and with breaks time
    else if(props.session.session_type.show_break_time)
    {
      return(
        <ListItemText
          key={props.session.id}
          primary={
          <> 
            <Grid container spacing={0} justifyContent="space-between" className={classes.grid}>
              <Grid item xs={3}>
                <Typography className={classes.sessiontype}>{props.session.session_type.name}</Typography>
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    inputVariant="outlined"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {props.session.session_type.show_start_end_time?
              <>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                       style={{width: '100%'}}
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      label="Start Time"
                      value={startDate}
                      error={false}
                      helperText={null}
                      onChange={handleStartDateChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                       style={{width: '100%'}}
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      label="End Time"
                      value={endDate}
                      error={false}
                      helpertext={null}
                      onChange={handleEndDateChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </>
              :''}
              {props.session.session_type.show_duration?
                <Grid item xs>
                  <OutlinedInput
                    style={{width: '100%'}}
                    name="duration"
                    className={classes.duration}
                    value={duration}
                    error={false}
                    helpertext={null}
                    onChange={handleChangeInput}
                    onBlur={timeConvertBlur}
                  />
                </Grid>
              :''}
              <Grid item xs>
                <OutlinedInput
                  name="smoko"
                  className={classes.notes}
                  value={smoko} 
                  onChange={handleChangeInput}
                  type="number"
                  endAdornment={<InputAdornment className={classes.mins} position="end">mins</InputAdornment>}
                />
              </Grid>
            </Grid>
          </>
        }/>
      );
    }    
    else
    {
      //* no customer and no breaks time
      return(
        <ListItemText
          key={props.session.id}
          primary={
          props.session.session_type.full_day || props.session.session_type.public_holiday?
          <>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={5}>
                <Typography>
                  <span className={classes.sessiontype}>{props.session.session_type.name}</span>   
                  {props.session.session_type.full_day?
                    <FormControlLabel
                      label="Full day"
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          id="full_day_cb"
                          name="checkbox_full_day"
                          label="Full day"
                          onChange={(e)=>handleChangeChecked(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#db8a2e"
                            }
                          }}
                        />
                      }
                    />
                  :props.session.session_type.public_holiday?
                    <>
                      <FormControlLabel
                        label="Did you work on the public holiday?"
                        className={classes.checkbox}
                        control={
                          <Checkbox
                            id="public_holiday_cb"
                            name="checkbox_public_holiday"
                            checked={publicHoliday}
                            onChange={(e)=>handleChangeChecked(e)}
                            sx={{
                              "&.Mui-checked": {
                                color: "#db8a2e"
                              }
                            }}
                          />
                        }
                      />
                    </>
                  :''}
                </Typography>
              </Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>
              <Grid item xs={2} className={classes.grid2}></Grid>
              <Grid item xs={2} className={classes.grid2}></Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>
            </Grid>

            <Grid container spacing={0} justifyContent="space-between" className={classes.grid}>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    inputVariant="outlined"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {fullDay === false && publicHoliday === true?
              <>
                {props.session.session_type.show_start_end_time?
                <>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      style={{width: '100%'}}
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      label="Start Time"
                      value={startDate}
                      error={false}
                      helperText={null}
                      onChange={handleStartDateChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      style={{width: '100%'}}
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      label="End Time"
                      value={endDate}
                      error={false}
                      helpertext={null}
                      onChange={handleEndDateChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                </>
                :''}
                {props.session.session_type.show_duration?
                  <Grid item xs>
                    <OutlinedInput
                      style={{width: '100%'}}
                      name="duration"
                      className={classes.duration}
                      value={duration}
                      error={false}
                      helpertext={null}
                      onChange={handleChangeInput}
                      onBlur={timeConvertBlur}
                    />
                  </Grid>
                :''}
                <Grid item xs>
                </Grid>       
              </>
              :''}
            </Grid>
          </>
          :
          <>
            {/* no customer and no breaks time and no full day or public holiday */}
            <Grid container spacing={0} className={classes.grid} justifyContent="flex-start">
              <Grid item xs={3} className={classes.grid3}>
                <Typography>
                  <span>{props.session.session_type.name}</span>   
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    inputVariant="outlined"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              {props.session.session_type.show_start_end_time?
                <>
                  <Grid item xs="auto">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        format="hh:mm a"
                        inputVariant="outlined"
                        size="small"
                        label="Start Time"
                        value={startDate}
                        error={false}
                        helperText={null}
                        onChange={handleStartDateChange}
                        keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs="auto">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        format="hh:mm a"
                        inputVariant="outlined"
                        size="small"
                        label="End Time"
                        value={endDate}
                        error={false}
                        helpertext={null}
                        onChange={handleEndDateChange}
                        keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </>
              :''}
              {props.session.session_type.show_duration?
                <Grid item xs>
                  <OutlinedInput
                    name="duration"
                    className={classes.duration}
                    value={duration}
                    error={false}
                    helpertext={null}
                    onChange={handleChangeInput}
                    onBlur={timeConvertBlur}
                  />
                </Grid>
              :''}
            </Grid>
          </>          
        }/>
      );
    }
  }
  else
  {
    //* with customer and with breaks start and end time
    if(props.session.session_type.show_break_start_end_time)
    {
      return(
        <ListItemText
          key={props.session.id}
          primary={
          <div>
            <Grid container spacing={0} justifyContent="flex-start" className={classes.grid}>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    inputVariant="outlined"
                    label="Date"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}></Grid>
              {props.session.session_type.show_start_end_time?
                <Grid item xs={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      style={{width: '100%'}}
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      placeholder="08:00 AM"
                      label="Start Time"
                      mask="__:__ _M"
                      error={false}
                      helperText={null}
                      value={startDate}
                      onChange={handleStartDateChange}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              :''}             

              {props.session.session_type.show_duration?<Grid item xs={1}></Grid>:''}

              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    style={{width: '100%'}}
                    id="break_start_time"
                    name="break_start_time"
                    label="Break start"
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    mask="__:__ _M"
                    error={false}
                    helperText={null}
                    value={breakStartTime}
                    onChange={handleBreakStartChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                  />
                </MuiPickersUtilsProvider>                      
              </Grid>
              <Grid item xs></Grid>
            </Grid>
  
            <Grid container spacing={0} justifyContent="flex-start">
              <Grid item xs={3}>
                {/* eslint-disable react/jsx-no-duplicate-props */}
                <Autocomplete
                  style={{width: '100%'}}
                  id="autoCustomer"
                  open={open}
                  onFocus={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    props.changeAutoCustomer(newValue);
                  }}
                  filterOptions={filterOptionsCustomer}
                  size="small"
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.customer}
                      variant="outlined"
                      onChange={(e)=>setLengthCustomer(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {/* eslint-enable react/jsx-no-duplicate-props */}
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  style={{width: '100%'}}
                  id="Activity"
                  open={openActivity}
                  onFocus={() => {
                    setOpenActivity(true);
                  }}
                  onClose={() => {
                    setOpenActivity(false);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={optionsActivity}
                  onChange={(event, newValue) => {
                    props.changeAutoActivity(newValue);
                  }}
                  filterOptions={filterOptionsActivity}
                  size="small"
                  loading={loadingActivity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.activity}
                      variant="outlined"
                      onChange={(e)=>setLengthActivity(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingActivity ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {props.session.session_type.show_start_end_time?
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    style={{width: '100%'}}
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    mask="__:__ _M"
                    label="End Time"
                    value={endDate}
                    error={false}
                    helperText={null}
                    onChange={handleEndDateChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              :''}

              {props.session.session_type.show_duration?
              <Grid item xs={1}>
                <OutlinedInput
                  style={{width: '100%'}}
                  name="duration"
                  placeholder="1h 30m"
                  className={classes.duration}
                  value={duration} 
                  onChange={handleChangeInput}
                  onBlur={timeConvertBlur}
                />
              </Grid>
              :''}

              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    style={{width: '100%'}}
                    id="break_end_time"
                    name="break_end_time"
                    label="Break End"
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    mask="__:__ _M"
                    error={false}
                    helperText={null}
                    value={breakEndTime}
                    onChange={handleBreakEndChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                  />
                </MuiPickersUtilsProvider>                      
              </Grid>
              <Grid item xs></Grid>
            </Grid>
          </div>
        }/>
      );
    }  
    //* with customer and with breaks time
    else if(props.session.session_type.show_break_time)
    {
      return(
        <ListItemText
          key={props.session.id}
          primary={
          <div>
            <Grid container spacing={0} justifyContent="flex-start"  className={classes.grid}>
              <Grid item xs={5} className={classes.grid3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    inputVariant="outlined"
                    label="Date"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>

                {props.session.session_type.full_day?
                  <FormControlLabel
                    label="Full day"
                    className={classes.checkbox}
                    control={
                      <Checkbox
                        id="full_day_cb"
                        name="checkbox_full_day"
                        label="Full day"
                        onChange={(e)=>handleChangeChecked(e)}
                        sx={{
                          "&.Mui-checked": {
                            color: "#db8a2e"
                          }
                        }}
                      />
                    }
                  />
                :props.session.session_type.public_holiday?
                  <>
                    <FormControlLabel
                      label="Did you work on the public holiday?"
                      className={classes.checkbox}
                      control={
                        <Checkbox
                          id="public_holiday_cb"
                          name="checkbox_public_holiday"
                          checked={publicHoliday}
                          onChange={(e)=>handleChangeChecked(e)}
                          sx={{
                            "&.Mui-checked": {
                              color: "#db8a2e"
                            }
                          }}
                        />
                      }
                    />
                  </>
                :''}
              </Grid>
              
              <Grid item xs={2} className={classes.grid3}></Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>             
              <Grid item xs={2} className={classes.grid2}></Grid>
              <Grid item xs={2} className={classes.grid2}></Grid>
            </Grid>                        
            <Grid container spacing={0} justifyContent="flex-start">
              <Grid item xs={3}  className={classes.grid3}>
                {/* eslint-disable react/jsx-no-duplicate-props */}
                <Autocomplete
                  id="autoCustomer"
                  open={open}
                  onFocus={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    props.changeAutoCustomer(newValue);
                  }}
                  filterOptions={filterOptionsCustomer}
                  size="small"
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.customer}
                      variant="outlined"
                      onChange={(e)=>setLengthCustomer(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {/* eslint-enable react/jsx-no-duplicate-props */}
              </Grid>
              <Grid item xs={3} className={classes.grid3}>
              <Autocomplete
                  id="Activity"
                  open={openActivity}
                  onFocus={() => {
                    setOpenActivity(true);
                  }}
                  onClose={() => {
                    setOpenActivity(false);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={optionsActivity}
                  onChange={(event, newValue) => {
                    props.changeAutoActivity(newValue);
                  }}
                  filterOptions={filterOptionsActivity}
                  size="small"
                  loading={loadingActivity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.activity}
                      variant="outlined"
                      onChange={(e)=>setLengthActivity(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingActivity ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {props.session.session_type.show_start_end_time?
              <>
              <Grid item xs={1} className={classes.grid1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    style={{width: '100%'}}
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    label="Start Time"
                    mask="__:__ _M"
                    error={false}
                    helperText={null}
                    value={startDate}
                    onChange={handleStartDateChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={1} className={classes.grid1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    style={{width: '100%'}}
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    mask="__:__ _M"
                    label="End Time"
                    value={endDate}
                    error={false}
                    helperText={null}
                    onChange={handleEndDateChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              </>
              :''}
              {props.session.session_type.show_duration?
              <Grid item xs={2} className={classes.grid2}>
                <OutlinedInput
                  style={{width: '100%'}}
                  name="duration"
                  placeholder="1h 30m"
                  className={classes.duration}
                  value={duration} 
                  onChange={handleChangeInput}
                  onBlur={timeConvertBlur}
                />
              </Grid>     
              :''}   
              <Grid item xs={2} className={classes.grid2}>
                <OutlinedInput
                  name="smoko"
                  className={classes.notes}
                  value={smoko} 
                  onChange={handleChangeInput}
                  type="number"
                  endAdornment={<InputAdornment className={classes.mins} position="end">mins</InputAdornment>}
                />
              </Grid>
            </Grid>
          </div>
        }/>
      );
    }
    else
    {
      //* with customer and no breaks
      return(
        <ListItemText
          key={props.session.id}
          primary={
          <div>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={3} className={classes.grid3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    style={{width: '100%'}}
                    value={selectedDate}
                    onChange={handleDateChange}
                    size="small"
                    inputVariant="outlined"
                    label="Date"
                    animateYearScrolling
                    maxDate={moment(props.timesheets.end_time).format("YYYY-MM-DD")}
                    minDate={moment(props.timesheets.start_time).format("YYYY-MM-DD")}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3} className={classes.grid3}></Grid>
              <Grid item xs={1} className={classes.grid1}></Grid>             
              <Grid item xs={2} className={classes.grid2}></Grid>
              <Grid item xs={2} className={classes.grid2}></Grid>
            </Grid>
            <Grid container spacing={3} justifyContent="space-between">
              <Grid item xs={3} className={classes.grid3}>
                {/* eslint-disable react/jsx-no-duplicate-props */}
                <Autocomplete
                  id="autoCustomer"
                  open={open}
                  onFocus={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    props.changeAutoCustomer(newValue);
                  }}
                  filterOptions={filterOptionsCustomer}
                  size="small"
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.customer}
                      variant="outlined"
                      onChange={(e)=>setLengthCustomer(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
                {/* eslint-enable react/jsx-no-duplicate-props */}
              </Grid>
              <Grid item xs={3} className={classes.grid3}>
              <Autocomplete
                  id="Activity"
                  open={openActivity}
                  onFocus={() => {
                    setOpenActivity(true);
                  }}
                  onClose={() => {
                    setOpenActivity(false);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={optionsActivity}
                  onChange={(event, newValue) => {
                    props.changeAutoActivity(newValue);
                  }}
                  filterOptions={filterOptionsActivity}
                  size="small"
                  loading={loadingActivity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={props.activity}
                      variant="outlined"
                      onChange={(e)=>setLengthActivity(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingActivity ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              {props.session.session_type.show_start_end_time?
              <>
              <Grid item xs={1} className={classes.grid1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    mask="__:__ _M"
                    label="Start Time"
                    error={false}
                    helperText={null}
                    value={startDate}
                    onChange={handleStartDateChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={1} className={classes.grid1}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    format="hh:mm a"
                    inputVariant="outlined"
                    size="small"
                    placeholder="08:00 AM"
                    mask="__:__ _M"
                    label="End Time"
                    value={endDate}
                    error={false}
                    helperText={null}
                    onChange={handleEndDateChange}
                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker}/>}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              </>
              :''}
              {props.session.session_type.show_duration?
              <Grid item xs={2} className={classes.grid2}>
                <OutlinedInput
                  style={{width: '100%'}}
                  name="duration"
                  placeholder="1h 30m"
                  className={classes.duration}
                  value={duration} 
                  onChange={handleChangeInput}
                  onBlur={timeConvertBlur}
                />
              </Grid>
              :''}        
              <Grid item xs={2} className={classes.grid2}>
              </Grid>
            </Grid>
          </div>
        }/>
      );
    }
  }
};

export default EditSessionList;