import React, { lazy, Suspense } from 'react';
import {
  Container,
  AppBar,
  Tabs,
  Tab,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';
import PropTypes from 'prop-types';
import EventEmitter from 'src/utils/EventEmitter';
import { useStyles } from './style.js';

const Employees = lazy(() => import('./Employees'));
const Customers = lazy(() => import('./Customers'));
const PublicHolidays = lazy(() => import('./PublicHolidays'));
const Activities = lazy(() => import('./Activities'));
const SessionOption = lazy(() => import('./SessionOption'));
const Integration = lazy(() => import('./Integration'));
const ConfigurationSettings = lazy(() => import('./Configuration'));
const Awards = lazy(() => import('./Awards'));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={30}>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Settings = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  let enableAward = JSON.parse(window.localStorage.getItem('config_ts')).enable_awards;

  const handleChange = (event, newValue) => {
    setValue(newValue);
    changeTab(newValue);
  };

  const changeTab = (data) => {
    EventEmitter.emit('changeTab', {
      text: data
    })
  }

  return (
    <Page className={classes.root} title="Settings">
      <Container maxWidth={false}>
        <div className="control-pane">
          <div className="control-section">
            <Suspense fallback={''}>
              <AppBar position="static" className={classes.Appbar} >
                <Tabs value={value} onChange={handleChange} variant="scrollable" scrollButtons="on" allowscrollbuttonsmobile="true" aria-label="settings tab">
                  <Tab className={classes.employees} label="Employees" {...a11yProps(0)} />
                  <Tab className={classes.customers} label="Customers"  {...a11yProps(1)} />
                  <Tab className={classes.employees} label="Public Holidays"  {...a11yProps(2)} />
                  <Tab className={classes.employees} label="Activities"  {...a11yProps(3)} />
                  <Tab className={classes.employees} label="Awards"  {...a11yProps(4)} style={enableAward ? {} : { display: 'none' }} />
                  <Tab className={classes.employees} label="Session Option"  {...a11yProps(4)} style={enableAward ? {} : { display: 'none' }} />
                  <Tab className={classes.employees} label="Integration"  {...a11yProps(5)} />
                  <Tab className={classes.employees} label="Configuration"  {...a11yProps(6)} />
                </Tabs>
              </AppBar>
              <TabPanel value={value} index={0} className={classes.tab}>
                <Employees />
              </TabPanel>
              <TabPanel id="customers_panel" value={value} index={1} className={classes.tab}>
                <Customers />
              </TabPanel>
              <TabPanel id="holiday_panel" value={value} index={2} className={classes.tab}>
                <PublicHolidays />
              </TabPanel>
              <TabPanel id="activities_panel" value={value} index={3} className={classes.tab}>
                <Activities />
              </TabPanel>
              <TabPanel id="awards_panel" value={value} index={4} className={classes.tab} >
                <Awards />
              </TabPanel>
              <TabPanel id="awards_panel" value={value} index={5} className={classes.tab} >
                <SessionOption />
              </TabPanel>
              <TabPanel id="integration_panel" value={value} index={6} className={classes.tab}>
                <Integration />
              </TabPanel>
              <TabPanel id="configuration_panel" value={value} index={7} className={classes.tab}>
                <ConfigurationSettings />
              </TabPanel>
            </Suspense>
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default Settings;
