import React, { useEffect, useState, useImperativeHandle } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  OutlinedInput,
  TextField,
  ListItemSecondaryAction,
  InputAdornment,
} from '@material-ui/core';
import { LinearProgress } from '@mui/material';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import directus from '../../../services/directus';
import '../style.css';
import AddEquipment from '../AddEquipment/AddEquipment';
import AddMaterial from '../AddMaterial/AddMaterial';
import { useStylesAddSessionListDetails, UploadButton, FileChip, ListCustom } from '../style.js';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../Components/ErrorMessage';
import { MuiPickersUtilsProvider, KeyboardTimePicker, } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import InsertInvitationIcon from '@material-ui/icons/AccessAlarm';
import CircularProgress from '@material-ui/core/CircularProgress';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import useStateRef from "react-usestateref";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@material-ui/core/Divider';

const AddSessionListDetails = React.forwardRef((props, ref) => {
  //* when the user adds a new session and clicks save, the session will be processed on submitAdd in the AddSession>index.js file. In the process of this function, it will trigger to run the updateSessionDetail function in this file by sending some of its parameter values.
  useImperativeHandle(ref, () => ({
    confirmEditDetails(session, createAnother, value, upload, optionsList) {
      updateSessionDetail(session, createAnother, value, upload, optionsList);
    }
  }));

  const classes = useStylesAddSessionListDetails();

  const [sessionData] = useState(props.session);
  const [materialData, setMaterialData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [allMaterialData, setAllMaterialData] = useState([]);
  const [allEquipmentData, setAllEquipmentData] = useState([]);
  const [file, setFile, fileRef] = useStateRef([]);
  const [loadingFile, setLoadingFile] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      fillResourceData(sessionData);
    }

    return () => isSubscribed = false
  }, [])

  /**
   * TODO: save session data details to directus, such as material or equipment data if the selected session type is 'Manual Entry' (MFM), data from the 'Medical Certificate' file upload if the selected session type is 'Sick Leave', and options list if employees already have awards (GTNT)
   * * when the submitAdd function in the AddSession>index.js file is executed, it will trigger this function to be executed as well by passing a value to its parameter
   * @param {object} session The new session data to be added
   * @param {boolean} createAnother Does the user tick or untick the checkbox 'create another'? true | false
   * @param {boolean} value Does the session type selected show customers? true | false (MFM)
   * @param {*} upload If the selected session type is 'Sick Leave', will have value = 'Medical Certificate', else it will have value = null
   * @param {array} optionsList Options list owned by employees (GTNT)
   */
  const updateSessionDetail = async (session, createAnother, value, upload, optionsList) => {
    if (value) {
      let dataArray = [];
      equipmentData.map((data) => {
        dataArray = [...dataArray, { session: session.id, resource: data.id, type: 'equipment', quantity: 0 }];
        return null;
      })
      materialData.map((data) => {
        dataArray = [...dataArray, { session: session.id, resource: data.id, type: 'chemical', quantity: data.quantity }];
        return null;
      })

      //* If the user has added equipment data and material data, then save the data in 'sessions_resources' items in directus
      if (dataArray.length !== 0) {
        try {
          await directus.createItems("sessions_resources", dataArray);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }

    //* If the selected session type is Sick Leave and the user has selected the file to be uploaded, then upload the file to the directus. After that, save the id from that file based on the id from the session added to directus in sessions_directus_files items
    if (upload) {
      if (fileRef.current.length > 0) {
        const form = document.querySelector("#my-form");
        const data = new FormData(form);

        let uploadFile = await directus.uploadFiles(data, onUploadProgress);

        try {
          await directus.createItem('sessions_directus_files', {
            session: session.id,
            directus_file: uploadFile.data.id
          });
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }

    let OptionsData = [];

    //* filter option data in the options list by outputting option data that has type 4, 3, or 7
    optionsList.map((options) => {
      if (options.type !== "4" && options.type !== "3" && options.type !== "7") {
        OptionsData = [...OptionsData,
        {
          session: session.id,
          status: 'published',
          session_option: options.id,
          value: options.value === null ? options.data.input_type === 'checkbox' ? 'false' : 0 : options.value
        }];
      }
    })

    //* After the options list is filtered, create the options data to 'directus' in sessions_session_options items
    if (OptionsData.length > 0) {
      try {
        await directus.createItems("sessions_session_options", OptionsData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    props.setAddLoading(false);

    //* if the 'Create Another' checkbox = false or unchecked, then run the handleClose function from props
    if (createAnother === false) {
      props.handleClose();
    }

    setMaterialData([]);
    setEquipmentData([]);
    props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
  }

  /**
   * TODO: 
   * * 1. get resources with type = 'equipment' from directus and set the result to allEquipmentData state
   * * 2. get resources with type = 'chemical' from directus and set the result to allMaterialData state
   */
  const fillResourceData = async () => {
    try {
      var allEquipment = await directus.getItems('resources', {
        // fields: '*',
        fields: 'id, code, name',
        filter: {
          type: { eq: 'equipment' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllEquipmentData(allEquipment.data);

    try {
      var allMaterial = await directus.getItems('resources', {
        // fields: '*',
        fields: 'id, code, name',
        filter: {
          type: { eq: 'chemical' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    setAllMaterialData(allMaterial.data);
  }

  /**
   * TODO: Add new data to the equipmentData array, so that it displays a new column for equipment input
   * * This function is executed when the user clicks the add equipment button
   * @param {*} equip
   */
  const addEquipment = (equip) => {
    if (equip === null || equip === []) {
      let data = [];
      data.list_id = equipmentData.length + 1;
      data.value = 2;
      setEquipmentData([...equipmentData, data]);
    }
    else {
      let var_list_id = equipmentData.length + 1;
      let var_value = 2;
      setEquipmentData([...equipmentData, { list_id: var_list_id, ...equip, value: var_value }]);
    }
  };

  /**
   * TODO: Delete equipment data selected for deletion from the equipmentData state
   * * This function is executed when the user clicks the remove button in the equipment input field
   * @param {object} equipData Equipment data in the column that the user wants to delete
   */
  const deleteEquipment = (equipData) => {
    if (equipData.value === 2) {
      setEquipmentData(equipmentData.filter((equip) => equip.list_id !== equipData.list_id));
    }
  };

  /**
   * TODO: change the equipment type in the equipmentData state, according to the equipment type selected by the user in the select equipment column
   * * This function is executed when the user clicks on the select equipment column
   * @param {object} newData New equipment data selected
   * @param {object} oldData Old equipment data in the column that was clicked
   */
  const updateEquipment = (newData, oldData) => {
    setEquipmentData(
      equipmentData.map((equip) => (equip.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2 }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId }
        : equip)
      )
    );
  };

  /**
   * TODO: displays the equipment input fields, as many as set in the equipmentData state
   * @param {array} Data Equipment data that increases when the user clicks the add equipment button and decreases when the user clicks the delete button
   */
  const LoopEquipmentData = (Data) => {
    return (
      equipmentData && allEquipmentData ?
        Data.map((equipData, index) => {
          if (equipData.value !== 3) {
            return (
              <ListItem key={index}>
                <Autocomplete
                  key={index}
                  size="small"
                  className={classes.AutoEquipment}
                  options={allEquipmentData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  id={`AutoComplete-equip-${index}`}
                  onChange={(event, newValue) => {
                    updateEquipment(newValue, equipData);
                  }}
                  disableClearable
                  renderInput={(params) => <TextField {...params} variant="outlined" label={equipData.name !== null ? equipData.name : ''} />}
                />
                <ListItemSecondaryAction>
                  <RemoveCircleIcon
                    id="iconButton"
                    className="delete_equipment_iconButton"
                    color="error"
                    style={{ fontSize: 20 }}
                    onClick={() => deleteEquipment(equipData)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
          else {
            return null;
          }
        })
        : ''
    );
  }
  
  /**
   * TODO: Add new data to the materialData array, so that it displays a new column for material input
   * * This function is executed when the user clicks the add material button
   * @param {*} material
   * @param {*} qty 
   */
  const addMaterial = (material, qty) => {
    if (material === null || material === []) {
      let data = [];
      data.list_id = materialData.length + 1;
      data.value = 2;
      data.quantity = 0;
      setMaterialData([...materialData, data]);
    }
    else {
      let var_quantity = qty;
      let var_list_id = materialData.length + 1;
      let var_value = 2;
      setMaterialData([...materialData, { list_id: var_list_id, ...material, value: var_value, quantity: var_quantity }]);
    }
  };

  /**
   * TODO: Delete material data selected for deletion from the materialData state
   * * This function is executed when the user clicks the remove button in the material input field
   * @param {object} chemiData Material data in the column that the user wants to delete
   */
  const deleteMaterial = (chemiData) => {
    if (chemiData.value === 2) {
      setMaterialData(materialData.filter((material) => material.list_id !== chemiData.list_id));
    }
    else {
      setMaterialData(materialData.map((material) => material.list_id === chemiData.list_id ?
        { list_id: chemiData.list_id, ...chemiData, value: 3, resourceDataId: chemiData.resourceDataId, quantity: chemiData.quantity }
        : material)
      );
    }
  };

  /**
   * TODO: change the material type in the materialData state, according to the material type selected by the user in the select material column
   * * This function is executed when the user clicks on the select material column
   * @param {object} newData Selected material type
   * @param {object} oldData Old material data in the column that was clicked
   */
  const updateMaterial = (newData, oldData) => {
    setMaterialData(
      materialData.map((material) => (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2, quantity: oldData.quantity }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId, quantity: oldData.quantity }
        : material)
      )
    );
  };

  /**
   * TODO: changing the quantity value in the materialData state is the same as what the user typed in the quantity column
   * * This function is executed when the user changes the quantity in the material input column
   * @param {event} e 
   * @param {object} oldData Material data in the column that was clicked
   */
  const handleChange = (e, oldData) => {
    const { name, value } = e.target;
    setMaterialData(
      materialData.map((material) =>
      (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { ...material, value: 2, [name]: value }
          :
          { ...material, value: 1, [name]: value }
        : material)
      )
    );
  }

  /**
   * TODO: displays the material input fields, as many as set in the materialData state
   * @param {array} Data Material data that increases when the user clicks the add material button and decreases when the user clicks the delete button
   */
  const LoopMaterialData = (Data) => {
    return (
      Data.map((materialData, index) => {
        if (materialData.value !== 3) {
          return (
            <ListItem key={index}>
              <ListItemText primary={
                <Grid container spacing={0} alignItems="center">
                  <Grid item xs={9}>
                    <Autocomplete
                      key={index}
                      size="small"
                      className={classes.AutoEquipment}
                      options={allMaterialData}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      id={`AutoComplete-material-${index}`}
                      onChange={(event, newValue) => {
                        updateMaterial(newValue, materialData);
                      }}
                      disableClearable
                      renderInput={(params) => <TextField {...params} variant="outlined" label={materialData.name !== null ? materialData.name : ''} />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField name="quantity" onChange={(event) => handleChange(event, materialData)} value={materialData.quantity} variant="outlined" label='Kg/L' size="small" type="number" step="any" />
                  </Grid>
                </Grid>
              } />
              <ListItemSecondaryAction>
                <RemoveCircleIcon
                  id="iconButton"
                  className="delete_material_iconButton"
                  color="error"
                  style={{ fontSize: 20 }}
                  onClick={() => deleteMaterial(materialData)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
        else {
          return null;
        }
      })
    );
  }

  /**
   * TODO: set file state with data value of selected file to upload
   * @param {event} e 
   */
  const handleChangeUpload = (e) => {
    if (e.target.files.length > 0) {
      setFile([...fileRef.current, e.target.files[0]]);
    }
  }

  /**
   * * this function will be executed when the user clicks the x icon next to the file name after the user uploads the file for the medical certificate
   * TODO: set file state and value of file input  to be empty
   * @param {number} index 
   */
  const handleDeleteFile = (index) => {
    setFile([]);
    document.getElementById('contained-button-file').value = "";
  }

  // TODO: see the progress of uploading files to directus when saving session details
  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  /**
   * * This function is executed when the user has not selected the upload file. The upload file feature will appear if the selected session type is Sick Leave
   * @returns Upload button for upload medical certificate
   */
  const uploadButton = () => {
    return (
      <UploadButton
        id="attach_file_btn"
        name="file"
        variant="contained"
        component="span"
        startIcon={<FileUploadIcon />}
        onChange={(e) => handleChangeUpload(e)}
        disabled={fileRef.current.length > 0}
      >
        {props.selectData ? props.selectData.upload_description ? props.selectData.upload_description : '' : ''}
      </UploadButton>
    );
  }

  // TODO: Displays the name of the file that has been uploaded
  const uploadList = () => {
    return (
      <>
        {fileRef.current.length > 0 ?
          fileRef.current.map((filedata, index) => {
            return (
              <>
                <Grid item xs={12}>
                  <FileChip
                    icon={<InsertDriveFileIcon />}
                    label={filedata.name}
                    onDelete={() => handleDeleteFile(index)}
                    style={{ marginRight: '5px' }}
                  />
                </Grid>
              </>
            );
          })
          : ''}
      </>
    );
  }

  //Material+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //Details Body/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Card className={classes.cardDetail} elevation={0}>
        <CardContent className={classes.cardDetailContent}>
          {/* ==========================================Sub Content========================================== */}
          <List>
            <ListItem>
              <ListItemText primary={
                <Grid container spacing={2} justifyContent="space-between">
                  <Grid item xs={4}>
                    {props.selectData ?
                      props.selectData.show_customer ?
                        allMaterialData ?
                          <div>
                            <AddMaterial
                              addMaterial={addMaterial}
                              allMaterialData={allMaterialData}
                            />
                            <Card className={classes.cardSubDetail}>
                              <CardContent className={classes.cardSubDetailContent}>
                                <List dense>
                                  {LoopMaterialData(materialData)}
                                </List>
                              </CardContent>
                            </Card>
                          </div>
                          : ''
                        : ''
                      : ''
                    }
                  </Grid>
                  <Grid item xs={3}>
                    {props.selectData ?
                      props.selectData.show_customer ?
                        allEquipmentData ?
                          <div>
                            <AddEquipment
                              addEquipment={addEquipment}
                              allEquipmentData={allEquipmentData}
                            />
                            <Card className={classes.cardSubDetail}>
                              <CardContent className={classes.cardSubDetailContent}>
                                <List dense>
                                  {LoopEquipmentData(equipmentData)}
                                </List>
                              </CardContent>
                            </Card>
                          </div>
                          : ''
                        : ''
                      : ''
                    }
                  </Grid>

                  <Grid item xs={3}>
                    <Typography>Notes</Typography>
                    <TextField
                      id="notes_timesheet"
                      className={classes.notes}
                      name="notes"
                      onChange={props.handleChangeInput}
                      value={props.inputNotes}
                      variant="outlined"
                      label='Notes'
                      rows={4}
                      multiline
                    />
                  </Grid>
                  <Grid item xs={2}>
                    {props.fullDay === false && props.publicHoliday === true ?
                      props.selectData ?
                        props.selectData.show_customer ?
                          props.selectData.show_break_start_end_time ?
                            <>
                              <Typography>Breaks</Typography>
                              {props.dateLoading ?
                                <LinearProgress sx={{ mt: '15px' }} />
                                :
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    id="break_start_time"
                                    name="break_start_time"
                                    label="Break Start"
                                    format="hh:mm a"
                                    inputVariant="outlined"
                                    size="small"
                                    placeholder="08:00 AM"
                                    mask="__:__ _M"
                                    error={false}
                                    helperText={null}
                                    value={props.breakStartTime}
                                    onChange={props.changeBreakStartTime}
                                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                                  />
                                  <br /><br />
                                </MuiPickersUtilsProvider>
                              }

                              {props.dateLoading ?
                                <LinearProgress sx={{ mt: '55px' }} />
                                :
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    id="break_end_time"
                                    name="break_end_time"
                                    label="Break End"
                                    format="hh:mm a"
                                    inputVariant="outlined"
                                    size="small"
                                    placeholder="08:00 AM"
                                    mask="__:__ _M"
                                    error={false}
                                    helperText={null}
                                    value={props.breakEndTime}
                                    onChange={props.changeBreakEndTime}
                                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                                  />
                                </MuiPickersUtilsProvider>
                              }
                            </>
                            : props.selectData.show_break_time ?
                              <div>
                                <Typography>Breaks</Typography>
                                <OutlinedInput
                                  name="smoko"
                                  id="smoko_timesheet"
                                  className={classes.notes}
                                  value={props.inputSmoko}
                                  onChange={props.handleChangeInput}
                                  type="number"
                                  endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                                />
                              </div>
                              : ''
                          :
                          props.selectData.show_break_start_end_time ?
                            <>
                              <Typography>Breaks</Typography>
                              {props.dateLoading ?
                                <LinearProgress sx={{ mt: '15px' }} />
                                :
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    id="break_start_time"
                                    name="break_start_time"
                                    label="Break Start"
                                    format="hh:mm a"
                                    inputVariant="outlined"
                                    size="small"
                                    placeholder="08:00 AM"
                                    mask="__:__ _M"
                                    error={false}
                                    helperText={null}
                                    value={props.breakStartTime}
                                    onChange={props.changeBreakStartTime}
                                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                                  />
                                  <br /><br />
                                </MuiPickersUtilsProvider>
                              }
                              {props.dateLoading ?
                                <LinearProgress sx={{ mt: '55px' }} />
                                :
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <KeyboardTimePicker
                                    id="break_end_time"
                                    name="break_end_time"
                                    label="Break End"
                                    format="hh:mm a"
                                    inputVariant="outlined"
                                    size="small"
                                    placeholder="08:00 AM"
                                    mask="__:__ _M"
                                    error={false}
                                    helperText={null}
                                    value={props.breakEndTime}
                                    onChange={props.changeBreakEndTime}
                                    keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                                  />
                                </MuiPickersUtilsProvider>
                              }
                            </>
                            : props.selectData.show_break_time ?
                              <div>
                                <Typography>Breaks</Typography>
                                <OutlinedInput
                                  name="smoko"
                                  id="smoko_timesheet"
                                  className={classes.notes}
                                  value={props.inputSmoko}
                                  onChange={props.handleChangeInput}
                                  type="number"
                                  endAdornment={<InputAdornment position="end">mins</InputAdornment>}
                                />
                              </div>
                              : ''
                        : ''
                      : ''
                    }
                  </Grid>
                </Grid>
              } />
            </ListItem>
          </List>
        </CardContent>
      </Card>

      {props.selectData ?
        props.selectData.upload_description ?
          <Card className={classes.cardDetail} elevation={0}>
            <CardContent className={classes.cardDetailFileContent}>
              <List>
                <ListItem>
                  <Grid container spacing={2} justifyContent="space-arround">
                    {loadingFile ?
                      <span display="inline-flex"><CircularProgress size={20} /></span>
                      :
                      <form id="my-form" className={classes.form}>
                        <Grid item xs={12}>
                          <input type="file" name="file"
                            onChange={(e) => handleChangeUpload(e)}
                            id="contained-button-file"
                            className={classes.input}
                          />
                          <label htmlFor="contained-button-file">
                            {fileRef.current.length === 0 ?
                              uploadButton()
                              : ''
                            }
                          </label>
                        </Grid>
                      </form>
                    }
                    {uploadList()}
                  </Grid>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          : ''
        : ''}

      {/* add session list details */}
      {props.selectData ?
        props.selectData.session_options.length > 0 ?
          props.loadingSession ?
            <Card className={classes.cardDetail} elevation={0}>
              <CardContent className={classes.cardDetailSessionOptionsContent}>
                <List>
                  <LinearProgress sx={{ m: '10px' }} />
                </List>
              </CardContent>
            </Card>
            :
            props.optionsList.length > 0 ?
              <Card className={classes.cardDetail} elevation={0}>
                <CardContent className={classes.cardDetailSessionOptionsContent}>
                  <ListCustom disablePadding={true}>
                    {props.optionsList.map((optionlist, index) => {
                      return (
                        optionlist.type === '1' || optionlist.type === '5' ?
                          <>
                            {/* {index===0?
                        <ListCustom disablePadding={true}>
                          <ListItem>
                            <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">              
                              <Grid item xs={10}>
                                <Typography><b>Description</b></Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <Grid container spacing={0} justifyContent="center" alignItems="center">  
                                  <Typography><b>Answer</b></Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </ListCustom>
                      :''}
                      <Divider /> */}
                            <ListItem>
                              <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">
                                <Grid item xs={10}>
                                  <Typography variant="h6">{optionlist.data.description}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Grid container spacing={0} justifyContent="center" alignItems="center">
                                    <Typography variant="h6">
                                      {optionlist.data.input_type === "checkbox" ?
                                        optionlist.value ?
                                          <Checkbox
                                            id="checkbox"
                                            name="checkbox"
                                            sx={{
                                              "&.Mui-checked": {
                                                color: "#05a0af"
                                              }
                                            }}
                                            defaultChecked
                                            onChange={(e) => props.handleChangeSessionOptions(e, optionlist.id)}
                                          />
                                          :
                                          <Checkbox
                                            id="checkbox"
                                            name="checkbox"
                                            sx={{
                                              "&.Mui-checked": {
                                                color: "#05a0af"
                                              }
                                            }}
                                            onChange={(e) => props.handleChangeSessionOptions(e, optionlist.id)}
                                          />
                                        :
                                        <TextField
                                          id="outlined-number"
                                          label="Number"
                                          name="number"
                                          type="number"
                                          InputLabelProps={{
                                            shrink: true,
                                          }}
                                          variant="outlined"
                                          size="small"
                                          value={optionlist.value ? optionlist.value : 0}
                                          onChange={(e) => props.handleChangeSessionOptions(e, optionlist.id)}
                                        />
                                      }
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </ListItem>
                            <Divider />
                          </>
                          : '');
                    })}
                  </ListCustom>
                </CardContent>
              </Card>
              : ''
          : ''
        : ''}
    </>
  );
});

export default AddSessionListDetails;
