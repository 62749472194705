import React, { useState } from 'react';
import {
  CircularProgress,
  LinearProgress,
} from '@mui/material';
import {
  Grid,
  ListItemText,
  TextField,
  OutlinedInput
} from '@material-ui/core';
import InsertInvitationIcon from '@material-ui/icons/AccessAlarm';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import directus from '../../../services/directus';
import '../style.css';
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { useStylesAddSessionList } from '../style.js';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../Components/ErrorMessage';
import linq from "linq";

const AddSessionList = (props) => {
  const classes = useStylesAddSessionList();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [lengthCustomer, setLengthCustomer] = React.useState('');
  const [lengthActivity, setLengthActivity] = React.useState('');
  const loading = open && options.length === 0;
  const [openActivity, setOpenActivity] = useState(false);
  const [optionsActivity, setOptionsActivity] = useState([]);
  const loadingActivity = openActivity && optionsActivity.length === 0;
  const { enqueueSnackbar } = useSnackbar();

  /**
   * * If the user clicks on the column select customer, this function will be executed
   * TODO:
   * * If the user has selected the select activity first, then get customer data from directus that has the same activity as the activity the user selected. But if the user doesn't select an activity before clicking on the select customer, then get all the customer data from directus
   * * Then, from the results of taking customers data from directus, get each first letter and the amount or length of activity data owned by each customer
   */
  function callCustomers() {
    let active = true;
    let filterData;

    if (!loading) {
      return undefined;
    }
    try
    {
      (async () => {
        filterData= { 
          selectable: 1,
          status: 'published'
        };
        
        if(props.activity!==null && props.activity.length > 0){
          filterData = {
            ...filterData,
            'activities.activities_id':props.activity.id
          }
        }
        
        var CustomerEmployeeResult = await directus.getItems('customers', {
          fields: 'id, name, activities.id',
          filter: filterData,
          sort: "name"
        });

        const options = CustomerEmployeeResult.data.map((option) => {
          const firstLetter = option.name[0].toUpperCase();
          return {
            firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
            length: option.activities.length,
            ...option,
          };
        });
        
        if (active) {
          setOptions(options);
        }
      })();
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    return () => {
      active = false;
    };
  }
  
  // TODO: If there is a change value in the loading variable it will run the callCustomers function
  React.useEffect(() => {
    callCustomers();
  }, [loading]);
  
  // TODO: if the user clicks on the 'select customer' field, the 'open' state will be set to true, but if not, the 'open' state will be set to false, so the options state will be set to an empty array
  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  /**
   * * If the user clicks on the column select activity, this function will be executed
   * TODO:
   * * First, get all activity data from directus, then check whether the user has selected a customer in the select customer column ?
   * * If true => from the activity data, get the activity data that doesn't have a customer (customers.length = 0) then combine the data with the results of data retrieval from directus based on the customer id that has been selected by the user, then set the value to the optionsActivity state
   * * But if false => from all activity data obtained at the beginning, add in each data, the length or the number of customers that each activity data has, then set the value to optionsActivity state
   */
  function callActivity() {
    let activeActivity = true;
    let filterData;
    let activitiesResult = [];
    
    if (!loadingActivity) {
      return undefined;
    }
    try
    {
      (async () => {
        filterData= { 
          selectable: 1,
          status: 'published'
        };
        
        var Activities = await directus.getItems('activities', {
          fields: 'id, code, name, customers.id',
          filter: filterData,
          sort: "name"
        });
        
        if(props.customer!==null && props.customer.length > 0)
        {
          
          let activitiesListFirst = linq.from(Activities.data)
          .where(x => x.customers.length === 0).toArray();
          
          filterData = {
            ...filterData,
            'customers.customers_id':props.customer.id
          }
          
          var Activities2 = await directus.getItems('activities', {
            fields: 'id, code, name',
            filter: filterData,
            sort: "name"
          });
          
          activitiesResult=Activities2.data.concat(activitiesListFirst);
          
          if (activeActivity) {
            setOptionsActivity(Object.keys(activitiesResult).map((key) => activitiesResult[key]));
          }
          
        }
        else
        {
          const activityOptions2 = Activities.data.map((activityOption) => {
            return {
              length: activityOption.customers.length,
              ...activityOption,
            };
          });

          if (activeActivity) {
            setOptionsActivity(Object.keys(activityOptions2).map((key) => activityOptions2[key]));
          }

        }
      })();
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }

    return () => {
      activeActivity = false;
    };
  }

  // TODO: If there is a change value in the loadingActivity variable it will run the callActivity function
  React.useEffect(() => {
    callActivity();
  }, [loadingActivity]);

  // TODO: if the user clicks on the 'select activity' field, the 'openActivity' state will be set to true, but if not, the 'openActivity' state will be set to false, so the optionsActivity state will be set to an empty array
  React.useEffect(() => {
    if (!openActivity) {
      setOptionsActivity([]);
    }
  }, [openActivity]);

  //* Options filter in select customer column. To filter customer options based on the option name typed by the user in the select customer column
  const filterOptionsCustomer = createFilterOptions({
    matchFrom: lengthCustomer.length < 2 ? 'start' : 'any',
    stringify: option => option.name,
  });
  
  //* Options filter in select activity column. To filter activity options based on the option name typed by the user in the select activity column
  const filterOptionsActivity = createFilterOptions({
    matchFrom: lengthActivity.length < 2 ? 'start' : 'any',
    stringify: option => option.name,
  });

  return (
    <ListItemText
      primary={
        <Grid container spacing={3} justifyContent="flex-end" >
          {/* eslint-disable react/jsx-no-duplicate-props */}
          {props.selectData ?
            props.selectData.show_customer ?
            <>
              <Grid item xs={3}>
                <Autocomplete
                  id="autoCustomer"
                  open={open}
                  onOpen={() => {
                    setOpen(true);
                  }}
                  onClose={() => {
                    setOpen(false);
                  }}
                  options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                  groupBy={(option) => option.firstLetter}
                  getOptionLabel={(option) => option.name}
                  onChange={(event, newValue) => {
                    props.changeCustomer(newValue);
                  }}
                  filterOptions={filterOptionsCustomer}
                  size="small"
                  value={props.customer}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Customer'
                      variant="outlined"
                      value={props.customer}
                      onChange={(e) => setLengthCustomer(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </>
            : ''
          : ''}
          {/* eslint-enable react/jsx-no-duplicate-props */}
            
          {props.selectData ?
            props.selectData.show_activity ?
            <>
              <Grid item xs={3}>
                <Autocomplete
                  id="Activity"
                  open={openActivity}
                  onOpen={() => {
                    setOpenActivity(true);
                  }}
                  onClose={() => {
                    setOpenActivity(false);
                  }}
                  getOptionSelected={(option, value) => option.name === value.name}
                  getOptionLabel={(option) => option.name}
                  options={optionsActivity}
                  onChange={(event, newValue) => {
                    props.changeActivity(newValue);
                  }}
                  filterOptions={filterOptionsActivity}
                  size="small"
                  value={props.activity}
                  loading={loadingActivity}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Activity'
                      variant="outlined"
                      onChange={(e) => setLengthActivity(e.target.value)}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingActivity ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            </>
            : ''
          : ''}
         
          {props.selectData ?
            props.selectData.show_start_end_time ?
              props.fullDay === false && props.publicHoliday === true?
                props.dateLoading?
                <Grid item xs={2}>
                  <LinearProgress sx={{mt: '12px'}}/>
                </Grid>
                :
                <Grid item xs={2}>
                  <div id="start_time_container">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardTimePicker
                      id="start_time"
                      label="Start Time"
                      format="hh:mm a"
                      inputVariant="outlined"
                      size="small"
                      placeholder="08:00 AM"
                      mask="__:__ _M"
                      error={false}
                      helperText={null}
                      value={props.startTime}
                      onChange={(e) => props.changeStartTime(e)}
                      keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                    />
                  </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              :''
            :''
          :''}
        
          {props.selectData ?
            props.selectData.show_start_end_time ?
              props.fullDay === false && props.publicHoliday === true?
                props.dateLoading?
                <Grid item xs={2}>
                  <LinearProgress sx={{mt: '12px'}}/>
                </Grid>
                :
                <Grid item xs={2}>
                  <div id="end_time_container">
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardTimePicker
                        id="end_time"
                        label="End Time"
                        format="hh:mm a"
                        inputVariant="outlined"
                        size="small"
                        placeholder="08:00 AM"
                        mask="__:__ _M"
                        error={false}
                        helperText={null}
                        value={props.endTime}
                        onChange={(e) => props.changeEndTime(e)}
                        keyboardIcon={<InsertInvitationIcon className={classes.KeyboardDateTimePicker} />}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Grid>
              :''
            :''
          :''}
          
          {props.selectData?
            props.selectData.show_duration?
              props.fullDay === false && props.publicHoliday === true?
              <Grid item xs={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <OutlinedInput
                    id="duration_timesheet"
                    name="duration"
                    placeholder="1h 30m"
                    className={classes.notes}
                    value={props.duration}
                    onChange={(e) => props.changeDuration(e)}
                    onBlur={(e) => props.time_convert(e)}
                    size="small"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              :''
            :''
          :''}
      
        </Grid>
      } />
  );
};

export default AddSessionList;