import React, { useState } from 'react';
import { 
  // withStyles, 
  Button, 
  TextField, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle, 
  Typography, 
  CircularProgress 
} from '@material-ui/core';
import {
  CancelButton,
  ActionButton,
} from './style.js';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';

const ResetPassword = () => {
  const [content, setContent] = useState('');
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  var react_app_url=process.env.REACT_APP_URL;
  if(window.location.hostname.search('interax') !== -1){
    react_app_url=process.env.REACT_APP_URL_INTERAX;
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setLoading(false);
    setOpen(false);
  };

  const handleChange = (e) => {
    const {name, value} = e.target;

    if(name === "content")
    {
      setContent(value);
    }
  }

  const send = () => {

    try
    {
      setLoading(true);

      const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      };

      fetch(`${react_app_url}console/custom/passwordreset/${content}`, requestOptions)
      .then(res => console.log(res));

      setTimeout(function(){handleClose() }, 2000);
    }
    catch(e)
    {
      enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
    }
  }

  return (
    <div>
      <Button color="primary" onClick={handleClickOpen}>
        <Typography variant="h6">Forgot Password</Typography> 
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth={'sm'} fullWidth={true}>
       {Loading?
          <>
            <DialogTitle id="simple-dialog-title">Sending email...</DialogTitle>
            <DialogContent stlye={{textAlign:'center'}}>
              <CircularProgress style={{ color: '#FA9917'}}/>
            </DialogContent>
          </>
        : 
          <>
            <DialogTitle id="form-dialog-title"><Typography variant="h6">Forgot Password</Typography> </DialogTitle>
            <DialogContent>
              <TextField
                margin="dense"
                id="content"
                name="content"
                label="Email Address/ Phone"
                type="email"
                variant="outlined"
                value={content}
                onChange={(e)=>handleChange(e)}
                fullWidth
              />
            </DialogContent>
            <DialogActions>
                <CancelButton 
                  size="small" 
                  variant="contained"
                  onClick={handleClose}
                  >
                  <Typography variant="h6">Cancel</Typography> 
                </CancelButton>
                <ActionButton
                  variant="contained"
                  onClick={send}
                  >
                  <Typography variant="h6">Send</Typography> 
                </ActionButton>
            </DialogActions>
          </>
        }
      </Dialog>
    </div>
  );
};

export default ResetPassword;
