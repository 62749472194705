import React, { useState } from 'react';
import {
  Typography,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogContentText,
  TextField,
  DialogActions,
  CircularProgress,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  useStylesList,
  RejectButton,
  ApproveButton,
  UnapproveButton,
  CancelButtonDialog,
  RejectButtonDialog,
  CustomTooltip,
  useStylesApprovalTimesheetList
} from './style.js';
import { AdminPermission, EmployeeLeavePermission } from 'src/utils/Permission';
import moment from 'moment';
import directus from '../../services/directus';
import linq from "linq";
import EventEmitter from 'src/utils/EventEmitter';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';

const ApprovalTimesheetList = (props) => {
  const classes = useStylesList();
  const Styleclasses = useStylesApprovalTimesheetList();

  const [checkBoxLoading, setCheckBoxLoading] = useState(false);
  const [note, setNote] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const [sessionChoosen, setSessionChoosen] = React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  /**
   * * This function is executed when the user clicks the reject button
   * @param data is the session data that the user wants to reject
   * TODO: set sessionChosen with the data session that the user wants to reject and open a dialog
   */
  const handleClickOpen = (data) => {
    setSessionChoosen(data)
    setOpen(true);
  };

  /**
   * * This function is executed when the user clicks the cancel button when they want to reject a leave request or during the onClose dialog
   * TODO: close dialog with set open to a false and set note to ''
   */
  const handleClose = () => {
    setOpen(false);
    setNote('');
  };

  /**
   * @param num is session duration
   * TODO: to calculate how many hours and minutes of the total duration of the data session
   * @returns text description of hours and minutes
   */
  function time_convert(num) {
    num = Math.abs(num);
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return (<span><b>{hours}</b>hr <b>{minutes}</b>min</span>);
  }

  /**
   * * This function will be executed when session data duration is not set
   * @param started is the session start time in the session data
   * @param ended is the session end time in the session data
   * TODO: If 'started' is greater than 'ended', it means 'end time' on a different day or the following day, then calculate the duration with 'start time' and 'end time' on different days, but if 'started' less than 'ended' it means it is still on the same day, so calculate the duration with the time on the same day
   * @returns description text about minutes if duration is less than one hour and returns description text about hours and minutes if duration exceeds one hour
   */
  function durationTime(started, ended) {
    if (ended != null) {
      var dif;
      if (started > ended) {
        dif = moment.duration(moment('1991-01-02T' + ended).diff('1991-01-01T' + started));
      }
      else {
        dif = moment.duration(moment('1991-01-01T' + ended).diff('1991-01-01T' + started));
      }

      if (dif.hours() === 0) {
        return (<span><b>{dif.minutes()}</b>min</span>);
      }
      else {
        return (<span><b>{dif.hours()}</b>hr <b>{dif.minutes()}</b>min</span>);
      }

    }
  }

  /**
   * * This function will be executed when the user types in the note column when rejecting an employee leave
   * TODO: set note state with what the user typed in the note field
   */
  const handleInputChange = e => {
    const { value } = e.target;
    setNote(value);
  }

  /**
   * * This function will be executed :
   * * 1. when the user clicks the done icon or want to approve an employee leave request
   * * 2. when the user clicks the Unapprove button. The Unapprove button appears after the user approves the employee leave request
   * * 3. when the user clicks on Reject button on dialog when the user will reject the employee leave request
   * 
   * @param data is session data that the user wants to approve, unapproved, or reject 
   * @param status :
   * * - has the value 'approved_hrd' => when the user clicks the done icon button and the user is 'admin' and the leave_status of the data session is 'pending' or 'approved'
   * * - has the value 'approved' => when the user clicks the done icon button and the user is not an admin, but the user has leave_approved data and the leave_status of the data session is 'pending'
   * * - has the value 'pending' => when the user clicks the Unapprove button
   * * - has the value 'rejected' => when the user clicks the Reject button
   * 
   * TODO: 
   * * > if the status is equal to 'rejected', then update the sessions data in directus according to the id of the data parameter and change leave_status to be 'rejected' and leave_note to be the same value as what was typed in the notes column when rejecting leave
   * * > but if the status is other than 'rejected', then update the sessions data in directus according to the id of the data parameter and change leave_status to be the same value as the status parameter
   * * > then, emit an event with the name reloadMenuLeaveRequests and set {text: 'reload'} values ​​in it
   * * > finally, call the loadSessions function from props and set CheckBoxLoading to false
   */
  const changeStatus = async (data, status) => {
    handleClose();
    setCheckBoxLoading(true);
    if (status === 'rejected') {
      try {
        await directus.updateItem('sessions', data.id, { leave_status: status, leave_note: note });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    else {
      try {
        await directus.updateItem('sessions', data.id, { leave_status: status });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    EventEmitter.emit('reloadMenuLeaveRequests', { text: 'reload' });

    props.loadSessions();
    setCheckBoxLoading(false);
  }

  /**
   * @param {*} employee is employee data requesting leave approval
   * @param {*} sessionData is the entire session data of all employees requesting leave approval
   * TODO: Calculate how much session data each employee requesting a leave request has
   */
  const countData = (employee, sessionData) => {
    let filter = linq.from(sessionData)
      .where(x => x.employee.id === employee.id).count();

    return filter;
  }

  //* This variable determines whether the Reject button is disabled or not, depending on whether the note column is empty or contains
  const isInvalid = note === '';

  return (
    <div>
      <div>

        {props.employees.map((employee, index) => {
          return (
            <div key={index}>
              <Accordion
                id={`panel1a_${index}`}
                elevation={0}
                className={Styleclasses.accordion}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id={`panel1a-header_${index}`}
                >
                  <Grid container spacing={0} justifyContent="space-between">
                    <Grid item xs={2}>
                      <Typography id={`code_${index}`}>{employee.code}</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography id={`name_${index}`}>{employee.user.first_name} {employee.user.last_name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography id={`email_${index}`}>{employee.user.email}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Chip className={classes.chip} label={<span id={`lr_count_${index}`}>{countData(employee, props.sessions)}</span>} />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails id={`panel1a-detail_${index}`} className={Styleclasses.details}>
                  {props.sessions.map((session, index) => {
                    let status;
                    let statusName;
                    if (session.leave_status === 'approved') {
                      status = { backgroundColor: '#DAF0DC' };
                      statusName = 'Approved';
                    }
                    else if (session.leave_status === 'rejected') {
                      status = { backgroundColor: '#FFDBD9' };
                      statusName = 'Rejected';
                    }
                    else if (session.leave_status === 'pending') {
                      status = { backgroundColor: '#D4E3F2' };
                      statusName = 'Waiting Approval';
                    }
                    else if (session.leave_status === 'awaiting_approval') {
                      status = { backgroundColor: '#FFF0DD' };
                      statusName = 'Waiting Approval';
                    }
                    else if (session.leave_status === 'approved_hrd') {
                      status = { backgroundColor: '#b8d4ff' };
                      statusName = 'Approved by Payroll';
                    }

                    if (session.employee.id === employee.id) {
                      return (
                        <div key={index}>
                          <Card elevation={0} className={classes.card} style={status}>
                            <CardContent className={classes.cardContent}>
                              <Grid container spacing={0}>
                                <Grid item xs={2}>
                                  <Typography id={`session_date_${index}`}>
                                    {moment(session.session_date).format('DD MMM YYYY')}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography>
                                    <span id={`status_name_${index}`}>{statusName}</span>
                                    {session.leave_note ?
                                      <CustomTooltip title={<Typography variant="h6">{session.leave_note}</Typography>}>
                                        <InfoIcon fontSize='small' style={{ height: '15px', marginBottom: '-2px' }} />
                                      </CustomTooltip>
                                      : ''}
                                  </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                  <Typography id={`session_duration_${index}`}>
                                    {session.duration > 0 ? time_convert(session.duration) : durationTime(session.session_start_time, session.session_end_time)}
                                  </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                  <Typography>{session.session_type.name}</Typography>
                                </Grid>
                                <Grid item xs={2} style={{ textAlign: 'right' }}>
                                  {AdminPermission() && (session.leave_status === 'pending' || session.leave_status === 'approved') ?
                                    checkBoxLoading ?
                                      <CircularProgress className={classes.circularLoading} size={20} />
                                      :
                                      <div>
                                        <ApproveButton
                                          id={`approve_approved_hrd_btn_${index}`}
                                          variant="contained"
                                          onClick={() => changeStatus(session, 'approved_hrd')}
                                        >
                                          <DoneIcon />
                                        </ApproveButton>
                                        <RejectButton
                                          id={`reject_approved_hrd_btn_${index}`}
                                          variant="contained"
                                          onClick={() => handleClickOpen(session)}
                                        >
                                          <CloseIcon />
                                        </RejectButton>
                                      </div>
                                    :
                                    ''
                                  }
                                  {session.leave_status === 'pending' && EmployeeLeavePermission() && !AdminPermission() ?
                                    checkBoxLoading ?
                                      <CircularProgress className={classes.circularLoading} size={20} />
                                      :
                                      <div>
                                        <ApproveButton
                                          id={`approve_approved_btn_${index}`}
                                          variant="contained"
                                          onClick={() => changeStatus(session, 'approved')}
                                        >
                                          <DoneIcon />
                                        </ApproveButton>
                                        <RejectButton
                                          id={`reject_approved_btn_${index}`}
                                          variant="contained"
                                          onClick={() => handleClickOpen(session)}
                                        >
                                          <CloseIcon />
                                        </RejectButton>
                                      </div>
                                    : ''
                                  }
                                  {session.leave_status === 'approved_hrd' && AdminPermission() ?
                                    checkBoxLoading ?
                                      <CircularProgress className={classes.circularLoading} size={20} />
                                      :
                                      <UnapproveButton
                                        id={`unapprove_approved_hrd_btn_${index}`}
                                        variant="contained"
                                        onClick={() => changeStatus(session, 'pending')}
                                      >
                                        <Typography variant="h6">Unapprove</Typography>
                                      </UnapproveButton>
                                    : ''}
                                  {AdminPermission() ?
                                    ''
                                    :
                                    session.leave_status === 'approved' && EmployeeLeavePermission() ?
                                      checkBoxLoading ?
                                        <CircularProgress className={classes.circularLoading} size={20} />
                                        :
                                        <UnapproveButton
                                          id={`unapprove_approved_btn_${index}`}
                                          variant="contained"
                                          onClick={() => changeStatus(session, 'pending')}
                                        >
                                          <Typography variant="h6">Unapprove</Typography>
                                        </UnapproveButton>
                                      : ''
                                  }
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Card>
                          <div style={{ height: 10 + 'px' }}></div>
                        </div>
                      );
                    }
                  })}
                </AccordionDetails>
              </Accordion>
              <div style={{ height: 10 + 'px' }}></div>
            </div>
          );
        })}
        <Dialog id="reject_approval_dialog" isopen={`${open}`} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
          <DialogContent>
            <DialogContentText>
              Please enter the note here.
            </DialogContentText>
            <TextField
              name="reject-note"
              id="reject-note"
              label="Notes"
              multiline
              fullWidth={true}
              minRows={4}
              maxRows={4}
              value={note}
              onChange={handleInputChange}
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <CancelButtonDialog
              size="small"
              variant="contained"
              onClick={handleClose}
            >
              <Typography variant="h6">Cancel</Typography>
            </CancelButtonDialog>
            <RejectButtonDialog
              size="small"
              variant="contained"
              disabled={isInvalid}
              onClick={() => changeStatus(sessionChoosen, 'rejected')}
            >
              <Typography variant="h6">Reject</Typography>
            </RejectButtonDialog>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default ApprovalTimesheetList;
