// import { colors } from "@material-ui/core";

export default {
  h1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 35,
    letterSpacing: '-0.24px'
  },
  h2: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 29,
    letterSpacing: '-0.24px'
  },
  h3: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 24,
    letterSpacing: '-0.06px'
  },
  h4: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '-0.06px'
  },
  h5: {
    fontFamily: 'Montserrat',
    fontWeight: 'bold',
    fontSize: 16,
    letterSpacing: '-0.05px',
    
  },
  h6: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 14,
    letterSpacing: '-0.05px',
    colors:' #333942'
  },
  h7: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 12,
    letterSpacing: '-0.05px',
    colors:' #333942'
  },
  body1: {
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 16
  },
  // body2: {
  //   fontFamily: 'Montserrat',
  //   fontWeight: 'bold',
  //   fontSize: 16
  // },
  subtitle1: {
    fontFamily: 'Montserrat',
    fontWeight: 600,
    fontSize: 16,
    lineHeight: '24px',
    colors:' #333942'

  },
  subtitle2: {
      fontFamily: 'Montserrat',
      fontWeight: 900,
      fontSize: 16,
      // fontWeight: 'bold'
      
  },
  overline: {
    fontWeight: 500
  },
};
