import React from 'react';
import { useNavigate } from 'react-router-dom';
import { 
  Typography, 
  Card,
  CardContent,
  Grid,
} from '@material-ui/core';
import { 
  CustomChip,
  useStylesList, 
  LeaveSupervisorChip,
  ReviewButton,
  TooltipCustom,
} from './style.js';
import Checkbox from "@mui/material/Checkbox";
import HailIcon from '@mui/icons-material/Hail';
import { AdminPermission} from 'src/utils/Permission';

const ApprovalTimesheetList = (props) => {
  const navigate = useNavigate();
  const classes = useStylesList();

  //* this check variable is used to check in the configuration whether enable_leave_supervisor is true or false, if true then display the icon
  var check = JSON.parse(window.localStorage.getItem('configuration'));

  /**
   * @param num is the total_minutes value in the timesheet data
   * TODO: convert the value of num to an absolute value with the Math.abs function, then do the math to get the hours and minutes
   * @returns text captions for hours and minutes
   */
  function time_convert(num)
  { 
    num = Math.abs(num);
    var hours = Math.floor(num / 60);  
    var minutes = num % 60;
    
    return (<span><b>{hours}</b>hr <b>{minutes}</b>min</span>);         
  }

  const timesheetlist = (timesheet,index,status,statusName) => {
    return(
    <div key={index}>
      <Card id={`timesheet_${index}`} elevation={0} className={classes.card} style={status}>
        <CardContent className={classes.cardContent}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={3} sm={3} md={3} lg={2}>
              <Grid container spacing={0}>
                <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
                  <Typography id={`employee_code_${index}`}>
                    {AdminPermission() && props.configuration?
                      props.configuration && timesheet.employee.status !== 'deleted'?
                        <Checkbox
                        sx={{
                          "&.Mui-checked": {
                            color: "#2AC940"
                          }
                        }}
                        checked={timesheet.select?timesheet.select:false}
                        onChange={event => {props.checkTimesheet(event.target.checked, timesheet.id)}}
                        />
                      :
                        <Checkbox
                          sx={{
                            "&.Mui-checked": {
                              color: "#2AC940"
                            }
                          }}
                          disabled
                        />
                    :''
                    }
                    {timesheet.employee.code}
                  </Typography>
                </Grid>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  {timesheet.employee.leave_approver?
                    timesheet.employee.parent_leave?
                      timesheet.employee.parent_leave.length ===0 && timesheet.employee.leave_approver.length === 0 ?
                        timesheet.employee.type === 'Full-Time'?
                        check.enable_leave_supervisor ? 
                        <TooltipCustom placement="right" arrow title={
                          <Typography variant="h6">{timesheet.employee.user?timesheet.employee.user.first_name:''} {timesheet.employee.user?timesheet.employee.user.last_name:''} doesn't have a leave supervisor </Typography>}>
                            <LeaveSupervisorChip 
                              variant="outlined" 
                              size="small"
                              icon={<HailIcon fontSize="small" sx={{ color: '#FFFFFF' }} />}
                            />
                        </TooltipCustom>
                      :''
                      :''
                      :''
                    :''
                  :''
                  }
                </Grid> 
              </Grid>
            </Grid>
            <Grid item xs={2} sm={2}  md={2} lg={3}>
              <Typography noWrap id={`employee_user_${index}`}>
                {timesheet.employee.user?timesheet.employee.user.first_name:''} {timesheet.employee.user?timesheet.employee.user.last_name:''}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Typography noWrap id={`employee_phone_${index}`}>
                {timesheet.employee.mobile_number}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>
              <Typography noWrap id={`employee_status_${index}`}>
                {statusName} 
              </Typography>
            </Grid>
            <Grid item xs={1} sm={1} md={1} lg={1}>
              <Typography noWrap id={`employee_total_time_${index}`}>
                {time_convert(timesheet.total_minutes)} 
              </Typography>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2}>                 
              <ReviewButton
                id={`review_btn_${index}`}
                variant="contained"
                className={classes.chipButton}
                onClick={()=>navigate(`/approvals/${timesheet.id}`, {state: {id:index, arrow: props.timesheets}})}
              >
                {timesheet.totalCountTimesheet-timesheet.timesheet_review.length !== 0 ?
                  <TooltipCustom placement="left" arrow title={<Typography variant="h6">
                    You have {(timesheet.timesheet_review_unread_count)} unread messages for this timesheet</Typography>}>
                    <CustomChip 
                      size="small" 
                      label={(timesheet.timesheet_review_unread_count)}
                    />
                  </TooltipCustom>
                :''}
                <Typography>REVIEW</Typography> 
              </ReviewButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div style={{height:10+'px'}}></div>
    </div>
    );
  }

  return (
    <>
      {props.timesheets.map((timesheet, index) => {
        let status='';
        let statusName='';

        /**
         * * timesheetStatusType is the timesheet data that has been added with background_color, text_color, and name values ​​that match the status of each of these timesheets. This process can be seen in the statusSummaryTypeLoad function in the ApprovalListView.js file
         */
        let match_status = props.timesheetStatusType.find(o => o.value === timesheet.status);

        /**
         * * Sets the background_color, text_color, and name of the timesheet data to display
         */
        if(match_status){
          if(timesheet.employee.status === 'deleted'){
            status = {backgroundColor: match_status.background_color, color: 'rgb(255 0 0 / 77%)', textShadow:'0px 1px #a05d49' };
          }else{
            status = {backgroundColor: match_status.background_color, color: match_status.text_color };
          }
          statusName = match_status.name;
        }

        if(props.unreadMessageFilter === true)
        {
          //* displays only data that have unread messages
          if((timesheet.totalCountTimesheet-timesheet.timesheet_review.length) > 0)
          {
            return timesheetlist(timesheet,index,status,statusName);
          }
        }
        else
        {
          return timesheetlist(timesheet,index,status,statusName);
        }
        return null;
      })}
    </>
  );
};

export default ApprovalTimesheetList;