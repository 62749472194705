import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  makeStyles,
  ListItemText,
  Divider,
  Typography,
  withStyles
} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import MuiListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
  },
  button: {
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 'auto',
  },
  active: {
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },

  },
  divider: {
    height: '1px',
    marginLeft: '8px',
    marginRight: '8px',
    backgroundColor: '#F2F2F6'
  }
}));

const ListItem = withStyles({
  root: {
	display: 'flex',
	paddingTop: 5,
	paddingBottom: 5,
	color: '#333942',
	"&$selected": {
	  backgroundColor: "#39F",
	  color: 'white !important',
	},
	"&$selected:hover": {
	  backgroundColor: "#39F",
	},
  },
  selected: {}
})(MuiListItem);

const NavItem = (
  {
    href,
    icon: Icon,
    title,
    number,
    selectedIndex,
    handleListItemClick,
    listStyle,
    dividerValue,
    features,
    leave_Count,
    ...rest
  }) => {
  const classes = useStyles();

  return (
    <span>
      <ListItem
        id={`desktop_nav_item-${title.replace(/\s/g, '')}`}
        disableGutters
        {...rest}
        style={listStyle}
        selected={selectedIndex === number}
        onClick={(event) => handleListItemClick(event, number, href, title, features)}
        activeclassname={classes.active}
        className={classes.button}
        component={RouterLink}
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <ListItemText className={classes.title} primary={<Typography variant="subtitle1">{title}
          {href === '/leave_requests' ? <Chip id="leave_requests_num" style={{ float: 'right' }} size="small" label={<span>{leave_Count}</span>} /> : ''}
        </Typography>} />
      </ListItem>
      {dividerValue === 1 ? <Divider className={classes.divider} /> : ''}
    </span>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string,
  number: PropTypes.number,
  dividerValue: PropTypes.number,
  selectedIndex: PropTypes.number,
  listStyle: PropTypes.object,
  features: PropTypes.object,
  leave_Count: PropTypes.number
};

export default NavItem;