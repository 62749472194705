import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from '@material-ui/core';
import directus from '../../services/directus';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import TextField from '@mui/material/TextField';
import { SubmitButton, CancelButton } from './style.js';

let calculate;

export default function ApproveRejectButton(props) {
  const [open, setOpen] = React.useState(false);
  const [note, setNote] = React.useState('');
  const [weekMinutes, setWeekMinutes] = React.useState(null);

  const { enqueueSnackbar } = useSnackbar();

  let rdoHoursEnable = JSON.parse(window.localStorage.getItem('directus_employee')).rdo_hours_enable;
  var fullWeekMinutes = JSON.parse(window.localStorage.getItem('configuration')).full_week_minutes;
  var fullWeekMinutesRDO = JSON.parse(window.localStorage.getItem('configuration')).full_week_minutes_rdo;

  /**
   * * This function is executed when the user clicks the 'Submit For Approval' button
   * TODO:
   * * 1. set 'weekMinutes' state and 'calculate' variable if enableTimesheetBankHours from props is true, the value depends on rdoHoursEnable if true or false
   * * 2. set duration state and durationClaim state with return value from time_convert_Duration function
   */
  const handleClickOpen = async () => {
    if (props.enableTimesheetBankHours) {
      if (rdoHoursEnable) {
        calculate = (props.totalDuration - fullWeekMinutesRDO);
        setWeekMinutes(fullWeekMinutesRDO);
      }
      else {
        calculate = (props.totalDuration - fullWeekMinutes);
        setWeekMinutes(fullWeekMinutes);
      }
    }
    setDuration(time_convert_Duration(props.timesheets.banked_minutes));
    setDurationClaim(time_convert_Duration(props.timesheets.claim_banked_minutes));
    setOpen(true);
  };

  // TODO: close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * TODO: set the note state, or duration state, or durationClaim state to be the same as that typed by the user, according to the text field that has changed
   * @param e => handle onChange :
   * * name="notes" => if there is a change in the note field when the user wants to submit for approval
   * * name="duration" => if weekMinutes less than props.totalDuration will display a text field for RDO Time Accrued or Bank Extra Hours. If there is a change in the text field, parameter e will have name='duration'
   * * name="duration_claim" => if there is a change in the RDO Time Taken field or Claim Banked Hours field when the user wants to submit for approval
   */
  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'notes') {
      setNote(value);
    }
    else if (name === 'duration') {
      setDuration(value);
    }
    else if (name === 'duration_claim') {
      setDurationClaim(value);
    }
  }

  /**
   * TODO: update timesheet data on directus based on the timesheet id taken from the URL, by changing the status to 'awaiting_approval', submission_notes equal to the note typed by the user when submitting for approval, changing the value for the banked_minutes field if the duration state is not empty or null, and change the value for the claim_banked_minutes field if the durationClaim state is neither empty nor null
   * * When the user wants to submit for approval and clicks the 'Submit' button on the confirmation dialog, this function will be executed
   */
  const submitApprovalButton = async () => {
    props.changeButtonLoading(true);
    let timesheetId = window.timesheetId;

    let dataSubmit;

    if (duration !== '' || duration !== "0" || duration !== null) {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: note,
        banked_minutes: parseTimeSpan(duration)
      }
    }
    else {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: note,
      }
    }

    if (durationClaim !== '' || durationClaim !== "0" || durationClaim !== null) {
      dataSubmit = { ...dataSubmit, claim_banked_minutes: parseTimeSpan(durationClaim) };
    }

    try {
      await directus.updateItem('timesheets', timesheetId, dataSubmit);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }


    props.loadTimesheets();
    props.changeButtonLoading(false);
  }

  /**
   * TODO: get sessions data from directus, filter based on the status equal to 'rejected' and based on the timesheet id that is being displayed. If there is session data obtained from the directus, then change each status in the session data to 'pending' in the directus. Next, update the timesheet status to 'pending' on directus based on the timesheet id that is being displayed.
   * * This function is executed when the user clicks the 'Edit Submission' button
   * * The 'Edit Submission' button will appear if the status of the timesheet is 'rejected' or 'awaiting_approval'
   */
  const editSubmissionButton = async () => {
    props.changeButtonLoading(true);
    let timesheetId = window.timesheetId;

    try {
      var getSessionsData = await directus.getItems('sessions', {
        // fields: '*',
        fields: 'id',
        filter: {
          timesheet: window.timesheetId,
          status: 'rejected'
        },
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (getSessionsData.data.length > 0) {
      let sessionData = [];
      //eslint-disable-next-line array-callback-return
      getSessionsData.data.map((data) => {
        sessionData.push({ id: data.id, status: 'pending' });
      })

      try {
        await directus.updateItems('sessions', sessionData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    
    try {
      await directus.updateItem('timesheets', timesheetId, {
        status: 'pending'
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    props.loadTimesheets();
    props.changeButtonLoading(false);
  }

  const [duration, setDuration] = React.useState(time_convert_Duration(props.timesheets.banked_minutes));
  const [durationClaim, setDurationClaim] = React.useState(time_convert_Duration(props.timesheets.claim_banked_minutes));

  /**
   * TODO: return true if valid and set duration to empty if no valid
   * @param data is the number or date which will be checked as valid or not
   */
  const checkTimeValid = (data) => {
    if (moment(data).isValid()) {
      return true;
    }
    else {
      setDuration('');
    }
  }

  /**
   * TODO: return true if valid and set durationClaim to empty if no valid
   * * this function is called inside the time_convert_claim function
   * @param data : the value to be checked is valid or not
   */
  const checkClaimTimeValid = (data) => {
    if (moment(data).isValid()) {
      return true;
    }
    else {
      setDurationClaim('');
    }
  }

  /**
   * * when the user wants to submit for approval and fills in the 'Claim Banked Hours' field, then the user leaves the field or the field loses focus (onBlur), this function is executed
   * * durationClaim equal to which the user typed in the 'Claim Banked Hours' field
   * TODO: 
   * * if durationClaim is not empty or not null or not = 0 then:
   * * check whether the input from the user includes the letter h(for hours) or m(for minutes)?
   * *  > if true :
   * *    -> check if there is only m (parts.length === 1)?
   * *      ~ if true, then parse to integer and check whether the value is valid or not, if valid set durationClaim state with the results of the parse to integer and combined with the letter m. But if it is not valid then set the durationClaim state to be empty
   * *      ~ if false, then calculate: the result of parse to integer(split h index-0) * 60 plus the result of parse to integer(split h index-1 which is given a default value of 0 so that it is not NaN), if the result is valid set durationClaim by combining hour values and the minutes
   * *  > if the input from the user does not include the letters h or m, then: get the hour from the number typed, if what is typed is the number 24 it means it is at 00.00, if it is more than 24, then subtract with the number 24, until the last subtraction result is smaller than 24 (because in 1 day there are only 24 hours)
   */
  function time_convert_claim() {
    if (durationClaim !== '' || durationClaim !== "0" || durationClaim !== null) {
      let parts = durationClaim.split("h");
      let parts2 = durationClaim.split("m");
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkClaimTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDurationClaim(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkClaimTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDurationClaim((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) > 60 ? 0 + "m" : parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
          }
        }
      }
      else {
        var decimalTimeString = durationClaim;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkClaimTimeValid(n)) {
          setDurationClaim((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }

  // function time_convert()
  // {
  //   if(duration !== '' || duration !== "0" || duration !== null)
  //   {
  //     let parts = duration.split("h");
  //     let parts2 = duration.split("m");
  //     if(parts.length > 1 || parts2.length > 1)
  //     {
  //       if(parts.length === 1)
  //       {
  //         if(checkTimeValid(parseInt(parts[0].slice(0, -1), 10)))
  //         {
  //           setDuration(parseInt(parts[0].slice(0, -1), 10) + "m");
  //         }
  //       }
  //       else
  //       {
  //         if(checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1)|0, 10)))))
  //         {
  //           setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1)|0, 10)>60?0 +"m" :parseInt(parts[1].slice(0, -1)|0, 10) +"m"));
  //         }
  //       }
  //     }
  //     else
  //     {
  //       var decimalTimeString = duration;
  //       var n = new Date(0,0);
  //       n.setMinutes(+decimalTimeString * 60);
  //       if(checkTimeValid(n))
  //       {
  //         setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
  //       }
  //     }
  //   }
  // }


  // TODO: set duration state depends on what the user typed in the RDO Time Accrued or Bank Extra Hours column
  function time_convert() {
    if (duration !== '' || duration !== "0" || duration !== null) {
      let parts = duration.split("h");
      let parts2 = duration.split("m");

      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            if ((parseInt(parts[0], 10) * 60) > calculate) {
              // setDuration(calculate + "h " + (parseInt(parts[1].slice(0, -1)|0, 10) +"m"));
              setDuration(time_convert_Duration(calculate));
            }
            else {
              if ((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))) > calculate) {
                setDuration(time_convert_Duration(calculate));
              }
              else {
                setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) > 60 ? 0 + "m" : parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
              }
            }
          }
        }
      }
      else {
        var n = new Date(0, 0);

        if (checkTimeValid(n)) {
          if ((duration * 60) > calculate) {
            // setDuration(calculate + "h " + moment(n).minute() +"m");
            setDuration(time_convert_Duration(calculate));
          }
          else {
            // setDuration((moment(n).hour() + "h ") + moment(n).minute() + "m");
            setDuration(time_convert_Duration(duration * 60))
          }
        }
      }
    }
  }

  /**
   * @param num is the number that will convert to hour and minute format
   * @returns the result of counting hours and minutes
   */
  function time_convert_Duration(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return hours + "h " + minutes + "m";
  }

  /**
   * TODO: split h and m from timeString parameters, check if any of the splits results have a length greater than 1:
   * * -> if true => if the results of the split parts.length = 1 (meaning there is only a value for minutes) then it returns only the value of the total minutes, but other than that it returns the results of calculating the hours multiplied by 60 plus the total minutes
   * * -> if false => return the result of adding hours and minutes
   * @param timeString:
   * * has the value equal to the duration state, if the duration state is not null or empty
   * * has the value equal to the durationClaim state, if the durationClaim state is not null or empty
   */
  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if (parts.length > 1 || parts2.length > 1) {
      if (parts.length === 1) {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
      }
    }
    else {
      var decimalTimeString = timeString;
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  return (
    <div>
      {props.timesheets.status === 'pending' ?
        <div>
          <SubmitButton
            id="submit_for_approval_btn"
            size="small"
            onClick={handleClickOpen}
          >
            <Typography variant="h6">Submit for Approval</Typography>
          </SubmitButton>
          <Dialog id="submit_for_approval_dialog" isopen={`${open}`} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
            <DialogContent>
              <DialogContentText>
                Timesheet submission details
              </DialogContentText>
              <Typography variant="h6">Notes</Typography>
              <TextField
                id="reject-note"
                name="notes"
                placeholder='(Optional) Leave a note'
                multiline
                fullWidth={true}
                minRows={4}
                maxRows={4}
                value={note}
                onChange={handleInputChange}
                variant="outlined"
              />

              {props.enableTimesheetBankHours ?
                <div>
                  {weekMinutes < props.totalDuration ?
                    <>
                      <br />
                      <TextField
                        variant="outlined"
                        label={rdoHoursEnable ? "RDO Time Accrued" + (calculate >= 0 ? "(max: " + time_convert_Duration(calculate) + ")" : '') : "Bank Extra Hours" + (calculate >= 0 ? "(max: " + time_convert_Duration(calculate) + ")" : '')}
                        name="duration"
                        placeholder="1h 30m"
                        sx={{ width: '45%' }}
                        value={duration}
                        onChange={handleInputChange}
                        onBlur={(e) => time_convert(e)}
                        size="small"
                      />
                      <br />
                    </>
                    : ''}
                  <br />
                  <TextField
                    variant="outlined"
                    label={rdoHoursEnable ? "RDO Time Taken" : "Claim Banked Hours"}
                    name="duration_claim"
                    placeholder="1h 30m"
                    sx={{ width: '45%' }}
                    value={durationClaim}
                    onChange={handleInputChange}
                    onBlur={(e) => time_convert_claim(e)}
                    size="small"
                  />
                </div>
                : ''}
            </DialogContent>
            <DialogActions>
              <CancelButton
                id="cancel_approval"
                size="small"
                variant="contained"
                onClick={handleClose}
              >
                <Typography variant="h6"> Cancel</Typography>
              </CancelButton>
              <SubmitButton
                id="submit_approval"
                size="small"
                variant="contained"
                onClick={submitApprovalButton}
              >
                <Typography variant="h6">Submit</Typography>
              </SubmitButton>
            </DialogActions>
          </Dialog>
        </div>
        : ''}

      {props.timesheets.status === 'rejected' || props.timesheets.status === 'awaiting_approval' ?
        <SubmitButton
          id="edit_submission_btn"
          size="small"
          variant="contained"
          onClick={editSubmissionButton}
        >
          <Typography variant="h6">Edit Submission</Typography>
        </SubmitButton>
        : ''}
    </div>
  );
}
