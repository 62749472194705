import React from 'react';
import { 
  Checkbox,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const SelectEmployee = (props) => {

  return (
    <div>
      <Autocomplete
        multiple
        data-testid='autocomplete-search'
        id="add_new_timesheet"
        options={props.employeesFilterData}
        disableCloseOnSelect
        getOptionLabel={(option) => option.user.first_name + ' ' + option.user.last_name}
        onChange={(event, newValue) => {
          props.setEmployeesFilterChosen(newValue)
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.code} - {option.user.first_name} {option.user.last_name}
          </React.Fragment>
        )}
        style={{ width: 500 }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" label="Select employees to add" placeholder="Select..." />
        )}
      />
    </div>
  );
};

export default SelectEmployee;
