import { makeStyles } from '@material-ui/core';

import { 
  Button,
  withStyles
} from '@material-ui/core';

export const ActionButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#FA9917',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#d8820f',
    },
  },
})(Button);

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  daterangepickerControlSection: {
    maxWidth: '246px',
    margin: '30px auto',
    paddingTop: '50px'
  },
  circular: {
    color: '#FA9917',
    marginLeft: '45%'
  },
  headerWidth: {
    padding: '0 50px 0 15px',
  },
  filter: {
    float: 'right',
  }
}));

export const useStylesApprovalTimesheetList = makeStyles({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px !important'
  },
  chip: {
    height: '25px !important',
    float: 'right',
    backgroundColor: '#FA9917',
    color: 'white'
  },
  chipContainer: {
    paddingRight: '2%',
  },
  chipButton: {
    height: '25px !important',
    float: 'right',
    lineHeight: '0px'
  },
  accordion: {
    minHeight:'48px',
    margin: '0px'
  }
});