import { withStyles, Button, makeStyles, Tooltip } from '@material-ui/core';

export const RejectButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    height: '25px',
    width: '50px',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
})(Button);

export const ApproveButton = withStyles({
  root: {
    marginRight: '10px',
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '25px',
    width: '50px',
    minWidth: '50px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const UnapproveButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '25px',
    width: '150px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const CancelButtonDialog = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const RejectButtonDialog = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
})(Button);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),  
  },
  circular: {
    color: '#FA9917',
    // marginLeft: '45%'
    float:'right'
  },
  circularMain: {
    color: '#FA9917',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  circularLoading: {
    color: '#FA9917',
  },
  filter: {
    float: 'right',
  },
  // buttonText: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   borderRight: 0,
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,
  //   marginTop: '0px',
  //   borderRadius: '0px'
  // },
  // buttonLeft: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   width: '55px',
  //   minWidth: '55px',
  //   borderRadius: '24px 0 0 24px',
  //   borderRight: 0,
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,
  // },
  // buttonRight: {
  //   backgroundColor: 'white',
  //   padding: 0,
  //   width: '55px',
  //   minWidth: '55px',
  //   borderRadius: '0 24px 24px 0',
  //   boxShadow: '0 0 black',
  //   border: 0,
  //   height: 30,
  // },
  card: {
    borderRadius: '8px',
    backgroundColor: '#f2f2f6',
  },
  cardContent: {
    paddingTop: '0px',
    paddingBottom: '0px !important'
  },
  // headerWidth: {
    // paddingTop: 0,
    // paddingBottom: 0
  // },
  position: {
    display:'inline-block',
    backgroundColor:'white',
    height:'30px',
    paddingTop: '3px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  // export: {
  //   float:'right',
  //   marginLeft:'5px', 
  //   borderRadius: '24px',
  // },
  // send: {
  //   float:'right',
  //   borderRadius: '24px',
  //   marginLeft:'5px', 
  // },
  // add: {
  //   float:'right', 
    // marginRight:'378px',
    // marginTop:'-30px'
  // },
  // iconWidth: {
  //   minWidth: '30px'
  // },
  // Appbar: {
  //   backgroundColor: '#E2E2E7',
  //   color: '#333',
  //   height: 35,
  //   paddingBottom: '10px',
  //   borderRadius: '6px',
  //   boxShadow: '0px 0px !important',
  //   '& .Mui-selected': {
  //     backgroundColor: 'white !important',
  //     borderRadius: '6px !important',
  //     margin: '2px !important',
  //   },
  //   '& .MuiTabs-scroller': {
  //     position: 'initial !important',
  //   },
  // },
  // Appbar: { 
  //   '& .MuiBox-root': {
  //     padding: '10px 0px 0px 0px',
  //   },
  // },
  employees: {
    maxHeight: 30, 
    minHeight: 10, 
    paddingTop:'10px', 
    fontFamily: 'Montserrat', 
    fontWeight: 'bold'
  },
  // disableButtonLeft:{
  //   backgroundColor: 'white',
  //   width: '55px',
  //   minWidth: '55px',
  //   height: 30,
  //   borderRadius: '24px 0 0 24px',
  //   borderRight: 0,
  //   border: '0 !important',
  // },
}));

export const useStylesList = makeStyles({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px !important'
  },
  chip: {
    height: '25px !important',
    float: 'right',
    backgroundColor: '#FA9917',
    color: 'white'
  },
  chipContainer: {
    paddingRight: '2%',
  },
  chipButton: {
    height: '25px !important',
    float: 'right',
    lineHeight: '0px'
  },
  accordion: {
    minHeight:'48px',
    margin: '0px'
  },
  circularLoading: {
    color: '#FA9917',
  },
});

export const useStylesApprovalTimesheetList = makeStyles({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px !important'
  },
  chip: {
    height: '25px !important',
    float: 'right',
    backgroundColor: '#FA9917',
    color: 'white'
  },
  chipContainer: {
    paddingRight: '2%',
  },
  chipButton: {
    height: '25px !important',
    float: 'right',
    lineHeight: '0px'
  },
  accordion: {
    minHeight:'48px',
    margin: '0px'
  }
});