import { makeStyles } from '@material-ui/core';
import 
{ 
  withStyles,
  Fab,
  Button,
  Tooltip,
  TextField,
  Chip,
} from '@material-ui/core';

export const customStyle = {
  gridPageStyle : {
    textAlign:'left',
    marginBottom:'10px',
    fontFamily:'Montserrat'
  },
  spanStyle : {
    paddingRight: '20px'
  },
  bgColor : {
    backgroundColor: '#f2f2f6'
  },
  btnAddStyle : {
    textAlign: 'right',
    paddingRight: '40px'
  },
  marginBottomStyle : {
    marginBottom: '20px'
  },
  textAlignRight : {
    textAlign: 'right'
  },
  selectStyle : {
    maxHeight: '250px',
    overflow:'scroll',
    paddingTop:'5px',
    overflowX:'hidden'
  },
  cbSelectStyle : {
    marginRight: 8
  },
  textAlignLeft : {
    textAlign: 'left'
  },
  maxWidth: {
    maxWidth: '8.333333% !important'
  },
  selectPayrunStyle: {
    marginBottom:'15px',
    marginTop:'15px'
  },
  dialogActionStyle: {
    height: "60px"
  },
  selectWidthStyle: {
    width: 500
  },
  //employees
  marBottom: {
    marginBottom: '10px'
  },
  codeStyle: {
    paddingLeft: '16px'
  },
  nameStyle: {
    paddingLeft: '10px'
  },
  emailStyle: {
    marginRight: '-17px'
  },
  phoneStyle: {
    marginRight: '-10px'
  },
  roleStyle: {
    marginRight: '-5px'
  },
  btnETStyle: {
    paddingLeft: '0px'
  },
  textTrans: {
    textTransform: 'capitalize'
  },
  accordionStyle: {
    userSelect: 'auto',
    cursor: 'auto',
    alignItems: 'flex-start'
  },
  gridItemStyle: {
    overflowX: "hidden",
    textOverflow: "ellipsis",
    overflowWrap: "break-word",
    paddingRight: "5px"
  },
  btnDelStyle: {
    color:'transparent'
  },
  maxAndPaddRight: {
    maxWidth: '8.333333% !important',
    paddingRight: '2px'
  },
  gridLastAccessedStyle: {
    paddingBottom: '1px',
    paddingTop: '7px'
  },
  lastAccessFont: {
    fontSize: '13px'
  },
  dialogContentStyle: {
    marginBottom: '5px'
  },
  styleCheckBox: {
    marginRight: 8
  },
  formFontStyle: {
    fontSize: '14px'
  },
  autoCompStyle: {
    marginBottom: '10px',
    marginTop: '5px'
  },
  gridPageEmployeeStyle: {
    textAlign: 'left',
    marginTop: '10px',
    marginBottom: '10px',
    fontFamily: 'Montserrat'
  },
  gridMasterDataStyle: {
    textAlign: 'right',
    marginTop: '12px',
    marginBottom: '10px',
    fontFamily: 'Montserrat'
  },
  // Session Option
  typographyStyle: {
    paddingLeft: '10px'
  },

}

export const LeaveSupervisorChip = withStyles(() => ({
  root: {
    backgroundColor:'#FA9917',
    height:'30px',
    width: '30px',
    border:'0px',
    color: '#FA9917',
    '&:hover': {
      backgroundColor: '#FA9917',
    },
    paddingLeft: '12px',
    marginRight:'10px',
    marginTop:'0px'
  },
}))(Chip);


export const AwardChip = withStyles(() => ({
  root: {
    height:'31px',
    width: 'auto',
    color: 'black',
    border: '1px solid #0d47a1',
    textAlign:'center',
    marginRight:'3px',
    marginTop:'0px',
    '& .MuiChip-labelSmall':{
      paddingRight:'1px',
    }
  },
}))(Chip);

export const AwardChipValue = withStyles(() => ({
  root: {
    backgroundColor:'#2e73b8',
    height: '25px',
    width: 'auto',
  },
}))(Chip);

export const AddButton = withStyles(() => ({
    root: {
      backgroundColor: '#3399FF',
      width: '30px',
      height: '30px',
      minHeight: '30px',
      boxShadow: '0 0 black',
      left: '8%',
    },
  }))(Fab);
  export const AddAwardRulesButton = withStyles(() => ({
    root: {
      backgroundColor: '#3399FF',
      width: '30px',
      height: '30px',
      minHeight: '30px',
      boxShadow: '0 0 black',
      marginRight:'5px',
      bottom:'2px',
      left:'2px'
    },
  }))(Fab);

export const SubmitButton = withStyles(() => ({
    root: {
      borderRadius: '24px',
      backgroundColor: '#2AC940',
      color: '#ffffff',
      height: '30px',
      width: '120px',
      '&:hover': {
        backgroundColor: '#22a033',
      },
    }
  }))(Button);


export const CustomTooltip = withStyles({
    tooltip: {
      fontSize: "16px",
      maxWidth: 500,
      backgroundColor: "black"
    }
  })(Tooltip);

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    paddingTop: 25,
    fontFamily: 'Montserrat', 
  },
  Appbar: {
    backgroundColor: '#E2E2E7',
    color: '#333',
    height: 35,
    paddingBottom: '10px',
    borderRadius: '6px',
    boxShadow: '0px 0px !important',
    '& .Mui-selected': {
      backgroundColor: 'white !important',
      borderRadius: '6px !important',
      margin: '2px !important',
    },
    '& .MuiTabs-scroller': {
      position: 'initial !important',
    },
  },
  tab: { 
    '& .MuiBox-root': {
      padding: '10px 0px 0px 0px',
    },

  },
  employees: {
    maxHeight: 30, 
    minHeight: 10, 
    paddingTop:'10px', 
    fontFamily: 'Montserrat', 
    fontWeight: 'bold'
  },
  customers:{
    maxHeight: 30, 
    minHeight: 10, 
    paddingTop:'10px', 
    fontFamily: 'Montserrat', 
    fontWeight: 'bold'
  },
  alignright:{
    textAlign:'right',
  },
  cardSubDetail:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF',
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
  },
}));

export const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      borderColor: 'red',
      backgroundColor: 'white',
      borderRadius: '6px',
      height: '53.63px'
    },
    '& .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl': {
      marginTop: '8px'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: "translate(14px, -12px) scale(0.75)"
    }
  },
})(TextField);

export const RosterButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#ffb41e',
    color: '#ffffff',
    height: '25px',
    width: '100px',
    '&:hover': {
      backgroundColor: '#ffb41e',
    },
  },
})(Button);

export const SyncButton = withStyles({
  root: {
    marginLeft:'5px',
    borderRadius: '24px',
    backgroundColor: '#8e92e8',
    color: '#ffffff',
    height: '25px',
    width: 'auto',
    '&:hover': {
      backgroundColor: '#8e92e8',
    },
  },
})(Button);


export const pageOptions = [
  {
    value: 10,
    label: "10",
  },
  {
    value: 25,
    label: "25",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 100,
    label: "100",
  },
];

export const inputTypeOptions = [
  {
    value: "checkbox",
    label: "Checkbox",
  },
  {
    value: "numeric",
    label: "Numeric",
  },
  {
    value: "none",
    label: "None",
  },
];

export const useStylesEmployees = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '10px !important',
    fontFamily:'Montserrat',
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#ffffff',
  },
  linear: {
    color: '#FA9917',
    marginTop: '10px',
    marginBottom:'10px'
  },
  circular: {
    color: '#FA9917',
    marginLeft: '45%'
  },
  circularSync: {
    color: '#FA9917',
    marginLeft: '1%',
    marginBottom: '-1%',
  },
  circularSave: {
    color: '#FA9917',
  },
  circularSyncLogFile: {
    color: '#FA9917',
    placeSelf: 'center'
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 30,    
  },
  disableButtonLeft:{
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    border: '0 !important',
  },

  filter:{
    '& .MuiListItem-button:hover' :{
      backgroundColor: '#39F',
      color: 'white'
  },
  '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover' : {
    backgroundColor: '#39F',
    color: 'white'
  },
    heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  expandIcon:{
    '& .MuiButtonBase-root.MuiIconButton-root.MuiAccordionSummary-expandIcon.MuiIconButton-edgeEnd': {
      paddingTop: '22px',
    }
  },
  alignright:{
    textAlign:'right',
  },
  cardSubDetail:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF',
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
  },

},
  

}));