//No Company

import React, { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { 
  Box, 
  Button, 
  Container, 
  TextField, 
  Typography, 
} from '@material-ui/core';
import { useStyles } from './style.js';
import Page from 'src/components/Page';
// import { changeProject, directusProject } from 'src/services/directus';
// import * as sessions from 'src/utils/sessions';
import ResetPassword from './ResetPassword';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LockIcon from '@material-ui/icons/Lock';
import useStateRef from "react-usestateref";

import LoginPassword from './LoginPassword';
import LoginSMSEmail from './LoginSMSEmail';

const LoginView = () => {
  const classes = useStyles();
  // const navigate = useNavigate();

  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [mode, setMode, modeRef] = useStateRef(1);

  var react_app_url=process.env.REACT_APP_URL;
  if(window.location.hostname.search('interax') !== -1){
    react_app_url=process.env.REACT_APP_URL_INTERAX;
  }


  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, isBusy]);

  const sendOTP = (company, email, modeValue) => {
    setIsBusy(true);

    if (modeValue !== 4) {

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username: email, channel: modeValue === 2 ? 'sms' : 'email' })
      };

      fetch(`${react_app_url}console/custom/auth/otp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.error) {
            window.localStorage.clear();
            window.alert(result.error.message);
            setIsBusy(false);
          }
          else {
            setMode(modeValue)
            setIsBusy(false);
          }
        })
    }

    else {
      setMode(modeValue);
      setIsBusy(false);
    }
  }

  return (
    <Page className={classes.root} title="Login">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              email: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email('Must be a valid Value')
                .max(255),
            })}
            onSubmit={() => {
              setIsBusy(true);
            }}
          >
            {({ handleBlur, handleChange, values }) => (
              <div>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                </Box>

                {/* <TextField
                  disabled={modeRef.current=== 2||modeRef.current=== 3}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Company Domain"
                  margin="normal"
                  name="company"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="company"
                  value={values.company}
                  variant="outlined"
                /> */}
                <TextField
                  className="username_textfield"
                  disabled={modeRef.current === 2 || modeRef.current === 3}
                  fullWidth
                  label="Username"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                  variant="outlined"
                />

                <Box my={2}>
                  {modeRef.current === 1 ?
                    <div>
                      <Button color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<PhoneIcon />} onClick={() => sendOTP(values.company, values.email, 2)}>
                        <Typography variant="h6">Send PIN to SMS</Typography>
                      </Button>
                      <br /><br />
                      <Button color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<EmailIcon />} onClick={() => sendOTP(values.company, values.email, 3)}>
                        <Typography variant="h6">Send PIN to Email</Typography>
                      </Button>
                      <br /><br />
                      <Button id="signin_button" color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<LockIcon />} onClick={() => sendOTP(values.company, values.email, 4)}>
                        <Typography variant="h6">Sign in using Password</Typography>
                      </Button>
                    </div>
                    :
                    modeRef.current === 2 || modeRef.current === 3 ?
                      <LoginSMSEmail
                        setMode={setMode}
                        // company={values.company}
                        email={values.email}
                        mode={mode === 2 ? 'sms' : 'email'}
                      />
                      :
                      <div>
                        <LoginPassword
                          setMode={setMode}
                          email={values.email}
                        />
                      </div>
                  }

                </Box>
                <ResetPassword />
              </div>

            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
