import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  Container, 
  CircularProgress,
  Grid,
  Button,
  Card,
  CardContent,
  Menu,
  MenuItem,
  Chip
} from '@material-ui/core';
import Page from 'src/components/Page';
// import { enableRipple } from '@syncfusion/ej2-base';
import useStateRef from "react-usestateref";
import ApprovalTimesheetList from './ApprovalTimesheetList'
import { AdminPermission } from 'src/utils/Permission';
import { useStyles } from './style.js';
import { loadSessions } from '../../Controller/LeaveRequests.js';
import { filter_status, filter_color } from '../../Controller/LeaveRequest/ApprovalListView.js';

const LeaveRequests = () => {
  const classes = useStyles();
  const [initialized, setInitialized] = useState(false);
  const [items, setItems] = useState({ employees: null, sessions: null });
  const [status, setStatus, statusRef] = useStateRef(window.localStorage.getItem('LRfilter')?window.localStorage.getItem('LRfilter'):AdminPermission()?'approved':'pending');
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  // enableRipple(true);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      callSessions();
    }
  }, []);

  //* this function is executed when the user clicks on the filter button
  // TODO: opens the filter item menu
 const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  // TODO: close the filter item menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * TODO:
   * * get the session data from directus by calling the loadSessions function in the LeaveRequests.js controller
   * * set items state with values ​​obtained from directus, for data sessions and employees. If no data sessions are found, set the items state for sessions and employees with an empty array value
  */
  const callSessions = async () => {
    setMoveLoading(true);
    
    let result = await loadSessions('null', statusRef.current, 2);

    if(result.length > 0)
    {
      setItems(prevItems => ({
        ...prevItems,
        sessions: result[0],
        employees: result[1]
      }));
    }
    else
    {
      setItems(prevItems => ({
        ...prevItems,
        sessions: [],
        employees: []
      }));
    }
    setMoveLoading(false);
  }

  /**
   * * this function is executed when the user clicks on any of the items on the filter menu
   * @param input is the type of status that is obtained when the user clicks on any of the items on the filter menu
   * TODO: Set an item in local storage with the name 'LRfilter' and with the value of the status type that gets from the value of the input parameter. Then set the status with the value of the input parameter and run the callSessions and handleClose functions
   */
  function filterStatus(input) {
    window.localStorage.setItem('LRfilter', input);
    setStatus(input);
    callSessions()
    handleClose();
  }

  return (
    <Page className={classes.root} title="Leave Requests">
      <Container maxWidth={false}>
        <div className="control-pane">
          <div className="control-section">
            <Card elevation={0} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={0}>
                  <Grid item xs={2}>
                    <Typography variant="h5"><b>Code</b></Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h5"><b>Name</b></Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5"><b>Email</b></Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <span className={classes.filter}>
                      <Chip 
                        size="small"
                        style={filter_color(statusRef.current)}
                        label={ 
                          <Typography variant="h6" id="lr_status_chip">
                            {filter_status(statusRef.current)}
                          </Typography>  
                        } 
                      />
                      <Button
                        id="filter_lr_btn"
                        size="small"
                        onClick={handleClick}
                        disabled={moveLoading}
                      >
                        <i className="fa fa-filter fa-lg"></i><Typography variant="h6">Filter</Typography>
                      </Button>
                      <Menu
                        id="lr_status_filter_menu"
                        anchorEl={anchorEl}
                        keepMounted
                        isopen={`${Boolean(anchorEl)}`}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        <MenuItem id="approved_menu" onClick={()=>filterStatus('approved')}>Approved by Supervisor</MenuItem>
                        <MenuItem id="approved_hrd_menu" onClick={()=>filterStatus('approved_hrd')}>Approved by Payroll</MenuItem>
                        <MenuItem id="rejected_menu" onClick={()=>filterStatus('rejected')}>Rejected</MenuItem>
                        <MenuItem id="pending_menu" onClick={()=>filterStatus('pending')}>Waiting Approval</MenuItem>
                        <MenuItem id="all_menu" onClick={()=>filterStatus('all')}>All</MenuItem>
                      </Menu>
                    </span>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            {items.sessions && items.employees?
              items.sessions.length>0?
                <ApprovalTimesheetList 
                  employees={items.employees}
                  sessions={items.sessions}
                  loadSessions={callSessions}
                  statusRef={statusRef.current}
                />
              :
              !moveLoading?               
                <Grid 
                  container
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                  style={{opacity:'0.2',paddingTop:'6pc'}}
                >
                  <Grid item xs={12} sm={12} lg={12}>
                    <img alt="No Data Found" height="230" width= "230" src="/static/images/folder.png?v=0.0.1"/>
                    <Typography variant="h3" style={{textAlign:'end'}}>No Data Found</Typography>
                  </Grid>
                </Grid>
              :''
            :
              <CircularProgress className={classes.circularMain}/>
            }
          </div>
        </div>
      </Container>
    </Page>
  );
};

export default LeaveRequests;
