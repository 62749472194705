import DoneAllIcon from '@material-ui/icons/DoneAll';
import DateRangeIcon from '@material-ui/icons/DateRange';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SettingsIcon from '@material-ui/icons/Settings';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import * as permission from 'src/utils/Permission';

export function items()
{

let menu_items = [
  {
    href: '/',
    title: permission.SupervisorPermission()?'Approvals':'My Timesheet',
    number: permission.SupervisorPermission()?3:2,
    dividerValue: 1,
    features: {
      search: false,
    },
    listStyle: {
      display: 'none',
    }
  },
  {
    href: '/timesheet',
    icon: DateRangeIcon,
    title: 'My Timesheet',
    number: 2,
    dividerValue: 1,
    features: {
      search: false,
    },
    listStyle: {
      paddingLeft: '15px',
      borderRadius: '8px 8px 0 0'
    }
  }
];

if( permission.SupervisorPermission() || permission.CustomerEmployeePermission() || permission.EmployeeEmployeePermission())
{
  menu_items.push(
  {
    href: '/approvals',
    icon: DoneAllIcon,
    title: 'Approvals',
    number: 3,
    dividerValue: 1,
    features: {
      search: false,
    },
    listStyle: {
      paddingLeft: '15px',
    }
  },
  ) 
}

if(permission.EmployeeLeavePermission() ||permission.AdminPermission())
{
  
  menu_items.push(
  {
    href: '/leave_requests',
    icon: EventAvailableIcon,
    title: 'Leave Requests',
    number: 5,
    dividerValue: 1,
    features: {
      search: true,
    },
    listStyle: {
      paddingLeft: '15px'
    }
  },
  ) 
}

if( permission.AdminPermission() )
{
  // menu_items.push(
  // {
  //   href: '/reporting',
  //   icon: FileDownloadIcon,
  //   title: 'Reporting',
  //   number: 6,
  //   dividerValue: 1,
  //   features: {
  //     search: false,
  //   },
  //   listStyle: {
  //     paddingLeft: '15px',
  //   }
  // }
  // );

  menu_items.push(
  {
    href: '/settings',
    icon: SettingsIcon,
    title: 'Settings',
    number: 7,
    dividerValue: 0,
    features: {
      search: true,
    },
    listStyle: {
      paddingLeft: '15px',
      borderRadius: '0 0 8px 8px'
    }
  }
  );
}

return menu_items;
}