import React, {useState} from 'react';
import { 
  List,
  ListItem,
  ListItemSecondaryAction,
  Typography
} from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import '../style.css';


const AddEquipment = (props) => {
  const [newValue, setNewValue] = useState([]);
  
  // TODO: set newValue with the value 'undefined' every time the user clicks the add equipment button
  function handleChange(newData){
    setNewValue(newData);
  }

  /**
   * TODO: The first time when the user clicks the add icon, default newValue = [], so newValue != undefined and runs the addEquipment function from props with parameter 1 = newValue(default = []) ==> then the return result (undefined) is passed to the handleChange function, the next time when the user clicks the add icon it will run the addEquipment function from props by sending 1 parameter with a null value
   * * This function is executed when the user clicks the add equipment button
   * @param {event} e 
   */
  const handleSubmit = e => {
    e.preventDefault();
    if(newValue === undefined)
    {
      handleChange(props.addEquipment(null));
    }
    else
    {
      handleChange(props.addEquipment(newValue));
    }
    
  }

  return(
    <form>
      <List dense={true}>
        <ListItem>
          <Typography>Equipment</Typography>
          <ListItemSecondaryAction>
            <AddCircleIcon id="iconButton" className="add_equipment_iconButton" color="action" style={{ fontSize: 20 }} onClick={handleSubmit}/>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </form>
  );
};

export default AddEquipment;
