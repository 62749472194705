import { makeStyles } from '@material-ui/core';
import 
{ 
  withStyles,
  Button,
  Tooltip,
} from '@material-ui/core';
import MuiListItem from "@material-ui/core/ListItem";

export const SubmitButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    width: '120px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  }
}))(Button);

export const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "16px",
    maxWidth: 500,
    backgroundColor: "grey"
  }
})(Tooltip);

export const CancelButton = withStyles({
root: {
  borderRadius: '24px',
  marginRight: '4px',
  backgroundColor: '#e0e0e0de',
  height: '30px',
  width: '120px',
  '&:hover': {
    backgroundColor: '#bdbdbdde',
  },
},
})(Button);

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#ffffff'
  },
  // avatar: {
  //   width: 60,
  //   height: 60,
  // },
  logo: {
    height:46,
    width: 227,
  },
  logo1: {
    height:57,
    marginRight:25,
  },
  avatar: {
    cursor: 'pointer',
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  toolbar: {
    paddingLeft: '26px'
  },
  search: {
    width: '200px',

    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '14px',
      backgroundColor: 'rgba(142,142,147,0.12)',
      '& fieldset': {
        // borderColor: 'white',
        border:'0px',
      },
    },
  },
  linear: {
    color: '#FA9917',
    marginTop: '10px',
    marginBottom:'10px'
  },
  title: {
    flexGrow: 1,
    color: 'black',
    paddingLeft: '40px',
    fontSize: '22px'
  },
  style: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  },
  circular: {
    color: '#FA9917',
  },
  circularProfile: {
    color: '#FA9917',
    margin:'25px'
  },
}));

export const ListItem = withStyles({
  root: {
    display: 'flex',
    paddingTop: 5,
    paddingBottom: 5,
    color: '#333942',
    "&$selected": {
      backgroundColor: "#39F",
      color: 'white !important',
    },
    "&$selected:hover": {
      backgroundColor: "#39F",
    },
    // "&:hover": {
    //   backgroundColor: "#39F",
    //   color: "white"
    // }
  },
  selected: {}
})(MuiListItem);