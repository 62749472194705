const user = JSON.parse(window.localStorage.getItem('directus_user'));

export function AdminPermission() {
  if(user)
  {
    return (user.role.name === 'Administrator' || user.role.name === 'Admin' || user.role.name === 'Company Admin');
  }
  else
  {
    return false;
  }
}

export function SupervisorPermission() {
  if(user)
  {
    return (user.role.name === 'Supervisor' || AdminPermission());
  }
  else
  {
    return false;
  }
}

export function CustomerEmployeePermission() {
  if(JSON.parse(window.localStorage.getItem('directus_employee')))
  {
    var employee = JSON.parse(window.localStorage.getItem('directus_employee'));

    if(employee.customers && employee.customers.length > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}

export function EmployeeLeavePermission() {
  if(JSON.parse(window.localStorage.getItem('directus_employee')))
  {
    var employee = JSON.parse(window.localStorage.getItem('directus_employee'));

    if(employee.leave_approver && employee.leave_approver.length > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}

export function EmployeeEmployeePermission() {
  if(JSON.parse(window.localStorage.getItem('directus_employee')))
  {
    var employee = JSON.parse(window.localStorage.getItem('directus_employee'));

    if(employee.related && employee.related.length > 0)
    {
      return true;
    }
    else
    {
      return false;
    }
  }
}
