import { makeStyles } from '@material-ui/core';
import {  
  withStyles,
  Button,
} from '@material-ui/core';

export const SubmitButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    height: '30px',
    paddingLeft: '25px',
    paddingRight: '25px',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
})(Button);

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    paddingLeft: '25px',
    paddingRight: '25px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),

  },
  daterangepickerControlSection: {
    maxWidth: '246px',
    margin: '30px auto',
    paddingTop: '50px'
  },
  circular: {
    color: '#FA9917',
    marginLeft: '45%'
  },
  circularpending: {
    color: '#FA9917',
    // marginLeft: '45%'
  },
  select: {
    width: '120px'
  },
  buttonGroup: {
    height: '30px',
    borderRadius: '24px',
    width: '120px',
    boxShadow: '0 0 black',
  },
  disableButtonLeft:{
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    border: '0 !important',
    // marginTop:'-10px',
    marginLeft: '-15px',
    marginRight: '-2px',
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
    // marginTop:'-10px',
    marginLeft: '-15px',
    marginRight: '-2px',
  },
  disableButtonRight:{
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '0 24px 24px 0',
    border: '0 !important',
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
    // marginTop:'-10px',
  },
  addButtonRight: {
    backgroundColor: 'white',
    width: '60px',
    borderRadius: '0 24px 24px 0',
    
  },
  gridContainer: {
    paddingLeft: '1.2%',
    paddingBottom: '1%',
  },
  navigation: {
    float:'right',
    paddingTop: '25px'
  },
  Chip: {
    height: '20px',
    marginTop: '-8px',
    // color: 'black !important',
    color:' #333942'
  },
  date: {
    fontWeight: '400',
    paddingRight:'10px',
    display:'inline-block',
    backgroundColor: 'white', 
    lineHeight: '30px',
    paddingLeft: '10px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonText: {
    backgroundColor: 'white',
    padding: 0,
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
    marginTop: '0px',
    borderRadius: '0px'
  },
}));