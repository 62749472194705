import { 
  withStyles, 
  Button, 
  makeStyles,
  Chip, 
  Tooltip,
} from '@material-ui/core';
import Checkbox from "@mui/material/Checkbox";

export const CancelButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#e0e0e0de',
    height: '30px',
    width: '120px',
    marginRight:'8px',
    '&:hover': {
      backgroundColor: '#bdbdbdde',
    },
  },
})(Button);

export const ActionButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#FA9917',
    color: '#ffffff',
    height: '30px',
    width: '150px',
    '&:hover': {
      backgroundColor: '#d8820f',
    },
  },
})(Button);

export const CustomChip = withStyles(() => ({
  root: {
    backgroundColor:'white',
    color:'black',
    position:'absolute',
    left:'2%',
    '& .MuiChip-label':{
      overflow:'initial',
    },
    '& .MuiChip-sizeSmall':{
      height: '20px',
      width: '19px'
    }
  },
}))(Chip);

export const LeaveSupervisorChip = withStyles(() => ({
  root: {
    backgroundColor:'#FA9917',
    height:'20px',
    width: '20px',
    border:'0px',
    color: '#FA9917',
    '&:hover': {
      backgroundColor: '#FA9917',
    },
    paddingLeft: '12px',
    marginRight:'10px',
    marginTop:'10px'
  },
}))(Chip);

export const ReviewButton = withStyles({
  root: {
    borderRadius: '24px',
    backgroundColor: '#FA9917',
    color: '#ffffff',
    height: '30px',
    width: '125px',
    maxWidth:'125px',
    '&:hover': {
      backgroundColor: '#d8820f',
    },
  },
})(Button);


export const TooltipCustom = withStyles({
  tooltip: {
    color: "#FFFFFF",
    backgroundColor: "#000000"
  }
})(Tooltip);

export const LockButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: '#ffb41e',
    marginRight: '5px',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#faac11',
    },
  },
}))(Button);

export const ColorButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: ' #3399FF',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#1E90FF',
    },
  },
}))(Button);

export const ApprovedPaidButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#2AC940',
    },
  },
}))(Button);

export const YesPayrollButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: '#2AC940',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#22a033',
    },
  },
}))(Button);

export const NoPayrollButton = withStyles(() => ({
  root: {
    borderRadius: '24px',
    backgroundColor: '#FF5F58',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#c34c46',
    },
  },
}))(Button);

export const ApprovePaidCheckbox = withStyles(() => ({
  root:{
    // color: '#2AC940 !important',
    '&. MuiCheckbox-indeterminate': {
      color: "#2AC940 !important"
    },
  }
}))(Checkbox);

export const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),  
  },
  circular: {
    color: '#FA9917',
    float:'right'
  },
  circularMain: {
    color: '#FA9917',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  circularLoading: {
    color: '#FA9917',
  },
  filter: {
    float: 'right',
  },
  buttonSearch: {
    backgroundColor: 'white',
    width: '90px',
    borderRadius: '24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 40,
    marginLeft:'10px'
  },
  buttonText: {
    backgroundColor: 'white',
    padding: 0,
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
    marginTop: '0px',
    borderRadius: '0px'
  },
  buttonLeft: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '24px 0 0 24px',
    borderRight: 0,
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  buttonRight: {
    backgroundColor: 'white',
    padding: 0,
    width: '60px',
    borderRadius: '0 24px 24px 0',
    boxShadow: '0 0 black',
    border: 0,
    height: 30,
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#f2f2f6',
  },
  cardContent: {
    paddingTop: '0px',
    paddingBottom: '0px !important'
  },
  send: {
    float:'right',
    borderRadius: '24px',
    marginLeft:'5px', 
  },
  add: {
    float:'right', 
  },
  iconWidth: {
    minWidth: '30px'
  },
  icon:{
    paddingRight:'32px',
  },
  filterandsort:{
  '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover' : {
    backgroundColor: '#39F !important',
    color: 'white'
  },
  '& .MuiListItem-button:hover':{
    backgroundColor: '#39F !important',
    color: 'white',
  }
  },
  
  updatestatus:{
    '& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover' : {
      backgroundColor: '#e1f5ed !important',
      color: 'black'
    },
    '& .MuiListItem-button:hover':{
      backgroundColor: '#e1f5ed !important',
      color: 'black',
    }
    }
}));

export const useStylesList = makeStyles({
  card: {
    borderRadius: '8px',
  },
  cardContent: {
    paddingTop: '10px',
    paddingBottom: '10px !important'
  },
  chipButton: {
    height: '30px !important',
    float: 'right',
    lineHeight: '0px',
  },
  accordion: {
    minHeight:'48px',
    margin: '0px'
  },
});

export const styles = makeStyles(theme => ({
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: "0 2px",
    color: "inherit",
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  highlightNonCurrentMonthDay: {
    color: "#676767",
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: "highlight",
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  },
  endHighlight: {
    extend: "highlight",
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  },
}));