//No Company

import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, TextField, Typography, makeStyles, CircularProgress } from '@material-ui/core';
import { directusProject } from 'src/services/directus';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import LockIcon from '@material-ui/icons/Lock';
// import Bugsnag from '@bugsnag/js';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import BusinessIcon from '@mui/icons-material/Business';


const useStyles = makeStyles(() => ({
  root: {}
}));
let company = [];

const LoginPassword = (props) => {

  function ChooseCompanyDialog(props) {
    const classes = useStyles();
    const { onClose, open } = props;
    const [moveLoading, setMoveLoading] = React.useState(false);

    const handleListItemClick = async (value) => {
        setMoveLoading(true);
      if (value !== false) {
        window.localStorage.setItem('company', JSON.stringify(value));
        
        let directus = await directusProject(value.company.domain, value);
        await window.localStorage.setItem('directus_data', JSON.stringify(value));
        var employee = await directus.getItems('employees', { filter: { user: { eq: value.user.id } }, fields: 'user.*.*,*.*', single: true });

        window.localStorage.setItem('directus_employee', JSON.stringify(employee.data));
        window.localStorage.setItem('directus_user', JSON.stringify(employee.data.user));

        var result = await directus.getItems('configuration');
        window.localStorage.setItem('configuration', JSON.stringify(result.data[0]));

        window.location.href = '/';
        setIsBusy(false);

        onClose(false);
      }
      else 
      {
        onClose(false);
      }

      setMoveLoading(false);
    };

    return (
            <Dialog id="company-dialog" isopen={`${open}`} onClose={() => handleListItemClick(false)} aria-labelledby="simple-dialog-title" open={open}>
              {moveLoading?
              <>
                <DialogTitle id="simple-dialog-title">Signing in...</DialogTitle>
                  <List style={{ width: '375px',textAlign:'center'}}>
                      <CircularProgress style={{ color: '#FA9917'}}/>
                  </List>
              </>
              : 
              <>
                <DialogTitle id="simple-dialog-title">Choose Company</DialogTitle>
                <List style={{ width: '375px' }}>
                    {company !== 0 ?
                      company.map((companylist, index) => (
                        <div className={classes.demo} key={index}>
                          <List>
                            <ListItem id={`company_${index}`} button onClick={() => handleListItemClick(companylist)}>
                              <ListItemAvatar>
                                <Avatar>
                                  <BusinessIcon />
                                </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                primary={companylist.company.name}
                              />
                            </ListItem>
                            <Divider variant="middle" component="li" />
                          </List>
                        </div>
                      ))
                    : ''}
                </List>
                <DialogActions>
                  <Button onClick={() => handleListItemClick(false)} color="primary">
                    Cancel
                  </Button>
                </DialogActions>
              </>
              }
          </Dialog>
    );
  }

  const [isInitialized, setIsInitialized] = React.useState(false);
  const [isBusy, setIsBusy] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  // const [state, setState] = React.useState(false);
  const [isSuccess, setIsSuccess] = React.useState(false);

  var react_app_url=process.env.REACT_APP_URL;
  if(window.location.hostname.search('interax') !== -1){
    react_app_url=process.env.REACT_APP_URL_INTERAX;
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setIsBusy(false);
  };

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true);
    }
  }, [isInitialized, isBusy]);

  return (


    <Formik
      initialValues={{
        password: ''
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .max(255)
          .required('Password is required')
      })}
      onSubmit={form => {
        setIsBusy(true);
        // Bugsnag.start({
        //   onError: function (event) {
        //     event.addMetadata('data', {
        //       email: props.email,
        //       // company: companyRef.current,
        //     })
        //   }
        // })

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username: props.email, password: form.password })
        };

        fetch(`${react_app_url}/console/custom/auth/authenticate `, requestOptions)
          .then(response => response.json())
          .then(async result => {

            if (result.error) {
              setIsSuccess(false);
              window.localStorage.clear();
              window.alert(result.error.message);
              setIsBusy(false);
            }
            else {
              setIsSuccess(true);
              if (result.data.length === 1) {
                window.localStorage.setItem('company', JSON.stringify(result.data[0].company));
                let directus = directusProject(result.data[0].company.domain, result.data[0]);
                
                window.localStorage.setItem('directus_data', JSON.stringify(result.data[0]));

                var employee = await directus.getItems('employees', { filter: { user: { eq: result.data[0].user.id } }, fields: 'user.*.*,*.*', single: true });

                window.localStorage.setItem('directus_employee', JSON.stringify(employee.data));
                window.localStorage.setItem('directus_user', JSON.stringify(employee.data.user));

                var config_result = await directus.getItems('configuration');
                window.localStorage.setItem('configuration', JSON.stringify(config_result.data[0]));

                window.location.href = '/';
                setIsBusy(false);
                
              }

              else {
                result.data.map(async (data, index) => {

                  company[index] = data;
                })
                handleClickOpen();
              }
            }

          })
          
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form onSubmit={handleSubmit}>
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Password"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
            id="password-input"
          />

          <Box my={2}>
            <Button id="signin_password_button" issuccess={`${isSuccess}`} color="secondary" disabled={isBusy} fullWidth size="large" type="submit" variant="contained" startIcon={<LockIcon />}>
              <Typography variant="h6">Sign in</Typography>
            </Button>
          </Box>
          <Button startIcon={<KeyboardBackspaceIcon />} onClick={() => props.setMode(1)}>Back</Button>

          <ChooseCompanyDialog id="choose_company_dialog" open={open} onClose={handleClose} />

        </form>
      )}
    </Formik>
  );
};

export default LoginPassword;