import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  Grid
} from '@material-ui/core';
import {
  SubmitButton,
  ApproveButton,
  RejectButton,
  CancelButton,
} from './style.js';
import { useNavigate } from 'react-router-dom';
import directus from '../../services/directus';
import { SupervisorPermission, AdminPermission } from 'src/utils/Permission';
import useStateRef from "react-usestateref";
import LockIcon from '@material-ui/icons/Lock';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import TextField from '@mui/material/TextField';
import Divider from '@material-ui/core/Divider';
const moment = require('moment-timezone');

let calculate;

export default function ApproveRejectButton(props) {
  /* eslint-disable no-unused-vars*/
  const navigate = useNavigate();
  const [initialized, setInitialized] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [openApprove, setOpenApprove] = React.useState(false);
  const [approveText, setApproveText] = React.useState('');
  const [note, setNote] = React.useState('');

  const [approveCount, setApproveCount, approveCountRef] = useStateRef('');
  const [checkedCount, setCheckedCount, checkedCountRef] = useStateRef('');
  const [openSubmit, setOpenSubmit] = React.useState(false);

  const [noteSubmit, setNoteSubmit] = React.useState('');
  const [timesheetEvent, setTimesheetEvent, timesheetEventRef] = useStateRef('');
  const [approvalLockData, setApprovalLockData, ApprovalLockDataRef] = useStateRef('');
  const { enqueueSnackbar } = useSnackbar();
  const [weekMinutes, setWeekMinutes] = React.useState(null);

  const [duration, setDuration] = React.useState(time_convert_Duration(0));
  const [durationClaim, setDurationClaim] = React.useState(time_convert_Duration(0));
  const [rdoHoursEnable, setRdoHoursEnable] = useState(null);

  var fullWeekMinutes = JSON.parse(window.localStorage.getItem('configuration')).full_week_minutes;
  var fullWeekMinutesRDO = JSON.parse(window.localStorage.getItem('configuration')).full_week_minutes_rdo;

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
    }
  });


  /**
   * TODO: open confirm reject dialog, with set open state to 'true'
   * * This function is executed when the user clicks the 'Reject' button
   */
  const handleClickOpen = () => {
    setOpen(true);
  };

  // TODO: close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  /**
   * * This function is executed when the user clicks the 'Approve' button
   * TODO: 
   * * if the timesheet being reviewed does not have session data: will display the message 'This timesheet has no sessions. Please add a session before approving the timesheet.'
   * * if there is session data whose start_time is empty, or start_time and end time have the same time, or duration = 0 : then display the error message "There is " + approveErrorValue + " session(s) with a duration of 0 hours. Please fix this duration first and try again."
   * * if no session data is ticked: will display a message 'No sessions selected, all sessions will be approved, are you sure?'
   * * if all session data is ticked: will display a confirmation message 'All sessions selected, are you sure?'
   * * if not all session data is selected, only some: will display a confirmation message '(number of sessions ticked) sessions selected, are you sure?'
   * * other than the conditions above: will display a message 'There is no process in this sessions'
   */
  const handleClickOpenApprove = () => {
    let checkValue = [];
    let approveErrorValue = 0;
    let checkedErrorValue = 0;
    let sessionPending = 0;
    let isNoCheck = false;

    props.sessionData.map((data) => {
      //* if session data is ticked and status = pending
      if (data.select && data.status === 'pending') {
        if (data.session_start_time !== null && data.session_start_time !== data.session_end_time) {
          checkValue.push({ id: data.id, status: 'approved' });
        }
        else if (data.duration !== 0) {
          checkValue.push({ id: data.id, status: 'approved' });
        }
        else {
          approveErrorValue = approveErrorValue + 1;
        }
      }

      if (data.status === 'pending') {
        sessionPending = sessionPending + 1;
      }

      return null;
    })

    //* if no data session is ticked
    if (checkValue.length === 0) {
      isNoCheck = true;
      props.sessionData.map((data) => {

        if (data.status === 'pending') {
          if (data.session_start_time !== null && data.session_start_time !== data.session_end_time) {
            checkValue.push({ id: data.id, status: 'approved' });
          }
          else if (data.duration !== 0) {
            checkValue.push({ id: data.id, status: 'approved' });
          }
          else {
            checkedErrorValue = checkedErrorValue + 1;
          }
        }
      })
    }

    if (props.sessionData.length === 0) {
      setApproveText('This timesheet has no sessions. Please add a session before approving the timesheet.');
    }
    else if (approveErrorValue > 0) {
      setApproveCount(approveErrorValue);
      setApproveText("There is " + approveErrorValue + " session(s) with a duration of 0 hours. Please fix this duration first and try again.");
    }
    else if (checkedErrorValue > 0) {
      setCheckedCount(checkedErrorValue);
      setApproveText("There is " + checkedErrorValue + " session(s) with a duration of 0 hours. Please fix this duration first and try again.");
    }
    else if (isNoCheck === true) {
      setApproveText('No sessions selected, all sessions will be approved, are you sure?');
    }
    else if (checkValue.length === sessionPending) {
      setApproveCount(0);
      setCheckedCount(0);
      setApproveText('All sessions selected, are you sure?');
    }
    else if (checkValue.length > 0) {
      setApproveCount(0);
      setCheckedCount(0);
      setApproveText(checkValue.length + ' sessions selected, are you sure?');
    }
    else {
      setApproveText('There is no process in this sessions');
    }
    setOpenApprove(true);
  };

  // TODO: close dialog approve session list
  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  // TODO: moves to the next timesheet, if there is a next timesheet or if props.right is not null
  function nextTimesheet() {
    navigate('/approvals/' + props.right.id);
    props.loadTimesheets(props.right.id);
    props.changeButtonLoading(false);
  }

  /**
   * * This function is executed when the user clicks 'Approve' button in the dialog
   * TODO:
   * * 1. update timesheet status and session status from that timesheet to 'approved' in directus based on session data that has been ticked or if nothing is ticked then update all session data on the timesheet that is being reviewed
   * * 2. after completing the update on directus, then navigate to the next timesheet if there is a next timesheet, if not there then navigate to the approvals page
   */
  const approveButton = async () => {
    handleCloseApprove();
    props.changeButtonLoading(true);
    let checkValue = [];
    let allSession = [];

    let timesheetId = window.location.pathname.split('/').pop();

    props.sessionData.map((data) => {
      if (data.select && data.status === 'pending') {
        checkValue.push({ id: data.id, status: 'approved' });
      }
      return null;
    })

    if (checkValue.length === 0) {
      try {
        var SessionResult = await directus.getItems('sessions', {
          fields: 'id, status',
          filter: {
            timesheet: { in: timesheetId },
            status: 'pending'
          }
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if (SessionResult.data.length !== 0) {
        SessionResult.data.map((data) => {
          allSession.push({ id: data.id, status: 'approved' });
          return null;
        })

        try {
          await directus.createItems('approvals_record', [{ approved_by: JSON.parse(window.localStorage.getItem('directus_employee')).id, timesheet: timesheetId }]);

          await directus.updateItems(
            'sessions',
            allSession,
            {
              fields: 'id',
            }
          );
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      try {
        //eslint-disable-next-line no-redeclare
        var SessionResult = await directus.getItems('sessions', {
          fields: 'id',
          filter: {
            employee: { eq: props.employeeIdChosen },
            timesheet: { eq: timesheetId },
            status: 'pending'
          }
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }


      if (SessionResult.data.length === 0) {
        try {
          await directus.updateItem('timesheets', timesheetId, {
            status: 'approved',
          });
          props.right ? nextTimesheet() : navigate('/approvals');
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
      else {
        props.loadTimesheets();
        props.changeButtonLoading(false);
      }
    }
    else {
      try {
        await directus.updateItems(
          'sessions',
          checkValue,
          {
            fields: 'id',
          }
        );
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      try {
        //eslint-disable-next-line no-redeclare
        var SessionResult = await directus.getItems('sessions', {
          fields: 'id, status',
          filter: {
            employee: { eq: props.employeeIdChosen },
            timesheet: { eq: timesheetId },
            status: 'pending'
          }
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }

      if (SessionResult.data.length === 0) {
        try {
          await directus.updateItem('timesheets', timesheetId, {
            status: 'approved',
          });
          props.right ? nextTimesheet() : navigate('/approvals');
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
      else {
        props.loadTimesheets();
        props.changeButtonLoading(false);
      }
    }
  }

  /**
   * * This function is executed when the user clicks 'Reject' button in the dialog
   * TODO: 
   * * 1. if there is a data session ticked, then update the status of the data session on directus to be 'rejected'
   * * 2. but if there is no session data ticked, there is no need to update the session data, just update the timesheet status in directus to be 'rejected' by adding a note from the user
   * * 3. after completing the update on directus, then navigate to the next timesheet if there is a next timesheet, if not there then navigate to the approvals page
   */
  const rejectButton = async () => {
    handleClose();
    props.changeButtonLoading(true);
    let checkValue = [];

    props.sessionData.map((data) => {
      if (data.select && data.status === 'pending') {
        checkValue.push({ id: data.id, status: 'rejected' });
      }
      return null;
    })

    if (checkValue.length !== 0) {
      try {
        await directus.updateItems('sessions', checkValue);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    let timesheetId = window.location.pathname.split('/').pop();
    try {
      await directus.updateItem('timesheets', timesheetId, {
        status: 'rejected',
        reviewer_notes: note,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    props.right ? nextTimesheet() : navigate('/approvals');
  }

  /**
   * * This function is executed when the user clicks the 'Unapprove' button. The 'Unapprove' button will appear, if the admin or supervisor has approved the timesheet
   * TODO: 
   * * 1. get data sessions from directus, with a filter based on the employee id of the timesheet being reviewed, timesheet id being reviewed, and based on status = 'approved'
   * * 2. if there is data obtained in point 1, then update the session status of the data to be 'pending'
   * * 3. but if no data is obtained in point 1, just update the status of the timesheet on directus to 'awaiting_approval' based on the timesheet id being reviewed
   */
  const unApproveButton = async () => {
    handleClose();
    props.changeButtonLoading(true);
    let checkValue = [];

    let timesheetId = window.location.pathname.split('/').pop();
    try {
      var SessionResult = await directus.getItems('sessions', {
        fields: 'id, status',
        filter: {
          employee: { eq: props.employeeIdChosen },
          timesheet: { eq: timesheetId },
          status: 'approved',
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (SessionResult.data.length > 0) {
      SessionResult.data.map((data) => {
        checkValue.push({ id: data.id, status: 'pending' });
      })

      try {
        await directus.updateItems('sessions', checkValue);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    try {
      await directus.updateItem('timesheets', timesheetId, {
        status: 'awaiting_approval',
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    props.loadTimesheets();
    props.changeButtonLoading(false);
  }

  /**
   * @param e => event to handle onChange :
   * * name="notes" => if there is a change in the note field when the user wants to reject
   * * name="notesSubmit" => if there is a change in the note field when the user wants to submit for approval
   * * name="duration" => if weekMinutes less than props.totalDuration will display a text field for RDO Time Accrued or Bank Extra Hours. If there is a change in the text field, parameter e will have name='duration'
   * * name="duration_claim" => if there is a change in the RDO Time Taken field or Claim Banked Hours field when the user wants to submit for approval
   * TODO: set the note state, or the noteSubmit state, or duration state, or durationClaim state to be the same as that typed by the user, according to the text field that has changed
   */
  const handleInputChange = e => {
    const { name, value } = e.target;
    if (name === 'notes') {
      setNote(value);
    }
    else if (name === 'notesSubmit') {
      setNoteSubmit(value);
    }
    else if (name === 'duration') {
      setDuration(value);
    }
    else if (name === 'duration_claim') {
      setDurationClaim(value);
    }
  }

  /**
   * * This function is executed when the user clicks the 'Submit for Approval' button
   * TODO: 
   * * 1. If enableTimesheetBankHours from props is 'true', then check the value of rdo_hours_enable in the employee data in the timesheet data being reviewed:
   * *    -> if true = set weekMinutes with value fullWeekMinutesRDO and set the calculate variable with the value from the result of reducing the total duration with fullWeekMinutesRDO
   * *    -> but if false = set weekMinutes with value fullWeekMinutes and set the calculate variable with value from the result of reducing the total duration with fullWeekMinutes
   * * 2. Set rdoHoursEnable, duration, durationClaim, and openSubmit state
   */
  const handleClickSubmit = async () => {
    if (props.enableTimesheetBankHours) {
      if (props.timesheets.employee.rdo_hours_enable) {
        setWeekMinutes(fullWeekMinutesRDO);
        calculate = (props.totalDuration - fullWeekMinutesRDO);
      }
      else {
        setWeekMinutes(fullWeekMinutes);
        calculate = (props.totalDuration - fullWeekMinutes);
      }
    }

    setRdoHoursEnable(props.timesheets.employee.rdo_hours_enable);
    setDuration(time_convert_Duration(props.timesheets.banked_minutes));
    setDurationClaim(time_convert_Duration(props.timesheets.claim_banked_minutes));
    setOpenSubmit(true);
  };

  /**
   * * when the user wants to submit for approval and clicks the 'cancel' button in the dialog, this function will be executed
   * TODO: close the confirmation dialog
   */
  const handleCloseSubmit = () => {
    setOpenSubmit(false);
  };

  /**
   * TODO: return true if valid and set duration to empty if not valid
   * @param data is the number or date which will be checked as valid or not
   */
  const checkTimeValid = (data) => {
    if (moment(data).isValid()) {
      return true;
    }
    else {
      setDuration('');
    }
  }

  /**
   * TODO: return true if valid and set durationClaim to empty if no valid
   * * this function is called inside the time_convert_claim function
   * @param data : the value to be checked is valid or not
   */
  const checkClaimTimeValid = (data) => {
    if (moment(data).isValid()) {
      return true;
    }
    else {
      setDurationClaim('');
    }
  }

  // TODO: set duration state depends on what the user typed in the RDO Time Accrued or Bank Extra Hours column
  function time_convert() {
    if (duration !== '' || duration !== "0" || duration !== null) {
      let parts = duration.split("h");
      let parts2 = duration.split("m");

      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDuration(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            if ((parseInt(parts[0], 10) * 60) > calculate) {
              // setDuration(calculate + "h " + (parseInt(parts[1].slice(0, -1)|0, 10) +"m"));
              setDuration(time_convert_Duration(calculate));
            }
            else {
              if ((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))) > calculate) {
                setDuration(time_convert_Duration(calculate));
              }
              else {
                setDuration((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) > 60 ? 0 + "m" : parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
              }
            }
          }
        }
      }
      else {
        var n = new Date(0, 0);

        if (checkTimeValid(n)) {
          if ((duration * 60) > calculate) {
            setDuration(time_convert_Duration(calculate));
          }
          else {
            setDuration(time_convert_Duration(duration * 60))
          }
        }
      }
    }
  }

  /**
   * * when the user wants to submit for approval and fills in the 'Claim Banked Hours' field, then the user leaves the field or the field loses focus (onBlur), this function is executed
   * * durationClaim equal to which the user typed in the 'Claim Banked Hours' field
   * TODO: 
   * * if durationClaim is not empty or not null or not = 0 then:
   * * check whether the input from the user includes the letter h(for hours) or m(for minutes)?
   * *  > if true :
   * *    -> check if there is only m?
   * *      ~ if true, then parse to integer and check whether the value is valid or not, if valid set durationClaim state with the results of the parse to integer and combined with the letter m. But if it is not valid then set the durationClaim state to be empty
   * *      ~ if false, then calculate: the result of parse to integer(split h index-0) * 60 plus the result of parse to integer(split h index-1 which is given a default value of 0 so that it is not NaN) if the result is valid set durationClaim by combining hour values and the minutes
   * *  > if the input from the user does not include the letters h or m, then: get the hour from the number typed, if what is typed is the number 24 it means it is at 00.00, if it is more than 24, then subtract with the number 24, until the last subtraction result is smaller than 24 (because in 1 day there are only 24 hours) 
   */
  function time_convert_claim() {
    if (durationClaim !== '' || durationClaim !== "0" || durationClaim !== null) {
      let parts = durationClaim.split("h");
      let parts2 = durationClaim.split("m");
      if (parts.length > 1 || parts2.length > 1) {
        if (parts.length === 1) {
          if (checkClaimTimeValid(parseInt(parts[0].slice(0, -1), 10))) {
            setDurationClaim(parseInt(parts[0].slice(0, -1), 10) + "m");
          }
        }
        else {
          if (checkClaimTimeValid((parseInt(parts[0], 10) * 60 + (parseInt(parts[1].slice(0, -1) | 0, 10))))) {
            setDurationClaim((parseInt(parts[0], 10) + "h ") + (parseInt(parts[1].slice(0, -1) | 0, 10) > 60 ? 0 + "m" : parseInt(parts[1].slice(0, -1) | 0, 10) + "m"));
          }
        }
      }
      else {
        var decimalTimeString = durationClaim;
        var n = new Date(0, 0);
        n.setMinutes(+decimalTimeString * 60);
        if (checkClaimTimeValid(n)) {
          setDurationClaim((moment(n).hour() + "h ") + moment(n).minute() + "m");
        }
      }
    }
  }


  /**
   * @param num is the number that will convert to hour and minute format
   * @returns the result of counting hours and minutes
   */
  function time_convert_Duration(num) {
    var hours = Math.floor(num / 60);
    var minutes = num % 60;

    return hours + "h " + minutes + "m";
  }

  /**
   * TODO: split h and m from timeString parameters, check if any of the splits results have a length greater than 1:
   * * -> if true => if the results of the split parts.length = 1 (meaning there is only a value for minutes) then it returns only the value of the total minutes, but other than that it returns the results of calculating the hours multiplied by 60 plus the total minutes
   * * -> if false => return the result of adding hours and minutes
   * @param timeString:
   * * has the value equal to the duration state, if the duration state is not null or empty
   * * has the value equal to the durationClaim state, if the durationClaim state is not null or empty
   */
  function parseTimeSpan(timeString) {
    let parts = timeString.split("h");
    let parts2 = timeString.split("m");
    if (parts.length > 1 || parts2.length > 1) {
      if (parts.length === 1) {
        return (parseInt(parts[0].slice(0, -1), 10))
      }
      else {
        return (parseInt(parts[0], 10) * 60) + (parseInt(parts[1].slice(0, -1) | 0, 10))
      }
    }
    else {
      var decimalTimeString = timeString;
      var n = new Date(0, 0);
      n.setMinutes(+decimalTimeString * 60);
      return ((moment(n).hour() * 60) + moment(n).minute());
    }
  }

  /**
   * TODO: update timesheet data on directus based on the timesheet id taken from the URL, by changing the status to 'awaiting_approval', submission_notes equal to the note typed by the user when submitting for approval, changing the value for the banked_minutes field if the duration state is not empty or null, and change the value for the claim_banked_minutes field if the durationClaim state is neither empty or null
   * * When the user wants to submit for approval and clicks the 'Submit' button on the confirmation dialog, this function will be executed
   */
  const submitApprovalButton = async () => {
    props.changeButtonLoading(true);
    let timesheetId = window.location.pathname.split('/').pop();

    let dataSubmit;

    if (duration !== '' || duration !== "0" || duration !== null) {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: noteSubmit,
        banked_minutes: parseTimeSpan(duration)
      }
    }
    else {
      dataSubmit = {
        status: 'awaiting_approval',
        submission_notes: noteSubmit,
      }
    }

    if (durationClaim !== '' || durationClaim !== "0" || durationClaim !== null) {
      dataSubmit = { ...dataSubmit, claim_banked_minutes: parseTimeSpan(durationClaim) };
    }

    try {
      await directus.updateItem('timesheets', timesheetId, dataSubmit);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    props.loadTimesheets();
    props.changeButtonLoading(false);
  }


  // TODO: set timesheetEvent state with the data from the timesheet_event data retrieval results in directus according to the timesheet id that is the same as the id of the location pathname or URL. And set approvalLockData with the value from the result of retrieving approvals_lock data in directus according to start_date which is the same as start_time from props
  const firstLoad = async () => {
    let timesheetId = window.location.pathname.split('/').pop();
    try {
      var TimesheetEvent = await directus.getItems('timesheet_event', {
        fields: 'id, type, created_on, owner.id, owner.first_name, owner.last_name',
        filter: {
          'timesheet.id': { eq: timesheetId },
        },
        sort: 'created_on'
      });
      setTimesheetEvent(TimesheetEvent.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    try {
      var lockResult = await directus.getItems('approvals_lock', {
        fields: 'id, lock',
        filter: {
          start_date: { eq: props.timesheets.start_time },
        },

      })
      setApprovalLockData(lockResult.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const isInvalid = note === '';

  return (
    <div>
      {props.timesheets.status === 'pending' || props.timesheets.status === 'rejected' ?
        <>
          <div style={{ float: 'right', paddingTop: '10px', paddingRight: '50px', paddingBottom: '10px' }}>
            <SubmitButton
              id="submit_for_approval_btn"
              size="small"
              variant="contained"
              onClick={handleClickSubmit}
              disabled={props.timesheets.employee.status === 'deleted'}
            >
              <Typography variant="h6"> Submit for Approval </Typography>
            </SubmitButton>
          </div>
          <Dialog id="submit_for_approval_dialog" isopen={`${openSubmit}`} open={openSubmit} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
            <DialogContent>
              <DialogContentText>
                Timesheet submission details
              </DialogContentText>
              <Typography variant="h6">Notes</Typography>
              <TextField
                id="reject-note"
                name="notesSubmit"
                placeholder='(Optional) Leave a note'
                multiline
                fullWidth={true}
                minRows={4}
                maxRows={4}
                value={noteSubmit}
                onChange={handleInputChange}
                variant="outlined"
              />

              {props.enableTimesheetBankHours ?
                <div>
                  {weekMinutes < props.totalDuration ?
                    <>
                      <br />
                      <TextField
                        variant="outlined"
                        label={rdoHoursEnable ? "RDO Time Accrued" + (calculate >= 0 ? "(max: " + time_convert_Duration(calculate) + ")" : '') : "Bank Extra Hours" + (calculate >= 0 ? "(max: " + time_convert_Duration(calculate) + ")" : '')}
                        name="duration"
                        placeholder="1h 30m"
                        sx={{ width: '45%' }}
                        value={duration}
                        onChange={handleInputChange}
                        onBlur={(e) => time_convert(e)}
                        size="small"
                      />
                      <br />
                    </>
                    : ''}
                  <br />
                  <TextField
                    variant="outlined"
                    label={rdoHoursEnable ? "RDO Time Taken" : "Claim Banked Hours"}
                    name="duration_claim"
                    placeholder="1h 30m"
                    sx={{ width: '45%' }}
                    value={durationClaim}
                    onChange={handleInputChange}
                    onBlur={(e) => time_convert_claim(e)}
                    size="small"
                  />
                </div>
                : ''}
            </DialogContent>
            <DialogActions>
              <CancelButton
                id="cancel_approval"
                size="small"
                variant="contained"
                onClick={handleCloseSubmit}
              >
                <Typography variant="h6"> Cancel</Typography>
              </CancelButton>
              <SubmitButton
                id="submit_approval"
                size="small"
                variant="contained"
                onClick={submitApprovalButton}
              >
                <Typography variant="h6">Submit</Typography>
              </SubmitButton>
            </DialogActions>
          </Dialog>
        </>
        : ''}

      {props.timesheets.status === 'awaiting_approval' ?
        <div style={{ float: 'right', paddingTop: '10px', paddingRight: '50px', paddingBottom: '10px', }}>
          <ApproveButton
            id="approve_session_btn"
            size="small"
            variant="contained"
            onClick={handleClickOpenApprove}
            disabled={props.timesheets.employee.status === 'deleted'}
          >
            <Typography variant="h6">Approve</Typography>
          </ApproveButton>
          <Dialog id="approve_dialog" isopen={`${openApprove}`} open={openApprove} onClose={handleCloseApprove} aria-labelledby="form-dialog-title" fullWidth={true}>
            <DialogContent>
              <DialogContentText>
                <span id="approve_text">{approveText}</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>

              {approveCountRef.current > 0 || checkedCountRef.current > 0 || props.sessionData.length === 0 ?
                <span>
                  <CancelButton
                    id="cancel_approve_btn"
                    size="small"
                    variant="contained"
                    onClick={handleCloseApprove}
                  >
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                </span>
                :
                <span>
                  <CancelButton
                    id="cancel_approve_btn"
                    size="small"
                    variant="contained"
                    onClick={handleCloseApprove}
                  >
                    <Typography variant="h6">Cancel</Typography>
                  </CancelButton>
                  <ApproveButton
                    id="approve_btn"
                    size="small"
                    variant="contained"
                    onClick={approveButton}
                  >
                    <Typography variant="h6">Approve</Typography>
                  </ApproveButton>
                </span>
              }
            </DialogActions>
          </Dialog>
          <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
          <RejectButton
            id="reject_session_btn"
            size="small"
            variant="contained"
            onClick={handleClickOpen}
            disabled={props.timesheets.employee.status === 'deleted'}
          >
            <Typography variant="h6">Reject</Typography>
          </RejectButton>

          {/* Reject Dialog */}
          <Dialog id="reject_dialog" isopen={`${open}`} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
            <DialogContent>
              <DialogContentText>
                Please enter the note here.
              </DialogContentText>

              <TextField
                name="notes"
                id="reject-note"
                label="Notes"
                multiline
                fullWidth={true}
                minRows={4}
                maxRows={4}
                value={note}
                onChange={handleInputChange}
                variant="outlined"
              />
            </DialogContent>
            <DialogActions>
              <CancelButton
                id="cancel_reject_btn"
                size="small"
                variant="contained"
                onClick={handleClose}
              >
                <Typography variant="h6">Cancel</Typography>
              </CancelButton>
              <RejectButton
                id="reject_btn"
                size="small"
                variant="contained"
                disabled={isInvalid}
                onClick={rejectButton}
              >
                <Typography variant="h6">Reject</Typography>
              </RejectButton>
            </DialogActions>
          </Dialog>
        </div>
        : ''}

      {/* eslint-disable-next-line no-mixed-operators */}
      {SupervisorPermission() || AdminPermission() && (props.timesheets.status === 'approved') ?
        (props.timesheets.status === 'pending' || props.timesheets.status === 'rejected' || props.timesheets.status === 'awaiting_approval' || props.timesheets.status === 'paid') ?
          ''
          :
          <div style={{ float: 'right', paddingTop: '10px', paddingRight: '50px', paddingBottom: '10px' }}>
            {ApprovalLockDataRef.current ?
              ApprovalLockDataRef.current[0].lock === 'true' ?
                <div>
                  <LockIcon
                    style={{ marginBottom: '-10px' }}
                    color="disabled"
                  />
                  <ApproveButton
                    id="unapprove_btn"
                    size="small"
                    variant="contained"
                    disabled
                  >
                    Unapprove
                  </ApproveButton>
                </div>

                :
                <div>
                  <ApproveButton
                    id="unapprove_btn"
                    size="small"
                    variant="contained"
                    onClick={unApproveButton}
                    disabled={props.timesheets.employee.status === 'deleted'}
                  >
                    Unapprove
                  </ApproveButton>

                </div>
              : ''}
          </div>
        : ''
      }
    </div>
  );
}
