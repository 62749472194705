import { 
  makeStyles,
  Chip,
  withStyles, 
  Button, 
  List,
} from '@material-ui/core';

let screenHeight = window.screen.height-460;

export const FileChip = withStyles(() => ({
  root: {
    backgroundColor:'#8bc1e0',
    color:'white',
    marginRight:'3px',
    '& .MuiSvgIcon-root':{
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#8bc1e0',
    },
    '&:focus':{
      backgroundColor:'#8bc1e0',
    }
  },
}))(Chip);

export const AddChip = withStyles(() => ({
  root: {
    backgroundColor:'#3399FF',
    color:'white',
    '& .MuiSvgIcon-root':{
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#3399FF',
    },
    '&:focus':{
      backgroundColor:'#3399FF',
    }
    
  },
}))(Chip);

export const UploadButton = withStyles(() => ({
  root: {
    backgroundColor:'#0aa4c7',
    color:'white',
    marginRight:'3px',
    '& .MuiSvgIcon-root':{
      color:'white'
    },
    '&:hover':{
      backgroundColor:'#0aa4c7',
    },
    '&:focus':{
      backgroundColor:'#0aa4c7',
    },
    borderRadius:'10px'
    
  },
}))(Button);

export const ListCustom = withStyles(() => ({
  root:{
    '& .MuiListItem-gutters':{
      paddingTop:'0px',
      paddingBottom:'0px',
    },
  }
}))(List);


export const useStyles = makeStyles(() => ({
  inputWidth: {
    padding: '12px 0px 0px 0px !important',
  },
  inputWidthDate: {
    padding: '0px 0px 0px 0px !important',
  },
  lunchInputWidth: {
    padding: '0px !important',
  },
  notes: {
    width: '60%',
    height: '70%'
  },
  duration: {
    height: '70%',
  },

  mins: {
    marginLeft: '0px',
  },
  KeyboardDateTimePicker: {
    margin: '-12px',
  },
  grid3: {
    padding: '0px 0px 0px 0px !important',
    flexBasis: '24%',
    maxWidth: '24%',
  },
  grid4: {
    padding: '12px 0px 0px 0px !important',
  },
  grid7: {
    padding: '12px 0px 0px 0px !important',
  },
  grid3custom: {
    padding: '12px 0px 16px 0px !important',
    flexBasis: '24%',
    maxWidth: '24%',
  },
  grid4custom: {
    padding: '12px 0px 0px 0px !important',
    flexBasis: '25%',
    maxWidth: '25%',
  },
  grid1custom: {
    padding: '0px 0px 0px 0px !important',
    flexBasis: '12%',
    maxWidth: '12%',
  },
  grid2custom: {
    padding: '0px 0px 0px 0px !important',
    flexBasis: '14%',
    maxWidth: '14%',
  },
  grid1: {
    padding: '0px 0px 0px 0px !important',
    flexBasis: '12%',
    maxWidth: '12%',
  },
  grid2: {
    padding: '0px 0px 0px 0px !important',
    flexBasis: '14%',
    maxWidth: '14%',
  },
  sessiontype:{
    paddingLeft:'12px',
    paddingRight:'7px'
  },
  checkbox:{
    paddingLeft:'15px',
    color:'black'
  },
  grid: {
    padding: '12px 0px 0px 0px !important',
  },

}))

export const useStylesSessionListDetails = makeStyles((theme) => ({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#FFF0DD',
  },
  cardHeader: {
    backgroundColor: '#8AA9CA',
    color: '#FFF',
    padding: '0px 16px 0px 0px'
  },
  cardContent: {
    padding: '0px'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: screenHeight+'px',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    backgroundColor: '#D4E3F2'
  },
  cardActions: {
    width: '100%',
    backgroundColor: '#39F',
    color: '#fff'
  },
  circular: {
    color: '#FA9917',
  },
  cardDetailContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF4E6',
  },
  cardDetailFileContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6eaff',
  },
  cardDetailSessionOptionsContent:
  {
    padding: '7px 0px 7px 0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6fffc',
  },
  noCardDetailSessionOptionsContent:
  {
    padding:'0px !important',
  },
  cardDetail:
  {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  noCardDetail:
  {
    padding:'0px'
  },
  cardSubDetail:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF',
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
  },
  cardSubDetailSmoko:
  {
    padding: '0px !important',
    borderRadius: '24px',
    backgroundColor: '#D0F2D4',
  },
  cardSubDetailContentSmoko:
  {
    padding: '0px !important',
  },
  testInput:
  {
    backgroundColor: '#FFF',
    width: '100%',
    height: '90%',
  },
  checkedStatus:
  {
    color: 'green !important'
  },
  checkedStatusFailed:
  {
    color: 'red !important'
  },
  AutoEquipment: {
    width: '100%',
  },
  notes: {
    backgroundColor: 'white',
    width: '100%'
  },
  listStyle: {
    padding: 0
  },
  input: {
    display: 'none',
  },
}));

export const useStylesAddSessionList = makeStyles(() => ({
  KeyboardDateTimePicker: {
    margin: '-12px',
  },
  notes: {
    height: '70%'
  },
}))

export const useStylesAddSessionListDetails = makeStyles((theme) => ({
  details: {
    display: 'block'
  },
  card: {
    borderRadius: '8px',
    backgroundColor: '#FFF0DD',
  },
  cardHeader: {
    backgroundColor: '#8AA9CA',
    color: '#FFF',
    padding: '0px 16px 0px 0px'
  },
  cardContent: {
    padding: '0px'
  },
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: screenHeight+'px',
  },
  listSection: {
    backgroundColor: 'inherit',
  },
  ul: {
    backgroundColor: 'inherit',
    padding: 0,
  },
  subHeader: {
    backgroundColor: '#D4E3F2'
  },
  cardActions: {
    width: '100%',
    backgroundColor: '#39F',
    color: '#fff'
  },
  circular: {
    color: '#FA9917',
    
  },
  cardDetailFileContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6eaff',
  },
  cardDetailContent:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF4E6',
  },
  cardDetailSessionOptionsContent:
  {
    padding: '7px 0px 7px 0px !important',
    borderRadius: '8px',
    backgroundColor: '#e6fffc',
  },
  cardDetail:
  {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingBottom: '20px',
  },
  cardSubDetail:
  {
    padding: '0px !important',
    borderRadius: '8px',
    backgroundColor: '#FFF',
  },
  cardSubDetailContent:
  {
    padding: '0px !important',
  },
  cardSubDetailSmoko:
  {
    padding: '0px !important',
    borderRadius: '24px',
    backgroundColor: '#D0F2D4',
  },
  cardSubDetailContentSmoko:
  {
    padding: '0px !important',
  },
  testInput:
  {
    backgroundColor: '#FFF',
    width: '100%',
    height: '90%',
  },
  checkedStatus:
  {
    color: 'green !important'
  },
  checkedStatusFailed:
  {
    color: 'red !important'
  },
  AutoEquipment: {
    width: '100%',
  },
  notes: {
    backgroundColor: 'white',
    width: '100%'
  },
  input:{
    display:'none'
  }
}));