import React, { useState, useEffect } from 'react';
import {
  Typography,
  Container,
  CircularProgress,
  Grid,
  Button,
  Menu,
  MenuItem,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControlLabel,
  ListItemIcon,
} from '@material-ui/core';
import {
  useStyles,
  CancelButton,
  ActionButton,
  LockButton,
  ColorButton,
  ApprovedPaidButton,
  YesPayrollButton,
  NoPayrollButton,
} from './style.js';
import Page from 'src/components/Page';
// import { enableRipple } from '@syncfusion/ej2-base';
import WarningIcon from '@mui/icons-material/Warning';
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import GetAppIcon from '@material-ui/icons/GetApp';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import AddIcon from '@material-ui/icons/Add';
import SendIcon from '@material-ui/icons/Send';
import PaymentIcon from '@material-ui/icons/Payment';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Icon from "@material-ui/core/Icon";
import linq from "linq";
import useStateRef from "react-usestateref";
import directus from '../../services/directus';
import ApprovalTimesheetList from './ApprovalTimesheetList';
import moment from 'moment';
import { AdminPermission, SupervisorPermission } from 'src/utils/Permission';
import { getConfiguration } from 'src/utils/sessions';
import EventEmitter from 'src/utils/EventEmitter';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import SelectEmployee from './SelectEmployee';
import { red } from '@mui/material/colors';
import Avatar from '@mui/material/Avatar';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

let startApprovals;
let endApprovals;
let filterSessionLeave = 0;
let filterTimesheets = [];
let loop = 0;

const ApprovalListView = () => {
  /* eslint-disable */
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [items, setItems] = React.useState({ employees: null, timesheets: null, sessions: null });
  const [employeesFilterData, setEmployeesFilterData] = React.useState(null);
  const [initialized, setInitialized] = React.useState(false);
  const [employeesFilterChosen, setEmployeesFilterChosen] = React.useState(null);
  const [status, setStatus, statusRef] = useStateRef('all');
  const [statusView, setStatusView, statusViewRef] = useStateRef(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [startDate, setStartDate, startDateRef] = useStateRef('');
  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [openNotif, setOpenNotif] = React.useState(false);
  const [addTimesheetLoading, setAddTimesheetLoading] = React.useState(false);
  const [moveLoading, setMoveLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [exportLoading, setExportLoading] = React.useState(false);
  const [anchorElExport, setAnchorElExport] = React.useState(null);
  const [openDialogPayroll, setOpenDialogPayroll] = React.useState(false);
  const [openDialogPayrollMsg, setOpenDialogPayrollMsg] = React.useState(false);
  const [anchorE2, setAnchorE2] = React.useState(null);
  const [openDialogChangeStatus, setOpenDialogChangestatus] = React.useState(false);
  const [changeLoading, setChangeLoading] = React.useState(false);

  const [payrollMessage, setPayrollMessage] = React.useState(false);
  const [searchApproval, setSearchApprovals, SearchApprovalRef] = useStateRef('');
  const [approvalLockData, setApprovalLockData, approvalLockDataRef] = useStateRef('');
  const [lockLoading, setLockLoading] = React.useState(false);
  const [exportdata, setExportData] = React.useState(null);
  const [selectedStatus, setSelectedStatus] = React.useState(5);
  const [selectedView, setSelectedView] = React.useState(1);
  const [errorText, setErrorText] = React.useState(null);
  // const [timesheetReview, setTimesheetReview, timesheetReviewRef] = useStateRef(null);
  const [unreadMessage, setUnreadMessage, unreadMessageRef] = useStateRef('');
  const [configuration, setConfiguration] = useState(null);
  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  const [description, setDescription, descriptionRef] = useStateRef('');
  const [notification, setNotification, notificationRef] = useStateRef('');
  const [title, setTitle, titleRef] = useStateRef('');
  const [statusValue, setStatusValue] = useState(null);
  const [codeAll, setCodeAll] = useState(false);

  /* eslint-enable */
  // enableRipple(true);

  var react_app_url = process.env.REACT_APP_URL;
  if (window.location.hostname.search('interax') !== -1) {
    react_app_url = process.env.REACT_APP_URL_INTERAX;
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      firstLoad();
      ApprovalExport();
      loadConfiguration();
      statusSummaryTypeLoad();
    }

    /**
     * TODO : Set searchApproval with the value from the eventData param and call the firstLoad function
     * @param eventData obtained when the user input in the search field
     */
    const searchApprovals = (eventData) => {
      setSearchApprovals(eventData.text);
      firstLoad();
    }

    //* listen for the searchApprovals event that has been emitted and catch its value in the parameter in the searchApprovals function above
    const listener = EventEmitter.addListener('searchApprovals', searchApprovals);

    return () => {
      listener.remove();
      setInitialized(false);
    }
  }, []);

  // TODO: emit an event with the name summaryDate and set startdate and enddate values ​​in it
  const SummaryDate = () => {
    EventEmitter.emit('summaryDate', {
      startdate: startDateRef.current,
      enddate: endDateRef.current
    })
  }

  // TODO: set the configuration state value with the config_ts item from directus
  const loadConfiguration = async () => {
    try {
      var configuration_data = await directus.getItems('config_ts');
      setConfiguration(configuration_data.data[0]);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e.code), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  };

  /**
   * TODO:
   * * 1. get the status field from the timesheets collection in directus and store its value in the timesheet_data variable
   * * 2. get listing all [key, value] pairs of timesheet_data.data.options.status_mapping and store its value in the timesheet_status variable
   * * 3. set name and background_color according to the data type whether approved, rejected, pending, or other and save it as an object in the timesheet_list variable array
   * * 4. finally set timesheetStatusType state with value from timesheet_list variable
   */
  const statusSummaryTypeLoad = async () => {
    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      var timesheet_list = [];
      if (timesheet_status.length > 0) {
        timesheet_status.map((value) => {
          if (value[1].value !== 'deleted') {
            var background_color = "";
            var name = value[1].name;
            var text_color = "#000000de";

            if (value[1].value === "approved") {
              background_color = "#DAF0DC";
            } else if (value[1].value === "rejected") {
              background_color = "#FFDBD9";
            } else if (value[1].value === "pending") {
              background_color = "#D4E3F2";
              name = "Not Yet Submitted";
            } else if (value[1].value === "awaiting_approval") {
              background_color = "#FFF0DD";
              name = "Awaiting Approvals";
            } else if (value[1].value === "sent") {
              background_color = "deepskyblue";
              name = "Sent to Payroll";
            } else if (value[1].value === "paid") {
              background_color = "#acebac";
            } else {
              background_color = "#ffffff";
            }
            timesheet_list.push({
              value: value[1].value,
              name: name,
              background_color: background_color,
              text_color: text_color
            })
          }
          return null;
        })

        setTimesheetStatusType(timesheet_list);
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  /**
   * * When the user clicks the checkbox to the left of the code text, this function is executed
   * TODO: to tick all the checkboxes in the data approval list, by setting the value true to the select field or false if the user unchecks this checkbox. And the last, set codeAll state with value depends on e.target.checked
   * @param e is event sent when the checkbox is clicked
   */
  const handleChangeCode = (e) => {
    setItems(prevItems => ({
      ...prevItems, timesheets:
        items.timesheets.map(d => {
          d.select = e.target.checked;
          return d;
        })
    })
    );
    setCodeAll(e.target.checked);
  };

  /**
   * * when user clicks on Add Employee Timesheet button, this function is executed
   * TODO: set open state with the value true, to open the dialog
   */
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  /**
   * * when the user clicks cancel button on the dialog add employee timesheet, this function is executed
   * TODO: set open state with the value false, to close the dialog
   */
  const handleCloseDialog = () => {
    setEmployeesFilterChosen('');
    setOpen(false);
  };

  /**
   * * when the user clicks ok button on the dialog error, this function is executed
   * TODO: set openError state with the value false, to close the dialog
   */
  const handleCloseError = () => {
    setOpenError(false);
  };

  /**
   * * when the user clicks the 'Send to Payroll' menu item on export menu, this function is executed
   * TODO: set openDialogPayroll state with the value true, to open the dialog
   */
  const handleClickOpenDialogPayroll = () => {
    handleCloseExport();
    setOpenDialogPayroll(true);
  };

  /**
   * * when the user clicks the 'no' button on dialog after clicks the 'Send to Payroll' menu item on export menu, this function is executed
   * TODO: set openDialogPayroll state with the value false, to close the dialog
   */
  const handleCloseDialogPayroll = () => {
    setOpenDialogPayroll(false);
  };

  /**
   * * when the user clicks the one of the menu items on update status menu, this function is executed
   * TODO: set the state values ​​for OpenDialogChangestatus to true and set the state values ​​for statusValue, title and description depending on the status parameters received
   * @param status is the name of the status that corresponds to the menu item that was clicked
   */
  const handleClickOpenDialogChangeStatus = (status) => {
    setOpenDialogChangestatus(true);
    setStatusValue(status.value);
    setTitle("Change Status to " + status.name);
    setDescription("This will change the timesheets status to " + status.name + ". Are you sure?");
  };

  /**
   * * when the user clicks the 'NO' button in the dialog, after clicking one of the menu items on the update status menu, this function is executed
   * TODO: set openDialogChangestatus state with the value false, to close the dialog
   */
  const handleCloseDialogChangeStatus = () => {
    setOpenDialogChangestatus(false);
    handleCloseChangeStatus();
  };

  /**
   * * when the user clicks the 'YES' button in the dialog, after clicking one of the menu items on the update status menu, this function is executed
   * TODO: 
   * * 1. Initialization of the timesheets variable with the value of the timesheets data in the items state
   * * 2. if the timesheets variable is not empty :
   * *    - find data in the timesheets variable that has been checked for its checkbox, and is not in the 'deleted' status and save the result to variable timesheetsList
   * *    - if no data found, a notification dialog will appear with the information that has been set
   * *    - but if the data is found :
   * *        > save every timesheet id from variable timesheetsList  whose total_minutes is not 0 and whose employee status is not 'deleted' to the timesheetId variable
   * *        > and also save each timesheet id and status with latest value which user want to update from timesheetsList variable to updateList variable
   * *        > then update the timesheets data in directus according to the value of the updateList variable
   * *        > if the timesheetId is not empty, get the session data that is the same as the id in the timesheetId variable, then update the data in directus with the status value that has been changed according to what the user selected
   * 
  */
  const updateChangeStatus = async () => {
    var timesheets = items.timesheets;
    let updateList = [];
    let sessionList = [];
    let timesheetId = [];

    setChangeLoading(true);
    handleCloseDialogChangeStatus();
    handleCloseChangeStatus();

    if (timesheets) {
      let timesheetsList = linq.from(timesheets)
        .where(x => x.select === true && x.employee.status !== 'deleted')
        .toArray();

      if (timesheetsList.length > 0) {
        timesheetsList.map((value) => {
          if (value.total_minutes !== 0 && value.employee.status !== 'deleted') {
            timesheetId = [...timesheetId, value.id];
          }

          updateList.push({
            id: value.id,
            status: statusValue
          })
        })

        await directus.updateItems('timesheets', updateList);

        try {
          if (timesheetId.length > 0) {
            var SessionResult = await directus.getItems('sessions', {
              fields: 'timesheet.id,id,status',
              filter: {
                'timesheet.id': { eq: timesheetId },
              },
              limit: -1
            });

            if (SessionResult.data.length > 0) {
              SessionResult.data.map((value) => {
                sessionList.push({
                  id: value.id,
                  status: statusValue === 'awaiting_approval' ? 'pending' : statusValue
                })
              })
              await directus.updateItems('sessions', sessionList);
            }
          }
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }

        loadTimeSheets('null', statusRef.current);
        EventEmitter.emit('reloadChangeStatus', { value: true })
        setCodeAll(false);
      }
      else {
        setOpenNotif(true);
        setNotification('There is no timesheet selected. Please select your timesheet first.')
      }

      setOpenDialogChangestatus(false);
      setChangeLoading(false);
    }
  }

  /**
   * * When the user clicks the Export button, this function is executed
   * TODO: open menu items export
   */
  const handleClickExport = (event) => {
    setAnchorElExport(event.currentTarget);
  };

  // TODO: close the menu items export
  const handleCloseExport = () => {
    setAnchorElExport(null);
  };

  /**
   * * When the user clicks the Status button, this function is executed
   * TODO: open menu items filter status
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // TODO: close the menu items filter status
  const handleClose = () => {
    setAnchorEl(null);
  };

  /**
   * * When the user clicks the Update Status button, this function is executed
   * TODO: open menu items update status
  */
  const handleClickChangeStatus = (event) => {
    setAnchorE2(event.currentTarget);
  };

  // TODO: close the menu items update status
  const handleCloseChangeStatus = () => {
    setAnchorE2(null);
  };

  /**
   * * When updating the status, the user does not check one of the checkboxes and clicks OK in the notification dialog, this function is executed
   * TODO: Close the dialog
   */
  const handleCloseNotif = () => {
    setOpenNotif(null);
  };

  /**
   * * When the user clicks the Unread Messages checkbox, this function is executed
   * TODO: set the unreadMessage state with the value true or false depending on whether or not the user clicks the Unread Messages checkbox
   */
  const tickHandleChange = (event) => {
    setUnreadMessage(event.target.checked);
  };

  /**
   * * This function will be executed when the Approvals page is opened and when a date range is clicked
   * @param data have default value false, the value changes to true if the user clicks on the date range at the top of the page
   * TODO: 
   * * 1. check if the approvals_date in local storage has a value and whether the data parameter is false
   * * 2. if point 1 is true, set the startApprovals and endApprovals variables based on the results of aprrovals_date taken from local storage
   * * 3. if point 1 is false, check for today in which week or date range. Then set the startApprovals and endApprovals variables with the specified date.
   * * 4. Finally, set the startDate and endDate state values ​​and call the SummaryDate, lockApproval, and loadTimeSheets functions
   */
  const firstLoad = async (data = false) => {

    if (window.localStorage.getItem('approvals_date') && data === false) {
      startApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).start;
      endApprovals = JSON.parse(window.localStorage.getItem('approvals_date')).end;
    }
    else {
      var ConfigurationResult = await getConfiguration();
      let config_day = moment(moment().day(ConfigurationResult.first_day_of_week)._d);

      if (moment(moment().add(-2, 'd').format('YYYY-MM-DD')).isBefore(moment(config_day).format('YYYY-MM-DD'))) {
        startApprovals = (moment(config_day).add(-7, 'd').format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
      else {
        startApprovals = (moment(config_day).format('YYYY-MM-DD 00:00:00'));
        endApprovals = (moment(startApprovals).add(6, 'd').format('YYYY-MM-DD 23:59:59'));
      }
    }

    setStartDate(startApprovals ? startApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 00:00:00'));
    setEndDate(endApprovals ? endApprovals : moment().add(-2, 'd').format('YYYY-MM-DD 23:59:59'));

    SummaryDate();
    lockApproval();
    loadTimeSheets('null', statusRef.current);

  }

  /**
   * * this function is called inside the firstLoad and moveTimesheet functions
   * TODO: 
   * * 1. get data approvals_lock which has start_date value equal to startDateRef.current from directus
   * * 2. if no data is found then create items approvals_lock with start_date value equal to startDateRef.current and lock is set to false
   * * 3. Finally, set approvalLockdata with the value of the data in the lockResult variable
   */
  const lockApproval = async () => {
    try {
      setLockLoading(true);
      var lockResult = await directus.getItems('approvals_lock', {
        fields: 'id,lock',
        filter: {
          start_date: startDateRef.current
        },
      })

      if (lockResult.data.length === 0) {
        try {
          lockResult = await directus.createItems('approvals_lock', [{ start_date: startDateRef.current, lock: 'false' }]);
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }

      setApprovalLockData(lockResult.data[0]);
      setLockLoading(false);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  /**
   * * when the user clicks the Lock Approvals or Unlock Approvals button, this function will be executed
   * @param id is the id value of the current date range data
   * @param event value is false if the user clicks unlock approvals button and value is true if the user click the lock approvals button
   * TODO: update the lock field with the same value as the event parameter in approvals_lock in directus based on parameter id
   */
  const loadLock = async (id, event) => {
    setLockLoading(true);
    try {

      var lockResult = await directus.updateItem('approvals_lock', id,
        {
          lock: event,
        },
        { fields: 'id,lock' }
      );
      setApprovalLockData(lockResult.data);

    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
    setLockLoading(false);
  }

  /**
   * @param {*} employeeData has a null value every time this function is called from another function
   * @param {*} status has a statusRef.current value or a specific status value sent from another function
   * TODO: set the timesheets value in the items state with the results of retrieving approvals timesheet data from directus which has been filtered and changed as needed
   */
  const loadTimeSheets = async (employeeData, status) => {
    setMoveLoading(true);
    let employeesId = [];
    let filterData;
    let employeeArray = [];
    let loopCountEmployee = 0;
    let employeeCount = 0;

    if (employeeData !== 'null') {
      if (employeeData.length > 30) {
        employeeData.map((data, index) => {

          if (data) {
            if (index !== 0 && index % 30 === 0) {
              employeeArray[loopCountEmployee] = employeesId;
              employeesId = [];
              loopCountEmployee = loopCountEmployee + 1;
              employeeCount = 0
            }
            employeesId[employeeCount] = data.id;
            employeeCount = employeeCount + 1;

            if (index + 1 === employeeData.length) {
              employeeArray[loopCountEmployee] = employeesId;
            }
          }
        })
      }
      else {
        employeeData.map((data, index) => {
          if (data) {
            employeesId[index] = data.id;
          }
        })
      }
    }
    else {
      if (SearchApprovalRef.current) {
        //* If the search column is not empty then filterData by taking all employee.status items that have a value of 'published' or 'deleted' and filter first_name or last_name or code based on the search field
        filterData = {
          'employee.status': { in: ['published', 'deleted'] },
          'employee.user.first_name': { like: SearchApprovalRef.current },
          'employee.user.last_name': { 'logical': 'or', like: SearchApprovalRef.current },
          'employee.code': { 'logical': 'or', like: SearchApprovalRef.current },
        };
      }

      else {
        //* If the search column is empty then filterData by taking all employee.status items that have a value of 'published' or 'deleted'
        filterData = {
          'employee.status': { in: ['published', 'deleted'] },
        };
      }
    }

    if (status === 'all') {
      //* the status will have an 'all' value if the user clicks the menu item 'all' in a status filter and set the filterData value equal to the current filterData value
      filterData = {
        ...filterData,
      }
    }
    else {
      //* if status value is not 'all', then set filterData value by adding filter for status which has the same value as status parameter value
      filterData = {
        ...filterData,
        status: { eq: status }
      }
    }

    //* add filter for start_time Greater than or equal to (gte) startDateRef.current value and end_time Less than or equal to (lte) endDateRef.current value
    filterData = {
      ...filterData,
      start_time: { gte: startDateRef.current },
      end_time: { lte: endDateRef.current },
    }

    //* get the employee or user id value that is currently logged in
    var employeeID = JSON.parse(window.localStorage.getItem('directus_employee')).id;

    let TimesheetsResult = [];
    let countTimesheetArray = 0;

    if (employeeData.length > 30) {
      for (const employee of employeeArray) {
        filterData = {
          ...filterData,
          employee: { in: employee },
        }
        try {

          let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
            fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
            filter: filterData,
            limit: -1,
            show_timesheet_review: true,
            timesheet_review_filter:
            {
              "read_by.employees_id": { in: { employeeID } }
            },
          });

          TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

          countTimesheetArray = countTimesheetArray + 1;
        }
        catch (e) {
          enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
        }
      }
    }
    else {
      //* add filter for employee with the same value as employeeID
      filterData = {
        ...filterData,
        employee: { in: employeesId },
      }

      try {
        //* get timesheets from directus with specific fields and with filter = filterData and store the value in the fetchTimesheetResult variable and then concatenate the result with TimesheetsResult variable
        let fetchTimesheetResult = await directus.api.get('/custom/approvals/timesheets', {
          fields: 'id,end_time,start_time,status,total_minutes,employee.user.id,employee.user.first_name,employee.user.last_name,employee.id,employee.code,employee.status,employee.type,employee.leave_approver,employee.mobile_number,employee.parent_leave',
          filter: filterData,
          limit: -1,
          show_timesheet_review: true,
          timesheet_review_filter:
          {
            "read_by.employees_id": { in: { employeeID } }
          },
        });

        TimesheetsResult = TimesheetsResult.concat(fetchTimesheetResult.data);

      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    let timesheetId = [];

    //* get all data.id from TimesheetsResult variable and store the value in the timesheetId variable as an array
    TimesheetsResult.map((data) => {
      timesheetId = [...timesheetId, data.id];
    })

    var employeeID = JSON.parse(window.localStorage.getItem('directus_employee')).id;
    try {
      //* get timesheet_review with some fields specified and with filter 'timesheet.id' which equals the value of variable timesheetId
      var TimesheetReview = await directus.api.post('/custom/mobile/timesheets/batch-data', {
        "timesheet_review": {
          // fields: '*,employee.user.first_name,employee.user.last_name,read_by.employees_id.id',
          fields: 'id, timesheet.id',
          filter: {
            'timesheet.id': { in: timesheetId }
          },
          sort: "created_on"
        }
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsResult.map((data, index) => {
      let result = 0
      //* look for the timesheet data in the timesheet_review data with the same id then calculate it 
      result = linq.from(TimesheetReview.data.timesheet_review)
        .where(x => x.timesheet.id === data.id).count();

      //* update the timesheet data by adding the totalCountTimesheet key with the same value as the result variable and also the select key with the false value
      TimesheetsResult[index] = { ...data, totalCountTimesheet: result, select: false };
    })

    if (TimesheetsResult.length > 0) {
      //* default value of statusViewRef = 1
      if (statusViewRef.current === 2) {
        filterSessionLeave = 0;
        filterTimesheets = [];
        loop = 0;
        filterLeaveStatus(TimesheetsResult)
      }
      else {
        TimesheetFinishing(TimesheetsResult);
      }
    }
    else {
      //* if there is no data in TimesheetsResult set Items state and run loadTimesheetsFinishLoading function
      setItems(prevItems => ({
        ...prevItems,
        timesheets: TimesheetsResult,
      }));

      loadTimesheetsFinishLoading();
    }

  };

  /**
   * TODO : 
   * * 1. Set item with name 'approvals_date' to local storage, with value start = startDateRef.current and end = endDateRef.current
   * * 2. run the loadEmployeeFilter function
   */
  const loadTimesheetsFinishLoading = () => {
    window.localStorage.setItem('approvals_date', JSON.stringify({ start: startDateRef.current, end: endDateRef.current }));
    setMoveLoading(false);

    loadEmployeeFilter();
  }

  const filterLeaveStatus = async (TimesheetsResult) => {
    try {
      var SessionResult = await directus.getItems('sessions', {
        // fields: '*.*',
        fields: 'id',
        filter: {
          'timesheet.id': { eq: TimesheetsResult[filterSessionLeave].id },
          'session_type.leave': { nempty: true }
        },
        limit: -1
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (SessionResult.data.length > 0) {
      filterTimesheets[loop] = TimesheetsResult[filterSessionLeave];
      loop = loop + 1;
    }

    if (filterSessionLeave + 1 === TimesheetsResult.length) {
      TimesheetFinishing(filterTimesheets);
    }
    else {
      filterSessionLeave = filterSessionLeave + 1;
      filterLeaveStatus(TimesheetsResult);
    }
  }

  /**
   * @param TimesheetsResult is the timesheet data that is sent when this function is called
   * TODO:
   * * 1. Check if the search column is empty or not, if empty only sort the timesheet data based on the code field, but if not empty filter the timesheet data based on start_time and end_time and status value is not 'deleted', after that sort timesheet data based on the code
   * * 2. Sort the timesheet data, starting with status awaiting approval, then pending, approved, and rejected. The last, set items state with add the new timesheets data value and run loadTimesheetsFinishLoading function
   */
  const TimesheetFinishing = async (TimesheetsResult) => {
    let filterdata;
    let filterTimesheet;
    if (SearchApprovalRef.current) {
      filterdata = linq.from(TimesheetsResult)
        .where(x => x.start_time === startDateRef.current && x.end_time === endDateRef.current && x.status !== 'deleted').toArray();
      filterTimesheet = linq.from(filterdata)
      .orderBy(x => x.employee.code).toArray();
    }
    else {
      filterTimesheet = linq.from(TimesheetsResult)
        .orderBy(x => x.employee.code).toArray();
    }

    filterTimesheet = linq.from(filterTimesheet)
      .orderByDescending(x => x.status === 'awaiting_approval')
      .thenByDescending(x => x.status === 'pending')
      .thenByDescending(x => x.status === 'approved')
      .thenByDescending(x => x.status === 'rejected').toArray();

    setItems(prevItems => ({
      ...prevItems,
      timesheets: filterTimesheet
    }));

    loadTimesheetsFinishLoading();
  }

  /**
   * TODO: Retrieve employee data that does not have data approvals in the displayed date range, to be used as an employee option when the user wants to add an employee timesheet
   */
  const loadEmployeeFilter = async () => {
    let ExcludeEmployee;
    try {
      //* Get id and employee.id fields from timesheets data in directus by filtering start_time Greater than or equal to (gte) startDateRef.current value and end_time Less than or equal to (lte) endDateRef.current value
      var TimesheetsResult = await directus.api.get('/custom/approvals/timesheets', {
        fields: 'id,employee.id',
        filter: {
          start_time: { gte: startDateRef.current },
          end_time: { lte: endDateRef.current },
        },
        limit: -1,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    //* Collect id data from employee in ExcludeEmployee variable
    TimesheetsResult.data.map((data, index) => {
      if (index === 0) {
        if (data.employee !== null) {
          ExcludeEmployee = data.employee.id;
        }
      }
      else {
        if (data.employee !== null) {
          ExcludeEmployee = ExcludeEmployee + "," + data.employee.id;
        }
      }
    })

    try {
      //* get first_name, last_name, and email fields from the user data related to the employee and also the id, code, and status fields of the employee data, then filter the data based on id which is not in one of the values ​​(nin) the ExcludeEmployee variable and filter based on status value is 'published'. After that, fetch only data that has user and set employeesFilterData state with that value.
      var EmployeeResult = await directus.getItems('employees', {
        fields: 'user.first_name, user.last_name, user.email, id, code, status',
        filter: {
          id: { nin: ExcludeEmployee },
          status: { eq: 'published' },
        },
        sort: 'code',
        limit: -1
      });

      let empResult = [];
      empResult = linq.from(EmployeeResult.data)
        .where(x => x.user !== null).toArray();

      setEmployeesFilterData(empResult);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

  }

  /**
   * * when the user clicks the Add Selected button in the dialog when wanting to add an employee timesheet, this function will be executed
   * * employeesFilterChosen is a collection of selected employee data to add to the timesheet
   * TODO: 
   * * 1. adds each selected employee data, into the addTotalEmployee variable with the data object format with the specified [key, value] pairs
   * * 2. check if the selected employee data has the same data as the timesheet data that was created in directus which has start_time, end_time, and total_minutes specified,
   * *    if not there, then create items timesheets in directus according to the data added to the addTotalEmployee variable,
   * *    but if there is, then first delete the same data, then create the new items timesheets
   */
  const addTimesheet = async () => {
    let addTotalEmployee = [];
    setLoading(true);

    employeesFilterChosen.map((data) => {
      addTotalEmployee.push({
        employee: data.id,
        start_time: startDateRef.current,
        end_time: endDateRef.current,
        total_minutes: 0,
        status: 'pending'
      })
    })


    let EmployeeId = [];
    let TimesheetId = [];

    employeesFilterChosen.map((data) => {
      EmployeeId.push(data.id);
    })

    try {
      var TimesheetsCheck = await directus.getItems('timesheets', {
        fields: 'id,employee.id,start_time,end_time',
        filter: {
          start_time: { gte: startDateRef.current },
          end_time: { lte: endDateRef.current },
          total_minutes: 0,
          'employee.id': { eq: EmployeeId }
        },
        limit: -1,
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    TimesheetsCheck.data.map((data) => {
      TimesheetId.push(data.id);
    })
    
    try {
      if (TimesheetsCheck.data.length === 0) {
        await directus.createItems('timesheets', addTotalEmployee);
        setErrorText(null);
      }
      else {
        await directus.deleteItems('timesheets', TimesheetId);
        await directus.createItems('timesheets', addTotalEmployee);
        setErrorText(null);
      }
    }
    catch (e) {
      if (TimesheetId.length === 0) {
        setOpenError(true);
        setErrorText('This employee already has a timesheet created for this week that you do not have access to.\n Please check with you administrator how to get access.');
      }
    }

    loadTimeSheets('null', statusRef.current);
    setLoading(false);
    handleCloseDialog();
  }

  /**
   * * when the user clicks on any of the items on the status filter menu, this function is executed
   * @param input is the type of status that the user clicks on to filter the data view
   * TODO: set selectedStatus and status state with the value of input parameter and then run loadTimeSheets function with parameter value 2 as input parameter and finally, run handleClose function
   */
  function filterStatus(input) {
    setSelectedStatus(input);
    setStatus(input);
    loadTimeSheets('null', input);
    handleClose();
  }

  /**
   * @param input has a value of -1 if the back arrow was clicked by the user, a value of 1 if the forward arrow was clicked by the user, and a value of 0 if the date range was clicked by the user
   * TODO:
   * * If the input parameter is equal to 0, then run the firstLoad function by passing true as the parameter value
   * * But if the input value is not equal to 0, then set the startDate and endDate states using the moment function, which formats the date based on the value of the input parameter, so if 1 the date will be formatted a week after, and if -1 the date will be formatted a week earlier. Then run the SummaryDate, lockApproval, and also loadTimeSheets functions
   */
  function moveTimesheet(input) {
    if (input === 0) {
      firstLoad(true);
    }
    else {
      setStartDate(moment(startDate).add(input, 'w').format('YYYY-MM-DD 00:00:00'));
      setEndDate(moment(endDate).add(input, 'w').format('YYYY-MM-DD 23:59:59'));

      SummaryDate();
      lockApproval();
      loadTimeSheets('null', statusRef.current);
    }
  }

  // function downloadFile(data, filename, mime) {
  //   // It is necessary to create a new blob object with mime-type explicitly set
  //   // otherwise only Chrome works like it should
  //   const blob = new Blob([data], { type: mime || 'application/octet-stream' });
  //   if (typeof window.navigator.msSaveBlob !== 'undefined') {
  //     // IE doesn't allow using a blob object directly as link href.
  //     // Workaround for "HTML7007: One or more blob URLs were
  //     // revoked by closing the blob for which they were created.
  //     // These URLs will no longer resolve as the data backing
  //     // the URL has been freed."
  //     window.navigator.msSaveBlob(blob, filename);
  //     return;
  //   }
  //   // Other browsers
  //   // Create a link pointing to the ObjectURL containing the blob
  //   const blobURL = window.URL.createObjectURL(blob);

  //   const tempLink = document.createElement('a');
  //   tempLink.style.display = 'none';
  //   tempLink.href = blobURL;
  //   tempLink.setAttribute('download', filename);
  //   // Safari thinks _blank anchor are pop ups. We only want to set _blank
  //   // target if the browser does not support the HTML5 download attribute.
  //   // This allows you to download files in desktop safari if pop up blocking
  //   // is enabled.
  //   if (typeof tempLink.download === 'undefined') {
  //     tempLink.setAttribute('target', '_blank');
  //   }
  //   document.body.appendChild(tempLink);
  //   tempLink.click();
  //   document.body.removeChild(tempLink);
  //   setTimeout(() => {
  //     // For Firefox it is necessary to delay revoking the ObjectURL
  //     window.URL.revokeObjectURL(blobURL);
  //   }, 100);
  // }

  /**
   * * this function will be executed when the user clicks on an item on the export menu or when the user clicks the yes button in the dialog that appears after clicking send to payroll item on the export menu
   * @param export_name is the name of the export type that the user clicked on
   * @param export_id is the id of the export type that the user clicked on
   * @param send_to_email is true or false depending on the value of the export type that the user clicked on
   * TODO: 
   * * initialize the requestOptions variable and determine the value of the dataUrl variable based on checking the value of the export_name parameter is the same as payroll or not.
   * * if the user chooses export payroll, then fetch based on the URL that has been specified and provide a response according to the results received
   * * if the user chooses export other than payroll, then fetch based on the specified URL and provide a response that is adjusted to the status of the results received
   */
  async function exportTimesheets(export_name, export_id = "", send_to_email = null) {
    handleCloseExport();
    handleCloseDialogPayroll();
    setExportLoading(true);

    let dataURL = '';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + directus.api.auth.config.token },
      body: JSON.stringify({ export_id: export_id, status: selectedStatus !== 5 && selectedStatus !== "all" ? selectedStatus : "" })
    };

    var employee = JSON.parse(window.localStorage.getItem('directus_employee'));
    if (export_name === 'payroll') {
      dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/sync/timesheet/send/` + moment(items.timesheets[0].start_time).format('YYYY-MM-DD') + `?email=` + employee.user.email;
    }
    else {
      dataURL = `${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/export/` + export_name + `/` + moment(items.timesheets[0].start_time).format('YYYY-MM-DD') + `?email=` + employee.user.email;
    }

    if (export_name !== 'payroll') {
      await fetch(dataURL, requestOptions)
        .then(resp => resp.blob())
        .then(async blob => {
          var file = await blob.text();
          setExportLoading(false);
          if (file.length === 0) {
            alert('Connection error. Please try again. Code: 0');
          } else if (file.includes("\"error\"")) {
            try {
              var response = JSON.parse(file);
              alert(response.error);
            } catch {
              console.log(file);
              alert('Connection error. Please try again. Code: 1');
            }
          } else if (file.includes("\"error_message\"")) {
            try {
              var response = JSON.parse(file);
              alert(response.error_message);
            } catch {
              console.log(file);
              alert('Connection error. Please try again. Code: 2');
            }
          } else if (file.includes("\"status_message\"")) {
            try {
              var response = JSON.parse(file);
              alert(response.status_message);
            } catch {
              console.log(file);
              alert('Connection error. Please try again. Code: 3');
            }
          } else {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = `DigiTime-Export-${moment(items.timesheets[0].start_time).format('YYYY-MM-DD')}.xlsx`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            setExportLoading(false);
          }
        })
    } else {
      fetch(dataURL, requestOptions)
        .then(response => response.json())
        .then(result => {
          // if(index === items.timesheets.length - 1){
          setExportLoading(false);
          // }

          if (result.status === "complete") {
            setPayrollMessage("All timesheets for this period have been processed. There are no more approved timesheets that need to be processed.");
            setOpenDialogPayrollMsg(true);
          }
          else if (result.status === "started") {
            setPayrollMessage("Background processing of Payroll transfer has now started. You will be sent an email with the results.\nThere are " + result.count + " timesheets to process. This could take up to " + Math.ceil(result.count * 0.06) + " minutes to process.");
            setOpenDialogPayrollMsg(true);
          }
          else if (result.status === "processing") {
            setPayrollMessage("Background processing of Payroll transfer is already in progress. Please wait until this is complete and try again.");
            setOpenDialogPayrollMsg(true);
          }
          else if (result.status === "error") {
            setPayrollMessage("Error processing. Please try again later." + result.message);
            setOpenDialogPayrollMsg(true);
          }
        })
        .catch(error => {
          setExportLoading(false);
          alert('Connection error. Please try again.');
        });
    }
  }

  /**
   * TODO: set exportData state value with result from getting id, name, link, icon, send_to_email fields from approvals_export in directus
   */
  const ApprovalExport = async () => {

    try {
      var ExportResult = await directus.getItems('approvals_export', {
        fields: 'id,name,link,icon,send_to_email,number',
        sort: 'number',
        filter: {
          status: { eq: 'published' }
        },
      });

      setExportData(ExportResult.data.sort((a, b) => a.number - b.number));
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

  }

  /**
   * @param checked is true or false depending on whether the user checked or unchecked the checkbox in the timesheet data
   * @param id is the id data of the checked or unchecked timesheet
   * TODO: change the value of the select field of the timesheet that the user checked, with the received value of the checked parameter and set that latest value to the items state
   */
  const checkTimesheet = (checked, id) => {
    setItems({
      timesheets:
        items.timesheets.map(data => {
          if (id === data.id) {
            data.select = checked;
          }
          return data;
        })
    });
  }

  return (
    <Page className={classes.root} title="Approvals">
      <Container maxWidth={false}>
        {/* <div className="control-pane">
          <div className="control-section"> */}
            <Grid container spacing={0}>
              <Grid item xs={5} sm={6} md={5} lg={5}>
                <div style={{ marginBottom: '8px' }}>
                  <Button id="approvals_date_range_prev" variant="outlined" size="small" onClick={() => moveTimesheet(-1)} className={classes.buttonLeft}>
                    <ArrowBackIosRoundedIcon />
                  </Button>
                  <Button id="approvals_date_range" variant="outlined" size="small" onClick={() => moveTimesheet(0)} className={classes.buttonText}>
                    <Typography id="approvals_date_range_text" color="textPrimary" variant="h4" align="center">
                      {moment(startDateRef.current).format('DD MMM YYYY') + ' - ' + moment(endDateRef.current).format('DD MMM YYYY')}
                    </Typography>
                  </Button>
                  <Button id="approvals_date_range_next" variant="outlined" size="small" onClick={() => moveTimesheet(1)} className={classes.buttonRight}>
                    <ArrowForwardIosRoundedIcon />
                  </Button>
                  {moveLoading ? <CircularProgress className={classes.circularLoading} size={20} style={{ marginBottom: '-8px', marginLeft: '6px' }} /> : ''}
                  {SupervisorPermission() && !AdminPermission() ?
                    lockLoading ?
                      <CircularProgress className={classes.circular} size={20} style={{ marginBottom: '-8px', float: 'none', marginLeft: '6px' }} />
                      :
                      approvalLockDataRef.current ?
                        <span>
                          {approvalLockDataRef.current.lock === 'true' ?
                            <LockIcon style={{ marginBottom: '-8px', marginLeft: '6px', color: '#989595' }} />

                            :
                            <LockOpenIcon style={{ marginBottom: '-8px', marginLeft: '6px', color: '#989595' }} />

                          }
                        </span>
                        : ''
                    : ''}
                </div>
              </Grid>
              <Grid item xs={7} sm={6} md={7} lg={7}>
                <div style={{ textAlign: 'right' }}>
                  {AdminPermission() ?
                    items.timesheets ?
                      items.timesheets.length > 0 ?
                        <div className={classes.filter}>
                          <Button
                            id="export_btn"
                            variant="outlined"
                            size="small"
                            onClick={handleClickExport}
                            disabled={exportLoading}
                            className={classes.send}
                          >
                            <GetAppIcon fontSize="small" /><Typography variant="h6">Export</Typography><ArrowDropDownIcon fontSize='small' />
                            {exportLoading ? <CircularProgress className={classes.circularLoading} size={20} /> : ''}
                          </Button>

                          <Menu
                            id="export_menu"
                            anchorEl={anchorElExport}
                            keepMounted
                            open={Boolean(anchorElExport)}
                            isopen={`${Boolean(anchorElExport)}`}
                            onClose={handleCloseExport}
                          >
                            {exportdata ?
                              exportdata.map((data, index) => {
                                return (
                                  <span key={index}>
                                    {data.name === 'Send to Payroll' ?
                                      <MenuItem id={`${data.name.replace(/\s/g, '')}_menu`} onClick={() => handleClickOpenDialogPayroll()}>
                                        <ListItemIcon className={classes.iconWidth}>
                                          <SendIcon fontSize="small" />
                                        </ListItemIcon>
                                        <Typography variant="h6">Send to Payroll</Typography>
                                      </MenuItem>
                                      :
                                      <MenuItem id={`${data.name.replace(/\s/g, '').replace("&", '')}_menu`} onClick={() => exportTimesheets(data.link, data.id, data.send_to_email)}>
                                        <ListItemIcon>
                                          <Icon fontSize="small" className={classes.icon}>{data.icon}</Icon>
                                          <Typography variant="h6" style={{ color: '#333943' }}>{data.name}</Typography>
                                        </ListItemIcon>
                                      </MenuItem>
                                    }
                                  </span>
                                );
                              })
                              : ""
                            }
                          </Menu>
                          <Dialog id="openDialogPayroll" isopen={`${openDialogPayroll}`} open={openDialogPayroll} onClose={handleCloseDialogPayroll} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Send to Payroll</DialogTitle>
                            <DialogContent>
                              <DialogContentText>
                                Are you sure want to transfer all approved timesheets to the Payroll system?
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <YesPayrollButton id="yes_payroll_btn" onClick={() => exportTimesheets('payroll')} color="primary">
                                Yes
                              </YesPayrollButton>
                              <NoPayrollButton id="no_payroll_btn" onClick={() => handleCloseDialogPayroll()} color="primary">
                                No
                              </NoPayrollButton>
                            </DialogActions>
                          </Dialog>
                          <Dialog id="send_to_payroll_status" isopen={`${openDialogPayrollMsg}`} open={openDialogPayrollMsg} aria-labelledby="form-dialog-title">
                            <DialogTitle id="form-dialog-title">Send to Payroll Status</DialogTitle>
                            <DialogContent>
                              <DialogContentText id="payroll_message">
                                {payrollMessage}
                              </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                              <YesPayrollButton onClick={() => { setOpenDialogPayrollMsg(false); }} color="primary">
                                Close
                              </YesPayrollButton>
                            </DialogActions>
                          </Dialog>
                        </div>
                        : ''
                      : <CircularProgress style={{ marginLeft: '-10%' }} className={classes.circular} size={20} />
                    : ''
                  }

                  {SupervisorPermission() ?
                    employeesFilterData ?
                      <ColorButton id="employee_timesheet_btn" variant="outlined" size="small" className={classes.add} startIcon={<AddIcon />} onClick={() => handleClickOpenDialog()}>
                        <Typography variant="h6">Employee Timesheet</Typography>
                      </ColorButton>
                      : ''
                    : ''}

                  {AdminPermission() ?
                    lockLoading ?
                      <div>
                        <CircularProgress className={classes.circular} size={20} style={{ float: 'right', marginRight: '15px' }} />
                      </div>
                      :
                      approvalLockDataRef.current ?
                        <div>
                          {approvalLockDataRef.current.lock === 'true' ?
                            <LockButton variant="outlined" size="small" className={classes.add} startIcon={<LockIcon />} onClick={() => loadLock(approvalLockDataRef.current.id, 'false')}>
                              <Typography variant="h6">Unlock Approvals</Typography>
                            </LockButton>
                            :
                            <LockButton variant="outlined" size="small" className={classes.add} startIcon={<LockOpenIcon />} onClick={() => loadLock(approvalLockDataRef.current.id, 'true')}>
                              <Typography variant="h6">Lock Approvals</Typography>
                            </LockButton>
                          }
                        </div>
                        : ''
                    : ''}
                  {AdminPermission() && configuration ?
                    configuration.enable_update_status ?
                      changeLoading ? <CircularProgress className={classes.circular} size={20} style={{ float: 'right', marginRight: '15px' }} />
                        :
                        <div>
                          <ApprovedPaidButton
                            id="employee_timesheet_btn"
                            variant="outlined"
                            size="small"
                            className={classes.approved_paid}
                            onClick={handleClickChangeStatus}
                            disabled={moveLoading}
                            style={{ marginRight: "5px" }}
                            startIcon={<PaymentIcon />}
                          >
                            Update Status
                          </ApprovedPaidButton>
                        </div>
                      : ''
                    : ''
                  }

                  <Menu
                    id="status_filter_menu"
                    anchorEl={anchorE2}
                    keepMounted
                    open={Boolean(anchorE2)}
                    isopen={`${Boolean(anchorE2)}`}
                    onClose={handleCloseChangeStatus}
                    className={classes.updatestatus}
                  >
                    {timesheetStatusType.map((status, index) => {
                      return (
                        <div key={index}>
                          <MenuItem onClick={() => handleClickOpenDialogChangeStatus(status)}>
                            {status.name === 'Waiting Approval' ? "Awaiting Approval" : status.name}
                          </MenuItem>
                        </div>
                      );
                    })
                    }
                  </Menu>

                  <Dialog id="openDialogPaid" isopen={`${openDialogChangeStatus}`} open={openDialogChangeStatus} onClose={handleCloseDialogChangeStatus} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"> {titleRef.current} </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {descriptionRef.current}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <YesPayrollButton id="yes_paid_btn" onClick={() => updateChangeStatus()} color="primary">
                        Yes
                      </YesPayrollButton>
                      <NoPayrollButton id="no_paid_btn" onClick={() => handleCloseDialogChangeStatus()} color="primary">
                        No
                      </NoPayrollButton>
                    </DialogActions>
                  </Dialog>

                  <Dialog id="openNotif" isopen={`${openNotif}`} open={openNotif} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title"> Can't Update {titleRef.current} </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        {notificationRef.current}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <YesPayrollButton id="ok_paid_btn" onClick={() => handleCloseNotif()} color="primary">
                        Ok
                      </YesPayrollButton>
                    </DialogActions>
                  </Dialog>

                  {AdminPermission() || SupervisorPermission() ?
                    <div>
                      <FormControlLabel
                        style={{ marginTop: '-6px' }}
                        control={
                          <Checkbox
                            color="primary"
                            onChange={tickHandleChange}
                            name="checked"
                          />
                        }
                        label="Unread Messages"
                      />
                    </div>
                    : ''}
                </div>
                <Dialog
                  id="add_new_timesheet_dialog"
                  open={open}
                  isopen={`${open}`}
                >
                  <DialogTitle>Add New Timesheets</DialogTitle>
                  <DialogContent>
                    <SelectEmployee employeesFilterData={employeesFilterData} setEmployeesFilterChosen={(value) => setEmployeesFilterChosen(value)} icon={icon} checkedIcon={checkedIcon} />
                  </DialogContent>
                  {addTimesheetLoading ?
                    <DialogActions>
                      <CircularProgress className={classes.circular} />
                    </DialogActions>
                    :
                    <DialogActions>
                      {loading ?
                        <CircularProgress className={classes.circular} />
                        :
                        <>
                          <CancelButton
                            id="cancel_btn"
                            size="small"
                            variant="contained"
                            onClick={handleCloseDialog}
                            display='none'
                          >
                            <Typography variant="h6">Cancel</Typography>
                          </CancelButton>
                          <ActionButton
                            id="add_btn"
                            variant="contained"
                            onClick={() => addTimesheet(employeesFilterChosen)}
                            disabled={employeesFilterChosen === null || employeesFilterChosen.length < 1}
                          >
                            <Typography variant="h6">Add Selected</Typography>
                          </ActionButton>
                        </>
                      }
                    </DialogActions>
                  }
                </Dialog>

                <Dialog open={openError}>
                  <DialogTitle style={{ backgroundColor: red[100] }}>
                    <div style=
                      {{
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <Avatar sx={{ bgcolor: red[200], color: red[600], width: 40, height: 40 }}>
                        <WarningIcon fontSize='medium' />
                      </Avatar>
                      <span style={{ paddingLeft: '5px' }}>Error Message</span>
                    </div>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <Typography>
                        {errorText}
                      </Typography>
                    </DialogContentText>
                    <DialogActions>
                      <CancelButton
                        size="small"
                        variant="contained"
                        onClick={handleCloseError}
                      >
                        <Typography variant="h6">Ok</Typography>
                      </CancelButton>
                    </DialogActions>
                  </DialogContent>
                </Dialog>

              </Grid>
            </Grid>
            <Card elevation={0} className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container spacing={0} className={classes.headerWidth}>
                  <Grid item xs={3} sm={3} md={3} lg={2}>
                    <Typography variant="h5">
                      {AdminPermission() && configuration ?
                        configuration.enable_update_status ?
                          <Checkbox
                            id="codeAll"
                            sx={{
                              "&.Mui-checked": {
                                color: "#2AC940"
                              }
                            }}
                            onChange={(e) =>
                              handleChangeCode(e)
                            }
                            checked={codeAll}
                          />
                          : ''
                        : ''}
                      <b>Code</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={3} lg={3}>
                    <Typography variant="h5"><b>Name</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant="h5"><b>Phone</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2}>
                    <Typography variant="h5"><b>Status</b></Typography>
                  </Grid>
                  <Grid item xs={1} sm={1} md={1} lg={1}>
                    <Typography variant="h5"><b>Total Time</b></Typography>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2}>
                    <div className={classes.filter}>
                      <Button
                        id="status_filter_btn"
                        size="small"
                        onClick={handleClick}
                        disabled={moveLoading}
                      >
                        <i className="fa fa-filter fa-lg"></i><Typography variant="h6">Status</Typography>
                      </Button>
                      <Menu
                        id="status_filter_menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        isopen={`${Boolean(anchorEl)}`}
                        onClose={handleClose}
                        className={classes.filterandsort}
                      >
                        {timesheetStatusType.map((status, index) => {
                          return (
                            <div key={index}>
                              <MenuItem onClick={() => filterStatus(status.value)} selected={status.value === selectedStatus} isselected={`${status.value === selectedStatus}`}>{status.name === 'Waiting Approval' ? "Awaiting Approval" : status.name}</MenuItem>
                            </div>
                          );
                        })
                        }
                        <MenuItem id="all_status_menu" onClick={() => filterStatus('all')} selected={selectedStatus === 'all'} isselected={`${selectedStatus === 'all'}`}>All</MenuItem>
                      </Menu>
                    </div>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>

            {items.timesheets ?
              items.timesheets.length > 0 ?
                <div>
                  <ApprovalTimesheetList
                    employees={items.employees}
                    sessions={items.sessions}
                    timesheets={items.timesheets}
                    unreadMessageFilter={unreadMessageRef.current}
                    timesheetStatusType={timesheetStatusType}
                    checkTimesheet={checkTimesheet}
                    configuration={configuration ? configuration.enable_update_status : 0}
                  />
                </div>
                :
                !moveLoading ?
                  <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    alignItems="center"
                    style={{ opacity: '0.2', paddingTop: '6pc' }}
                  >
                    <Grid item xs={12} sm={12} lg={12}>
                      <img alt="No Data Found" height="230" width="230" src="/static/images/folder.png?v=0.0.1" />
                      <Typography variant="h3" style={{ textAlign: 'end' }}>No Data Found</Typography>
                    </Grid>

                  </Grid>
                  : ''
              :
              <CircularProgress className={classes.circularMain} />
            }
          {/* </div>
        </div> */}
      </Container>
    </Page>
  );
};

export default ApprovalListView;