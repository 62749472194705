/**
 * @param input is the status type selected by the user on the filter menu
 * TODO: returns the status name to display according to the status filter the user selected on the leave request page
 */
export const filter_status = (input) => {
  if (input === 'pending') {
    return 'Waiting Approval';
  }
  else if (input === 'approved') {
    return 'Approved by Supervisor';
  }
  else if (input === 'approved_hrd') {
    return 'Approved by Payroll';
  }
  else if (input === 'rejected') {
    return 'Rejected';
  }
  else {
    return 'All';
  }
}

/**
 * @param input is the status type selected by the user on the filter menu
 * TODO: returns the background color for the status name text next to the filter button according to the status filter the user selected on the leave request page
 */
export const filter_color = (input) => {
  if (input === 'pending') {
    return { backgroundColor: '#D4E3F2' };
  }
  else if (input === 'approved') {
    return { backgroundColor: '#DAF0DC' };
  }
  else if (input === 'approved_hrd') {
    return { backgroundColor: '#b8d4ff' };
  }
  else if (input === 'rejected') {
    return { backgroundColor: '#FFDBD9' };
  }
  else {
    return { backgroundColor: '#FFF0DD' };
  }
}