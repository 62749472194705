import React, { useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { 
  Menu, 
  MenuItem, 
  Avatar, 
  AppBar, 
  Box, 
  Hidden, 
  IconButton, 
  Toolbar, 
  ListItemIcon,
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Grid, 
  CircularProgress,
  Switch,
} from '@material-ui/core';
import {
  SubmitButton, 
  CancelButton,
  useStyles 
} from './style.js';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import EventEmitter from 'src/utils/EventEmitter';
import { items } from 'src/utils/Menu';
import { Settings } from './ComponentTopBar/Settings';
import { Approvals } from './ComponentTopBar/Approvals';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import Button from '@mui/material/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import directus from '../../services/directus';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../views/Components/ErrorMessage';
import useStateRef from "react-usestateref";


const TopBar = ({ className, onMobileNavOpen, user, company, ...rest }) => {
  /* eslint-disable */
  const classes = useStyles();
  const [initialized, setInitialized] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [firstUrl, setFirstUrl] = React.useState('');
  const [features, setFeatures] = React.useState({});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openProfile, setOpenProfile] = React.useState(false);
  const [password, setPassword] =  React.useState({
    password: '',
    showPassword: false,
  });
  const [firstName, setFirstName, firstNameRef] = useStateRef('');
  const [lastName, setLastName, lastNameRef] = useStateRef('');
  const [mobileNumber, setMobileNumber, mobileNumberRef] = useStateRef('');
  const [email, setEmail, emailRef] = useStateRef('');
  const [userID, setUserID, userIDRef] = useStateRef('');
  const [employee, setEmployee, employeeRef] = useStateRef('');
  // const [emailNotification,setEmailNotification, emailNotificationRef]= useStateRef('');
  const [emailNotificationStatus,setEmailNotificationStatus, emailNotificationStatusRef]= useStateRef('');
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone, timezoneRef] = useStateRef('');
  const [timezoneFilterChosen, setTimezoneFilterChosen, timezoneFilterChosenRef] = useStateRef('');
  const [userLoading, setUserLoading ] =  React.useState(null);
  const { enqueueSnackbar } = useSnackbar();

  let configuration = JSON.parse(window.localStorage.getItem('configuration'));
  let Employee = JSON.parse(window.localStorage.getItem('directus_employee'));
  /* eslint-enable */

  var react_app_url=process.env.REACT_APP_URL;
  if(window.location.hostname.search('interax') !== -1){
    react_app_url=process.env.REACT_APP_URL_INTERAX;
  }

  /* eslint-disable react-hooks/exhaustive-deps*/
  React.useEffect(()=>{
    if (!initialized) {
      setInitialized(true);

      // eslint-disable-next-line array-callback-return
      items().map((item) => {
        if (item.href === '/'+window.location.pathname.split('/')[1])
        {
          setTitle(item.title);
          setFirstUrl(item.href);
          setFeatures(item.features);
        }
      })
      loadTimezone();
    }

    const changeTitle = (eventData) => {
      setTitle(eventData.text);
      setFirstUrl(eventData.href);
      setFeatures(eventData.features);
    }
    const listener = EventEmitter.addListener('topbar', changeTitle);

    return () => {
      listener.remove();
    }
  },[]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const handleClickShowPassword = () => {
    setPassword({
      ...password,
      showPassword: !password.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const handleChangeInput = (e) => {
    const { name, value, checked } = e.target;

    if(name === 'Email Notifications Enabled')
    {
      setEmailNotificationStatus(checked);
    }
    else if(name === 'firstname')
    {
      setFirstName(value);
    }
    else if(name === 'lastname')
    {
      setLastName(value);
    }
    else if(name === 'mobile_number')
    {
      setMobileNumber(value);
    }
    else if (name === 'password') {
      setPassword({ ...password,password:value });
    }
    
  };

  const loadTimezone = async () => {

    let timezoneResult = [];
    var timezone_data= await directus.getField("directus_users", "timezone");
    const timezone_list = Object.entries(timezone_data.data.options.choices);
    
    //eslint-disable-next-line array-callback-return
    timezone_list.map((data, index)=>{
      timezoneResult[index] = {id:data[0],name:data[1],value:data[0]};
    })
    setTimezone(timezoneResult);
  };

  const handleOpenProfile = async () => {
    setOpenProfile(true);
    let myId = JSON.parse(window.localStorage.getItem('directus_user'));
    getUser(myId.id);
    handleClose();
  };
  const handleCloseProfile = () => {
    setOpenProfile(false);
  };

  const getUser = async (id) => {
    setUserLoading(true);
    var User = await directus.getUser(id);
    setUserID(User.data.id);
    setFirstName(User.data.first_name);
    setLastName(User.data.last_name);
    setEmail(User.data.email);
    setEmployee(Employee.id);
    setMobileNumber(Employee.mobile_number);
    setEmailNotificationStatus(Employee.enable_email_notifications);
    setTimezoneFilterChosen({id:0,name:User.data.timezone,value:User.data.timezone});
    setUserLoading(false);
  };
  
  const sendEmail = () => {
    setLoading(true);
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/users/${emailRef.current}`, requestOptions)
    .then(res => console.log(res));

    setLoading(false);
    handleClose();
  }

  const SaveProfile = async () => {
    setLoading(true);

    if (timezoneFilterChosenRef.current.value || firstNameRef.current !=='' || lastNameRef.current !=='' ){
      try{
        await directus.updateUser(userID, {
          first_name: firstNameRef.current,
          last_name: lastNameRef.current,
          timezone:timezoneFilterChosenRef.current.value===null||timezoneFilterChosenRef.current.value===''?configuration.default_timezone:timezoneFilterChosenRef.current.value,
        });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }

    if(emailNotificationStatusRef.current !== null || mobileNumberRef.current !== null ){
      try
      {
        await directus.updateItem('employees', employeeRef.current, { enable_email_notifications: emailNotificationStatusRef.current, mobile_number: mobileNumberRef.current });
      }
      catch(e)
      {
        enqueueSnackbar(ErrorMessage(e), {anchorOrigin:{vertical: 'top',horizontal: 'right'}, variant:'error',autoHideDuration:3200});
      }
    }

    if(password.password !== null && password.password !== "" && password.password !== 'null')
    {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization' : 'Bearer '+directus.api.auth.config.token },
        body: JSON.stringify({ password: password.password })
      };
  
      fetch(`${react_app_url}${JSON.parse(window.localStorage.getItem('company'))}/custom/auth/setpassword/`+userIDRef.current, requestOptions)
      .then(response => response.text() )
      .then(result => {
        // console.log(result);
      });
    }

      var result = await directus.getItem('employees', employeeRef.current, { fields: '*.*' });
      if (!result.error) 
      {
        window.localStorage.setItem('directus_employee', JSON.stringify(result.data));
      }

    setLoading(false);
    handleCloseProfile();
  }
  
  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar id="toolbar" className={classes.toolbar}>
        <RouterLink to="/" style={{paddingTop: '5px'}}>
          <Logo className={window.location.hostname.search('digitime')!==-1?classes.logo:classes.logo1 } />
        </RouterLink>
        <Hidden mdDown>  
          <Typography variant="h3" className={classes.title} id="topbar_title">
            {title}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
        <Hidden mdDown>  
          {firstUrl === '/settings'?<Settings/>:firstUrl === '/approvals'?<Approvals/>:firstUrl === '/'?<Approvals/>:''}
          &nbsp;&nbsp;

          <Avatar className={classes.avatar} src={user.avatar} onClick={handleMenu} id="avatar_menu"/>

          <Menu
            id="menu_topbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            isopen={`${open}`}
            open={open}
            onClose={handleClose}
          >

            <List sx={{ width: '100%', maxWidth: 500}}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar className={classes.avatar} src={user.avatar} />
                </ListItemAvatar>
                  <ListItemText
                    primaryTypographyProps={{ id: "user-job_title" }}
                    primary={user.jobTitle}
                    secondaryTypographyProps={{ component: "div" }}
                    secondary={
                      <>
                        <Typography
                          sx={{ display: 'inline' }}
                          component="span"
                          variant="body2"
                        >
                          <span id="user-company">{company}</span> - <span id="user-role">{user.role === "1"?'Supervisor':user.role  === "2"?"Admin":user.role  === "0"?'Employee':user.role}</span>
                          <br/>
                          <h5><i> v 1.7.22 </i></h5>
                        </Typography>
                      </>
                    }
                  />
              </ListItem>
              <Divider variant="inset" component="li" />

              <MenuItem onClick={handleOpenProfile} >
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                  <span >Profile</span>
              </MenuItem>

              <MenuItem id="logout_btn" onClick={() => {
                window.localStorage.clear();
                window.location.reload();
              }}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
            </List> 
          </Menu>


          <Dialog 
            open={openProfile} 
            isopen={`${openProfile}`}
            onClose={handleCloseProfile}
            aria-labelledby="employee_dialog"
            fullWidth
          >
            {userLoading?
              <DialogContent style={{textAlign:'center'}}>
                <CircularProgress className={classes.circularProfile}/>
              </DialogContent>
            :
            <>
              <DialogTitle id="form-dialog-title"><Typography id="employee_dialog_title" component="div" variant="h6">
                <h2>
                  {firstNameRef.current} {lastNameRef.current}'s Profile
                </h2>
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Grid container>
                  {configuration.employee_settings?
                    <>
                      <Grid item xs={12}>
                        <TextField 
                          id="firstname_text" 
                          label="First Name" 
                          variant="outlined" 
                          name="firstname"
                          value={firstNameRef.current}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>
                      </Grid>  
                      <Grid item xs={12}>                    
                        <TextField 
                          id="lastname_text" 
                          label="Last Name" 
                          variant="outlined" 
                          name="lastname"
                          value={lastNameRef.current}
                          fullWidth={true}
                          autoComplete="off"
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>                        
                      </Grid>
                      <Grid item xs={12}>
                        <TextField 
                          id="outlined-basic" 
                          label="Mobile Number" 
                          variant="outlined" 
                          name="mobile_number"
                          value={mobileNumberRef.current}
                          fullWidth={true}
                          autocomplete="off"
                          onChange={handleChangeInput}
                        />
                        <br/>
                        <br/>
                      </Grid>
                    </>
                   :''
                  }
                  <Grid item xs={12}>
                    {timezoneRef.current && timezoneFilterChosenRef.current?
                      <>
                        <Autocomplete
                          id="combo-box-timezone"
                          options={timezoneRef.current}
                          getOptionLabel={(option) => option.value}
                          getOptionSelected={(option, value) => option.value === value.value}
                          fullWidth={true}
                          renderInput={(params) => <TextField {...params} label="Select Timezone" variant="outlined" />}
                          onChange={(event, newValue) => {
                            newValue===null?
                            setTimezoneFilterChosen({id:0,name:'',value:''})
                            :
                            setTimezoneFilterChosen({id:newValue.id,name:newValue.name,value:newValue.value})
                          }}
                          value={timezoneFilterChosenRef.current}
                        />
                        <br/>
                      </>
                    :''
                    }
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl  fullWidth={true} variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={password.showPassword ? 'text' : 'password'}
                        label="Password"
                        name="password"
                        onChange={handleChangeInput}
                        autocomplete="off"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {password.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>                   
                    <Button onClick={()=> sendEmail()} color="primary">
                      Send Reset Password Email
                    </Button>  
                    <br/>     
                  </Grid>
                  <Grid item xs={12}>
                    {emailNotificationStatusRef.current===true?
                      <FormControlLabel
                        control={
                          <Switch
                            name="Email Notifications Enabled"
                            defaultChecked
                            onChange={handleChangeInput}
                          />
                        }
                        label="Email Notifications Enabled"
                      />
                    :
                      <FormControlLabel
                        control={
                          <Switch
                            name="Email Notifications Enabled"
                            onChange={handleChangeInput}
                          />
                        }
                        label="Email Notifications Enabled"
                      />
                    }  
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                {!loading?
                  <>
                    <CancelButton 
                      id="cancel_btn"
                      size="small" 
                      variant="contained"
                      onClick={handleCloseProfile}
                    >
                      Cancel
                    </CancelButton>

                    <SubmitButton
                      id="save_btn"
                      size="small" 
                      variant="contained"
                      onClick={()=>SaveProfile()}
                    >
                      Save
                    </SubmitButton>
                  </>
                : <CircularProgress className={classes.circular}/>
                }
              </DialogActions>
            </>
          }
          </Dialog>
        </Hidden>
        <Hidden lgUp>
          <IconButton id="mobile_menu" color="primary" onClick={onMobileNavOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
