import React, { useEffect, useState, useImperativeHandle } from 'react';
import {
  Typography,
  Grid,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  TextField,
  ListItemSecondaryAction,
  CircularProgress,
} from '@material-ui/core';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import directus from '../../services/directus';
import './style.css';
import AddEquipment from './AddEquipment/AddEquipment';
import AddMaterial from './AddMaterial/AddMaterial';
import { useStylesSessionListDetails, UploadButton, FileChip, ListCustom } from './style.js';
import { useSnackbar } from 'notistack';
import ErrorMessage from '../Components/ErrorMessage';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import useStateRef from "react-usestateref";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@material-ui/core/Divider';
import { LinearProgress } from '@mui/material';
import Enumerable from 'linq';
import linq from "linq";

import EventEmitter from 'src/utils/EventEmitter';
import moment from 'moment';

const SessionListDetails = React.forwardRef((props, ref) => {
  //* when the user submits edited session data, the session will be processed on the submitEdit function in the SessionList.js. In the process of this function, it will trigger to run of the updateSessionDetail function in this file, by sending the session data to be edited as its parameter value
  useImperativeHandle(ref, () => ({
    confirmEditDetails(session) {
      updateSessionDetail(session);
    }
  }));
  const classes = useStylesSessionListDetails();

  let material = [];
  let equipment = [];
  let session_options_list = [];

  const [sessionData] = useState(props.session);
  const [materialData, setMaterialData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [allMaterialData, setAllMaterialData] = useState([]);
  const [allEquipmentData, setAllEquipmentData] = useState([]);
  const [input, setInput] = useState({ notes: '', smoko: 0 });
  const [file, setFile, fileRef] = useStateRef([]);
  const [delFile, setDelFile, delFileRef] = useStateRef([]);
  const [loadingFile, setLoadingFile] = React.useState(false);
  const [sessionOptions, setSessionOptions, sessionOptionsRef] = useStateRef([]);
  const [newSessionOptions, setNewSessionOptions, newSessionOptionsRef] = useStateRef([]);
  const [newList, setNewList, newListRef] = useStateRef();
  const [value, setValue, valueRef] = useStateRef([]);
  const [optionsList, setOptionsList, optionsListRef] = useStateRef([]);
  const [loadingSession, setLoadingSession] = React.useState(false);

  const [rosterStart, setRosterStart, rosterStartRef] = useStateRef(null);
  const [rosterEnd, setRosterEnd, rosterEndRef] = useStateRef(null);
  const [rosterBreakStart, setRosterBreakStart, rosterBreakStartRef] = useStateRef(null);
  const [rosterBreakEnd, setRosterBreakEnd, rosterBreakEndRef] = useStateRef(null);
  const [ordinaryHours, setOrdinaryHours, ordinaryHoursRef] = useStateRef(null);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let isSubscribed = true
    if (isSubscribed) {
      fillResourceData(sessionData);
      loadNewSessionOptionData(sessionData);
      rosterData(sessionData.session_date);
    }

    /**
     * * This function will be executed when the user edits session data the user changes the session start time or session end time
     * @param {*} eventData Will have an edited session 'start time' value and an edited session 'end time' value
     */
    const compareRoster = (eventData) => {
      getSessionOptionsData(sessionOptionsRef.current, eventData.editStartTime, eventData.editEndTime);
    }

    /**
     * * This function will be executed when the user edits session data the user changes the session date
     * @param {*} eventData Will have an edited session date value, an edited session 'start time' value, and an edited session 'end time' value
     */
    const compareDate = async (eventData) => {
      loadNewSessionOptionData(sessionData, 1);
      await rosterData(eventData.selectedDate);
      getSessionOptionsData(sessionOptionsRef.current, eventData.editStartTime, eventData.editEndTime);
    }

    const listener = EventEmitter.addListener('compareDate', compareDate);
    const listener1 = EventEmitter.addListener('compareRoster', compareRoster);

    return () => {
      listener.remove();
      listener1.remove();
      isSubscribed = false
    }

  }, [sessionData])

  /**
   * TODO: save session details changes to directus: such as changes that occur to materials, equipment, uploaded files, notes, or session options
   * * when the user submits edited session data, the session will be processed on the submitEdit function in the SessionList.js, it will trigger to run this function too
   */
  const updateSessionDetail = async () => {

    //* if any equipment data is deleted, edited, or added while editing the session => then delete, edit, or add the equipment data from sessions_resources on directus by filtering based on value (3 to delete, 1 to edit, and 2 to add)
    
    let addEquipment = [];
    let editEquipment = [];
    let delEquipment = [];

    equipmentData.map((data) => {
      if (data.value === 3) {
        delEquipment = [...delEquipment, data.resourceDataId];
      }
      if (data.value === 1) {
        editEquipment = [...editEquipment, { id: data.resourceDataId, session: sessionData.id, resource: data.id, type: 'equipment', quantity: 0 }];
      }
      if (data.value === 2 && data.name) {
        addEquipment = [...addEquipment, { session: sessionData.id, resource: data.id, type: 'equipment', quantity: 0 }];
      }
      return null;
    })

    if (delEquipment.length > 0) {
      try {
        await directus.deleteItems('sessions_resources', delEquipment);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (editEquipment.length > 0) {
      try {
        await directus.updateItems('sessions_resources', editEquipment);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (addEquipment.length > 0) {
      try {
        await directus.createItems('sessions_resources', addEquipment);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }
    
    //* if any material data is deleted, edited, or added while editing the session => then delete, edit, or add the material data from sessions_resources on directus by filtering based on value (3 to delete, 1 to edit, and 2 to add)

    let addMaterial = [];
    let editMaterial = [];
    let delMaterial = [];

    materialData.map((data) => {
      if (data.value === 3) {
        delMaterial = [...delMaterial, data.resourceDataId];
      }
      if (data.value === 1) {
        editMaterial = [...editMaterial, { id: data.resourceDataId, session: sessionData.id, resource: data.id, type: 'chemical', quantity: data.quantity }];
      }
      if (data.value === 2 && data.name) {
        addMaterial = [...addMaterial, { session: sessionData.id, resource: data.id, type: 'chemical', quantity: data.quantity }];
      }
      return null;
    })

    if (delMaterial.length > 0) {
      try {
        await directus.deleteItems('sessions_resources', delMaterial);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (editMaterial.length > 0) {
      try {
        await directus.updateItems('sessions_resources', editMaterial);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (addMaterial.length > 0) {
      try {
        await directus.createItems('sessions_resources', addMaterial);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    //* update notes based on the id of session data edited on directus
    try {
      await directus.updateItem('sessions', sessionData.id, { notes: input.notes });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    //* If the selected session type is 'Sick Leave' and the user has selected a new file to upload, then upload the file to directus. After that, save the id of the file based on the id of the edited session to directus in the 'sessions_directus_files' item
    if (fileRef.current.length > 0) {
      const form = document.querySelector("#my-form");
      const data = new FormData(form);

      let uploadFile = await directus.uploadFiles(data, onUploadProgress);

      try {
        await directus.createItem('sessions_directus_files', {
          session: sessionData.id,
          directus_file: uploadFile.data.id
        });
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    //* If the edited session already has an uploaded file and the user deletes the file => then delete the file in directus in the 'sessions_directus_files' item
    if (delFileRef.current.length > 0) {
      await directus.deleteItems('sessions_directus_files', delFileRef.current);
    }

    //* if any options data is deleted, edited, or added while editing the session => then delete, edit, or add the options data from sessions_session_options on directus

    let OptionsData = [];
    let newData = [];
    let deleteData = [];
    optionsListRef.current.map((options) => {
      if (options.id.toString().length < 10) {
        if (options.type !== "4" && options.type !== "3" && options.type !== "7") {
          newData = [...newData,
          {
            session: sessionData.id,
            status: 'published',
            session_option: options.session_option.id,
            value: options.value === null ? options.session_option.input_type === 'checkbox' ? 'false' : options.session_option.input_type === 'none' ? 'null' : 0 : options.value
          }];
        }
      }
      else {
        if (options.type !== "4" && options.type !== "3" && options.type !== "7") {
          OptionsData = [...OptionsData,
          {
            id: options.id,
            session: sessionData.id,
            status: 'published',
            session_option: options.session_option.id,
            value: options.value === null ? options.session_option.input_type === 'checkbox' ? 'false' : options.session_option.input_type === 'none' ? 'null' : 0 : options.value
          }];
        }
        else {
          deleteData = [...deleteData, options.id];
        }
      }
    })

    if (newData.length > 0) {
      try {
        await directus.createItem("sessions_session_options", newData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (OptionsData.length > 0) {
      try {
        await directus.updateItems('sessions_session_options', OptionsData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    if (deleteData.length > 0) {
      try {
        await directus.deleteItems("sessions_session_options", deleteData);
      }
      catch (e) {
        enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
      }
    }

    props.loadSession(props.employeeIdChosen, props.timesheetIdChosen);
    props.editMode(props.sessionIdentify, false);
  }

  /**
   * TODO:
   * * 1. From the session data to be edited, collect the material resource data and enter the value into the materialData state, collect the equipment resource data and enter the value into the equipmentData state, and set the notes and break_time values ​​into the input state
   * * 2. Get all material and equipment resource data from directus and set that data to allEquipmentData and allMaterialData state
   * @param {object} sessionData Session data to be edited
   */
  const fillResourceData = async (sessionData) => {
    let countMaterial = 0;
    let countEquipment = 0;
    if (sessionData.resources) {
      sessionData.resources.map((resourceData) => {
        if (resourceData.resource) {
          if (resourceData.resource.type === 'chemical') {
            material[countMaterial] = { list_id: countMaterial, ...resourceData.resource, value: 0, resourceDataId: resourceData.id, quantity: resourceData.quantity }
            countMaterial = countMaterial + 1;
          }
          else if (resourceData.resource.type === 'equipment') {
            equipment[countEquipment] = { list_id: countEquipment, ...resourceData.resource, value: 0, resourceDataId: resourceData.id }
            countEquipment = countEquipment + 1;
          }
        }
        return null;
      })
    }
    setMaterialData(material);
    setEquipmentData(equipment);
    setInput({ notes: sessionData.notes, smoko: sessionData.break_time })

    try {
      var allEquipment = await directus.getItems('resources', {
        // fields: '*',
        fields: 'id, code, name',
        filter: {
          type: { eq: 'equipment' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllEquipmentData(allEquipment.data);

    try {
      var allMaterial = await directus.getItems('resources', {
        // fields: '*',
        fields: 'id, code, name',
        filter: {
          type: { eq: 'chemical' },
          status: { eq: 'published' }
        },
        limit: -1,
        sort: "name"
      });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    setAllMaterialData(allMaterial.data);
    return null;
  }

  /**
   * TODO: Add new data to the equipmentData array, so that it displays a new column for equipment input
   * * This function is executed when the user clicks the add equipment button
   * @param {*} equip
   */
  const addEquipment = (equip) => {
    if (equip === null || equip === []) {
      let data = [];
      data.list_id = equipmentData.length + 1;
      data.value = 2;
      setEquipmentData([...equipmentData, data]);
    }
    else {
      let var_list_id = equipmentData.length + 1;
      let var_value = 2;
      setEquipmentData([...equipmentData, { list_id: var_list_id, ...equip, value: var_value }]);
    }
  };

  /**
   * TODO: 
   * * if type = 2 it means that the equipment data has just been added and has not been saved to directus => then just remove the data from the equipmentData state
   * * but if the type is not 2, it means that the equipment data is data that was previously saved to directus => then set the data by changing the value = 3 in the equipmentData state, so that when submitting edits, the data will be processed to be deleted from directus
   * @param {*} equipData Equipment data to be deleted
   */
  const deleteEquipment = (equipData) => {
    if (equipData.value === 2) {
      setEquipmentData(equipmentData.filter((equip) => equip.list_id !== equipData.list_id));
    }
    else {
      setEquipmentData(equipmentData.map((equip) => equip.list_id === equipData.list_id ?
        { list_id: equipData.list_id, ...equipData, value: 3, resourceDataId: equipData.resourceDataId }
        : equip)
      );
    }
  };

  /**
   * TODO:
   * * if the edited equipment data has a value = 2, it means that the data has just been added => then add the latest type of equipment selected in the old equipment data in the equipmentData state by setting value = 2 so that when submitting edits, the data is created new in directus
   * * but if the type is not the same as 2, it means that the equipment data is data that was previously stored in Directus => then add the latest type of equipment selected in the old equipment data in the equipmentData state by setting value = 1 so that when submitting edit it will edit the old data on directus
   * @param {object} newData New equipment data selected
   * @param {object} oldData Old equipment data in the column that was clicked
   */
  const updateEquipment = (newData, oldData) => {
    setEquipmentData(
      equipmentData.map((equip) => (equip.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2 }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId }
        : equip)
      )
    );
  };

  /**
   * TODO: displays the equipment input fields, as many as set in the equipmentData state
   * @param {array} Data Equipment data that increases when the user clicks the add equipment button and decreases when the user clicks the delete button
   */
  const LoopEquipmentData = (Data) => {
    return (
      equipmentData && allEquipmentData ?
        Data.map((equipData, index) => {
          if (equipData.value !== 3) {
            return (
              <ListItem key={index}>
                <Autocomplete
                  key={index}
                  size="small"
                  className={classes.AutoEquipment}
                  options={allEquipmentData}
                  getOptionLabel={(option) => option.name ? option.name : ''}
                  id={`AutoComplete-equip-${index}`}
                  onChange={(event, newValue) => {
                    updateEquipment(newValue, equipData);
                  }}
                  disableClearable
                  // value={equipData.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" label={equipData.name !== null ? equipData.name : ''} />}
                />
                <ListItemSecondaryAction>
                  <RemoveCircleIcon
                    id="iconButton"
                    color="error"
                    style={{ fontSize: 20 }}
                    onClick={() => deleteEquipment(equipData)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          }
          else {
            return '';
          }
        })
        : ''
    );
  }
  
  /**
   * TODO: Add new data to the materialData array, so that it displays a new column for material input
   * * This function is executed when the user clicks the add material button
   * @param {*} material
   * @param {*} qty 
   */
  const addMaterial = (material, qty) => {
    if (material === null || material === []) {
      let data = [];
      data.list_id = materialData.length + 1;
      data.value = 2;
      data.quantity = 0;
      setMaterialData([...materialData, data]);
    }
    else {
      let var_quantity = qty;
      let var_list_id = materialData.length + 1;
      let var_value = 2;
      setMaterialData([...materialData, { list_id: var_list_id, ...material, value: var_value, quantity: var_quantity }]);
    }
  };

  /**
   * TODO: 
   * * if type = 2 it means that the material data has just been added and has not been saved to directus => then just remove the data from the materialData state
   * * but if the type is not 2, it means that the material data is data that was previously saved to directus => then set the data by changing the value = 3 in the materialData state, so that when submitting edits, the data will be processed to be deleted from directus
   * @param {*} chemiData Material data to be deleted
   */
  const deleteMaterial = (chemiData) => {
    if (chemiData.value === 2) {
      setMaterialData(materialData.filter((material) => material.list_id !== chemiData.list_id));
    }
    else {
      setMaterialData(materialData.map((material) => material.list_id === chemiData.list_id ?
        { list_id: chemiData.list_id, ...chemiData, value: 3, resourceDataId: chemiData.resourceDataId, quantity: chemiData.quantity }
        : material)
      );
    }
  };

  /**
   * TODO:
   * * if the edited material data has a value = 2, it means that the data has just been added => then add the latest type of material selected in the old material data in the materialData state by setting value = 2 so that when submitting edits, the data is created new in directus
   * * but if the type is not the same as 2, it means that the material data is data that was previously stored in Directus => then add the latest type of material selected in the old material data in the materialData state by setting value = 1 so that when submitting edit it will edit the old data on directus
   * @param {object} newData New material data selected
   * @param {object} oldData Old material data in the column that was clicked
   */
  const updateMaterial = (newData, oldData) => {
    setMaterialData(
      materialData.map((material) => (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { list_id: oldData.list_id, ...newData, value: 2, quantity: oldData.quantity }
          :
          { list_id: oldData.list_id, ...newData, value: 1, resourceDataId: oldData.resourceDataId, quantity: oldData.quantity }
        : material)
      )
    );
  };

  /**
   * TODO: changing the quantity value in the materialData state is the same as what the user typed in the quantity column
   * * This function is executed when the user changes the quantity in the material input column
   * @param {event} e 
   * @param {object} oldData Material data in the column that was clicked
   */
  const handleChange = (e, oldData) => {
    const { name, value } = e.target;
    setMaterialData(
      materialData.map((material) =>
      (material.list_id === oldData.list_id ?
        oldData.value === 2 ?
          { ...material, value: 2, [name]: value }
          :
          { ...material, value: 1, [name]: value }
        : material)
      )
    );
  }

  /**
   * * This function is executed when no file is selected
   * @returns Upload button for upload medical certificate
   */
  const uploadButton = () => {
    return (
      <UploadButton
        id="attach_file_btn"
        name="file"
        variant="contained"
        component="span"
        startIcon={<FileUploadIcon />}
        onChange={(e) => handleChangeUpload(e)}
        disabled={fileRef.current.length > 0}
      >
        {props.session.session_type.upload_description}
      </UploadButton>
    );
  }

  // TODO: Displays the name of the file that has been uploaded => if the session to be edited has a file that has been uploaded or if when editing the session the user selects a new file to upload
  const uploadList = () => {
    return (
      <>
        {props.session.attachments ?
          <>
            {props.session.attachments.map((file, index) => {
              return (
                file.directus_file !== null ?
                  delFileRef.current.length === 0 ?
                    <Grid item xs={12}>
                      <FileChip
                        icon={<InsertDriveFileIcon />}
                        label={file.directus_file ? file.directus_file.filename_download : ''}
                        onClick={(id) => handleClick(file.directus_file ? file.directus_file.data.full_url : '')}
                        onDelete={() => handleDelete(file.id)}
                        style={{ marginRight: '5px' }}
                      />
                    </Grid>
                    : ''
                  :
                  delFileRef.current.length === 0 ?
                    <Grid item xs={12}>
                      <FileChip
                        icon={<InsertDriveFileIcon />}
                        label={file.directus_file ? file.directus_file.filename_download : 'undefined file'}
                        onDelete={() => handleDelete(file.id)}
                        style={{ marginRight: '5px' }}
                      />
                    </Grid>
                    : ''
              );
            })}

            {fileRef.current.length > 0 ?
              fileRef.current.map((filedata, index) => {
                return (
                  <Grid item xs={12}>
                    <FileChip
                      icon={<InsertDriveFileIcon />}
                      label={filedata.name}
                      onDelete={() => handleDeleteFile(index)}
                      style={{ marginRight: '5px' }}
                    />
                  </Grid>
                );
              })
              : ''}
          </>
          : ''}
      </>
    );
  }

  /**
   * TODO: displays the material input fields, as many as set in the materialData state
   * @param {array} Data Material data that increases when the user clicks the add material button and decreases when the user clicks the delete button
   */
  const LoopMaterialData = (Data) => {
    return (
      Data.map((materialData, index) => {
        if (materialData.value !== 3) {
          return (
            <ListItem key={index}>
              <ListItemText primary={
                <Grid container spacing={0}>
                  <Grid item xs={9}>
                    <Autocomplete
                      key={index}
                      size="small"
                      className={classes.AutoEquipment}
                      options={allMaterialData}
                      getOptionLabel={(option) => option.name ? option.name : ''}
                      id={`AutoComplete-material-${index}`}
                      onChange={(event, newValue) => {
                        updateMaterial(newValue, materialData);
                      }}
                      disableClearable
                      renderInput={(params) => <TextField {...params} variant="outlined" label={materialData.name !== null ? materialData.name : ''} />}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField name="quantity" onChange={(event) => handleChange(event, materialData)} value={materialData.quantity} variant="outlined" label='Kg/L' size="small" type="number" step="any" />
                  </Grid>
                </Grid>
              } />
              <ListItemSecondaryAction>
                <RemoveCircleIcon
                  id="iconButton"
                  color="error"
                  style={{ fontSize: 20 }}
                  onClick={() => deleteMaterial(materialData)}
                />
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
        else {
          return '';
        }
      })
    );
  }

  // TODO: Handles input changes for break and note fields. When the user types greater than 20 in the break field (MFM), set the value of the break field to 20 in the input state. But if the input is less than 20 or when a change occurs in the notes field => set the value to be the same as what the user typed in the input state
  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    let smokoValue = null;
    if (name === 'smoko' && value > 20) {
      smokoValue = 20
    }

    if (smokoValue !== null) {
      setInput({ ...input, [name]: smokoValue });
    }
    else {
      setInput({ ...input, [name]: value });
    }
  }

  /**
   * TODO: set file state with data value of selected file to upload
   * @param {event} e 
   */
  const handleChangeUpload = (e) => {
    if (e.target.files.length > 0) {
      setFile([...fileRef.current, e.target.files[0]]);
    }
  }

  /**
   * * this function will be executed when the user clicks the x icon next to the file name after the user uploads the file for the medical certificate
   * TODO: set file state and value of file input to be empty
   * @param {number} index 
   */
  const handleDeleteFile = (index) => {
    setFile([]);
    document.getElementById('contained-button-file').value = "";
  }

  // TODO: see the progress of uploading files to directus when saving update session details
  function onUploadProgress(progressEvent) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    console.log(percentCompleted + "% Done");
  }

  /**
   * TODO: open uploaded file => if an edited session has uploaded the file
   * @param {link} id 
   */
  const handleClick = (id) => {
    window.open(id);
  };
  
  /**
   * * this function will be executed when the user clicks the x icon next to the file name => if the file is a file that was previously uploaded to the data session being edited
   * TODO: set the id from file in delFile state
   * @param {*} id 
   */
  const handleDelete = async (id) => {
    setDelFile([...delFileRef.current, id]);
  };

  /**
   * TODO: set sessionOptions state with options data in the session being edited
   * @param {object} sessions Session data to be edited
   * @param {number} condition Will have value = 1 if called from compareDate function
   */
  const loadNewSessionOptionData = async (sessions, condition = 0) => {
    setLoadingSession(true);
    let newData = [];
    let newInfo = [];
    let countSessionOptions = 0;

    //* If the session data has options and is not null, insert the options data into the session_options_list array and count how many session options it has in the countSessionOptions variable
    if (sessions.options) {
      sessions.options.map((session_options) => {
        if (session_options.session_option !== null) {
          session_options_list[countSessionOptions] = { ...session_options };
          countSessionOptions = countSessionOptions + 1;
        }
      })
    }

    let listId = [];
    let csaId = [];
    let awardId = [];
    let sessionData = [];
    var OptionData1, OptionData2;

    /**
     * TODO:
     * * 1. if the session type of the data session being edited has session options data and has customer data assigned to the employee of that session, then get session_options based on these two data from directus
     * * 2. if the session type of the data session being edited has session options data and the employee of the session being edited has an award, then get session_options based on these two data from directus
     * * 3. if points 1 and 2 are not null, combine the results and take only the different data and set their value to the newSessionOptions state. But if only point 1 is not null then set the value of point 1 into the newSessionOptions state, similarly if only point 2 is not null
     */
    try {
      if (sessions.session_type.session_options) {
        if (sessions.session_type.session_options.length > 0) {
          sessions.session_type.session_options.map((value) => {
            if (value.session_options_id !== null) {
              listId = [...listId, value.session_options_id];
            }
          })

          var EmployeeData = await directus.getItems('employees', {
            fields: 'customer_assigned_to.id,award.id',
            filter: {
              'id': { in: props.employeeIdChosen }
            },
            limit: -1,
          });
          
          if (EmployeeData.data.length > 0) {
            EmployeeData.data.map((data) => {
              if (data.customer_assigned_to) {
                if (data.customer_assigned_to.id !== null) {
                  csaId = [...csaId, data.customer_assigned_to.id];
                }
              }
              if (data.award) {
                if (data.award.id !== null) {
                  awardId = [...awardId, data.award.id];
                }
              }
            })
          }

          if (listId.length > 0 && csaId.length > 0) {
            OptionData1 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'customers.customers_id': { in: csaId }
              },
              limit: -1,
            });
          }
          else {
            OptionData1 = null;
          }

          if (listId.length > 0 && awardId.length > 0) {
            OptionData2 = await directus.getItems('session_options', {
              fields: 'awards.*,customers.*,input_type,description,id,default_value,name,only_overtime,after_overtime,only_rostered_days',
              filter: {
                'id': { in: listId },
                'awards.awards_id': { in: awardId }
              },
              limit: -1,
            });
          }
          else {
            OptionData2 = null;
          }

          if (OptionData1 && OptionData2) {
            sessionData = OptionData1.data.concat(OptionData2.data);

            var result = Enumerable.from(sessionData)
              .distinct(d => JSON.stringify(d))
              .toArray();

            const output = [...new Map(result.map(o => [o.id, o])).values()];

            setNewSessionOptions(output);
          }
          else if (OptionData1 === null && OptionData2) {
            setNewSessionOptions(OptionData2.data);
          }
          else if (OptionData1 && OptionData2 === null) {
            setNewSessionOptions(OptionData1.data);
          }
        }
        else {
          setNewSessionOptions([]);
        }
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    /**
     * TODO:
     * * 1. If the session data being edited does not have options data, then set the sessionOptions state with the value of the session_options_list variable, and if the condition parameter value = 0 then run the getSessionOptionsData function with the value of the session_options_list variable as the parameter
     * * 2. But if the session data being edited has options data, then compare the session data set in newSessionOptions and the session data set in the session_options_list variable, if there is different data, combine the data with the options data in the session_options_list variable to the newInfo variable and set sessionOptions state with that result. If condition = 0 then run the getSessionOptionsData function by passing newInfo as its parameter
     */
    if (newSessionOptionsRef.current.length > 0) {
      let count = 1;
      newSessionOptionsRef.current.map(data => {
        const filterInput = linq.from(session_options_list)
          .where(x => x.session_option.id === data.id).toArray();

        if (filterInput.length === 0) {
          newData = [...newData,
          {
            id: count,
            session_option:
            {
              id: data.id,
              after_overtime: data.after_overtime,
              description: data.description,
              default_value: data.default_value,
              input_type: data.input_type,
              name: data.name,
              only_overtime: data.only_overtime,
              only_rostered_days: data.only_rostered_days,
            },
            value: data.default_value
          }];
          count++;
        }
      })
      newInfo = session_options_list.concat(newData);
      setSessionOptions(newInfo);
      if (condition === 0) {
        getSessionOptionsData(newInfo);
      }
    }
    else {
      setSessionOptions(session_options_list);
      if (condition === 0) {
        getSessionOptionsData(session_options_list);
      }
    }
    setLoadingSession(false);
  }

  /**
   * TODO: 
   * * > Show session option with set type = 1, if it has a value of only_overtime = true and the overtime duration is met, and input_type != 'none'
   * * > Show session option with set type = 5, if it has only_overtime = false and input_type != 'none'
   * * > Besides that, set the type value in the session option to not display
   * @param {object} sessionOptions Session options data 
   * @param {time} editStartTime If while editing the session the user changes the start time of the session and that will fill in this parameter
   * @param {time} editEndTime If while editing the session the user changes the end time of the session and that will fill in this parameter
   */
  function getSessionOptionsData(sessionOption, editStartTime = null, editEndTime = null) {
    let result = [];

    sessionOption.map((data, index) => {
      if (data.session_option.only_rostered_days && ((rosterStartRef.current === 'Invalid date' && rosterEndRef.current === 'Invalid date') || (rosterStartRef.current === null && rosterEndRef.current === null))) {
        (result[index] = { type: '7', id: data.id, session_option: data.session_option, value: data.value })
      }
      else {
        if (data.session_option.only_overtime) {
          if ((ordinaryHoursRef.current + (data.session_option.after_overtime * 60)) <= getDuration(moment(editStartTime ? editStartTime : '1991-01-01 ' + sessionData.session_start_time).format("1991-01-01THH:mm:ss"), moment(editEndTime ? editEndTime : '1991-01-01 ' + sessionData.session_end_time).format("1991-01-01THH:mm:ss"))) {
            //* Check if there is overtime and check the type
            (data.session_option.input_type !== 'none' ?
              result[index] = { type: '1', id: data.id, session_option: data.session_option, value: data.value } //show
              : result[index] = { type: '2', id: data.id, session_option: data.session_option, value: data.value })
          }
          else {
            //* Check if duration of time is less than roster duration and check the type
            (data.session_option.input_type !== 'none' ?
              result[index] = { type: '3', id: data.id, session_option: data.session_option, value: data.value }
              : result[index] = { type: '4', id: data.id, session_option: data.session_option, value: data.value })
          }
        }
        else {
          //* Check if the only overtime is false and check the type
          (data.session_option.input_type !== 'none' ?
            result[index] = { type: '5', id: data.id, session_option: data.session_option, value: data.value } //show
            : result[index] = { type: '6', id: data.id, session_option: data.session_option, value: data.value })
        }
      }
    })
    setOptionsList(result);
  }

  /**
   * TODO:
   * * 1. get roster data from directus based on selected employee id and based on date obtained from session parameter
   * * 2. if there is roster data obtained from directus and the session type of the edited session start_end_time_from_roster = true => then set rosterStart, rosterEnd, rosterBreakStart, rosterBreakEnd, and ordinaryHours according to that data
   * * 3. but if no roster data is obtained or the session type of the edited session start_end_time_from_roster = false => then set rosterStart, rosterEnd, rosterBreakStart, rosterBreakEnd, and ordinaryHours  to null
   * @param {date} session Session date
   */
  const rosterData = async (session) => {
    try {
      var rosterList = await directus.getItems('roster',
        {
          fields: 'break, day, start_time, end_time, ordinary_hours',
          filter: {
            employee: { eq: props.employeeIdChosen },
            day: { eq: moment(session).format('dddd').toLowerCase() },
            status: { eq: 'published' },
          }
        });
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }

    if (rosterList.data.length > 0 && props.session.session_type.start_end_time_from_roster) {
      rosterList.data.map((data) => {
        let end_time = '';
        if (!sessionData.session_type.leave) {
          end_time = data.end_time;
        }
        else {
          end_time = moment('1/1/1991 ' + data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");
        }

        // let end_time = '';
        // end_time = moment('1/1/1991 '+data.start_time).add(data.ordinary_hours, 'hours').add(data.break, 'minutes').format("HH:mm:ss");

        setRosterStart(moment('1/1/1991 ' + data.start_time).format());
        setRosterEnd(moment('1/1/1991 ' + end_time).format());
        setRosterBreakStart(getMiddleStartEndTime(data.start_time, end_time, data.break).start_time.format());
        setRosterBreakEnd(getMiddleStartEndTime(data.start_time, end_time, data.break).end_time.format());
        setOrdinaryHours(data.ordinary_hours * 60);

      });
    }
    else {
      setRosterStart(null);
      setRosterEnd(null);
      setRosterBreakStart(null);
      setRosterBreakEnd(null);
      setOrdinaryHours(null);
    }
  }

  /**
   * TODO: determine the start and end for break time in the middle between the start_time and end_time roster according to the break value of the roster
   * @param {time} a start time roster
   * @param {time} b end time roster
   * @param {number} c break roster
   */
  function getMiddleStartEndTime(a, b, c) {
    var basedate = '1/1/1991 ';
    var momentStart = moment(basedate + a);
    var momentEnd = moment(basedate + b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes() / 2 - (c / 2);
    var start = moment(basedate + a).add(diff, 'minutes').format();
    var end = moment(start).add(c, 'minutes').format();

    return { start_time: moment(start), end_time: moment(end) };
  }

  /**
   * TODO: calculates the time duration from param a to b and returns the total minutes of that duration
   * @param {time} a start
   * @param {time} b end
   */
  function getDuration(a, b) {
    var momentStart = moment(a);
    var momentEnd = moment(b);
    var duration = moment.duration(momentEnd.diff(momentStart));
    var diff = duration.asMinutes();

    return diff;
  }

  /**
   * TODO: handle changes that occur in the session option by set optionList value that is ticked or unticked or typed in the input column based on its id
   * @param {event} event 
   * @param {*} id id of typed or checked or unchecked session options
   */
  const handleChangeSessionOptions = (event, id) => {
    const { name, value } = event.target;
    setOptionsList(
      optionsListRef.current.map((data) =>
      (id === data.session_option.id ?
        name === 'number' ?
          { ...data, value: value }
          :
          { ...data, value: event.target.checked === true ? 'true' : 'false' }
        : data)
      )
    );
  };
  
  //* if no customer (GTNT)
  if (props.session.session_type.show_customer === false) {
    return (
      <>
        <Card className={classes.cardDetail} elevation={0}>
          <CardContent className={classes.cardDetailContent}>
            {/* ==================Sub Content========================================== */}
            <List className={classes.listStyle}>
              <ListItem>
                <ListItemText primary={
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item xs={8}></Grid>
                    <Grid item xs={4}>
                      <Typography>Notes</Typography>
                      <TextField
                        className={classes.notes}
                        name="notes"
                        onChange={handleChangeInput}
                        value={input.notes}
                        variant="outlined"
                        label='Notes'
                        rows={4}
                        multiline
                      />
                    </Grid>
                  </Grid>
                } />
              </ListItem>
            </List>
          </CardContent>
        </Card>

        {props.session.session_type.upload_description ?
          <Card className={classes.cardDetail} elevation={0}>
            <CardContent className={classes.cardDetailFileContent}>
              <List>
                <ListItem>
                  <Grid container spacing={2} justifyContent="space-arround">
                    {loadingFile ?
                      <span display="inline-flex"><CircularProgress size={20} /></span>
                      :
                      <form id="my-form" className={classes.form}>
                        <Grid item xs={12}>
                          <input type="file" name="file"
                            onChange={(e) => handleChangeUpload(e)}
                            id="contained-button-file"
                            className={classes.input}
                          />
                          <label htmlFor="contained-button-file">
                            {props.session.attachments.length > 0 ?
                              delFileRef.current.length > 0 && fileRef.current.length === 0 ?
                                uploadButton()
                                : ''
                              :
                              fileRef.current.length === 0 ?
                                uploadButton()
                                : ''
                            }
                          </label>
                        </Grid>
                      </form>
                    }
                    {uploadList()}
                  </Grid>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          : ''
        }

        {props.session.options.length > 0 ?
          optionsListRef.current ?
            loadingSession ?
              <Card className={classes.cardDetail} elevation={0}>
                <CardContent className={classes.cardDetailSessionOptionsContent}>
                  <List>
                    <LinearProgress sx={{ m: '10px' }} />
                  </List>
                </CardContent>
              </Card>
              :
              <>
                <Card className={classes.cardDetail} elevation={0}>
                  <CardContent className={classes.cardDetailSessionOptionsContent}>
                    <ListCustom disablePadding={true}>
                      {optionsListRef.current.map((optionlist, index) => {
                        return (
                          optionlist.type === "1" || optionlist.type === "5" ?
                            <>
                              <ListItem>
                                <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">
                                  <Grid item xs={10}>
                                    <Typography variant="h6">{optionlist.session_option.description}</Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Grid container spacing={0} justifyContent="center" alignItems="center">
                                      <Typography variant="h6">
                                        {optionlist.session_option.input_type === "checkbox" ?
                                          optionlist.value === "true" ?
                                            <Checkbox
                                              id="checkbox"
                                              name="checkbox"
                                              sx={{
                                                "&.Mui-checked": {
                                                  color: "#05a0af"
                                                }
                                              }}
                                              defaultChecked
                                              onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                            />
                                            :
                                            <Checkbox
                                              id="checkbox"
                                              name="checkbox"
                                              sx={{
                                                "&.Mui-checked": {
                                                  color: "#05a0af"
                                                }
                                              }}
                                              onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                            />
                                          :
                                          <TextField
                                            id="outlined-number"
                                            label="Number"
                                            name="number"
                                            type="number"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={optionlist.value ? optionlist.value : 0}
                                            onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                          />
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <Divider />
                            </>
                            : '');
                      })
                      }
                    </ListCustom>
                  </CardContent>
                </Card>
              </>
            : ''
          : ''
        }
      </>
    );
  }
  else {
    //* if with customer (MFM)
    return (
      <>
        <Card className={classes.cardDetail} elevation={0}>
          <CardContent className={classes.cardDetailContent}>
            {/* ==================Sub Content========================================== */}
            <List className={classes.listStyle}>
              <ListItem>
                <ListItemText primary={
                  <Grid container spacing={3} justifyContent="space-between">
                    <Grid item xs={4}>
                      {allMaterialData ?
                        <AddMaterial
                          addMaterial={addMaterial}
                          allMaterialData={allMaterialData}
                        />
                        : ''}
                      <Card className={classes.cardSubDetail}>
                        <CardContent className={classes.cardSubDetailContent}>
                          <List dense>
                            {LoopMaterialData(materialData)}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      {allEquipmentData ?
                        <AddEquipment
                          addEquipment={addEquipment}
                          allEquipmentData={allEquipmentData}
                        />
                        : ''}
                      <Card className={classes.cardSubDetail}>
                        <CardContent className={classes.cardSubDetailContent}>
                          <List dense>
                            {LoopEquipmentData(equipmentData)}
                          </List>
                        </CardContent>
                      </Card>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography>Notes</Typography>
                      <TextField
                        id="edit_notes"
                        className={classes.notes}
                        name="notes"
                        onChange={handleChangeInput}
                        value={input.notes}
                        variant="outlined"
                        label='Notes'
                        rows={4}
                        multiline
                      />
                    </Grid>
                  </Grid>
                } />
              </ListItem>
            </List>
          </CardContent>
        </Card>
        {props.session.session_type.upload_description ?
          <Card className={classes.cardDetail} elevation={0}>
            <CardContent className={classes.cardDetailFileContent}>
              <List>
                <ListItem>
                  <Grid container spacing={2} justifyContent="space-arround">
                    {loadingFile ?
                      <span display="inline-flex"><CircularProgress size={20} /></span>
                      :
                      <form id="my-form" className={classes.form}>
                        <Grid item xs={12}>
                          <input type="file" name="file"
                            onChange={(e) => handleChangeUpload(e)}
                            id="contained-button-file"
                            className={classes.input}
                          />
                          <label htmlFor="contained-button-file">
                            {props.session.attachments.length > 0 ?
                              delFileRef.current.length > 0 && fileRef.current.length === 0 ?
                                uploadButton()
                                : ''
                              :
                              fileRef.current.length === 0 ?
                                uploadButton()
                                : ''
                            }
                          </label>
                        </Grid>
                      </form>
                    }
                    {uploadList()}
                  </Grid>
                </ListItem>
              </List>
            </CardContent>
          </Card>
          : ''
        }

        {props.session.options.length > 0 ?
          optionsListRef.current ?
            loadingSession ?
              <Card className={classes.cardDetail} elevation={0}>
                <CardContent className={classes.cardDetailSessionOptionsContent}>
                  <List>
                    <LinearProgress sx={{ m: '10px' }} />
                  </List>
                </CardContent>
              </Card>
              :
              <>
                <Card className={classes.cardDetail} elevation={0}>
                  <CardContent className={classes.cardDetailSessionOptionsContent}>
                    <ListCustom disablePadding={true}>
                      {optionsListRef.current.map((optionlist, index) => {
                        return (
                          optionlist.type === "1" || optionlist.type === "5" ?
                            <>
                              <ListItem>
                                <Grid container spacing={0} justifyContent="space-evenly" alignItems="center">
                                  <Grid item xs={10}>
                                    <Typography variant="h6">{optionlist.session_option.description}</Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <Grid container spacing={0} justifyContent="center" alignItems="center">
                                      <Typography variant="h6">
                                        {optionlist.session_option.input_type === "checkbox" ?
                                          optionlist.value === "true" ?
                                            <Checkbox
                                              id="checkbox"
                                              name="checkbox"
                                              sx={{
                                                "&.Mui-checked": {
                                                  color: "#05a0af"
                                                }
                                              }}
                                              defaultChecked
                                              onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                            />
                                            :
                                            <Checkbox
                                              id="checkbox"
                                              name="checkbox"
                                              sx={{
                                                "&.Mui-checked": {
                                                  color: "#05a0af"
                                                }
                                              }}
                                              onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                            />
                                          :
                                          <TextField
                                            id="outlined-number"
                                            label="Number"
                                            name="number"
                                            type="number"
                                            InputLabelProps={{
                                              shrink: true,
                                            }}
                                            variant="outlined"
                                            size="small"
                                            value={optionlist.value ? optionlist.value : 0}
                                            onChange={(e) => handleChangeSessionOptions(e, optionlist.session_option.id)}
                                          />
                                        }
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </ListItem>
                              <Divider />
                            </>
                            : '');
                      })
                      }
                    </ListCustom>
                  </CardContent>
                </Card>
              </>
            : ''
          : ''
        }
      </>
    );
  }
});

export default SessionListDetails;
