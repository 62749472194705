import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import NavItem from './NavItem';
import EventEmitter from 'src/utils/EventEmitter';
import { items } from 'src/utils/Menu';
import { AdminPermission, EmployeeLeavePermission } from 'src/utils/Permission';
import directus from '../../../services/directus';
import { loadSessions } from '../../../Controller/LeaveRequests.js';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import useStateRef from "react-usestateref";
import { useSnackbar } from 'notistack';
import ErrorMessage from '../../../../src/views/Components/ErrorMessage';

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 254,
    top: '90px',
    backgroundColor: '#f2f2f6',
    borderRight: '0px'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  List: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '0 0 0 0'
  },
  box: {
    padding: '0 0 0 26px',
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  /* eslint-disable no-unused-vars*/
  const classes = useStyles();
  const location = useLocation();
  const [initialized, setInitialized] = React.useState(false);

  const [sessions, setSessions] = React.useState(null);
  const [summary, setSummary] = React.useState('');
  const [startDate, setStartDate, startDateRef] = useStateRef('');

  const [endDate, setEndDate, endDateRef] = useStateRef('');
  const { enqueueSnackbar } = useSnackbar();

  const [timesheetStatusType, setTimesheetStatusType] = React.useState([]);
  /* eslint-enable no-unused-vars*/

  /* eslint-disable react-hooks/exhaustive-deps*/
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }

    // eslint-disable-next-line array-callback-return
    items().map((item, index) => {
      if (item.href === '/' + window.location.pathname.split('/')[1]) {
        handleListItemClick(index, item.number, item.href, item.title, item.features)
      }
    })

    if (!initialized) {
      setInitialized(true);
      if (AdminPermission() || EmployeeLeavePermission()) {
        callSessions();
      }
    }

    const summaryDate = (eventData) => {
      setStartDate(eventData.startdate);
      setEndDate(eventData.enddate);
      statusSummaryLoad();

    }

    const listener = EventEmitter.addListener('summaryDate', summaryDate);

    const reloadLeaveRequests = async () => {
      callSessions();
    }

    const reloadLeaveRequestsListener = EventEmitter.addListener('reloadMenuLeaveRequests', reloadLeaveRequests);

    if (location.pathname === "/approvals" || location.pathname === "/") {
      statusSummaryTypeLoad();
    }

    const reloadChangeStatus = async () => {
      statusSummaryLoad();
    }

    const listener2 = EventEmitter.addListener('reloadChangeStatus', reloadChangeStatus);

    return () => {
      listener.remove();
      reloadLeaveRequestsListener.remove();
      listener2.remove();
    }
  }, [location.pathname]);
  /* eslint-enable react-hooks/exhaustive-deps*/

  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const handleListItemClick = (event, index, href, title, features) => {
    setSelectedIndex(index);
    if (AdminPermission() || EmployeeLeavePermission()) {
      callSessions();
    }
    EventEmitter.emit('topbar', {
      text: title,
      href: href,
      features: features
    })
  };

  const statusSummaryLoad = async () => {

    try {
      var ApprovalRecord = await directus.api.get('/custom/approvals/summary', {
        fields: '*',
        filter: {
          start_time: { gte: startDateRef.current },
          end_time: { lte: endDateRef.current }
        },
      });
      setSummary(ApprovalRecord.data);
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  //* if the logged-in is an admin, only get session data that has been 'approved', but if the logged-in is not an admin, only get session data that is 'pending' or has not been approved. Then set the value to the session state
  const callSessions = async () => {
    setSessions(await loadSessions('null', AdminPermission() ? 'approved' : 'pending'), 1);
  }

  const statusSummaryTypeLoad = async () => {

    try {
      var timesheet_data = await directus.getField("timesheets", "status");
      const timesheet_status = Object.entries(timesheet_data.data.options.status_mapping);

      var timesheet_list = [];
      if (timesheet_status.length > 0) {
        //eslint-disable-next-line array-callback-return
        timesheet_status.map((value) => {
          var background_color = "";
          var name = value[1].name;
          var text_color = "#000000de";

          if (value[1].value === "approved") {
            background_color = "#DAF0DC";
            name = "Approved";
          } else if (value[1].value === "rejected") {
            background_color = "#FFDBD9";
            name = "Rejected";
          } else if (value[1].value === "pending") {
            background_color = "#D4E3F2";
            name = "Not Yet Submitted";
          } else if (value[1].value === "awaiting_approval") {
            background_color = "#FFF0DD";
            name = "Awaiting Approvals";
          } else if (value[1].value === "sent") {
            background_color = "deepskyblue";
            name = "Sent to Payroll";
          } else if (value[1].value === "paid") {
            background_color = "#acebac";
            name = "Paid"
            } else if (value[1].value === "deleted") {
              background_color = "#ff0000";
              name = "Deleted"
          } else {
            background_color = "#ffffff";
            name = '';
          }
          timesheet_list.push({
            value: value[1].value,
            name: name,
            background_color: background_color,
            text_color: text_color
          })
        })
        setTimesheetStatusType(timesheet_list);
      }
    }
    catch (e) {
      enqueueSnackbar(ErrorMessage(e), { anchorOrigin: { vertical: 'top', horizontal: 'right' }, variant: 'error', autoHideDuration: 3200 });
    }
  }

  const content = (
    <Box height="100%" display="flex" flexDirection="column" id="navitem_mobile" >
      <Box p={2}>
        <List>
          {items().map((item, index) => (
            <NavItem
              id={`mobile_nav_item-${item.title.replace(/\s/g, '')}`}
              href={item.href}
              key={index}
              title={item.title}
              icon={item.icon}
              number={item.number}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              listStyle={item.listStyle}
              features={item.features}
              leave_Count={sessions ? sessions.length : 0}
            />
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  );

  const desktopContent = (
    <Box display="flex" flexDirection="column" id="navitem_desktop">
      <Box p={2} className={classes.box}>
        <List className={classes.List}>
          {items().map((item, index) => (
            <NavItem
              href={item.href}
              key={index}
              title={item.title}
              icon={item.icon}
              number={item.number}
              selectedIndex={selectedIndex}
              handleListItemClick={handleListItemClick}
              listStyle={item.listStyle}
              dividerValue={item.dividerValue}
              leave_Count={sessions ? sessions.length : 0}
            />
          ))}
        </List>
      </Box>
    </Box>
  );


  const statusSummary = (
    <Box display="flex" flexDirection="column" style={{ marginTop: '30px' }} id="status_summary">
      <Box p={2} className={classes.box}>
        <List className={classes.List}>
          <ListItem>
            <ListItemText style={{ fontSize: '16px' }}> <b>Status Summary</b> </ListItemText>
          </ListItem>
          <Divider />
          {timesheetStatusType.map((value, index) => (
            <>
              {/* {value.name !== 'Deleted' ? */}
                <ListItem key={index} style={{ fontSize: '16px', fontFamily: 'Montserrat' }}>
                  {value.name}
                  <ListItemText> <Chip id={`${value.value}-chip`} label={<b>{summary[value.value] ? summary[value.value] : 0}</b>} size="small" style={{ float: 'right', backgroundColor: value.background_color, color: value.text_color }} /></ListItemText>
                </ListItem>
                {/* : ''
              }  */}
              </>
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {desktopContent}
          {window.location.pathname === '/approvals' || window.location.pathname === '/' ?
            statusSummary
            :
            null
          }

        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default NavBar;
